import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import { fetchTeamDetails } from "../../../store/actions/organization/teamActions";
import Badge from '../../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';

interface TeamDetailParams {
  teamId: string;
}

const TeamDetail: React.FC = () => {
  const { teamId } = useParams<TeamDetailParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();

  const team = useSelector((state: RootState) =>
    state.teams.teams.find((t) => t.LegalTeamID === teamId)
  );

  useEffect(() => {
    if (!team) {
      dispatch(fetchTeamDetails(teamId));
    }
  }, [dispatch, teamId, team]);

  useEffect(() => {
    if (!team) {
      dispatch(fetchTeamDetails(teamId));
    }
  }, [dispatch, teamId, team]);

  useEffect(() => {
    if (team) {
      setBreadcrumbs([
        { name: 'Management', href: '/management', current: false },
        { name: 'Teams', href: '/management', current: false },
        { name: team.TeamName, href: `/management/teams/${teamId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [team, teamId, setBreadcrumbs]);

  if (!team) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded shadow-md">
      {/* Team Header */}
      <div className="mb-4">
        <h2 className="text-3xl font-bold text-gray-600">{team.TeamName}</h2>
        <p className="text-sm text-gray-500">{`Created on: ${new Date(team.CreationDate).toLocaleDateString()}`}</p>
      </div>

      {/* Team Lead */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Team Lead</h3>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          {`${team.TeamLeadFirstName} ${team.TeamLeadLastName} (${team.TeamLeadEmail})`}
        </p>
      </div>

      {/* Legal Subjects */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Legal Subjects</h3>
        <div className="flex flex-wrap gap-2 mt-2">
          {team.LegalSubjects.map((subject: any) => (
            <Badge key={subject.LegalSubjectID} color="blue">
              {subject.SubCategory}
            </Badge>
          ))}
        </div>
      </div>

      {/* Team Members */}
      <div>
        <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Team Members</h3>
        <ul className="mt-2 space-y-2">
          {team.Members.map((member: any) => (
            <li key={member.MemberID} className="p-4 border rounded-md shadow-sm">
              <p className="text-lg font-medium text-gray-800 dark:text-gray-200">
                {member.FirstName} {member.LastName}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400">{member.Email}</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">{member.PhoneNumber}</p>
              <Badge color="green">{member.MemberRole}</Badge>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TeamDetail;
