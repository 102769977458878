
import { useTranslation } from "react-i18next";
import GetReports from "../components/Reports/GetReports";
import { config } from "../utils/config";

function ReportsPage() {
    const { t } = useTranslation();
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');
    
    return (
        <>
        {!isDemoEnvironment ? (
            <GetReports /> 
        ) : (
            <div style={{ height: 'calc(100vh - 120px)' }} className="non-access">{t('Coming in Q4')}</div>
        )} 
        </>
    )
}

export default ReportsPage;