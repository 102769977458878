import React, { useState, useEffect, useRef, useMemo, ChangeEvent } from 'react';
import './MessageCenter.css';
import dayjs from 'dayjs';
import { useSwipeable } from 'react-swipeable';
import { getUserID, getUserType } from '../../../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../../shared/OutsideClick';
import { ChatImages } from '../../../Marketplace/Client/ChatInterface';
import { Form } from 'formik';
import { ClipLoader } from 'react-spinners';
import { deleteMesageChat, fetchCHatList } from '../../../../store/actions/DataAction';
import { RootState, useAppDispatch } from '../../../../store';
import { useSelector } from 'react-redux';
import Button from '../../../shared/TailwindComponents/Button';
import Input from '../../../shared/TailwindComponents/Input';
import AttachmentPreview from './AttachmentPreview';
import SocketServices from '../../../../utils/SocketServices';

interface Message {
  id: string,
  sender: string;
  content: string;
  timestamp: Date;
}

interface ChatWindowProps {
  messages: Message[];
  selectedChat: any;
  deadline?: string;
  jurisdiction?: string;
  price?: string;
  onSendMessage: (message: string, attachments: any) => void;
  chatId: number | string;
  isLoading: boolean;
}


// export const formatTime = (timestamp: any) => {
//   const date = new Date(timestamp);
//   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }); // Customize the time format as needed
// };

export const formatTime = (timestamp: any) => {
  const date = new Date(timestamp);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Gets the user's local timezone

  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timeZone, // Uses user's local timezone
  });
};

const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  selectedChat,
  onSendMessage,
  isLoading,
  chatId,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const userType = getUserType()
  const { t } = useTranslation()
  const [openOptions, setOpenOptions] = useState(false);
  const dispatch = useAppDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  console.log(selectedChat, userInfo);
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef<any>(null);
  const userID = getUserID();
  const renderedMessageIdsRef = useRef<Set<string>>(new Set());
  const [attachments, setAttachments] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);

  // Extract user and companyRole safely
  const user = useMemo(() => {
    if (selectedChat?.userData?.User) {
      return selectedChat.userData.User;
    } else if (selectedChat?.User) {
      return selectedChat.User;
    } else if (selectedChat?.userData) {
      return selectedChat.userData;
    } else if (selectedChat) {
      return selectedChat;
    } else {
      return null;
    }
  }, [selectedChat]);

  const companyRole =
    selectedChat?.userData?.CompanyRole || selectedChat?.CompanyRole || '';

  const firstName = user?.FirstName || '';
  const lastName = user?.LastName || '';


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    SocketServices.on('user_typing', (data: any) => {
      if (data.chatId === chatId && data.userId !== userID) {
        setIsTyping(true);
      }
    });

    SocketServices.on('user_stop_typing', (data: any) => {
      if (data.chatId === chatId && data.userId !== userID) {
        setIsTyping(false);
      }
    });

    return () => {
      SocketServices.removeListener('user_typing');
      SocketServices.removeListener('user_stop_typing');
    };
  }, [chatId, userID]);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '' || attachments.length > 0 || images.length > 0) {
      const currentTime = new Date();
      console.log(images, attachments, "kjsdkskd");
      
      const messageAttachments = images.length > 0 ? images : attachments;
      onSendMessage(newMessage, messageAttachments);
      setNewMessage('');
      setImages([]);
      setAttachments([]);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
    SocketServices.emit('typing', { chatId: chatId, userId: userID });

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      SocketServices.emit('stop_typing', { chatId: chatId, userId: userID });
    }, 2000);
  };

  function normalizeToMidnight(date: Date, timeZone: string): Date {
    const localizedDate = new Date(date.toLocaleString('en-US', { timeZone }));
    localizedDate.setHours(0, 0, 0, 0);
    return localizedDate;
  }
  function formatDate(timestamp: Date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date: any = new Date(timestamp);
    const now: any = new Date();

    const dateInTimeZone = normalizeToMidnight(date, timeZone);
    const nowInTimeZone = normalizeToMidnight(now, timeZone);

    const differenceInDays = Math.floor(
      (nowInTimeZone.getTime() - dateInTimeZone.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays === 0) {
      return 'Today';
    } else if (differenceInDays === 1) {
      return 'Yesterday';
    } else if (differenceInDays <= 3) {
      const options: Intl.DateTimeFormatOptions = { weekday: 'long', timeZone };
      return dateInTimeZone.toLocaleDateString('en-GB', options);
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', timeZone };
      return `${dateInTimeZone.toLocaleDateString('en-GB', options)}, ${dateInTimeZone.getFullYear()}`;
    }
  }

  const groupedMessages = useMemo(() => {
    const groups: { [date: string]: any[] } = {};
    messages.forEach((msg: any) => {
      const date = formatDate(msg.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
    });
    return groups;
  }, [messages]);





  const Message: React.FC<{ msg: any; userID: string, shouldAnimate: boolean }> = ({ msg, userID, shouldAnimate }) => {
    // console.log(msg, "msg")
    const [isSwiping, setIsSwiping] = useState(false);
    const [swipeDirection, setSwipeDirection] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const messageLimit = 100;
    const [duration, setDuration] = useState("0s")
    const isSender = msg.sender === userID;
    useEffect(() => {
      if (msg.Type === "Call") {
        const start = dayjs(msg.callStartedAt);
        const end = dayjs(msg.callEndedAt);

        const callDuration = dayjs.duration(end.diff(start));
        let formattedDuration = "";
        if (callDuration.hours() > 0) {
          formattedDuration += `${callDuration.hours()}h `;
        }
        if (callDuration.minutes() > 0) {
          formattedDuration += `${callDuration.minutes()}m `;
        }
        formattedDuration += `${callDuration.seconds()}s`;

        setDuration(formattedDuration);
      }
    }, [msg.Type, msg.callStartedAt, msg.callEndedAt]);


    const handlers = useSwipeable({
      onSwipedLeft: () => {
        setSwipeDirection('left');
        setIsSwiping(true);
        // setMessageReply(msg)

      },
      onSwipedRight: () => {
        // setSwipeDirection('right');
        // setIsSwiping(true);
        // setMessageReply(msg)
      },
      onSwiping: (eventData) => {
        setSwipeDirection(eventData.dir);
        setIsSwiping(true);
      },
      onSwiped: () => {
        setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
      },
      delta: 10,
      preventScrollOnSwipe: false,
      trackMouse: true,
    });

    useEffect(() => {
      if (isSwiping) {
        const timer = setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [isSwiping]);
    const messageClass = msg.sender && msg.sender ? 'self' : 'other'

    return (
      <div
        key={msg.id}
        className={`flex mb-2 ${isSender ? 'justify-end' : 'justify-start'} ${shouldAnimate ? 'animate-fade-in-up' : ''
          }`}
      >
        <div
          className={`max-w-xs md:max-w-md px-4 py-2 rounded-lg ${isSender
              ? 'bg-indigo-600 text-white'
              : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white'
            }`}
        >
          {/* Attachments */}
          {msg.attachments && msg.attachments.length > 0 && (
            <div className="mb-2">
           
              <AttachmentPreview attachments={msg.attachments} />
            </div>
          )}

          {/* Message Content */}
          <div>
            {isExpanded || msg.content.length <= messageLimit
              ? msg.content
              : `${msg.content.substring(0, messageLimit)}...`}
            {!isExpanded && msg.content.length > messageLimit && (
              <span
                className="text-sm text-blue-500 cursor-pointer"
                onClick={() => setIsExpanded(true)}
              >
                {t('Read more')}
              </span>
            )}
            {isExpanded && (
              <span
                className="text-sm text-blue-500 cursor-pointer"
                onClick={() => setIsExpanded(false)}
              >
                {t('Read less')}
              </span>
            )}
          </div>
          {/* Timestamp */}
          <div className="text-xs text-right mt-1">
            {formatTime(msg.timestamp)}
          </div>
        </div>
      </div>
    );
  };

  const handleOptionClick = () => {
    setOpenOptions(!openOptions)
  }

  const folderOptionsRef = useOutsideClick(() => {
    setOpenOptions(false)
  });


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // if (event.target.files) {
    //   const filesArray = Array.from(event.target.files);
    //   filesArray.forEach((file) => {
    //     const attachment: any = {
    //       file: file,
    //       fileName: file.name,
    //       fileType: file.type,
    //       fileSize: file.size,
    //       // fileUrl: URL.createObjectURL(file),
    //     };

    //     if (file.type.startsWith('image/')) {
    //       setImages((prevImages) => [...prevImages, attachment]);
    //     } else {
    //       setAttachments((prevAttachments) => [...prevAttachments, attachment]);
    //     }
    //   });
    // }

    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const newImages: any[] = [];
      const newAttachments: any[] = [];
    
      filesArray.forEach((file) => {
        if (file.type.startsWith('image/')) {
          newImages.push(file);
        } else {
          newAttachments.push(file);
        }
      });
    
      setImages((prevImages) => [...prevImages, ...newImages]);
      setAttachments((prevAttachments) => [...prevAttachments, ...newAttachments]);
    }
  };



  const handleRemoveAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };


  const handleDeleteChat = async (chatId: string) => {
    setIsDeleting(true)
    if (chatId) {
      const resp: any = await dispatch(deleteMesageChat(chatId))

      if (resp === 200) {
        await dispatch(fetchCHatList())
        setIsDeleting(true)
      }
    }
  }


  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-800">
        <div className="flex items-center">
          {user?.ProfilePicture ? (
            <img
              src={user.ProfilePicture}
              alt="Profile"
              className="h-10 w-10 rounded-full mr-3"
            />
          ) : (
            <div className="h-10 w-10 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center mr-3">
              <span className="text-white">
                {firstName.charAt(0)}
                {lastName.charAt(0)}
              </span>
            </div>
          )}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {
                selectedChat?.userData ?  selectedChat?.userData.UserUID === userInfo.UserUID  ? "You" : `${firstName} ${lastName}` : selectedChat?.User.UserUID === userInfo.UserUID ? "You" : `${firstName} ${lastName}`
              }
             
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {companyRole || ' '}
            </p>
          </div>
        </div>
        <div className="relative">
          <Button variant="neutral" onClick={() => setOpenOptions(!openOptions)}>
            <i className="fa-regular fa-ellipsis-v"></i>
          </Button>
          {openOptions && (
            <div
              className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 shadow-lg rounded-md py-1"
              ref={folderOptionsRef}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                variant="destructive"
                className="w-full text-left"
                onClick={() => handleDeleteChat(selectedChat.chatID)}
              >
                <i className="fa-sharp fa-solid fa-trash mr-2"></i>
                {t('Delete Chat')}
                {isDeleting && <ClipLoader size={10} color="#fff" />}
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Messages */}
      {isLoading ? (
        <div className="flex flex-grow justify-center items-center">
          <ClipLoader size={20} color="#000" />
        </div>
      ) : (
        <div className="flex-grow overflow-y-auto p-4">
          {Object.keys(groupedMessages).map((date) => (
            <React.Fragment key={date}>
              <div className="text-center text-gray-500 dark:text-gray-400 my-2">{date}</div>
              {groupedMessages[date].map((msg: any) => {
                const shouldAnimate = !renderedMessageIdsRef.current.has(msg.id);
                // Add the message ID to the set
                renderedMessageIdsRef.current.add(msg.id);
                return (
                  <Message
                    key={msg.id}
                    msg={msg}
                    userID={userInfo.UserUID}
                    shouldAnimate={shouldAnimate}
                  />
                );
              })}
            </React.Fragment>
          ))}
          <div ref={messagesEndRef} />
        </div>
      )}
      {isTyping && (
        <div className="flex items-center mb-2">
          <div className="h-8 w-8 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center mr-2">
            <span className="text-white">
              {firstName.charAt(0)}
              {lastName.charAt(0)}
            </span>
          </div>
          <div className="bg-gray-200 dark:bg-gray-700 px-4 py-2 rounded-lg">
            <div className="animate-pulse text-sm text-gray-500 dark:text-gray-400">
              {t('Typing...')}
            </div>
          </div>
        </div>
      )}

      {/* Input Area */}
      <div className="p-4 bg-gray-100 dark:bg-gray-800">
        {attachments.length > 0 && (
          <div className="flex space-x-2 mb-2">
            {attachments.map((file, index) => (
              <div
                key={index}
                className="flex items-center bg-gray-200 dark:bg-gray-700 px-2 py-1 rounded-md"
              >
                <span className="text-sm text-gray-700 dark:text-gray-300">{file.fileName}</span>
                <button
                  onClick={() => handleRemoveAttachment(index)}
                  className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100"
                >
                  <i className="fa-solid fa-times"></i>
                </button>
              </div>
            ))}
          </div>
        )}
        {/* Images Preview */}
        {images.length > 0 && <AttachmentPreview attachments={images} />}

        <div className="flex items-center space-x-2">
          <label htmlFor="file-upload" className="cursor-pointer text-gray-500 hover:text-gray-700">
            <i className="fa-light fa-paperclip-vertical"></i>
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            onChange={handleFileChange}
            className="hidden"
          />
          <Input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            // onKeyPress={handleKeyPress}
            placeholder={t('Type a message...')}
            className="flex-grow"
          />
          <Button className='rounded-lg' onClick={handleSendMessage}>
            <i className="fas fa-paper-plane mr-2"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
