import React, { useState } from 'react';

interface FiltersProps {
  filters: { [key: string]: any };
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

const Filters: React.FC<FiltersProps> = ({ filters, setFilters }) => {
  const [specialization, setSpecialization] = useState(filters.Specialization || '');
  const [minExperience, setMinExperience] = useState(filters.MinExperience || '');
  const [maxHourlyRate, setMaxHourlyRate] = useState(filters.MaxHourlyRate || '');

  const applyFilters = () => {
    setFilters({
      Specialization: specialization,
      MinExperience: minExperience,
      MaxHourlyRate: maxHourlyRate,
    });
  };

  return (
    <div className="flex space-x-4">
      {/* Specialization Filter */}
      <div>
        <label htmlFor="specialization" className="block text-sm font-medium text-gray-700">
          Specialization
        </label>
        <input
          type="text"
          id="specialization"
          value={specialization}
          onChange={(e) => setSpecialization(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        />
      </div>
      {/* Min Experience Filter */}
      <div>
        <label htmlFor="minExperience" className="block text-sm font-medium text-gray-700">
          Min Experience
        </label>
        <input
          type="number"
          id="minExperience"
          value={minExperience}
          onChange={(e) => setMinExperience(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        />
      </div>
      {/* Max Hourly Rate Filter */}
      <div>
        <label htmlFor="maxHourlyRate" className="block text-sm font-medium text-gray-700">
          Max Hourly Rate
        </label>
        <input
          type="number"
          id="maxHourlyRate"
          value={maxHourlyRate}
          onChange={(e) => setMaxHourlyRate(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        />
      </div>
      {/* Apply Filters Button */}
      <div className="flex items-end">
        <button
          onClick={applyFilters}
          className="mt-2 px-4 py-2 bg-primary-600 text-white rounded-md"
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
};

export default Filters;
