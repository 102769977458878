import React from 'react';
import Input from './Input';
import Textarea from './Textarea';
import Button from './Button';

const Form: React.FC = () => {
  return (
    <form className="space-y-4">
      <Input type="text" id="name" label="Name" placeholder="Enter your name" />
      <Input type="email" id="email" label="Email" placeholder="Enter your email" />
      <Textarea id="message" label="Message" placeholder="Enter your message" />
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default Form;
