import TaskDeadlineTracker from "../../Tasks/TaskDeadlineTracker";

const AdminFirm = () => {


    return (
        <div className="h-full p-2">
            <TaskDeadlineTracker />
        </div>
    );
}

export default AdminFirm;
