import { Employee, EmployeeActionTypes } from "../../../components/Management/Employees/types";
import { FETCH_EMPLOYEES_REQUEST, FETCH_EMPLOYEES_SUCCESS, FETCH_EMPLOYEES_FAILURE, ONBOARD_EMPLOYEE_FAILURE, ONBOARD_EMPLOYEE_REQUEST, ONBOARD_EMPLOYEE_SUCCESS, FETCH_EMPLOYEE_DETAILS_FAILURE, FETCH_EMPLOYEE_DETAILS_REQUEST, FETCH_EMPLOYEE_DETAILS_SUCCESS, CLEAR_EMPLOYEE_DETAILS } from "../../types";


interface EmployeeState {
  employees: Employee[];
  employee: Employee | null;
  loading: boolean;
  error: string | null;
}

const initialState: EmployeeState = {
  employees: [],
  employee: null,
  loading: false,
  error: null,
};

export const employeeReducer = (
  state = initialState,
  action: EmployeeActionTypes
): EmployeeState => {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
    case ONBOARD_EMPLOYEE_REQUEST:
    case FETCH_EMPLOYEE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
      };
    case FETCH_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
      };
    case ONBOARD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_EMPLOYEES_FAILURE:
    case ONBOARD_EMPLOYEE_FAILURE:
    case FETCH_EMPLOYEE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
  case CLEAR_EMPLOYEE_DETAILS:
  return {
    ...state,
    employee: null,
  };
    default:
      return state;
  }
};
