// src/components/Documents/FolderListItem.tsx

import React, { useState } from 'react';
import { FolderProps } from './types';
import clsx from 'clsx';

interface FolderListItemProps {
  folder: FolderProps;
  onSelectFolder: (folderId: string) => void;
  selectedFolderId: string | null;
  level?: number;
}

const FolderListItem: React.FC<FolderListItemProps> = ({ folder, onSelectFolder, selectedFolderId, level = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFolderClick = () => {
    onSelectFolder(folder.FolderID);
  };

  const toggleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const folderIconColor = getFolderIconColor(folder.FolderName);

  return (
    <div className="space-y-1">
      <div
        className={clsx(
          'flex items-center cursor-pointer px-2 py-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700',
          selectedFolderId === folder.FolderID && 'bg-gray-300 dark:bg-gray-600'
        )}
        onClick={handleFolderClick}
        style={{ paddingLeft: `${level * 16}px` }}
      >
        {folder.SubFolders && folder.SubFolders.length > 0 ? (
          <button onClick={toggleOpen}>
            {isOpen ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
          </button>
        ) : (
          <span style={{ width: '1rem' }}></span>
        )}
        <i
          className={clsx('fas fa-folder ml-2', folderIconColor)}
        ></i>
        <span className="ml-2">{folder.FolderName}</span>
      </div>
      {isOpen && folder.SubFolders && folder.SubFolders.length > 0 && (
        <div>
          {folder.SubFolders.map((subFolder: FolderProps) => (
            <FolderListItem
              key={subFolder.FolderID}
              folder={subFolder}
              onSelectFolder={onSelectFolder}
              selectedFolderId={selectedFolderId}
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
};

function getFolderIconColor(folderName?: string): string {
  if (!folderName) return 'text-gray-500'; 

  switch (folderName.toLowerCase()) {
    case 'projects':
      return 'text-green-500';
    case 'cases':
      return 'text-green-500';
    case 'shared documents':
        return 'text-purple-500';
    case 'contracts':
      return 'text-blue-500';
    case 'terms of service':
      return 'text-green-500';
    case 'signatures':
      return 'text-indigo-500';
    case 'legal services':
      return 'text-blue-500';
    case 'legal case mappings':
      return 'text-blue-500';
    case 'team documents':
      return 'text-yellow-500';
    default:
      return 'text-sky-300';
  }
}

export default FolderListItem;
