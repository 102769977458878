import React, { useState } from 'react';
import '../Settings.css';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { EditUser, userInformation } from '../../../store/actions/DataAction';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PreferencesSection from './Preferences';
import Reminder from './Reminder';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { showModal, showPasswordProfileModal } from '../../../store/actions/modalActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import AISettingsComponent from './AISettings';
import ApiLoader from '../../ApiLoader';
import CustomDateTimePicker from '../../shared/CustomDateTimePicker';
import { Tooltip } from '@mui/material';
import Input from '../../shared/TailwindComponents/Input';
import Button from '../../shared/TailwindComponents/Button';
import SocketServices from '../../../utils/SocketServices';
import { clearActiveSession, clearAllActiveSession, getActiveSessions } from '../../../store/actions/SecurityActions';
import SignatureModal from '../Security/DigitalSignature';
import apis from '../../../utils/apis';
import { useHistory } from 'react-router-dom';
import Badge from '../../shared/TailwindComponents/Badge';

export interface UserProps {
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  StreetAddress: string;
  Email: string;
  CountryOfJurisdiction: string;
  ZipCode?: string;
  ProfilePicture?: string;
  City?: string;
  Country?: string;
  State?: string;
  DateOfBirth?: string;
  Password?: string;
  Biography?: string;
  HourlyRate?: string;
}

const PersonalInfoAndProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useSelector((state: RootState) => state.user.userInfo) || {};
  const userType = getUserType();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const devices = useSelector((state: RootState) => state.security.sessions);
  const ownSessionId = sessionStorage.getItem('session_id');
  const userId = getUserID();
  const [signature, setSignature] = useState<string | null>(null);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const history = useHistory();
  const validationSchema = yup.object({
    FirstName: yup.string().required('Required'),
    LastName: yup.string().required('Required'),
    PhoneNumber: yup.string().required('Required'),
    // Email is now static and excluded from validation
    Address: yup.string(),
    Country: yup.string(),
    State: yup.string(),
    City: yup.string(),
    ZipCode: yup.string(),
    ProfilePicture: yup.string(),
    DateOfBirth: yup
      .string()
      .required(t('Date of Birth is required'))
      .test('is-valid-date', t('Invalid date'), (value) =>
        dayjs(value, 'DD-MM-YYYY', true).isValid()
      ),
  });

  const formik = useFormik({
    initialValues: {
      FirstName: userData.FirstName || '',
      LastName: userData.LastName || '',
      PhoneNumber: (userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') ? userData.PrimaryContact : userData.PhoneNumber || '',
      StreetAddress: userData.StreetAddress || '',
      Email: userData.Email || '', // Keep Email in initialValues
      ZipCode: userData.ZipCode || '',
      City: userData.City || '',
      Country: userData.Country || '',
      State: userData.State || '',
      DateOfBirth: userData.DateOfBirth || '',
      CountryOfJurisdiction: 'Norway',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: UserProps) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('UserID', userData.UserID);
      formData.append('UserType', userType);

      Object.keys(values).forEach((key) => {
        formData.append(key, (values as any)[key] ?? '');
      });

      const resp = await dispatch(EditUser(formData, onSuccess));
      if (resp) {
        setIsLoading(false);
        setIsEditing(false);
      } else {
        setIsLoading(false);
      }
    },
  });

  const onSuccess = () => {
    setIsEditing(false);
    dispatch(userInformation());
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: t('Account updated'),
        modalType: 'success',
        duration: 3000,
      })
    );
  };

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      currentPassword: yup.string().required('Required'),
      newPassword: yup.string().required('Required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setIsChangingPassword(true);
      // Implement your change password API call here
      try {
        const response = await apis({
          method: 'POST',
          url: '/account/change_password',
          data: {
            CurrentPassword: values.currentPassword,
            NewPassword: values.newPassword,
          },
        });
        if (response && response.status === 200) {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: t('Password changed successfully'),
              modalType: 'success',
              duration: 3000,
            })
          );
          passwordFormik.resetForm();
        }
      } catch (error: any) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to change password'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
      setIsChangingPassword(false);
    },
  });


  if (!userData) {
    return <div>{t('Loading...')}</div>;
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsLoading(false);
  };

  const handleDOBChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('DD-MM-YYYY'); // Ensure consistent format
      formik.setFieldValue('DateOfBirth', formattedDate);
    } else {
      formik.setFieldValue('DateOfBirth', '');
    }
  };
  
  const handleEditSubmit = () => {
    dispatch(showPasswordProfileModal(formik.values, userData.UserID, userType, onSuccess));
  };

  const formatDate = (dateString?: string | null): string => {
    if (!dateString) {
      return 'No Date';
    }

    const inputFormat = 'MM-DD-YYYY';
    const date = dayjs(dateString, inputFormat, true);

    if (!date.isValid()) {
      return 'Invalid Date';
    }

    const outputFormat = 'DD MMMM YYYY'; 
    return date.format(outputFormat);
  };

  const handleSignOutConfirm = (sessionId: string) => {
    dispatch(
      showModal({
        type: 'alert',
        message: t('Are you sure you want to sign out from this device?'),
        onConfirm: () => {
          // SocketServices.emit('logout_session', { session_id: sessionId });
          // if (ownSessionId !== sessionId) {
          //   dispatch(getActiveSessions());
          // }
          dispatch(clearActiveSession(sessionId))
        },
        onCancel: handleCancel,
        inputData: sessionId,
        showModal: true,
      })
    );
  };

  const handleSignOutAllAccounts = () => {
    dispatch(
      showModal({
        type: 'alert',
        message: t('Are you sure you want to sign out from all devices?'),
        onConfirm: () => {
          // SocketServices.emit('sign_out_all_accounts', { userId });
          dispatch(clearAllActiveSession())
        },
        onCancel: handleCancel,
        inputData: userId,
        showModal: true,
      })
    );
  };

  const handleDeleteAccount = () => {
    dispatch(
      showModal({
        type: 'alert',
        message: t(
          'Are you sure you want to delete your account? This action is irreversible.'
        ),
        onConfirm: async () => {
          try {
            const response = await apis({
              method: 'DELETE',
              url: '/account/delete_account',
            });
            if (response && response.status === 200) {
              dispatch(
                showFeedbackModal({
                  modalType: 'success',
                  message: t('Account deleted successfully.'),
                  showModal: true,
                  duration: 3000,
                })
              );
              // Clear session and redirect to login page
              sessionStorage.clear();
              history.push('/');
            }
          } catch (error: any) {
            dispatch(
              showFeedbackModal({
                modalType: 'error',
                message: t('Failed to delete account.'),
                showModal: true,
                duration: 3000,
              })
            );
          }
        },
        onCancel: () => {},
        showModal: true,
      })
    );
  };

  const handleSaveSignature = (signatureData: string) => {
    setSignature(signatureData);
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700">
      {/* Personal Information Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            Manage your personal details and profile information.
          </p>
        </div>
        <div className="md:col-span-2">
          {isLoading && (
            <div className="flex justify-center items-center">
              <ApiLoader />
            </div>
          )}
          {isEditing ? (
            <form onSubmit={formik.handleSubmit} className="space-y-8">
              <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                <div className="sm:col-span-3">
                  <Input
                    label="First Name"
                    id="FirstName"
                    name="FirstName"
                    type="text"
                    placeholder="Enter your first name"
                    value={formik.values.FirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
                    helperText={formik.touched.FirstName && formik.errors.FirstName}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Input
                    label="Last Name"
                    id="LastName"
                    name="LastName"
                    type="text"
                    placeholder="Enter your last name"
                    value={formik.values.LastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.LastName && Boolean(formik.errors.LastName)}
                    helperText={formik.touched.LastName && formik.errors.LastName}
                  />
                </div>
                <div className="col-span-full">
                  <Input
                    label="Email"
                    id="Email"
                    name="Email"
                    type="email"
                    placeholder="Enter your email"
                    value={formik.values.Email}
                    disabled
                    className="bg-gray-200 dark:bg-gray-700"
                  />
                </div>
                <div className="col-span-full">
                  <Input
                    label="Phone Number"
                    id="PhoneNumber"
                    name="PhoneNumber"
                    type="text"
                    placeholder="Enter your phone number"
                    value={formik.values.PhoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.PhoneNumber && Boolean(formik.errors.PhoneNumber)}
                    helperText={formik.touched.PhoneNumber && formik.errors.PhoneNumber}
                  />
                </div>
                {/* Include other fields as needed */}
              </div>

              {/* Signature Modal */}
              {/* <div className="col-span-full mt-8">
                <SignatureModal
                  onSave={handleSaveSignature}
                  userId={userId}
                  userType={userType}
                  onConfirm={() => {}}
                />
              </div> */}

              <div className="mt-8 flex gap-x-4">
                <Button variant="primary" onClick={handleEditSubmit} disabled={isLoading}>
                  Save
                </Button>
                <Button variant="destructive" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                <div className="sm:col-span-3">
                  <SectionDisplay title="First Name" value={userData.FirstName} />
                </div>
                <div className="sm:col-span-3">
                  <SectionDisplay title="Last Name" value={userData.LastName} />
                </div>
                <div className="col-span-full">
                  <SectionDisplay title="Email" value={userData.Email} />
                </div>
                <div className="col-span-full">
                  <SectionDisplay
                    title="Phone Number"
                    value={
                      userType === 'LawFirmAdmin' || userType === 'BusinessAdmin'
                        ? userData.PhoneNumber
                        : userData.PhoneNumber
                    }
                  />
                </div>
                {/* Include other fields as needed */}
              </div>

              {/* Signature Modal */}
              {/* <div className="col-span-full mt-8">
                <SignatureModal
                  onSave={handleSaveSignature}
                  userId={userId}
                  userType={userType}
                  onConfirm={() => {}}
                />
              </div> */}

              <div className="mt-8 flex">
                <Button variant="primary" onClick={handleEdit}>
                  Update
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Change Password Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Change password
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            Update your password associated with your account.
          </p>
        </div>
        <div className="md:col-span-2">
          <form onSubmit={passwordFormik.handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
              <div className="col-span-full">
                <Input
                  label="Current Password"
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  placeholder="Enter your current password"
                  value={passwordFormik.values.currentPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.currentPassword &&
                    Boolean(passwordFormik.errors.currentPassword)
                  }
                  helperText={
                    passwordFormik.touched.currentPassword &&
                    passwordFormik.errors.currentPassword
                  }
                />
              </div>
              <div className="col-span-full">
                <Input
                  label="New Password"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Enter your new password"
                  value={passwordFormik.values.newPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.newPassword &&
                    Boolean(passwordFormik.errors.newPassword)
                  }
                  helperText={
                    passwordFormik.touched.newPassword && passwordFormik.errors.newPassword
                  }
                />
              </div>
              <div className="col-span-full">
                <Input
                  label="Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm your new password"
                  value={passwordFormik.values.confirmPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.confirmPassword &&
                    Boolean(passwordFormik.errors.confirmPassword)
                  }
                  helperText={
                    passwordFormik.touched.confirmPassword &&
                    passwordFormik.errors.confirmPassword
                  }
                />
              </div>
            </div>
            <div className="mt-8 flex">
              <Button variant="primary" type="submit" disabled={isChangingPassword}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>

      {/* Log out other sessions Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Log out other sessions
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            Control and monitor all devices connected to your account.
          </p>
        </div>
        <div className="md:col-span-2">
          <div className="space-y-4">
            {devices && devices.length > 0 ? (
              devices.map((device: any, index: number) => (
                <div
                  key={index}
                  className="flex justify-between items-center bg-gray-100 dark:bg-gray-800 p-4 rounded-lg"
                >
                  <div>
                    <p className="font-medium">
                      {device.os || device.mobileModel}
                    </p>
                    <p className="text-sm text-gray-500">
                      {device.browser}
                    </p>
                    <p className="text-sm text-green-500">{device.status}</p>
                    <Badge color='indigo'>
                    {dayjs(device.lastActive).format('MMMM D, YYYY h:mm A')}
                    </Badge>
                  </div>
                  <Button
                    variant="danger"
                    onClick={() => handleSignOutConfirm(device.session_id)}
                  >
                    {t('Sign out')}
                  </Button>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500">
                {t('No devices currently connected.')}
              </p>
            )}
          </div>
          <div className="mt-8 flex">
            <Button variant="danger" onClick={handleSignOutAllAccounts}>
              {t('Sign out of all devices')}
            </Button>
          </div>
        </div>
      </div>

      {/* Delete Account Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Delete account
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            No longer want to use our service? You can delete your account here.
            This action is not reversible. All information related to this
            account will be deleted permanently.
          </p>
        </div>
        <div className="md:col-span-2 flex items-start">
          <Button variant="danger" disabled onClick={handleDeleteAccount}>
            Yes, delete my account
          </Button>
        </div>
      </div>
    </div>
  );
};

interface SectionDisplayProps {
  title: string;
  value?: string;
}

const SectionDisplay: React.FC<SectionDisplayProps> = ({ title, value }) => (
  <div className="flex flex-col">
    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {title}
    </label>
    <div className="mt-1 p-2 bg-gray-100 dark:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100">
      {value || 'N/A'}
    </div>
  </div>
);

export default PersonalInfoAndProfile;