import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..';
import apis from '../../../utils/apis';
import {
  PROPOSE_CASE_REQUEST,
  PROPOSE_CASE_SUCCESS,
  PROPOSE_CASE_FAILURE,
  GET_CLIENT_REQUESTS_REQUEST,
  GET_CLIENT_REQUESTS_SUCCESS,
  GET_CLIENT_REQUESTS_FAILURE,
  GET_INCOMING_CLIENT_REQUESTS_REQUEST,
  GET_INCOMING_CLIENT_REQUESTS_SUCCESS,
  GET_INCOMING_CLIENT_REQUESTS_FAILURE,
  ACCEPT_CLIENT_REQUEST_SUCCESS,
  ACCEPT_CLIENT_REQUEST_FAILURE,
  REJECT_CLIENT_REQUEST_SUCCESS,
  REJECT_CLIENT_REQUEST_FAILURE,
} from '../../types';
import { showFeedbackModal } from '../UserFeedbackActions';

// Action Creators
export const proposeCaseRequest = (): AnyAction => ({ type: PROPOSE_CASE_REQUEST });
export const proposeCaseSuccess = (): AnyAction => ({ type: PROPOSE_CASE_SUCCESS });
export const proposeCaseFailure = (error: string): AnyAction => ({
  type: PROPOSE_CASE_FAILURE,
  payload: error,
});

export const getClientRequestsRequest = (): AnyAction => ({ type: GET_CLIENT_REQUESTS_REQUEST });
export const getClientRequestsSuccess = (data: { requests: any[]; currentPage: number; totalPages: number }): AnyAction => ({
  type: GET_CLIENT_REQUESTS_SUCCESS,
  payload: data,
});
export const getClientRequestsFailure = (error: string): AnyAction => ({
  type: GET_CLIENT_REQUESTS_FAILURE,
  payload: error,
});

export const getIncomingClientRequestsRequest = (): AnyAction => ({ type: GET_INCOMING_CLIENT_REQUESTS_REQUEST });
export const getIncomingClientRequestsSuccess = (data: { requests: any[]; currentPage: number; totalPages: number }): AnyAction => ({
  type: GET_INCOMING_CLIENT_REQUESTS_SUCCESS,
  payload: data,
});
export const getIncomingClientRequestsFailure = (error: string): AnyAction => ({
  type: GET_INCOMING_CLIENT_REQUESTS_FAILURE,
  payload: error,
});

export const acceptClientRequestSuccess = (): AnyAction => ({ type: ACCEPT_CLIENT_REQUEST_SUCCESS });
export const acceptClientRequestFailure = (error: string): AnyAction => ({
  type: ACCEPT_CLIENT_REQUEST_FAILURE,
  payload: error,
});

export const rejectClientRequestSuccess = (): AnyAction => ({ type: REJECT_CLIENT_REQUEST_SUCCESS });
export const rejectClientRequestFailure = (error: string): AnyAction => ({
  type: REJECT_CLIENT_REQUEST_FAILURE,
  payload: error,
});

// Thunk Actions
export const proposeCase = (data: { LawyerID: string; CaseID: string; Description: string }) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(proposeCaseRequest());
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/create_client_request',
        data,
      });
      dispatch(proposeCaseSuccess());
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          message: 'Client request created successfully',
          showModal: true,
          duration: 3000,
        })
      );
    } catch (error: any) {
      dispatch(proposeCaseFailure(error.toString()));
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: 'Failed to create client request',
          showModal: true,
          duration: 3000,
        })
      );
    }
  };
};

export const getClientRequests = (page: number = 1, per_page: number = 10) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(getClientRequestsRequest());
      try {
        const response = await apis({
          method: 'GET',
          url: '/marketplace/my_client_requests',
          params: { page, per_page },
        });
        const { requests, current_page, total_pages } = response.data;
        dispatch(
          getClientRequestsSuccess({
            requests,
            currentPage: current_page || page,
            totalPages: total_pages || 1,
          })
        );
      } catch (error: any) {
        dispatch(getClientRequestsFailure(error.toString()));
      }
    };
  };
  
  export const getIncomingClientRequests = (page: number = 1, per_page: number = 10) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(getIncomingClientRequestsRequest());
      try {
        const response = await apis({
          method: 'GET',
          url: '/marketplace/incoming_client_requests',
          params: { page, per_page },
        });
        const { requests, current_page, total_pages } = response.data;
        dispatch(
          getIncomingClientRequestsSuccess({
            requests,
            currentPage: current_page || page,
            totalPages: total_pages || 1,
          })
        );
      } catch (error: any) {
        dispatch(getIncomingClientRequestsFailure(error.toString()));
      }
    };
  };
  
export const acceptClientRequest = (requestId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/accept_client_request',
        data: { RequestID: requestId },
      });
      dispatch(acceptClientRequestSuccess());
    } catch (error: any) {
      dispatch(acceptClientRequestFailure(error.toString()));
    }
  };
};

export const rejectClientRequest = (requestId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/reject_client_request',
        data: { RequestID: requestId },
      });
      dispatch(rejectClientRequestSuccess());
    } catch (error: any) {
      dispatch(rejectClientRequestFailure(error.toString()));
    }
  };
};
