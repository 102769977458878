const ExclamationIcon: React.FC = () => (
    <svg
      className="w-12 h-12 text-red-600 mx-auto"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 9v2m0 4h.01M12 5.5a.5.5 0 01.5.5v.01a.5.5 0 01-.5.5h-.01a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h.01z"
      />
    </svg>
  );
  
  export default ExclamationIcon;