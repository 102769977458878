import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import Header from '../shared/TailwindComponents/Header';
import Button from '../shared/TailwindComponents/Button';
import { useSelector } from 'react-redux';
import Badge from '../shared/TailwindComponents/Badge';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { getUserType } from '../../utils/authUtils';
import {
  clearAnalysisData,
  fetchAnalysisProgress,
  runCaseAnalysis,
} from '../../store/actions/marketplace2/caseAnalysisActions';
import { showPublishCaseSidePanel } from '../../store/actions/sidePanelActions';
import { fetchCaseById, generateDocument } from '../../store/actions/marketplace2/caseActions';
import FinancialDataSection from './FinancialDataSection';
import CaseProgressBar from './CaseProgressBar'; 
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import Tabs from '../shared/TailwindComponents/Tabs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ProgressStepper from './ProgressStepper';
import SplitButton from '../shared/TailwindComponents/SplitButton';
import { formatCamelCase } from '../../utils/functions';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

interface RouteParams {
  caseId: string;
}

const CaseDetails: React.FC = () => {
  const { caseId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('Case Information');
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  const caseItem = useSelector((state: RootState) => state.casesM.selectedCase);
  const analysisState = useSelector((state: RootState) => state.caseAnalysis);
  const userType = getUserType();
  const documentUrls = useSelector((state: RootState) => state.casesM.documentUrls);
  const isGeneratingDocument = useSelector((state: RootState) => state.casesM.isGeneratingDocument);
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    dispatch(fetchCaseById(caseId));
    dispatch(fetchAnalysisProgress(caseId));

    return () => {
      dispatch(clearAnalysisData());
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, [dispatch, caseId]);

  useEffect(() => {
    if (caseItem) {
      setBreadcrumbs([
        { name: 'Cases', href: '/cases', current: false },
        { name: caseItem.CaseName || `Case ${caseId}`, href: `/cases/${caseId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [caseItem, caseId, setBreadcrumbs]);

  useEffect(() => {
    if (analysisState.isRunning) {
      // Start polling if analysis is running
      if (!pollingRef.current) {
        startPolling();
      }
    } else {
      // Stop polling when analysis is not running
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
    }
  }, [analysisState.isRunning]);

  const startPolling = () => {
    if (!pollingRef.current) {
      pollingRef.current = setInterval(() => {
        dispatch(fetchAnalysisProgress(caseId));
      }, 2000); 
    }
  };

  const handleRunAnalysis = () => {
    dispatch(runCaseAnalysis(caseId));
    setActiveTab('Case Analysis');
  };

  const handleReanalyze = () => {
    dispatch(clearAnalysisData());
    handleRunAnalysis();
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleDeleteCase = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this case?'),
        onConfirm: async () => {
          // Implement delete case action
          dispatch(hideModal());
          history.push('/cases');
        },
        onCancel: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const handlePublishCase = () => {
    dispatch(showPublishCaseSidePanel(caseItem));
  };

  if (!caseItem) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  const handleGenerateDocument = () => {
    dispatch(generateDocument(caseId));
  };

  const handleDownloadPDF = () => {
    if (documentUrls?.pdf_url) {
      window.open(documentUrls.pdf_url, '_blank');
    }
  };
  
  const handleDownloadDocx = () => {
    if (documentUrls?.docx_url) {
      window.open(documentUrls.docx_url, '_blank');
    }
  };

  const tabs = [
    {
      label: 'Case Information',
      value: 'Case Information'
    },
     {
      label: 'Case Analysis',
      value: 'Case Analysis',
    }];

    const renderCaseInformation = () => {
      const caseFields = [
        { label: 'Case Type', value: caseItem.CaseType },
        { label: 'Status', value: caseItem.case_status },
        { label: 'Client ID', value: caseItem.ClientID },
        {
          label: 'Client User Type',
          value: formatCamelCase(caseItem.ClientUserType || ''),
        },
        { label: 'Organization Name', value: caseItem.OrganizationName },
        {
          label: 'Created At',
          value: new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(caseItem.created_at)),
        },
        {
          label: 'Updated At',
          value: new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(caseItem.updated_at)),
        },
      ];      
    
      return (
        <div className="mt-4">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4">{t('Case Information')}</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {caseFields.map(({ label, value }) => (
                <div key={label}>
                  <p className="text-sm font-medium text-gray-500">{t(label)}</p>
                  {label === 'Status' ? (
                    <Badge color={value === 'open' ? 'green' : 'indigo'}>{value}</Badge>
                  ) : (
                    <p className="text-sm">{value || t('N/A')}</p>
                  )}
                </div>
              ))}
            </div>
            {/* Display custom fields */}
            {caseItem.custom_fields && Object.keys(caseItem.custom_fields).length > 0 && (
              <div className="mt-6">
                <h4 className="text-lg font-medium">{t('Custom Fields')}</h4>
                <div className="mt-4 space-y-4">
                  {Object.entries(caseItem.custom_fields).map(([key, value]) => (
                    <div key={key}>
                      <p className="text-sm font-medium text-gray-500">{t(key)}</p>
                      <p className="text-sm whitespace-pre-wrap">{value as string}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* Display case fields */}
            {caseItem.fields && Object.keys(caseItem.fields).length > 0 && (
              <div className="mt-6">
                <h4 className="text-lg font-medium">{t('Case Details')}</h4>
                <div className="mt-4 space-y-4">
                  {Object.entries(caseItem.fields).map(([key, value]) => (
                    <div key={key}>
                      <p className="text-sm font-medium text-gray-500 capitalize">
                        {t(key.replace('_', ' '))}
                      </p>
                      <p className="text-sm whitespace-pre-wrap">{value as string}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
      </div>
        {/* Financial Data */}
        <section className="mt-8">
          <FinancialDataSection financialData={caseItem.FinancialData} caseId={caseItem.CaseID} />
        </section>
      </div>
    );
  };

  const renderCaseAnalysis = () => {
    if (analysisState.isRunning) {
      return (
        <div className="mt-4">
          <ProgressStepper steps={analysisState.progressSteps} />
        </div>
      );
    } else if (analysisState.error) {
      return (
        <div className="flex flex-col items-center justify-center mt-8">
          <p className="text-red-500">Error: {analysisState.error}</p>
          <Button onClick={handleRunAnalysis}>Retry Analysis</Button>
        </div>
      );
    } else if (analysisState.analysisData) {
      const analysisDataEntries = Object.entries(analysisState.analysisData).sort(
        (a, b) => {
          const desiredOrder = [
            'applicable_laws',
            'legal_analysis',
            'interpretation',
            'legal_strategy',
            'case_report',
          ];
  
          const indexA = desiredOrder.indexOf(a[0]);
          const indexB = desiredOrder.indexOf(b[0]);
  
          const adjustedIndexA = indexA === -1 ? Infinity : indexA;
          const adjustedIndexB = indexB === -1 ? Infinity : indexB;
  
          return adjustedIndexA - adjustedIndexB;
        }
      );
      return (
        <div className="mt-4 space-y-6">
                      <SplitButton
                onGenerate={handleGenerateDocument}
                onDownloadPDF={handleDownloadPDF}
                onDownloadDocx={handleDownloadDocx}
                isGenerating={isGeneratingDocument}
                pdfAvailable={!!documentUrls?.pdf_url}
                docxAvailable={!!documentUrls?.docx_url}
              />
        {analysisDataEntries.map(([key, value]) => (
            <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <div className="flex justify-between items-center">
                <h4 className="text-lg font-medium capitalize">{key.replace('_', ' ')}</h4>
                {/* Status Badge */}
                {analysisState.progressSteps && (
                  <Badge
                    color={
                      analysisState.progressSteps.find((step) => step.step === key)?.status ===
                      'Completed'
                        ? 'green'
                        : 'yellow'
                    }
                  >
                    {analysisState.progressSteps.find((step) => step.step === key)?.status ||
                      'Pending'}
                  </Badge>
                )}
              </div>
              <div className="mt-2 text-sm prose dark:prose-dark">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{value as string}</ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center justify-center mt-8">
          <p>No analysis has been run for this case.</p>
        </div>
      );
    }
  };
  
  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300">
      {/* Header */}
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <button onClick={handleBack} className="mr-4">
            <ChevronLeftIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          </button>
          <div>
            <h1 className="text-xl font-semibold">
              {caseItem?.CaseName || `Case ${caseItem?.CaseID}`}
            </h1>
            <p className="text-sm text-gray-500">{`Client ID: ${caseItem?.ClientID || 'N/A'}`}</p>
          </div>
        </div>
        <div className="flex items-center">
          <Button
            onClick={analysisState.analysisData ? handleReanalyze : handleRunAnalysis}
            // disabled={analysisState.isRunning}
          >
            {analysisState.analysisData ? 'Re-analyze' : 'Run Analysis'}
          </Button>
          {/* Ellipsis Menu */}
          <Menu as="div" className="relative inline-block text-left ml-4">
            <Menu.Button className="flex items-center">
              <EllipsisVerticalIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          // Handle Edit
                        }}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-gray-700' : ''
                        } block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                      >
                        Edit Case
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleDeleteCase}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-gray-700' : ''
                        } block w-full text-left px-4 py-2 text-sm text-red-700 dark:text-red-400`}
                      >
                        Delete Case
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {/* Case Progress Bar */}
      <CaseProgressBar steps={analysisState.progressSteps} />

      {/* Tabs */}
      {!analysisState.isRunning && (
        <div className="px-4">
          <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
        </div>
      )}

      {/* Content */}
      <div className="px-4 pb-6">
        {activeTab === 'Case Information' && renderCaseInformation()}
        {activeTab === 'Case Analysis' && renderCaseAnalysis()}
      </div>
    </div>
  );
};

export default CaseDetails;
