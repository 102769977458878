import { AnyAction } from "redux";
import { DELETE_CUSTOM_BLOB_DOCUMENT_FAILURE, DELETE_CUSTOM_BLOB_DOCUMENT_SUCCESS, FETCH_CUSTOM_BLOB_DOCUMENTS_FAILURE, FETCH_CUSTOM_BLOB_DOCUMENTS_REQUEST, FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS, FETCH_CUSTOM_INDEX_INFO_FAILURE, FETCH_CUSTOM_INDEX_INFO_REQUEST, FETCH_CUSTOM_INDEX_INFO_SUCCESS, POLL_CUSTOM_INDEX_STATUS_START, POLL_CUSTOM_INDEX_STATUS_STOP, POST_ADDITIONAL_CUSTOM_FILES_FAILURE, POST_ADDITIONAL_CUSTOM_FILES_REQUEST, POST_ADDITIONAL_CUSTOM_FILES_SUCCESS, POST_CUSTOM_FILES_FAILURE, POST_CUSTOM_FILES_REQUEST, POST_CUSTOM_FILES_SUCCESS, RERUN_CUSTOM_INDEX_FAILURE, RERUN_CUSTOM_INDEX_REQUEST, RERUN_CUSTOM_INDEX_SUCCESS, UPDATE_CUSTOM_INDEX_STATUS_MESSAGE } from "../types";

interface CustomAgentState {
    loading: boolean;
    customFilesData: any | null;
    additionalCustomFilesData: any | null;
    indexInfo: any | undefined;
    indexExists: boolean;
    blobDocuments: string[] | null;
    deleteCustomBlobDocumentResult: any | null;
    rerunCustomIndexResult: any | null;
    error: string | null;
    polling: boolean;
    statusMessage: string;
}

const initialState: CustomAgentState = {
    loading: false,
    customFilesData: null,
    additionalCustomFilesData: null,
    indexInfo: undefined,
    indexExists: false,
    blobDocuments: null,
    deleteCustomBlobDocumentResult: null,
    rerunCustomIndexResult: null,
    error: null,
    polling: false,
    statusMessage: "Loading..."
};

const customAgentReducer = (state = initialState, action: AnyAction): CustomAgentState => {
    switch (action.type) {
        case FETCH_CUSTOM_INDEX_INFO_REQUEST:
        case POST_ADDITIONAL_CUSTOM_FILES_REQUEST:
        case POST_CUSTOM_FILES_REQUEST:
        case RERUN_CUSTOM_INDEX_REQUEST:
        case FETCH_CUSTOM_BLOB_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case POST_CUSTOM_FILES_SUCCESS:
            return {
                ...state,
                customFilesData: action.payload,
                loading: false,
                error: null
            };
        case POST_CUSTOM_FILES_FAILURE:
            return {
                ...state,
                customFilesData: null,
                loading: false,
                error: action.payload
            };
        case POST_ADDITIONAL_CUSTOM_FILES_SUCCESS:
            return {
                ...state,
                additionalCustomFilesData: action.payload,
                loading: false,
                error: null
            };
        case POST_ADDITIONAL_CUSTOM_FILES_FAILURE:
            return {
                ...state,
                additionalCustomFilesData: null,
                loading: false,
                error: action.payload
            };
        case FETCH_CUSTOM_INDEX_INFO_SUCCESS:
            return {
                ...state,
                indexInfo: action.payload,
                indexExists: true,
                loading: action.payload.index_status.lastResult.status !== "success",
                error: null
            };
        case FETCH_CUSTOM_INDEX_INFO_FAILURE:
            return {
                ...state,
                indexInfo: undefined,
                indexExists: action.payload.indexExists,
                loading: false,
                error: action.payload.error
            };
        case FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS:
            return {
                ...state,
                blobDocuments: action.payload,
                loading: false,
                error: null
            };
        case FETCH_CUSTOM_BLOB_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                blobDocuments: [],
                error: action.payload
            };
        case DELETE_CUSTOM_BLOB_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteCustomBlobDocumentResult: action.payload,
                error: null
            };
        case DELETE_CUSTOM_BLOB_DOCUMENT_FAILURE:
            return {
                ...state,
                deleteCustomBlobDocumentResult: null,
                error: action.payload
            };
        case RERUN_CUSTOM_INDEX_SUCCESS:
            return {
                ...state,
                rerunCustomIndexResult: action.payload,
                error: null
            };
        case RERUN_CUSTOM_INDEX_FAILURE:
            return {
                ...state,
                rerunCustomIndexResult: null,
                error: action.payload
            };
        case POLL_CUSTOM_INDEX_STATUS_START:
            return {
                ...state,
                polling: true,
                statusMessage: "Building Custom AI..."
            };
        case POLL_CUSTOM_INDEX_STATUS_STOP:
            return {
                ...state,
                polling: false,
            };
        case UPDATE_CUSTOM_INDEX_STATUS_MESSAGE:
            return {
                ...state,
                statusMessage: action.payload,
            };
        default:
            return state;
    }
};

export default customAgentReducer;
