import { useContext, useEffect } from "react";
import Help from "../components/Help/Help";
import { LayoutContext } from "../contexts/LayoutContext";
function HelpPage() {
    const { setIsContentScrollable } = useContext(LayoutContext);
    useEffect(() => {
        setIsContentScrollable(true);
      
        return () => {
          setIsContentScrollable(true);
        };
      }, [setIsContentScrollable]);

    return (
        <div>
            <Help />
        </div>
    )
}

export default HelpPage;