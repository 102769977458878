import React, { useState } from 'react';
import { getUserType, isBetaEnv } from "../../../utils/authUtils";
import LawyersList from "../Client/LawyersList";
import PublishedCasesList from "../Lawyer/PublishedCasesList";
import ClientBidsList from '../Client/ClientBidsList';
import MyClientRequestsList from '../Lawyer/ClientsRequestsList';
import IncomingClientRequestsList from '../Lawyer/IncomingClientRequestList';
import Tabs from '../../shared/TailwindComponents/Tabs';


const Marketplace: React.FC = () => {
  const userType = getUserType();
  const [activeTab, setActiveTab] = useState<string>('default');

  let tabs: Array<{ label: string; value: string }> = [];
  let content: React.ReactNode = null;

  if (
    userType === 'IndividualClient' ||
    userType === 'BusinessAdmin' ||
    userType === 'BusinessEmployee'
  ) {
    // Set default active tab for clients
    if (activeTab === 'default') setActiveTab('lawyers');

    tabs = [
      { label: 'Lawyers', value: 'lawyers' },
      { label: 'My Bids', value: 'bids' },
      { label: 'My Client Requests', value: 'client_requests' },
    ];

    if (activeTab === 'lawyers') content = <LawyersList />;
    else if (activeTab === 'bids') content = <ClientBidsList />;
    else content = <MyClientRequestsList />; // For clients' own requests
  } else if (
    userType === 'LawFirmAdmin' ||
    userType === 'LawFirmEmployee' ||
    userType === 'IndependentLawyer'
  ) {
    // Set default active tab for lawyers
    if (activeTab === 'default') setActiveTab('cases');

    tabs = [
      { label: 'Published Cases', value: 'cases' },
      { label: 'My Client Requests', value: 'my_requests' },
      { label: 'Incoming Client Requests', value: 'incoming_requests' },
    ];

    if (activeTab === 'cases') content = <PublishedCasesList />;
    else if (activeTab === 'my_requests') content = <MyClientRequestsList />;
    else content = <IncomingClientRequestsList />;
  } else {
    content = <div>You are not authorized to view this page.</div>;
  }

  return (
    <>
      {isBetaEnv ? (
        <div className="flex h-calc-100vh-90px  justify-center items-center text-neutral-400">Coming soon</div>
            ) : (
    <div className="p-4">
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
        
        {content}

        </div>
      )}
      </>
  );
};

export default Marketplace;
