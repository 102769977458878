import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../store';
import { switchUser } from '../../store/actions/userSwitchAction';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { getIsAdmin, getRoles, getUserType } from '../../utils/authUtils';

const RoleDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [contextType, setContextType] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const userType = getUserType();
    const isAdmin = getIsAdmin();
    const roles = getRoles();

    if (!isAdmin) {
      // If the user is not an admin, don't show the role switcher
      return;
    }

    let displayedRole = '';
    let context = '';

    if (userType === 'BusinessAdmin' || userType === 'BusinessEmployee') {
      context = 'Business';
    } else if (userType === 'LawFirmAdmin' || userType === 'LawFirmEmployee') {
      context = 'LawFirm';
    }

    if (userType === 'BusinessAdmin' || userType === 'LawFirmAdmin') {
      displayedRole = 'Admin';
    } else if (userType === 'BusinessEmployee' || userType === 'LawFirmEmployee') {
      displayedRole = 'Employee';
    } else {
      displayedRole = '';
    }

    // Determine available roles to switch to
    const otherRoles = roles.filter((role: string) => role !== userType);
    const simplifiedRoles = otherRoles.map((role: string) => {
      if (role.includes('Admin')) {
        return 'Admin';
      } else if (role.includes('Employee')) {
        return 'Employee';
      } else {
        return role;
      }
    });

    setSelectedRole(displayedRole);
    setAvailableRoles(simplifiedRoles);
    setContextType(context);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleRoleChange = (role: string) => {
    if (selectedRole !== role) {
      setSelectedRole(role);
      setIsOpen(false);

      // Construct the full role name to send to the API
      let fullRole = '';
      if (contextType === 'Business') {
        fullRole = role === 'Admin' ? 'BusinessAdmin' : 'BusinessEmployee';
      } else if (contextType === 'LawFirm') {
        fullRole = role === 'Admin' ? 'LawFirmAdmin' : 'LawFirmEmployee';
      } else {
        // Handle other contexts if any
        return;
      }

      dispatch(switchUser(fullRole));
    } else {
      setIsOpen(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (availableRoles.length === 0) {
    // If no available roles to switch to, don't render the dropdown
    return null;
  }

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={clsx(
          'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-background-light text-text-light dark:bg-background-dark dark:text-text-dark hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-300',
          isOpen ? 'border-primary-500' : ''
        )}
      >
        {t(selectedRole) || t('Select Role')}
        <svg
          className="ml-2 -mr-1 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.584l3.71-4.354a.75.75 0 111.14.976l-4.25 5a.75.75 0 01-1.14 0l-4.25-5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref: any) => (
          <div
            ref={ref}
            className="origin-top-left absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-background-light dark:bg-background-dark ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
          >
            <div className="py-1">
              {availableRoles.map((role) => (
                <button
                  key={role}
                  onClick={() => handleRoleChange(role)}
                  className="w-full text-left px-4 py-2 text-sm text-text-light dark:text-text-dark hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 rounded"
                >
                  {t(role)}
                </button>
              ))}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default RoleDropdown;
