import axios, { AxiosError } from "axios"
import apis from "../../../utils/apis"
import { ErrorResponse } from "../SignInAction"
import { showFeedbackModal } from "../UserFeedbackActions"
import { Dispatch } from "react"
import { FETCH_ALL_EMPLOYEES_REQUEST, FETCH_ALL_EMPLOYEES_SUCCESS, FETCH_ALL_EMPLOYEES_FAILURE, FETCH_TASK_SUCCESS } from "../../types"

export interface IEmployee {
    EmployeeID: string;
    BusinessID: string;
    Designation: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Nationality: string;
    PhoneNumber: string;
    ComapnyRole?: string;
    ProfilePicture: string | null;
    Status: string | null;
  }
  
  interface BusinessAssignTaskData {
    TaskID?: string;
    UserType: string;
    TaskTitle: string;
    TaskDescription: string;
    TaskDeadline: string;
    ProjectID?: string;
    Assignees?: { Email: string }[];
  }
  
  export interface IBusinessEmployeeState {
    employees: Record<string, IEmployee>;
    loading: boolean;
    error: string | null;
  }

  export const fetchAllBusinessEmployees = () => {
    return async (dispatch: Dispatch<any>): Promise<IEmployee[] | null> => {
      dispatch({ type: FETCH_ALL_EMPLOYEES_REQUEST });
      try {
        const resp = await apis({
          method: 'POST',
          url: '/business/getAllEmployee',
          data: {}, 
        });
  
        if (resp && resp.status === 200) {
            const employees = resp.data.Employees || [];
            dispatch({ type: FETCH_ALL_EMPLOYEES_SUCCESS, payload: employees });
            return employees;          
        } else {
            const errorMessage = resp.data?.message || 'Failed to fetch employees';
            dispatch({ type: FETCH_ALL_EMPLOYEES_FAILURE, payload: errorMessage });
            dispatch(
              showFeedbackModal({
                showModal: true,
                message: errorMessage,
                modalType: 'error',
                duration: 3000,
              })
            );
          }
      } catch (err: any) {
        const errorMessage = err.response?.data?.message || 'Failed to fetch employees';
        dispatch({ type: FETCH_ALL_EMPLOYEES_FAILURE, payload: errorMessage });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage,
            modalType: 'error',
            duration: 3000,
          })
        );
      }
      return null;
    };
  };

  export const businessAssignTask = (dataValues: BusinessAssignTaskData) => {
    return async (dispatch: any) => {
      try {
        const resp: any = await apis({
          method: 'POST',
          url: 'market/business/assignTask',
          data: dataValues,
        });
  
        if (resp && resp.status === 200) {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || 'Task Assigned to respective users!',
              modalType: 'success',
              duration: 3000,
            })
          );
          return true;
        } else {
          // Handle errors
          const errorMessage = resp.data?.message || 'Failed to assign task';
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: errorMessage,
              modalType: 'error',
              duration: 3000,
            })
          );
        }
      } catch (err: any) {
        const errorMessage = err.response?.data?.message || 'An unknown error occurred';
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage,
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    };
  };


export const getBusinessAssignTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/getTask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const getAllBusinessAssignedTask = (UserType: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/getAllTask",
                data: {
                    UserType,
                }
            })

            if (resp && resp.status === 200) {
                dispatch({ type: FETCH_TASK_SUCCESS, payload: resp.data }); 
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const deleteBusinessAssignedTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/deleteTask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task Deleted Successfully!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}


export const completeBusinessAssignedTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/completetask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task Completed Successfully!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const getBusinessEmployeeTasks = (UserType: string, EmployeeEmail: string) => {
    return async (dispatch: any) => {
      try {
        const resp: any = await apis({
          method: "POST",
          url: "market/business/fetchEmployeeAllTasks",
          data: {
            UserType,
            EmployeeEmail,
          }
        });
  
        if (resp && resp.status === 200) {
          return resp.data; 
        } else {
          // Handle errors
        }
      } catch (err) {
        // Handle errors
      }
    };
  };
  