import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../contexts/ThemeContext';
import Button from './Button';
import clsx from 'clsx';

const NotFoundPage: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const history = useHistory();

  const handleNav = () => {
    history.goBack();; // Navigate back to the previous page
  };

  return (
    <div className="min-h-[calc(100vh-200px)] flex flex-col items-center justify-center p-4 bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300">
      {/* Image Section */}
      {/* <div className="mb-8">
        <img
          src={require("../../../resources/404pic.jpg")}
          alt="Page not found"
          className={clsx('w-64 h-64 object-contain', {
            'filter invert': theme === 'dark',
          })}
        />
      </div> */}



      {/* Text Section */}
      <div className="text-center">
        <p className="text-base font-semibold text-primary-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button variant="primary" onClick={handleNav}>← Back to home</Button>
          <Link to="/support">
            <Button variant="secondary">Contact support</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
