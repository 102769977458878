import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import {
  createTicket,
  fetchAllTickets,
  fetchAllUserTickets,
  fetchCategories,
  fetchTicket,
} from '../../store/actions/HelpAction';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { getUserType } from '../../store/actions/ChatAction';
import { expandSidePanel, collapseSidePanel } from '../../store/actions/sidePanelActions';
import TicketDetail from './TicketDetail';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import Badge from '../shared/TailwindComponents/Badge';
import { useHistory } from 'react-router-dom';

export interface TicketItem {
  Category: string;
  ClientEmail: string;
  CreatedAt: string;
  Description: string;
  Resolved: boolean;
  Status: string;
  Subject: string;
  TicketID: string;
  UpdatedAt: string;
  UserID: string;
  UserType: string;
}

const SupportTicketSystem: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const categories = useSelector((state: RootState) => state.help.categories);
  const userTickets = useSelector((state: RootState) => state.help.allUserTickets);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketItem | null>(null);
  const [isTicketLoading, setIsTicketLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsCategoriesLoading(true);
    dispatch(fetchCategories()).finally(() => setIsCategoriesLoading(false));

    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  }, [dispatch]);

  const initialValues = {
    Subject: '',
    Description: '',
    Category: '',
  };

  const validationSchema = Yup.object({
    Subject: Yup.string().required(t('Subject is required')),
    Description: Yup.string().required(t('Description is required')),
    Category: Yup.string().required(t('Category is required')),
  });

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void }
  ) => {
    const ticketData = {
      UserType: userType,
      ...values,
    };
    await dispatch(createTicket(ticketData))
      .then(() => {
        resetForm();
      })
      .finally(() => setSubmitting(false));
    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Resolved':
        return 'blue';
      default:
        return 'gray';
    }
  };

  const handleTicketClick = (ticketId: string) => {
    history.push(`/support/${ticketId}`);
  };

  return (
    <div className="flex flex-col md:flex-col w-full h-full overflow-hidden">
      {selectedTicket && (
        <div className="hidden md:block md:w-1/3 lg:w-1/4 h-full">
          <TicketDetail ticket={selectedTicket} isLoading={isTicketLoading} />
        </div>
      )}
      <div className="flex-1 p-4 overflow-y-auto">
        <h2 className="text-2xl font-semibold mb-2">{t('Support Ticket')}</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          {t('Submit your support ticket and we will get back to you within 24 hours.')}
        </p>
        <div className="flex flex-1 flex-row gap-5">
          {/* Formik Form for Submitting Tickets */}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, setFieldValue, errors, touched }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 bg-gray-100 dark:bg-gray-800 p-6 rounded-lg w-full lg:w-2/3 shadow-md dark:shadow-dark-md"
              >
                <Input
                  type="text"
                  id="Subject"
                  name="Subject"
                  label={t('Subject')}
                  value={values.Subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.Subject && touched.Subject ? errors.Subject : ''}
                />
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t('Category')}</label>
                  <Dropdown
                    options={categories || []}
                    onChange={(selectedValue) => setFieldValue('Category', selectedValue)}
                    value={values.Category}
                    placeholder={t('Select a category')}
                  />
                  {errors.Category && touched.Category && (
                    <p className="mt-2 text-sm text-danger-500">{errors.Category}</p>
                  )}
                </div>
                <Textarea
                  id="Description"
                  name="Description"
                  label={t('Description')}
                  value={values.Description}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  error={errors.Description && touched.Description ? errors.Description : undefined}
                  />
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? t('Submitting...') : t('Submit Ticket')}
                </Button>
              </form>
            )}
          </Formik>

          {/* Ticket List */}
          <div className="flex-1 flex flex-col">
            <h3 className="text-xl font-semibold mb-4">{t('Your Tickets')}</h3>
            {isTicketsLoading ? (
              <div className="flex items-center justify-center h-full">
                <ClipLoader size={30} color="#0a4076" />
              </div>
            ) : userTickets && userTickets.length > 0 ? (
              <ul className="space-y-2">
                {userTickets.map((ticket: TicketItem) => (
                  <li
                    key={ticket.TicketID}
                    className="flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-800 rounded cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
                    onClick={() => handleTicketClick(ticket.TicketID)}
                  >
                    <div>
                      <p className="text-sm font-medium">{ticket.Subject}</p>
                      <p className="text-xs text-gray-500">{ticket.Category}</p>
                    </div>
                    <Badge color={getStatusColor(ticket.Status)}>{t(ticket.Status)}</Badge>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('No tickets')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketSystem;
