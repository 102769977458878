import { AppThunk, SET_CURRENT_FOLDER, CLEAR_CURRENT_FOLDER, DELETE_FOLDER_FAILURE, RENAME_FOLDER, CREATE_SUBFOLDER_FAILURE, CREATE_SUBFOLDER_SUCCESS, FETCH_FILE_TYPES_FAILURE, FETCH_FILE_TYPES_SUCCESS, FETCH_STRUCTURE_FAILURE, FETCH_STRUCTURE_SUCCESS } from '../types';
import { getUserID, getUserType } from './ChatAction';
import axios, { AxiosError } from 'axios';
import apis from '../../utils/apis';
import { ErrorResponse } from './SignInAction';
import { showFeedbackModal } from './UserFeedbackActions';
import { FolderProps } from '../../components/Documents/types';

export const setCurrentFolder = (folderId: string) => ({
  type: SET_CURRENT_FOLDER,
  payload: folderId,
});

export const clearCurrentFolder = () => ({
  type: CLEAR_CURRENT_FOLDER,
});

export const setFolderName = (newFolderName: string) => ({
  type: RENAME_FOLDER,
  payload: newFolderName
});

const fetchFileCategorysSuccess = (fileTypes: any[]): { type: string; payload: any[] } => ({
  type: FETCH_FILE_TYPES_SUCCESS,
  payload: fileTypes,
});

const fetchFileCategorysFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_FILE_TYPES_FAILURE,
  payload: error,
});

const fetchStructureSuccess = (structure: any[]): { type: string; payload: any[] } => ({
  type: FETCH_STRUCTURE_SUCCESS,
  payload: structure,
});

const fetchStructureFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_STRUCTURE_FAILURE,
  payload: error,
});

const createSubFolderSuccess = (subFolder: any): { type: string; payload: any } => ({
  type: CREATE_SUBFOLDER_SUCCESS,
  payload: subFolder,
});

const createSubFolderFailure = (error: string): { type: string; payload: string } => ({
  type: CREATE_SUBFOLDER_FAILURE,
  payload: error,
});


export const renameFolder = (folderId: string, newFolderName: string): AppThunk<Promise<FolderProps[]>> => async (dispatch) => {
  try {
    const folderResponse = await apis({
      method: "POST",
      url: '/files/rename_folder',
      data: { FolderID: folderId, NewFolderName: newFolderName }
    });

    if (folderResponse.status === 200) {
      let folderName = folderResponse.data;
      dispatch(setFolderName(folderName));
      dispatch(showFeedbackModal({
        showModal: true,
        message: ("Folder renamed"),
        modalType: 'success',
        duration: 3000
      }));
      return Promise.resolve(folderName);
    } else {
      console.error('Error renaming folder:', folderResponse.statusText);
      if (axios.isAxiosError(folderResponse)) {
        const axiosError = folderResponse as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
      return Promise.reject(new Error('Failed to rename folder'));
    }
  } catch (error) {
    console.error('Error in renameFolder:', error);
    return Promise.reject(error);
  }
};


export const createFolder = (folderName: string, callback?: () => void): AppThunk => async (dispatch, getState) => {
  // const userId = getUserID();
  // if (!userId) {
  //   return;
  // }
  try {
    const response = await apis({
      method: 'POST',
      url: "/files/create_folder",
      data: { FolderName: folderName }
    });
    if (response && response.status === 200) {
      const data = await response.data;
      if (data.message) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Folder created',
          modalType: 'success',
          duration: 3000
        }));

        if (callback) callback();
        return true
      } else {
        console.error('Folder creation response format is incorrect', data);
        dispatch(showFeedbackModal({
          showModal: true,
          modalType: 'error',
          message: 'Failed to create folder. Please try again later.',
          duration: 5000
        }));
      }
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            modalType: 'error',
            message: (erroMessage.message || 'An unknown error occurred'),
            duration: 5000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            modalType: 'error',
            message: 'Network error. Please try again',
            duration: 5000
          }));
        }
      }
    }
  } catch (error) {
    console.error('Create folder error:', error);
    dispatch(showFeedbackModal({
      modalType: 'error',
      message: 'Failed to create folder',
      showModal: true,
      duration: 3000
    }))
  }
};

const deleteFolderFailure = (error: string) => ({ type: DELETE_FOLDER_FAILURE, payload: error });

export const deleteFolder = (folderId: string, callback: () => void): AppThunk => async (dispatch) => {
  try {
    const response = await apis({
      method: 'POST',
      url: "files/delete_folder",
      data: { FolderID: folderId }
    });

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Folder deleted',
        modalType: 'success',
        duration: 3000
      }));
      if (callback) callback();
      return true
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Failed to delete folder!',
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error. please try again.',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (error) {
    console.error("Error in deleting folder:", error);
    dispatch(deleteFolderFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Failed to delete folder!',
      modalType: 'error',
      duration: 3000
    }));
  }
};

export const fetchFileCategorys = (): AppThunk => async (dispatch) => {
  try {
    const response = await apis.get('/files/get_file_types');
    dispatch(fetchFileCategorysSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchFileCategorysFailure(error.message));
  }
};

export const fetchStructure = (): AppThunk<Promise<any[]>> => async (dispatch) => {
  try {
    const response = await apis({
      method: 'GET',
      url: '/files/get_file_structure',
    });

    if (response.status === 200) {
      const structure = response.data.data; 
      dispatch(fetchStructureSuccess(structure));
      return Promise.resolve(structure);
    } else {
      const error = 'Failed to fetch structure';
      dispatch(fetchStructureFailure(error));
      return Promise.reject(new Error(error));
    }
  } catch (error: any) {
    dispatch(fetchStructureFailure(error.message));
    return Promise.reject(error);
  }
};

export const createSubFolder = (
  folderName: string,
  parentFolderId: string
): AppThunk<Promise<any>> => async (dispatch) => {
  const userId = getUserID();
  const userType = getUserType();
  try {
    const response = await apis({
      method: 'POST',
      url: '/files/create_subfolder',
      data: { UserType: userType, FolderName: folderName, ParentFolderID: parentFolderId },
    });

    if (response.status === 200) {
      const subFolder = response.data;
      dispatch(createSubFolderSuccess(subFolder));
      return Promise.resolve(subFolder);
    } else {
      const error = 'Failed to create sub folder';
      dispatch(createSubFolderFailure(error));
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
      return Promise.reject(new Error(error));
    }
  } catch (error: any) {
    dispatch(createSubFolderFailure(error.message));
    return Promise.reject(error);
  }
};


