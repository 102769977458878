import React from "react";
import Marketplace from "../components/Marketplace2/shared/Marketplace";

type MarketplaceProps = {
  activeView?: string
}

const MarketplacePage: React.FC<MarketplaceProps> = ( { activeView } ) => {
  return (
    <>
      <Marketplace  />
    </>
  );
};

export default MarketplacePage;
