// WelcomeScreen.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';
import { FaBalanceScale, FaGavel, FaFileContract } from 'react-icons/fa';
import FileUploader from '../shared/TailwindComponents/FIleUploader';
import CompanyAgent from './CompanyAgent/CompanyAgent';
import CustomAgent from './CustomAgent/CustomAgent';

interface WelcomeScreenProps {
  onFileSelect: (index: number, file: File | null) => void;
  files: (File | null)[];
}


const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
  onFileSelect,
  files,
}) => {
  const { t } = useTranslation();
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);

  const getWelcomeContent = () => {
    switch (selectedModel) {
      case 'Law Research':
        return {
          title: t('Welcome to Law Research!'),
          description: t('Get insights and detailed explanations on legal matters.'),
          icon: <i className="fa-kit fa-logo text-6xl text-primary-500"></i>,
        };
      case 'Tax Research':
        return {
          title: t('Welcome to Tax Research!'),
          description: t('Find comprehensive information on tax laws and regulations.'),
          icon: <FaGavel className="h-12 w-12 text-primary-500" />,
        };
      case 'Case Research':
        return {
          title: t('Welcome to Case Research!'),
          description: t('Analyze and summarize legal cases effectively.'),
          icon: <FaFileContract className="h-12 w-12 text-danger-500" />,
        };
        case 'Contract Analysis':
          return {
            title: t('Welcome to Contract Analysis!'),
            description: t('Upload one or two contracts for analysis or comparison.'),
            icon: <FaFileContract className="h-12 w-12 text-primary-500" />,
          };
        case 'Company Agent':
          return {
            title: t(''),
            description: t(''),
            icon: <CompanyAgent/>,
          };
        case 'Custom Agent':
          return {
            title: t(''),
            description: t(''),
            icon: <CustomAgent/>,
          };
      default:
        return {
          title: t('Welcome!'),
          description: t('Select a service to get started.'),
          icon: <i className="fa-kit fa-logo text-6xl dark:text-sky-400/100 text-primary-500"></i>,
        };
    }
  };

  const { title, description, icon } = getWelcomeContent();


  return (
    <div className="p-4 h-full w-full flex flex-col justify-center items-center space-y-6">
      {icon}
      <h1 className="text-2xl font-medium text-gray-900 dark:text-white">{title}</h1>
      <p className="text-center text-gray-600 dark:text-gray-300">{description}</p>
      {selectedModel === 'Contract Analysis' && (
  <div className="w-full max-w-xl gap-2 flex flex-row">
    <FileUploader
      label={t('Upload Contract 1')}
      onFileSelect={(file) => onFileSelect(0, file)}
      file={files[0]}
    />
    <FileUploader
      label={t('Upload Contract 2 (optional)')}
      onFileSelect={(file) => onFileSelect(1, file)}
      file={files[1]}
    />
  </div>
)}
    </div>
  );
};

export default WelcomeScreen;
