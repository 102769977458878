import React from 'react';
import { useHistory } from 'react-router-dom';

interface ClientRequestCardProps {
  request: any;
}

const ClientRequestCard: React.FC<ClientRequestCardProps> = ({ request }) => {
  const history = useHistory();

  const handleViewRequest = () => {
    history.push(`/requests/${request.RequestID}`);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <h3 className="text-xl font-bold mb-2">{request.Description}</h3>
      <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">Case ID: {request.CaseID}</p>
      <button
        onClick={handleViewRequest}
        className="mt-4 px-4 py-2 bg-primary-500 hover:bg-primary-600 text-white rounded-md"
      >
        View Details
      </button>
    </div>
  );
};

export default ClientRequestCard;
