
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
// import RootState  from './index';
import { FolderProps, FileProps } from '../components/Documents/types';


export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;



export interface RootState {
  documents: {
    loading: boolean;
    documents: FileProps[];
    error: string;
  };
  folders: {
    loading: boolean;
    folders: FolderProps[];
    error: string;
  };

}

export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_QR_CODE = "SET_QR_CODE";

// Chat folder struct
export const SET_FOLDER_VISIBILITY = "SET_FOLDER_VISIBILITY";
export const SET_DOCUMENT_VISIBILITY = "SET_DOCUMENT_VISIBILITY";
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CHATGROUPS_SUCCESS = 'FETCH_CHATGROUPS_SUCCESS';

// Documents
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

export const FETCH_FILE_TYPES_SUCCESS = 'FETCH_FILE_TYPES_SUCCESS';
export const FETCH_FILE_TYPES_FAILURE = 'FETCH_FILE_TYPES_FAILURE';

export const FETCH_SUBFOLDERS_SUCCESS = 'FETCH_SUBFOLDERS_SUCCESS';
export const FETCH_SUBFOLDERS_FAILURE = 'FETCH_SUBFOLDERS_FAILURE';

export const FETCH_STRUCTURE_SUCCESS = 'FETCH_STRUCTURE_SUCCESS';
export const FETCH_STRUCTURE_FAILURE = 'FETCH_STRUCTURE_FAILURE';

export const CREATE_SUBFOLDER_SUCCESS = 'CREATE_SUBFOLDER_SUCCESS';
export const CREATE_SUBFOLDER_FAILURE = 'CREATE_SUBFOLDER_FAILURE';


export const FETCH_FOLDERS_REQUEST = 'FETCH_FOLDERS_REQUEST';
export const FETCH_FOLDERS_SUCCESS = 'FETCH_FOLDERS_SUCCESS';
export const FETCH_FOLDERS_FAILURE = 'FETCH_FOLDERS_FAILURE';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';
export const CLEAR_CURRENT_FOLDER = 'CLEAR_CURRENT_FOLDER';
export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE';
export const RENAME_FOLDER = 'RENAME_FOLDER';

export const ADD_DOCUMENT_ASSIGNEES = 'ADD_DOCUMENT_ASSIGNEES';
export const ADD_DOCUMENT_DEADLINE = 'ADD_DOCUMENT_DEADLINE';
export const ADD_DOCUMENT_SHARED_ACCESS = 'ADD_DOCUMENT_SHARED_ACCESS';
export const ADD_DOCUMENT_APPROVER = 'ADD_DOCUMENT_SHARED_ACCESS';

  export const SIGN_FILE_REQUEST = 'SIGN_FILE_REQUEST';
  export const SIGN_FILE_SUCCESS = 'SIGN_FILE_SUCCESS';
  export const SIGN_FILE_FAILURE = 'SIGN_FILE_FAILURE';



export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';
export const FETCH_FEEDBACK_TYPES_REQUEST = 'FETCH_FEEDBACK_TYPES_REQUEST';
export const FETCH_FEEDBACK_TYPES_SUCCESS = 'FETCH_FEEDBACK_TYPES_SUCCESS';
export const FETCH_FEEDBACK_TYPES_FAILURE = 'FETCH_FEEDBACK_TYPES_FAILURE';

  

// Marketplace  


export const SUBMIT_CASE_SUCCESS = 'SUBMIT_CASE_SUCCESS';
export const SUBMIT_CASE_FAILURE = 'SUBMIT_CASE_FAILURE';

export const FETCH_CASE_LIST_SUCCESS = 'FETCH_CASE_LIST_SUCCESS';
export const FETCH_CASE_LIST_FAILURE = 'FETCH_CASE_LIST_FAILURE';
export const FETCH_CASE_LIST_LAWYER_SUCCESS = 'FETCH_CASE_LIST_SUCCESS';
export const FETCH_CASE_LIST_LAWYER_FAILURE = 'FETCH_CASE_LIST_FAILURE';
export const FETCH_LCM_LIST_SUCCESS = 'FETCH_LCM_LIST_SUCCESS';
export const UPDATE_CASE_STATUS_SUCCESS = 'UPDATE_CASE_STATUS_SUCCESS';
export const UPDATE_CASE_STATUS_FAILURE = 'UPDATE_CASE_STATUS_FAILURE';

export const FETCH_LAWYERS_SUCCESS = 'FETCH_LAWYERS_SUCCESS';
export const FETCH_LAWYERS_FAILURE = 'FETCH_LAWYERS_FAILURE';


export const GET_RATINGS_REQUEST = 'GET_RATINGS_REQUEST';
export const GET_RATINGS_SUCCESS = 'GET_RATINGS_SUCCESS';
export const GET_RATINGS_FAILURE = 'GET_RATINGS_FAILURE';

export const GET_PUBLISHED_CASES_REQUEST = 'GET_PUBLISHED_CASES_REQUEST';

export const FETCH_BIDS_SUCCESS = 'FETCH_BIDS_SUCCESS';
export const FETCH_BIDS_FAILURE = 'FETCH_BIDS_FAILURE';

export const FETCH_BIDDING_SUCCESS = 'FETCH_BIDDING_SUCCESS';
export const FETCH_BIDDING_FAILURE = 'FETCH_BIDDING_FAILURE';
export const FETCH_FULL_CASE_SUCCESS = 'FETCH_FULL_CASE_SUCCESS';
export const FETCH_FULL_CASE_FAILURE = 'FETCH_FULL_CASE_FAILURE';
export const FETCH_PROPOSAL_SUCCESS = 'FETCH_PROPOSAL_SUCCESS';
export const FETCH_PROPOSAL_FAILURE = 'FETCH_PROPOSAL_FAILURE';


export const UPDATE_BIDDING_STATUS_REQUEST = 'UPDATE_BIDDING_STATUS_REQUEST';
export const UPDATE_BIDDING_STATUS_SUCCESS = 'UPDATE_BIDDING_STATUS_SUCCESS';
export const UPDATE_BIDDING_STATUS_FAILURE = 'UPDATE_BIDDING_STATUS_FAILURE';


export const SET_EXTERNAL_ACCOUNTS = "SET_EXTERNAL_ACCOUNTS"



// LAW FIRM

export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';

// TIME KEEPING
export const FETCH_TIME_ENTRIES = "FETCH_TIME_ENTRIES";
export const SET_TIME_ENTRIES = "SET_TIME_ENTRIES";
export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";
export const PAUSE_TIMER = "PAUSE_TIMER";
export const RESUME_TIMER = "RESUME_TIMER";
export const LOG_TIME_ENTRY = "LOG_TIME_ENTRY";
export const EDIT_TIME_ENTRY = "EDIT_TIME_ENTRY";
export const DELETE_TIME_ENTRY = "DELETE_TIME_ENTRY";
export const SET_TIMER_RUNNING_STATE = "SET_TIMER_RUNNING_STATE";
export const SET_TIMER = "SET_TIMER";
export const SET_PAUSED_STATE = "SET_PAUSED_STATE";

// CLIENTS

export const ONBOARD_CLIENT = "ONBOARD_CLIENT";
export const CREATE_CASE = "CREATE_CASE";
export const UPDATE_CASE = "UPDATE_CASE";
export const FETCH_CASE = "FETCH_CASE";
export const FETCH_CLIENT = "FETCH_CLIENT";
export const DELETE_CASE_FIELD = "DELETE_CASE_FIELD";
export const ANALYZE_CASE = "ANALYZE_CASE";
export const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";
export const FETCH_ALL_CLIENT_DETAILS_SUCCESS = 'FETCH_ALL_CLIENT_DETAILS_SUCCESS';
export const FETCH_ALL_CLIENT_DETAILS_FAILURE = 'FETCH_ALL_CLIENT_DETAILS_FAILURE';
export const FETCH_ALL_EXTERNAL_CASES = "FETCH_ALL_EXTERNAL_CASES";
export const GENERATE_DOCUMENT_REQUEST = 'GENERATE_DOCUMENT_REQUEST';
export const GENERATE_DOCUMENT_SUCCESS = 'GENERATE_DOCUMENT_SUCCESS';
export const GENERATE_DOCUMENT_FAILURE = 'GENERATE_DOCUMENT_FAILURE';

// DROPDOWNS

// AI Voices
export const FETCH_AI_VOICES_REQUEST = 'FETCH_AI_VOICES_REQUEST';
export const FETCH_AI_VOICES_SUCCESS = 'FETCH_AI_VOICES_SUCCESS';
export const FETCH_AI_VOICES_FAILURE = 'FETCH_AI_VOICES_FAILURE';

// AI Font Sizes
export const FETCH_AI_FONTSIZES_REQUEST = 'FETCH_AI_FONTSIZES_REQUEST';
export const FETCH_AI_FONTSIZES_SUCCESS = 'FETCH_AI_FONTSIZES_SUCCESS';
export const FETCH_AI_FONTSIZES_FAILURE = 'FETCH_AI_FONTSIZES_FAILURE';

// AI Jurisdictions
export const FETCH_AI_JURISDICTIONS_REQUEST = 'FETCH_AI_JURISDICTIONS_REQUEST';
export const FETCH_AI_JURISDICTIONS_SUCCESS = 'FETCH_AI_JURISDICTIONS_SUCCESS';
export const FETCH_AI_JURISDICTIONS_FAILURE = 'FETCH_AI_JURISDICTIONS_FAILURE';

// AI Languages
export const FETCH_AI_LANGUAGES_REQUEST = 'FETCH_AI_LANGUAGES_REQUEST';
export const FETCH_AI_LANGUAGES_SUCCESS = 'FETCH_AI_LANGUAGES_SUCCESS';
export const FETCH_AI_LANGUAGES_FAILURE = 'FETCH_AI_LANGUAGES_FAILURE';

// Businesses
export const FETCH_BUSINESSES_REQUEST = 'FETCH_BUSINESSES_REQUEST';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAILURE = 'FETCH_BUSINESSES_FAILURE';

// Case Statuses
export const FETCH_CASE_STATUSES_REQUEST = 'FETCH_CASE_STATUSES_REQUEST';
export const FETCH_CASE_STATUSES_SUCCESS = 'FETCH_CASE_STATUSES_SUCCESS';
export const FETCH_CASE_STATUSES_FAILURE = 'FETCH_CASE_STATUSES_FAILURE';

// Case Types
export const FETCH_CASE_TYPES_REQUEST = 'FETCH_CASE_TYPES_REQUEST';
export const FETCH_CASE_TYPES_SUCCESS = 'FETCH_CASE_TYPES_SUCCESS';
export const FETCH_CASE_TYPES_FAILURE = 'FETCH_CASE_TYPES_FAILURE';

// Desired Outcomes
export const FETCH_DESIRED_OUTCOMES_REQUEST = 'FETCH_DESIRED_OUTCOMES_REQUEST';
export const FETCH_DESIRED_OUTCOMES_SUCCESS = 'FETCH_DESIRED_OUTCOMES_SUCCESS';
export const FETCH_DESIRED_OUTCOMES_FAILURE = 'FETCH_DESIRED_OUTCOMES_FAILURE';

// Expected Outcomes
export const FETCH_EXPECTED_OUTCOMES_REQUEST = 'FETCH_EXPECTED_OUTCOMES_REQUEST';
export const FETCH_EXPECTED_OUTCOMES_SUCCESS = 'FETCH_EXPECTED_OUTCOMES_SUCCESS';
export const FETCH_EXPECTED_OUTCOMES_FAILURE = 'FETCH_EXPECTED_OUTCOMES_FAILURE';

// Lawfirm Roles
export const FETCH_LAWFIRM_ROLES_REQUEST = 'FETCH_LAWFIRM_ROLES_REQUEST';
export const FETCH_LAWFIRM_ROLES_SUCCESS = 'FETCH_LAWFIRM_ROLES_SUCCESS';
export const FETCH_LAWFIRM_ROLES_FAILURE = 'FETCH_LAWFIRM_ROLES_FAILURE';

// Legal Subjects
export const FETCH_LEGAL_SUBJECTS_REQUEST = 'FETCH_LEGAL_SUBJECTS_REQUEST';
export const FETCH_LEGAL_SUBJECTS_SUCCESS = 'FETCH_LEGAL_SUBJECTS_SUCCESS';
export const FETCH_LEGAL_SUBJECTS_FAILURE = 'FETCH_LEGAL_SUBJECTS_FAILURE';

// Platform Currencies
export const FETCH_PLATFORM_CURRENCIES_REQUEST = 'FETCH_PLATFORM_CURRENCIES_REQUEST';
export const FETCH_PLATFORM_CURRENCIES_SUCCESS = 'FETCH_PLATFORM_CURRENCIES_SUCCESS';
export const FETCH_PLATFORM_CURRENCIES_FAILURE = 'FETCH_PLATFORM_CURRENCIES_FAILURE';

// Platform Languages
export const FETCH_PLATFORM_LANGUAGES_REQUEST = 'FETCH_PLATFORM_LANGUAGES_REQUEST';
export const FETCH_PLATFORM_LANGUAGES_SUCCESS = 'FETCH_PLATFORM_LANGUAGES_SUCCESS';
export const FETCH_PLATFORM_LANGUAGES_FAILURE = 'FETCH_PLATFORM_LANGUAGES_FAILURE';

// Practice Areas
export const FETCH_PRACTICE_AREAS_REQUEST = 'FETCH_PRACTICE_AREAS_REQUEST';
export const FETCH_PRACTICE_AREAS_SUCCESS = 'FETCH_PRACTICE_AREAS_SUCCESS';
export const FETCH_PRACTICE_AREAS_FAILURE = 'FETCH_PRACTICE_AREAS_FAILURE';

// Preferred Languages
export const FETCH_PREFERRED_LANGUAGES_REQUEST = 'FETCH_PREFERRED_LANGUAGES_REQUEST';
export const FETCH_PREFERRED_LANGUAGES_SUCCESS = 'FETCH_PREFERRED_LANGUAGES_SUCCESS';
export const FETCH_PREFERRED_LANGUAGES_FAILURE = 'FETCH_PREFERRED_LANGUAGES_FAILURE';

// Urgency Status
export const FETCH_URGENCY_STATUS_REQUEST = 'FETCH_URGENCY_STATUS_REQUEST';
export const FETCH_URGENCY_STATUS_SUCCESS = 'FETCH_URGENCY_STATUS_SUCCESS';
export const FETCH_URGENCY_STATUS_FAILURE = 'FETCH_URGENCY_STATUS_FAILURE';

// Priority
export const FETCH_PRIORITY_REQUEST = 'FETCH_PRIORITY_REQUEST';
export const FETCH_PRIORITY_SUCCESS = 'FETCH_PRIORITY_SUCCESS';
export const FETCH_PRIORITY_FAILURE = 'FETCH_PRIORITY_FAILURE';

// Project Status
export const FETCH_PROJECT_STATUS_REQUEST = 'FETCH_PROJECT_STATUS_REQUEST';
export const FETCH_PROJECT_STATUS_SUCCESS = 'FETCH_PROJECT_STATUS_SUCCESS';
export const FETCH_PROJECT_STATUS_FAILURE = 'FETCH_PROJECT_STATUS_FAILURE';

// Project Category
export const FETCH_PROJECT_CATEGORY_REQUEST = 'FETCH_PROJECT_CATEGORY_REQUEST';
export const FETCH_PROJECT_CATEGORY_SUCCESS = 'FETCH_PROJECT_CATEGORY_SUCCESS';
export const FETCH_PROJECT_CATEGORY_FAILURE = 'FETCH_PROJECT_CATEGORY_FAILURE';

export const FETCH_COMPANY_SETTINGS_REQUEST = 'FETCH_COMPANY_SETTINGS_REQUEST';
export const FETCH_COMPANY_SETTINGS_SUCCESS = 'FETCH_COMPANY_SETTINGS_SUCCESS';
export const FETCH_COMPANY_SETTINGS_FAILURE = 'FETCH_COMPANY_SETTINGS_FAILURE';

export const UPDATE_COMPANY_SETTINGS_REQUEST = 'UPDATE_COMPANY_SETTINGS_REQUEST';
export const UPDATE_COMPANY_SETTINGS_SUCCESS = 'UPDATE_COMPANY_SETTINGS_SUCCESS';
export const UPDATE_COMPANY_SETTINGS_FAILURE = 'UPDATE_COMPANY_SETTINGS_FAILURE';

// company action types
export const POST_COMPANY_FILES_REQUEST = 'POST_COMPANY_FILES_REQUEST';
export const POST_COMPANY_FILES_SUCCESS = 'POST_COMPANY_FILES_SUCCESS';
export const POST_COMPANY_FILES_FAILURE = 'POST_COMPANY_FILES_FAILURE';
export const POST_ADDITIONAL_COMPANY_FILES_REQUEST = 'POST_ADDITIONAL_COMPANY_FILES_REQUEST';
export const POST_ADDITIONAL_COMPANY_FILES_SUCCESS = 'POST_ADDITIONAL_COMPANY_FILES_SUCCESS';
export const POST_ADDITIONAL_COMPANY_FILES_FAILURE = 'POST_ADDITIONAL_COMPANY_FILES_FAILURE';
export const FETCH_INDEX_INFO_REQUEST = 'FETCH_INDEX_INFO_REQUEST';
export const FETCH_INDEX_INFO_SUCCESS = 'FETCH_INDEX_INFO_SUCCESS';
export const FETCH_INDEX_INFO_FAILURE = 'FETCH_INDEX_INFO_FAILURE';
export const FETCH_BLOB_DOCUMENTS_REQUEST = 'FETCH_BLOB_DOCUMENTS_REQUEST';
export const FETCH_BLOB_DOCUMENTS_SUCCESS = 'FETCH_BLOB_DOCUMENTS_SUCCESS';
export const FETCH_BLOB_DOCUMENTS_FAILURE = 'FETCH_BLOB_DOCUMENTS_FAILURE';
export const DELETE_BLOB_DOCUMENT_SUCCESS = 'DELETE_BLOB_DOCUMENT_SUCCESS';
export const DELETE_BLOB_DOCUMENT_FAILURE = 'DELETE_BLOB_DOCUMENT_FAILURE';
export const RERUN_INDEX_REQUEST = 'RERUN_INDEX_REQUEST';
export const RERUN_INDEX_SUCCESS = 'RERUN_INDEX_SUCCESS';
export const RERUN_INDEX_FAILURE = 'RERUN_INDEX_FAILURE';
export const DELETE_INDEX_REQUEST = 'DELETE_INDEX_REQUEST';
export const DELETE_INDEX_SUCCESS = 'DELETE_INDEX_SUCCESS';
export const DELETE_INDEX_FAILURE = 'DELETE_INDEX_FAILURE';

export const POLL_INDEX_STATUS_START = 'POLL_INDEX_STATUS_START';
export const POLL_INDEX_STATUS_STOP = 'POLL_INDEX_STATUS_STOP';
export const UPDATE_INDEX_STATUS_MESSAGE = 'UPDATE_INDEX_STATUS_MESSAGE';

// custom agent types

export const POST_CUSTOM_FILES_REQUEST = 'POST_CUSTOM_FILES_REQUEST';
export const POST_CUSTOM_FILES_SUCCESS = 'POST_CUSTOM_FILES_SUCCESS';
export const POST_CUSTOM_FILES_FAILURE = 'POST_CUSTOM_FILES_FAILURE';
export const POST_ADDITIONAL_CUSTOM_FILES_REQUEST = 'POST_ADDITIONAL_CUSTOM_FILES_REQUEST';
export const POST_ADDITIONAL_CUSTOM_FILES_SUCCESS = 'POST_ADDITIONAL_CUSTOM_FILES_SUCCESS';
export const POST_ADDITIONAL_CUSTOM_FILES_FAILURE = 'POST_ADDITIONAL_CUSTOM_FILES_FAILURE';
export const FETCH_CUSTOM_INDEX_INFO_REQUEST = 'FETCH_CUSTOM_INDEX_INFO_REQUEST';
export const FETCH_CUSTOM_INDEX_INFO_SUCCESS = 'FETCH_CUSTOM_INDEX_INFO_SUCCESS';
export const FETCH_CUSTOM_INDEX_INFO_FAILURE = 'FETCH_CUSTOM_INDEX_INFO_FAILURE';
export const FETCH_CUSTOM_BLOB_DOCUMENTS_REQUEST = 'FETCH_CUSTOM_BLOB_DOCUMENTS_REQUEST';
export const FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS = 'FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS';
export const FETCH_CUSTOM_BLOB_DOCUMENTS_FAILURE = 'FETCH_CUSTOM_BLOB_DOCUMENTS_FAILURE';
export const DELETE_CUSTOM_BLOB_DOCUMENT_SUCCESS = 'DELETE_CUSTOM_BLOB_DOCUMENT_SUCCESS';
export const DELETE_CUSTOM_BLOB_DOCUMENT_FAILURE = 'DELETE_CUSTOM_BLOB_DOCUMENT_FAILURE';
export const RERUN_CUSTOM_INDEX_REQUEST = 'RERUN_CUSTOM_INDEX_REQUEST';
export const RERUN_CUSTOM_INDEX_SUCCESS = 'RERUN_CUSTOM_INDEX_SUCCESS';
export const RERUN_CUSTOM_INDEX_FAILURE = 'RERUN_CUSTOM_INDEX_FAILURE';

export const POLL_CUSTOM_INDEX_STATUS_START = 'POLL_CUSTOM_INDEX_STATUS_START';
export const POLL_CUSTOM_INDEX_STATUS_STOP = 'POLL_CUSTOM_INDEX_STATUS_STOP';
export const UPDATE_CUSTOM_INDEX_STATUS_MESSAGE = 'UPDATE_CUSTOM_INDEX_STATUS_MESSAGE';


// data action types
export const SET_COUNTRY = "SET_COUNTRY"
export const SET_CURRENCY = "SET_CURRENCY"
export const SET_STATE = "SET_STATE"
export const SET_CITY = "SET_CITY"
export const USER_INFO = "USER_INFO"
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const SET_AI_SETTINGS = 'SET_AI_SETTINGS';
export const GET_AI_SETTINGS_SUCCESS = 'GET_AI_SETTINGS_SUCCESS';
export const SET_CHAT_LIST = "SET_CHAT_LIST"
export const SET_MESSAGE_LIST = "SET_MESSAGE_LIST"
export const SET_AI_VOICES = 'SET_AI_VOICES'; 
export const SET_USER_TYPES = 'SET_USER_TYPES';

//  docuent types 

export const FETCH_CATEGORIES_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const FETCH_RECOMMENDED_DOCUMENTS_REQUEST = 'FETCH_RECOMMENDED_DOCUMENTS_REQUEST';
export const FETCH_RECOMMENDED_DOCUMENTS_FAILURE = 'FETCH_RECOMMENDED_DOCUMENTS_FAILURE';
export const FETCH_RECOMMENDED_DOCUMENTS_SUCCESS = 'FETCH_RECOMMENDED_DOCUMENTS_SUCCESS';
// export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
// export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
// export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const UPDATE_FOLDERS_WITH_DOCUMENTS = 'UPDATE_FOLDERS_WITH_DOCUMENTS';
export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';
export const SELECT_DOCUMENT_FOR_EDITING = 'SELECT_DOCUMENT_FOR_EDITING';
export const RESET_SELECTED_DOCUMENT = 'RESET_SELECTED_DOCUMENT';
export const ADD_SIGNATURE_REQUEST = 'ADD_SIGNATURE_REQUEST';
export const ADD_SIGNATURE_SUCCESS = 'ADD_SIGNATURE_SUCCESS';
export const ADD_SIGNATURE_FAILURE = 'ADD_SIGNATURE_FAILURE';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';
export const FETCH_ANONYMIZATION_DATASETS_REQUEST = 'FETCH_ANONYMIZATION_DATASETS_REQUEST';
export const FETCH_ANONYMIZATION_DATASETS_SUCCESS = 'FETCH_ANONYMIZATION_DATASETS_SUCCESS';
export const FETCH_ANONYMIZATION_DATASETS_FAILURE = 'FETCH_ANONYMIZATION_DATASETS_FAILURE';
export const ANONYMIZE_DOCUMENT_REQUEST = 'ANONYMIZE_DOCUMENT_REQUEST';
export const ANONYMIZE_DOCUMENT_SUCCESS = 'ANONYMIZE_DOCUMENT_SUCCESS';
export const ANONYMIZE_DOCUMENT_FAILURE = 'ANONYMIZE_DOCUMENT_FAILURE';
export const FETCH_FILE_DETAILS_REQUEST = 'FETCH_FILE_DETAILS_REQUEST';
export const FETCH_FILE_DETAILS_SUCCESS = 'FETCH_FILE_DETAILS_SUCCESS';
export const FETCH_FILE_DETAILS_FAILURE = 'FETCH_FILE_DETAILS_FAILURE';
export const FETCH_DOCUMENT_VERSIONS_REQUEST = 'FETCH_DOCUMENT_VERSIONS_REQUEST';
export const FETCH_DOCUMENT_VERSIONS_SUCCESS = 'FETCH_DOCUMENT_VERSIONS_SUCCESS';
export const FETCH_DOCUMENT_VERSIONS_FAILURE = 'FETCH_DOCUMENT_VERSIONS_FAILURE';
export const FETCH_TRANSLATION_LANGUAGES_REQUEST = 'FETCH_TRANSLATION_LANGUAGES_REQUEST';
export const FETCH_TRANSLATION_LANGUAGES_SUCCESS = 'FETCH_TRANSLATION_LANGUAGES_SUCCESS';
export const FETCH_TRANSLATION_LANGUAGES_FAILURE = 'FETCH_TRANSLATION_LANGUAGES_FAILURE';
export const TRANSLATE_DOCUMENT_REQUEST = 'TRANSLATE_DOCUMENT_REQUEST';
export const TRANSLATE_DOCUMENT_SUCCESS = 'TRANSLATE_DOCUMENT_SUCCESS';
export const TRANSLATE_DOCUMENT_FAILURE = 'TRANSLATE_DOCUMENT_FAILURE';


export const SET_FONT_SIZE = 'SET_FONT_SIZE';


// helo center types\
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';

export const FETCH_HELP_REQUEST = 'FETCH_HELP_REQUEST';
export const FETCH_HELP_SUCCESS = 'FETCH_HELP_SUCCESS';
export const FETCH_HELP_FAILURE = 'FETCH_HELP_FAILURE';

export const FETCH_USER_HELP_REQUEST = 'FETCH_USER_HELP_REQUEST';
export const FETCH_USER_HELP_SUCCESS = 'FETCH_USER_HELP_SUCCESS';
export const FETCH_USER_HELP_FAILURE = 'FETCH_USER_HELP_FAILURE';

// export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
// export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
// export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const FETCH_TICKET_REQUEST = 'FETCH_TICKET_REQUEST';
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export const FETCH_TICKET_FAILURE = 'FETCH_TICKET_FAILURE';

export const FETCH_ALL_TICKETS_REQUEST = 'FETCH_ALL_TICKETS_REQUEST';
export const FETCH_ALL_TICKETS_SUCCESS = 'FETCH_ALL_TICKETS_SUCCESS';
export const FETCH_ALL_TICKETS_FAILURE = 'FETCH_ALL_TICKETS_FAILURE';

export const FETCH_ALL_USER_TICKETS_REQUEST = 'FETCH_ALL_USER_TICKETS_REQUEST';
export const FETCH_ALL_USER_TICKETS_SUCCESS = 'FETCH_ALL_USER_TICKETS_SUCCESS';
export const FETCH_ALL_USER_TICKETS_FAILURE = 'FETCH_ALL_USER_TICKETS_FAILURE';

export const FETCH_RESOLVED_TICKETS_REQUEST = 'FETCH_RESOLVED_TICKETS_REQUEST';
export const FETCH_RESOLVED_TICKETS_SUCCESS = 'FETCH_RESOLVED_TICKETS_SUCCESS';
export const FETCH_RESOLVED_TICKETS_FAILURE = 'FETCH_RESOLVED_TICKETS_FAILURE';

export const FETCH_UNRESOLVED_TICKETS_REQUEST = 'FETCH_UNRESOLVED_TICKETS_REQUEST';
export const FETCH_UNRESOLVED_TICKETS_SUCCESS = 'FETCH_UNRESOLVED_TICKETS_SUCCESS';
export const FETCH_UNRESOLVED_TICKETS_FAILURE = 'FETCH_UNRESOLVED_TICKETS_FAILURE';

export const RESOLVE_TICKET_REQUEST = 'RESOLVE_TICKET_REQUEST';
export const RESOLVE_TICKET_SUCCESS = 'RESOLVE_TICKET_SUCCESS';
export const RESOLVE_TICKET_FAILURE = 'RESOLVE_TICKET_FAILURE';

// lawfir actions
export const FETCH_LAWYERS_FIRM_REQUEST = 'FETCH_LAWYERS_FIRM_REQUEST';
export const FETCH_LAWYERS_FIRM_SUCCESS = 'FETCH_LAWYERS_FIRM_SUCCESS';
export const FETCH_LAWYERS_FIRM_FAILURE = 'FETCH_LAWYERS_FIRM_FAILURE';
export const FETCH_CASES_FIRM_REQUEST = 'FETCH_CASES_FIRM_REQUEST';
export const FETCH_CASES_FIRM_SUCCESS = 'FETCH_CASES_FIRM_SUCCESS';
export const FETCH_CASES_FIRM_FAILURE = 'FETCH_CASES_FIRM_FAILURE';
export const FETCH_LEGAL_SUBJECTS_TEAM_REQUEST = 'FETCH_LEGAL_SUBJECTS_TEAM_REQUEST';
export const FETCH_LEGAL_SUBJECTS_TEAM_SUCCESS = 'FETCH_LEGAL_SUBJECTS_TEAM_SUCCESS';
export const FETCH_LEGAL_SUBJECTS_TEAM_FAILURE = 'FETCH_LEGAL_SUBJECTS_TEAM_FAILURE';
export const FETCH_LEGAL_TEAMS_REQUEST = 'FETCH_LEGAL_TEAMS_REQUEST';
export const FETCH_LEGAL_TEAMS_SUCCESS = 'FETCH_LEGAL_TEAMS_SUCCESS';
export const FETCH_LEGAL_TEAMS_FAILURE = 'FETCH_LEGAL_TEAMS_FAILURE';
export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';
export const FETCH_TEAM_REQUEST = 'FETCH_TEAM_REQUEST';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE';


export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const SET_SLIDER_POSITION = 'SET_SLIDER_POSITION';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SET_JURISDICTION_COUNTRY = "SET_JURISDICTION_COUNTRY"

export const FETCH_PRICING_SUCCESS = 'FETCH_PRICING_SUCCESS';
export const TOGGLE_PRICING_MODE = 'TOGGLE_PRICING_MODE';
export const TOGGLE_SUBSCRIPTION_PERIOD = 'TOGGLE_SUBSCRIPTION_PERIOD';
export const SET_VOICE = 'SET_VOICE';
export const SET_SPEECH_CONFIG = 'SET_SPEECH_CONFIG';
export const SHOW_FEEDBACK_MODAL = 'SHOW_FEEDBACK_MODAL';
export const HIDE_FEEDBACK_MODAL = 'HIDE_FEEDBACK_MODAL';
export const SELECT_CASE_FOR_VIEWING = 'SELECT_CASE_FOR_VIEWING';
export const SET_DETAILS_CONTENT = 'SET_DETAILS_CONTENT';
export const FETCH_TODO_LIST = "FETCH_TODO_LIST"
export const FETCH_ACTIVE_TODO_LIST = "FETCH_ACTIVE_TODO_LIST"
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_CHAT_GROUP_ID = 'SET_CHAT_GROUP_ID';
export const SET_CHAT_THEME = 'SET_CHAT_THEME';
export const SET_SESSIONS = 'SET_SESSIONS';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const FETCH_URGENCY_LIST = 'FETCH_URGENCY_LIST';
export const FETCH_STATUS_LIST = 'FETCH_STATUS_LIST';


// USER SWITCH
export const SWITCH_USER_REQUEST = 'SWITCH_USER_REQUEST';
export const SWITCH_USER_SUCCESS = 'SWITCH_USER_SUCCESS';
export const SWITCH_USER_FAILURE = 'SWITCH_USER_FAILURE';
export const SET_LICENSE_REQUESTS = 'SET_LICENSE_REQUESTS';
export const SET_LICENSE_DATA = 'SET_LICENSE_DATA';

// USER MANUAL
export const GET_USER_MANUAL_REQUEST = 'GET_USER_MANUAL_REQUEST';
export const GET_USER_MANUAL_SUCCESS = 'GET_USER_MANUAL_SUCCESS';
export const GET_USER_MANUAL_FAILURE = 'GET_USER_MANUAL_FAILURE';

// CASE MANAGEMENT

export const CREATE_LCM_CLIENT_CASE_REQUEST = 'CREATE_LCM_CLIENT_CASE_REQUEST';
export const CREATE_LCM_CLIENT_CASE_SUCCESS = 'CREATE_LCM_CLIENT_CASE_SUCCESS';
export const CREATE_LCM_CLIENT_CASE_FAILURE = 'CREATE_LCM_CLIENT_CASE_FAILURE';

export const CREATE_LCM_BUSINESS_CASE_REQUEST = 'CREATE_LCM_BUSINESS_CASE_REQUEST';
export const CREATE_LCM_BUSINESS_CASE_SUCCESS = 'CREATE_LCM_BUSINESS_CASE_SUCCESS';
export const CREATE_LCM_BUSINESS_CASE_FAILURE = 'CREATE_LCM_BUSINESS_CASE_FAILURE';

export const RUN_ANALYSIS_LCM_CLIENT_REQUEST = 'RUN_ANALYSIS_LCM_CLIENT_REQUEST';
export const RUN_ANALYSIS_LCM_CLIENT_SUCCESS = 'RUN_ANALYSIS_LCM_CLIENT_SUCCESS';
export const RUN_ANALYSIS_LCM_CLIENT_FAILURE = 'RUN_ANALYSIS_LCM_CLIENT_FAILURE';

export const RUN_ANALYSIS_LCM_BUSINESS_REQUEST = 'RUN_ANALYSIS_LCM_BUSINESS_REQUEST';
export const RUN_ANALYSIS_LCM_BUSINESS_SUCCESS = 'RUN_ANALYSIS_LCM_BUSINESS_SUCCESS';
export const RUN_ANALYSIS_LCM_BUSINESS_FAILURE = 'RUN_ANALYSIS_LCM_BUSINESS_FAILURE';

export const GET_PROGRESS_LCM_CLIENT_REQUEST = 'GET_PROGRESS_LCM_CLIENT_REQUEST';
export const GET_PROGRESS_LCM_CLIENT_SUCCESS = 'GET_PROGRESS_LCM_CLIENT_SUCCESS';
export const GET_PROGRESS_LCM_CLIENT_FAILURE = 'GET_PROGRESS_LCM_CLIENT_FAILURE';

export const GET_PROGRESS_LCM_BUSINESS_REQUEST = 'GET_PROGRESS_LCM_BUSINESS_REQUEST';
export const GET_PROGRESS_LCM_BUSINESS_SUCCESS = 'GET_PROGRESS_LCM_BUSINESS_SUCCESS';
export const GET_PROGRESS_LCM_BUSINESS_FAILURE = 'GET_PROGRESS_LCM_BUSINESS_FAILURE';

export const FETCH_ALL_LCM_CLIENT_CASES_REQUEST = 'FETCH_ALL_LCM_CLIENT_CASES_REQUEST';
export const FETCH_ALL_LCM_CLIENT_CASES_SUCCESS = 'FETCH_ALL_LCM_CLIENT_CASES_SUCCESS';
export const FETCH_ALL_LCM_CLIENT_CASES_FAILURE = 'FETCH_ALL_LCM_CLIENT_CASES_FAILURE';

export const FETCH_ALL_LCM_BUSINESS_CASES_REQUEST = 'FETCH_ALL_LCM_BUSINESS_CASES_REQUEST';
export const FETCH_ALL_LCM_BUSINESS_CASES_SUCCESS = 'FETCH_ALL_LCM_BUSINESS_CASES_SUCCESS';
export const FETCH_ALL_LCM_BUSINESS_CASES_FAILURE = 'FETCH_ALL_LCM_BUSINESS_CASES_FAILURE';

export const SET_SELECTED_LCM_CASE = 'SET_SELECTED_LCM_CASE';
export const CLEAR_SELECTED_LCM_CASE = 'CLEAR_SELECTED_LCM_CASE';
export const SET_SELECTED_ANALYSIS = 'SET_SELECTED_ANALYSIS';
export const CLEAR_SELECTED_ANALYSIS = 'CLEAR_SELECTED_ANALYSIS';


// CASE MANAGEMENT

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';

export const ADD_PROJECT_ASSIGNEE_REQUEST = 'ADD_PROJECT_ASSIGNEE_REQUEST';
export const ADD_PROJECT_ASSIGNEE_SUCCESS = 'ADD_PROJECT_ASSIGNEE_SUCCESS';
export const ADD_PROJECT_ASSIGNEE_FAILURE = 'ADD_PROJECT_ASSIGNEE_FAILURE';

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const FETCH_PROJECT_DETAILS_REQUEST = 'FETCH_PROJECT_DETAILS_REQUEST';
export const FETCH_PROJECT_DETAILS_SUCCESS = 'FETCH_PROJECT_DETAILS_SUCCESS';
export const FETCH_PROJECT_DETAILS_FAILURE = 'FETCH_PROJECT_DETAILS_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const APPROVE_PROJECT_REQUEST = 'APPROVE_PROJECT_REQUEST';
export const APPROVE_PROJECT_SUCCESS = 'APPROVE_PROJECT_SUCCESS';
export const APPROVE_PROJECT_FAILURE = 'APPROVE_PROJECT_FAILURE';

export const FETCH_USER_PROJECTS_REQUEST = 'FETCH_USER_PROJECTS_REQUEST';
export const FETCH_USER_PROJECTS_SUCCESS = 'FETCH_USER_PROJECTS_SUCCESS';
export const FETCH_USER_PROJECTS_FAILURE = 'FETCH_USER_PROJECTS_FAILURE';

// BUSINESS
export const FETCH_ALL_EMPLOYEES_REQUEST = 'FETCH_ALL_EMPLOYEES_REQUEST';
export const FETCH_ALL_EMPLOYEES_SUCCESS = 'FETCH_ALL_EMPLOYEES_SUCCESS';
export const FETCH_ALL_EMPLOYEES_FAILURE = 'FETCH_ALL_EMPLOYEES_FAILURE';

// BUSINESS
export const FETCH_WHITELABEL_SETTINGS_SUCCESS = 'FETCH_WHITELABEL_SETTINGS_SUCCESS';
export const FETCH_WHITELABEL_SETTINGS_FAILURE = 'FETCH_WHITELABEL_SETTINGS_FAILURE';

export const UPDATE_WHITELABEL_SETTINGS_SUCCESS = 'UPDATE_WHITELABEL_SETTINGS_SUCCESS';
export const UPDATE_WHITELABEL_SETTINGS_FAILURE = 'UPDATE_WHITELABEL_SETTINGS_FAILURE';

// TASKS

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASK_DETAILS = "FETCH_TASK_DETAILS";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const ASSIGN_TASK_SUCCESS = "ASSIGN_TASK_SUCCESS";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const COMPLETE_TASK_SUCCESS = "COMPLETE_TASK_SUCCESS";

// EMPLOYEES
export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';
export const ONBOARD_EMPLOYEE_REQUEST = 'ONBOARD_EMPLOYEE_REQUEST';
export const ONBOARD_EMPLOYEE_SUCCESS = 'ONBOARD_EMPLOYEE_SUCCESS';
export const ONBOARD_EMPLOYEE_FAILURE = 'ONBOARD_EMPLOYEE_FAILURE';
export const FETCH_EMPLOYEE_DETAILS_REQUEST = 'FETCH_EMPLOYEE_DETAILS_REQUEST';
export const FETCH_EMPLOYEE_DETAILS_SUCCESS = 'FETCH_EMPLOYEE_DETAILS_SUCCESS';
export const FETCH_EMPLOYEE_DETAILS_FAILURE = 'FETCH_EMPLOYEE_DETAILS_FAILURE';
export const CLEAR_EMPLOYEE_DETAILS = 'CLEAR_EMPLOYEE_DETAILS';

// TEAMS

export const FETCH_TEAMS_REQUEST = 'FETCH_TEAMS_REQUEST';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const FETCH_TEAM_DETAILS_REQUEST = 'FETCH_TEAM_DETAILS_REQUEST';
export const FETCH_TEAM_DETAILS_SUCCESS = 'FETCH_TEAM_DETAILS_SUCCESS';
export const FETCH_TEAM_DETAILS_FAILURE = 'FETCH_TEAM_DETAILS_FAILURE';


// MARKETPLACE

export const PUBLISH_CASE_SUCCESS = 'PUBLISH_CASE_SUCCESS';
export const PUBLISH_CASE_FAILURE = 'PUBLISH_CASE_FAILURE';

export const GET_PUBLISHED_CASES_SUCCESS = 'GET_PUBLISHED_CASES_SUCCESS';
export const GET_PUBLISHED_CASES_FAILURE = 'GET_PUBLISHED_CASES_FAILURE';

export const GET_LAWYERS_REQUEST = 'GET_LAWYERS_REQUEST';
export const GET_LAWYERS_SUCCESS = 'GET_LAWEYERS_SUCCESS';
export const GET_LAWYERS_FAILURE = 'GET_LAWEYERS_FAILURE';

export const UPLOAD_FINAL_DOCUMENT_SUCCESS = 'UPLOAD_FINAL_DOCUMENT_SUCCESS';
export const UPLOAD_FINAL_DOCUMENT_FAILURE = 'UPLOAD_FINAL_DOCUMENT_FAILURE';

export const GET_FINAL_DOCUMENTS_SUCCESS = 'GET_FINAL_DOCUMENTS_SUCCESS';
export const GET_FINAL_DOCUMENTS_FAILURE = 'GET_FINAL_DOCUMENTS_FAILURE';

export const SUBMIT_LAWYER_RATING_SUCCESS = 'SUBMIT_LAWYER_RATING_SUCCESS';
export const SUBMIT_LAWYER_RATING_FAILURE = 'SUBMIT_LAWYER_RATING_FAILURE';

export const GET_LAWYER_PROFILE_REQUEST = 'GET_LAWYER_PROFILE_REQUEST';
export const GET_LAWYER_PROFILE_SUCCESS = 'GET_LAWYER_PROFILE_SUCCESS';
export const GET_LAWYER_PROFILE_FAILURE = 'GET_LAWYER_PROFILE_FAILURE';

export const GET_CLIENT_BIDS_REQUEST = 'GET_CLIENT_BIDS_REQUEST';
export const GET_CLIENT_BIDS_SUCCESS = 'GET_CLIENT_BIDS_SUCCESS';
export const GET_CLIENT_BIDS_FAILURE = 'GET_CLIENT_BIDS_FAILURE';

export const SUBMIT_BID_REQUEST = 'SUBMIT_BID_REQUEST';
export const SUBMIT_BID_SUCCESS = 'SUBMIT_BID_SUCCESS';
export const SUBMIT_BID_FAILURE = 'SUBMIT_BID_FAILURE';

export const GET_CASE_BIDS_SUCCESS = 'GET_CASE_BIDS_SUCCESS';
export const GET_CASE_BIDS_FAILURE = 'GET_CASE_BIDS_FAILURE';

export const ACCEPT_BID_SUCCESS = 'ACCEPT_BID_SUCCESS';
export const ACCEPT_BID_FAILURE = 'ACCEPT_BID_FAILURE';

export const REJECT_BID_SUCCESS = 'REJECT_BID_SUCCESS';
export const REJECT_BID_FAILURE = 'REJECT_BID_FAILURE';

export const WITHDRAW_BID_SUCCESS = 'WITHDRAW_BID_SUCCESS';
export const WITHDRAW_BID_FAILURE = 'WITHDRAW_BID_FAILURE';

export const PROPOSE_CASE_REQUEST = 'PROPOSE_CASE_REQUEST';
export const PROPOSE_CASE_SUCCESS = 'PROPOSE_CASE_SUCCESS';
export const PROPOSE_CASE_FAILURE = 'PROPOSE_CASE_FAILURE';

export const GET_CLIENT_REQUESTS_REQUEST = 'GET_CLIENT_REQUESTS_REQUEST';
export const GET_CLIENT_REQUESTS_SUCCESS = 'GET_CLIENT_REQUESTS_SUCCESS';
export const GET_CLIENT_REQUESTS_FAILURE = 'GET_CLIENT_REQUESTS_FAILURE';

export const GET_INCOMING_CLIENT_REQUESTS_REQUEST = 'GET_INCOMING_CLIENT_REQUESTS_REQUEST';
export const GET_INCOMING_CLIENT_REQUESTS_SUCCESS = 'GET_INCOMING_CLIENT_REQUESTS_SUCCESS';
export const GET_INCOMING_CLIENT_REQUESTS_FAILURE = 'GET_INCOMING_CLIENT_REQUESTS_FAILURE';

export const ACCEPT_CLIENT_REQUEST_SUCCESS = 'ACCEPT_CLIENT_REQUEST_SUCCESS';
export const ACCEPT_CLIENT_REQUEST_FAILURE = 'ACCEPT_CLIENT_REQUEST_FAILURE';

export const REJECT_CLIENT_REQUEST_SUCCESS = 'REJECT_CLIENT_REQUEST_SUCCESS';
export const REJECT_CLIENT_REQUEST_FAILURE = 'REJECT_CLIENT_REQUEST_FAILURE';

export const SET_MARKETPLACE_ACTIVE_SUCCESS = 'SET_MARKETPLACE_ACTIVE_SUCCESS';
export const SET_MARKETPLACE_ACTIVE_FAILURE = 'SET_MARKETPLACE_ACTIVE_FAILURE';

export const GET_MARKETPLACE_SETTINGS_REQUEST = 'GET_MARKETPLACE_SETTINGS_REQUEST';
export const GET_MARKETPLACE_SETTINGS_SUCCESS = 'GET_MARKETPLACE_SETTINGS_SUCCESS';
export const GET_MARKETPLACE_SETTINGS_FAILURE = 'GET_MARKETPLACE_SETTINGS_FAILURE';

export const UPDATE_MARKETPLACE_SETTINGS_REQUEST = 'UPDATE_MARKETPLACE_SETTINGS_REQUEST';
export const UPDATE_MARKETPLACE_SETTINGS_SUCCESS = 'UPDATE_MARKETPLACE_SETTINGS_SUCCESS';
export const UPDATE_MARKETPLACE_SETTINGS_FAILURE = 'UPDATE_MARKETPLACE_SETTINGS_FAILURE';

export const UPLOAD_FIRM_LOGO_REQUEST = 'UPLOAD_FIRM_LOGO_REQUEST';
export const UPLOAD_FIRM_LOGO_SUCCESS = 'UPLOAD_FIRM_LOGO_SUCCESS';
export const UPLOAD_FIRM_LOGO_FAILURE = 'UPLOAD_FIRM_LOGO_FAILURE';

export const UPLOAD_PROFILE_PICTURE_REQUEST = 'UPLOAD_PROFILE_PICTURE_REQUEST';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILURE = 'UPLOAD_PROFILE_PICTURE_FAILURE';

export const GET_LAWYER_TOS_REQUEST = 'GET_LAWYER_TOS_REQUEST';
export const GET_LAWYER_TOS_SUCCESS = 'GET_LAWYER_TOS_SUCCESS';
export const GET_LAWYER_TOS_FAILURE = 'GET_LAWYER_TOS_FAILURE';

export const UPDATE_LAWYER_TOS_REQUEST = 'UPDATE_LAWYER_TOS_REQUEST';
export const UPDATE_LAWYER_TOS_SUCCESS = 'UPDATE_LAWYER_TOS_SUCCESS';
export const UPDATE_LAWYER_TOS_FAILURE = 'UPDATE_LAWYER_TOS_FAILURE';