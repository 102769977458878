import { SetFontSizeAction } from "../actions/FontSizeAction";
import { SET_FONT_SIZE } from "../types";

function getInitialFontSize() {
  const savedSettings = localStorage.getItem('aiSettings');
  if (savedSettings) {
    const settings = JSON.parse(savedSettings);
    return settings.FontSize;
  }
  return '14'; 
}

export interface FontSizeState {
  fontSize: string;
}

const initialState: FontSizeState = {
  fontSize: getInitialFontSize(), 
};

export const fontSizeReducer = (state = initialState, action: SetFontSizeAction): FontSizeState => {
  switch (action.type) {
    case SET_FONT_SIZE:
      return {
        ...state,
        fontSize: action.payload.fontSize,
      };
    default:
      return state;
  }
};
