import React from 'react';
import Badge from './Badge';

interface CardProps {
  title: string;
  price?: string;
  location?: string;
  description?: string;
  status?: string;
  meta?: { label: string; value: string }[];
  imageUrl?: string;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ title, price, location, status, meta, imageUrl, onClick }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-custom-light dark:shadow-custom-dark overflow-hidden cursor-pointer" onClick={onClick}>
      {imageUrl && <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />}
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <div className="flex items-center justify-between mb-2">
          <span className="text-xl font-bold">{price}</span>
          {status && <Badge color="green">{status}</Badge>}
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">{location}</p>
        {meta && (
          <div className="flex flex-wrap space-x-2">
            {meta.map((item) => (
              <div key={item.label} className="text-sm text-gray-500 dark:text-gray-400">
                <span className="font-medium">{item.label}:</span> {item.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
