import AdminFirm from "../components/Dashboard/AdminFirm/AdminFirm";


const DashboardPage = () => {
  return (
    <>
      <AdminFirm />
    </>
  );
};

export default DashboardPage;
