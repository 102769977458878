import React from 'react';
import { useTimer } from './TimeProvider';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import Badge from '../shared/TailwindComponents/Badge';

const TimekeepingTable = () => {
  const { t } = useTranslation();
  const { activeList, tasks, cases, selectedItems, handleItemClick } = useTimer();

  const items = activeList === 'tasks' ? tasks : cases;

const formatNumber = (value: string)  => {
  value.replace(/\B(?=(\d{3})+\b)/g, ' ')
}

  return (
    <div className="flex flex-col gap-6">
      {items.length === 0 ? (
        <div className="text-center text-gray-500 dark:text-gray-400">
          {activeList === 'tasks' ? t('No tasks available') : t('No cases available.')}
        </div>
      ) : (
        items.map((item: any) => {
          const itemID = item.TaskID || item.CaseID;
          const isSelected = selectedItems.some((selectedItem) => {
            const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
            return selectedItemID === itemID;
          });

          return (
            <div
              key={itemID}
              onClick={() => handleItemClick(item)}
              className={clsx(
                'p-4 rounded-lg shadow-md cursor-pointer transition transform hover:bg-primary-100',
                isSelected
                  ? 'bg-primary-100 dark:bg-blue-900 border border-blue-500'
                  : 'bg-white dark:bg-gray-700 border border-transparent hover:border-gray-300 dark:hover:border-gray-600'
              )}
            >
              {/* Render item details here, similar to the code in TimeTracker */}
              {activeList === 'tasks' ? (
                <div>
                  {/* Task Details */}
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      {item.TaskTitle}
                    </span>
                    <Badge
                      color={`${
                        item.Status === 'Active'
                          ? 'blue'
                          : 'gray'
                      }`}
                    >
                      {item.Status}
                    </Badge>
                  </div>
                  <span className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    {t('Deadline')}: {item.TaskDeadline}
                  </span>
                  <Tooltip title={item.TaskDescription} arrow>
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      {item.TaskDescription}
                    </p>
                  </Tooltip>
                </div>
              ) : (
                <div>
                  {/* Case Details */}
                  <div className="flex justify-between items-center mb-2">
                    {/* <span className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      {`${item.CaseType} - ${formatNumber(
                        item.MaxBudget ?? 0
                      )} ${item.Currency}`}
                    </span> */}
                    <span
                      className={`text-sm px-2 py-1 rounded ${
                        item.Status === 'Complete'
                          ? 'bg-green-200 text-green-800'
                          : 'bg-yellow-200 text-yellow-800'
                      }`}
                    >
                      {item.Status}
                    </span>
                  </div>
                  <span className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    {t('Jurisdiction')}: {item.CountryOfJurisdiction}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {t('Urgency')}: {item.Urgency}
                  </span>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default TimekeepingTable;
