import React from 'react';

interface ColorSwatchProps {
  color: string;
  label: string;
}

const ColorSwatch: React.FC<ColorSwatchProps> = ({ color, label }) => {
  return (
    <div className="flex items-center space-x-2">
      <span
        className="w-4 h-4 rounded"
        style={{ backgroundColor: color }}
      ></span>
      <span className="text-sm">{label}</span>
    </div>
  );
};

export default ColorSwatch;
