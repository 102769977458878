import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DropdownContextProps {
  openDropdownId: string | null;
  setOpenDropdownId: (id: string | null) => void;
}

const DropdownContext = createContext<DropdownContextProps>({
  openDropdownId: null,
  setOpenDropdownId: () => {},
});

export const DropdownProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  return (
    <DropdownContext.Provider value={{ openDropdownId, setOpenDropdownId }}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = () => useContext(DropdownContext);
