import React, { useEffect, useState } from 'react';
import Modal from './Modal'; // Assume you have a shared Modal component
import Dropdown from './Dropdown';
import Textarea from './Textarea';
import Button from './Button';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { proposeCase } from '../../../store/actions/marketplace2/marketplaceActions';
import { fetchCases } from '../../../store/actions/marketplace2/caseActions';

interface ProposeCaseModalProps {
  lawyerId: string;
  onClose: () => void;
}

const ProposeCaseModal: React.FC<ProposeCaseModalProps> = ({ lawyerId, onClose }) => {
  const dispatch = useAppDispatch();
  const cases = useSelector((state: RootState) => state.casesM.cases || []);
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    dispatch(fetchCases());
  }, [dispatch]);

  const handleSubmit = async () => {
    if (!selectedCaseId) {
      setError('Please select a case.');
      return;
    }
    try {
      await dispatch(
        proposeCase({
          LawyerID: lawyerId,
          CaseID: selectedCaseId,
          Description: description,
        })
      );
      onClose();
    } catch (error) {
      // Error handling is already done in the action
    }
  };

  const caseOptions = cases.map((caseItem) => ({
    value: caseItem.CaseID,
    label: caseItem.Title || `${caseItem.CaseName}`,
  }));

  return (
    <div >
      <div className="space-y-4">
        <Dropdown
          label="Select a Case"
          options={caseOptions}
          onChange={(value) => setSelectedCaseId(value as string)}
          error={error}
        />
        <Textarea
          label="Message"
          placeholder="Enter a message to the lawyer..."
          value={description}
          onChange={(e) => setDescription(e.target.value)} 
          id={'30'}        />
        <div className="flex justify-end space-x-2">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Send Request
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProposeCaseModal;
