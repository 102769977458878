import { SET_ACTIVE_ROLE, SET_SLIDER_POSITION } from "../types";



const initialState = {
    activeRole: 'account',
    sliderPosition: 0
};

const roleReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_ACTIVE_ROLE:
            return {
                ...state,
                activeRole: action.payload
            };
        case SET_SLIDER_POSITION:
            return {
                ...state,
                sliderPosition: action.payload
            };
        default:
            return state;
    }
};

export default roleReducer;
