import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

// Other imports
import { showAddCaseSidePanel } from '../../store/actions/sidePanelActions';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Stats, { StatItem } from '../shared/TailwindComponents/Stats';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { fetchCaseById, fetchCases } from '../../store/actions/marketplace2/caseActions';
import { getUserType } from '../../utils/authUtils';
import { BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import AddCaseForm from './AddCaseForm';

const Cases: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const isCasesLoading = useSelector((state: RootState) => state.casesM.isLoading);
  const allCases = useSelector((state: RootState) => state.casesM.cases || []);
  const totalClients = useSelector((state: RootState) => state.client.allClients.length);
  const userTypeName = getUserType();
  const location = useLocation();
  const locationState = location.state as any;
  const clientIdFromLocation = locationState?.clientId;
  const clientUserTypeFromLocation = locationState?.clientUserType;

  const [isAddingCase, setIsAddingCase] = useState(locationState?.isAddingCase || false);
  const [scrollTop, setScrollTop] = useState(0);
  const caseListRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(fetchCases());
  }, [dispatch]);

  const handleScroll = () => {
    const scrollPosition = caseListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    const caseList = caseListRef.current;
    if (caseList) {
      caseList.addEventListener('scroll', handleScroll);
      return () => {
        caseList.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const shrinkThreshold = 150; // Adjust this value as needed
  const isShrunk = scrollTop >= shrinkThreshold;

  const handleAddCaseClick = () => {
    setIsAddingCase(true);
  };

  const handleAddCaseClose = () => {
    setIsAddingCase(false);
  };

  const slugify = (text: string) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };

  const generateCaseSlug = (caseItem: any) => {
    const caseName = caseItem.CaseName || `Case-${caseItem.CaseID}`;
    return `${caseItem.CaseID}-${slugify(caseName)}`;
  };

  const handleCaseClick = (caseItem: any) => {
    history.push(`/cases/${caseItem.CaseID}`);
  };  

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const statsData = [
    {
      name: 'Total Cases',
      stat: allCases.length.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    {
      name: 'Total Clients',
      stat: totalClients.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
  ];

  // Filters and sort options
  const filters = [
    {
      id: 'CaseStatus',
      name: 'Case Status',
      options: [
        { value: 'open', label: 'Open' },
        { value: 'closed', label: 'Closed' },
      ],
    },
  ];

  const [sortOptions, setSortOptions] = useState([
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
    { value: 'date_newest', label: 'Newest First', current: false },
    { value: 'date_oldest', label: 'Oldest First', current: false },
  ]);

  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState(sortOptions.find((option) => option.current)?.value || 'name_asc');

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  
    // Update sortOptions to set the current property
    setSortOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        current: option.value === value,
      }))
    );
  };

  // Filter and sort cases
  const filteredCases = allCases.filter((caseItem: any) => {
    let matches = true;

    // Filter by Case Status
    if (selectedFilters['CaseStatus'] && selectedFilters['CaseStatus'].length > 0) {
      matches = matches && selectedFilters['CaseStatus'].includes(caseItem.case_status);
    }

    // Filter by Search Query
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      const caseName = (caseItem.CaseName || '').toLowerCase();
      const caseType = (caseItem.CaseType || '').toLowerCase();
      matches = matches && (caseName.includes(searchLower) || caseType.includes(searchLower));
    }

    return matches;
  });

  // Sort cases
  const sortedCases = filteredCases.sort((a: any, b: any) => {
    if (sortValue === 'name_asc') {
      return (a.CaseName || '').localeCompare(b.CaseName || '');
    } else if (sortValue === 'name_desc') {
      return (b.CaseName || '').localeCompare(a.CaseName || '');
    } else if (sortValue === 'date_newest') {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    } else if (sortValue === 'date_oldest') {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }
    return 0;
  });

  // Calculate current height of Stats component
  const fullStatsHeight = 250; // Adjust based on your full stats height
  const shrunkStatsHeight = 140; // Adjust based on your shrunk stats height

  const currentStatsHeight =
    scrollTop >= shrinkThreshold
      ? shrunkStatsHeight
      : fullStatsHeight - ((scrollTop / shrinkThreshold) * (fullStatsHeight - shrunkStatsHeight));

  return (
    <div className="h-[calc(100vh-90px)] flex flex-col overflow-hidden">
      {/* Sticky Stats Component */}
      {!isAddingCase && (
      <div
        className="sticky top-0 z-30 overflow-hidden bg-background-light dark:bg-background-dark"
        style={{
          height: currentStatsHeight,
          transition: 'height 0.2s ease-in-out',
        }}
      >
        <Stats stats={statsData as StatItem[]} isShrunk={scrollTop >= shrinkThreshold} />
      </div>
      )}

      {/* Header and Filter/Search Bar */}
      <div
        className={`${
          (isShrunk && !isAddingCase) ? 'sticky top-[80px] z-20' : ''
        } transition-all duration-200 bg-background-light dark:bg-background-dark`}
      >
          <Header
          title={isAddingCase ? t('Add New Case') : t('Cases')}
          subtitle={
            isAddingCase
              ? t('Fill in the details below to create a new case.')
              : t('Manage your cases and track their progress.')
          }
          actionLabel={isAddingCase ? 'Cancel Adding Case' : t('Add Case')}
          onActionClick={isAddingCase ? handleAddCaseClose : handleAddCaseClick}
        />
 {!isAddingCase && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
                    />
        )}
      </div>

      {/* Cases List */}
      <div className="flex-grow overflow-y-auto h-full " ref={caseListRef}>
      {isAddingCase ? (
              <AddCaseForm
              setIsAddingCase={setIsAddingCase}
              onClose={handleAddCaseClose}
              clientId={clientIdFromLocation}
              clientUserType={clientUserTypeFromLocation}
            />
        ) : isCasesLoading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader color="#000" loading={isCasesLoading} size={30} />
          </div>
        ) : (
        isCasesLoading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader color="#000" loading={isCasesLoading} size={30} />
          </div>
        ) : (
          <div className="space-y-4 px-4 py-4">
            {sortedCases.length > 0 ? (
              sortedCases.map((caseItem: any) => {
                // Generate badges
                const badges: BadgeItem[] = [];

                // Analysis Status Badge
                const analysisStatus = caseItem.progress?.find(
                  (step: any) => step.status === 'In Progress' || step.status === 'Pending'
                )
                  ? 'In Progress'
                  : 'Completed';

                badges.push({
                  label: `Analysis ${analysisStatus}`,
                  color: analysisStatus === 'Completed' ? 'green' : 'yellow',
                });

                // Published Status Badge (for specific user types)
                if (
                  userTypeName === 'IndividualClient' ||
                  userTypeName === 'BusinessAdmin' ||
                  userTypeName === 'BusinessEmployee'
                ) {
                  const isPublished = caseItem.IsPublishedToMarketplace;
                  badges.push({
                    label: isPublished ? 'Published to Marketplace' : 'Not Published',
                    color: isPublished ? 'green' : 'red',
                  });
                }

                // Case Status Badge
                badges.push({
                  label: `${caseItem.case_status}`,
                  color: caseItem.case_status === 'open' ? 'blue' : 'gray',
                });
                if (userTypeName === 'IndependentLawyer' || userTypeName === 'LawFirmAdmin' || userTypeName === 'LawFirmEmployee') {
                {
                  if (caseItem.ClientID) {
                    badges.push({
                      label: 'Client Attached',
                      color: 'green',
                    });
                  } else {
                    badges.push({
                      label: 'No Client',
                      color: 'red',
                    });
                  }
                }
              }

                // Prepare details
                const details = [
                  {
                    label: 'Case Type',
                    value: caseItem.CaseType,
                  },
                  {
                    label: 'Created At',
                    value: new Intl.DateTimeFormat('en-GB', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    }).format(new Date(caseItem.created_at)),
                  },
                ];

                // Include Deadline if available
                if (caseItem.MarketplaceData?.Deadline) {
                  details.push({
                    label: 'Deadline',
                    value: new Intl.DateTimeFormat('en-GB', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    }).format(new Date(caseItem.MarketplaceData.Deadline)),
                  });
                }

                return (
                  <ListItem
                    key={caseItem.CaseID}
                    title={caseItem.CaseName || `Case ${caseItem.CaseID}`}
                    subtitle={caseItem.AnonymizedDescription || ''}
                    details={details}
                    badges={badges}
                    onClick={() => handleCaseClick(caseItem)}
                    link={`/cases/${caseItem.CaseID}`}
                  />
                );
              })
            ) : (
              <p className="text-center">{t('No cases available')}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cases;
