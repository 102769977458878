import React, { useRef, useState, useEffect } from 'react';
import PlusIcon from '../icons/PlusIcon';

interface SingleFileInputProps {
  label?: string;
  accept?: string;
  file: File | null;
  setFile: (file: File | null) => void;
  subMsg?: string;
  existingFileUrl?: string;
}

const SingleFileInput: React.FC<SingleFileInputProps> = ({
  label,
  accept,
  file,
  setFile,
  subMsg,
  existingFileUrl,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(existingFileUrl || null);

  const handleAddFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreviewUrl(existingFileUrl || null);
  };

  useEffect(() => {
    // Clean up object URLs
    return () => {
      if (previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div>
      {label && <label className="block text-sm font-medium mb-1">{label}</label>}
      {subMsg && <p className="text-sm text-gray-500 dark:text-gray-300 text-left">{subMsg}</p>}
      <div className="flex items-center space-x-4">
        {previewUrl ? (
          <div className="relative">
            {accept?.includes('image') ? (
              <img
                src={previewUrl}
                alt="Preview"
                className="w-20 h-20 object-cover rounded border"
              />
            ) : (
              <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-600 ring-gray-500/10">
                {file?.name || 'File'}
              </span>
            )}
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 py-1"
              onClick={handleRemoveFile}
            >
              &times;
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={handleAddFile}
            className="w-20 h-20 flex items-center justify-center rounded border border-dashed border-gray-300 text-gray-500 hover:bg-gray-100 focus:outline-none"
          >
            <PlusIcon className="w-6 h-6" />
          </button>
        )}
      </div>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};

export default SingleFileInput;
