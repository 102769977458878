import React, { useEffect, useState } from 'react';
import './Settings.css';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { hideModal, showModal, ShowPaymentOptions, ShowReceiverOptions } from '../../store/actions/modalActions';
import { useSelector } from 'react-redux';
import { fetchPricing } from '../../store/actions/SignUpAction';
import { getUserType } from '../../store/actions/ChatAction';
import { Pricing, ServiceUsage, isAnalysisPricing, isCasePricing, isContractPricing, isLawfirmPricing, isLicensePricing, isSessionPricing, isSubscriptionPricing } from '../PlanCard/PricingPlans';
import CompanySettings from './CompanySettings';
import StripeContext from './Security/StripContect';
import { createAccountLink, createConnectedAccount, listRecieversAccount } from '../../store/actions/StripeActions';
import ApiLoader from '../ApiLoader';
import { ClipLoader } from 'react-spinners';

interface PaymentMethod {
  id: number;
  method: string;
  details: string;
  isDefault: boolean;
}

export interface ReceiverMethod {
  id: number;
  method: string;
  details: string;
  isDefault: boolean;
}

const FinanceSettings = () => {
  const isAnnual = false;
  const [currentPlan, setCurrentPlan] = useState<Pricing>();
  const { t } = useTranslation()
  const [randomPricingPlans, setRandomPricingPlans] = useState<Pricing[]>([]);
  const dispatch = useAppDispatch()
  const { pricingPlans } = useSelector((state: RootState) => state.pricing);
  const rawUserType = getUserType().toLowerCase();
  const [externalAccounts, setExternalAccounts] = useState<any[]>([]);
  const [loadingExternalAccount, setIsLoadingExternalAccount] = useState(false)
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const external_Accounts = useSelector((state: RootState) => state.stripe.external_accounts)
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io')
    || window.location.hostname.includes('beta.pons.io');

  type UserType = 'businessadmin' | 'employee' | 'lawfirmadmin' | 'lawfirmlawyer' | 'lawyer' | 'client';

  const userTypeToPricingType: { [key in UserType]: string } = {
    businessadmin: 'business',
    employee: 'business',
    lawfirmadmin: 'lawfirm',
    lawfirmlawyer: 'lawfirm',
    lawyer: 'lawyer',
    client: 'client'
  };

  const userType = rawUserType as UserType;


  const [receiverMethods, setReceiverMethods] = useState([
    { id: 1, method: 'Bank Account', details: 'Bank of America, Acc: ****1234', isDefault: true },
    { id: 2, method: 'E-Wallet', details: 'Payoneer: *****@lawfirm.com', isDefault: false },
  ]);

  const addReceiverMethod = async () => {
    if (external_Accounts && external_Accounts.length === 0) {
      const resp: any = await dispatch(createConnectedAccount("", userInfo.Email, userInfo.CustomerID))
      if (resp.url) {
        dispatch(showModal({
          type: "redirect",
          message: t("You are being redirected to stripe to complete the account onboarding process. Please wait..."),
          onConfirm: () => { console.log("redirected") },
          onCancel: () => hideModal(),
          showModal: true,
        }))
        window.location.href = resp.url;
      }
    } else {
      dispatch(ShowReceiverOptions())
    }

  };

  useEffect(() => {
    const fun = async () => {
      setIsLoadingExternalAccount(true)
      if (userInfo.UserType === 'IndependentLawyer') {
        const resp: any = await dispatch(listRecieversAccount(userInfo.CustomerID))
        if (resp) {
          setIsLoadingExternalAccount(false)
        }
      }

    }

    fun()
  }, [dispatch, userInfo.CustomerID, userInfo.UserType])

  useEffect(() => {
    if (external_Accounts && external_Accounts.length > 0) {
      setExternalAccounts(external_Accounts)
    }

  }, [external_Accounts])


  const currentPricingType = userTypeToPricingType[userType];

  useEffect(() => {
    if (pricingPlans.length === 0) {
      dispatch(fetchPricing());
    }
  }, [dispatch, pricingPlans.length]);

  useEffect(() => {
    if (pricingPlans.length > 0) {
      // Use currentPricingType to filter plans
      const filteredPlans = pricingPlans.filter((plan: Pricing) =>
        plan.UserType && plan.UserType.toLowerCase() === currentPricingType
      );

      if (filteredPlans.length > 0) {
        const randomIndices: number[] = [];
        while (randomIndices.length < 4 && filteredPlans.length > randomIndices.length) {
          const randomIndex = Math.floor(Math.random() * filteredPlans.length);
          if (!randomIndices.includes(randomIndex)) {
            randomIndices.push(randomIndex);
          }
        }

        const selectedPlans = randomIndices.map(index => filteredPlans[index]);
        setRandomPricingPlans(selectedPlans);
        setCurrentPlan(selectedPlans[0]);
      } else {
        setRandomPricingPlans([]);
        setCurrentPlan(undefined);
      }
    }
  }, [pricingPlans, currentPricingType]);


  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([
    { id: 1, method: `${t('Credit Card')}`, details: '**** **** **** 7282', isDefault: true },
    { id: 2, method: `${t('PayPal')}`, details: 'Sebastian@pons.io', isDefault: false },
    { id: 3, method: `${t('Bank Transfer')}`, details: 'Bank of America', isDefault: false },
  ]);


  const addPaymentMethod = () => {
    dispatch(ShowPaymentOptions())

  };

  const setDefaultPaymentMethod = (methodId: number) => {
    setPaymentMethods(paymentMethods => paymentMethods.map(method =>
      ({ ...method, isDefault: method.id === methodId })
    ));
  };

  const setDefaultReceiverMethod = (methodId: number) => {
    setReceiverMethods(receiverMethods => receiverMethods.map(method =>
      ({ ...method, isDefault: method.id === methodId })
    ));
  };

  const getPaymentIconClass = (method: string) => {
    switch (method) {
      case 'Credit Card':
        return 'fas fa-credit-card';
      case 'PayPal':
        return 'fab fa-paypal';
      case 'Bank Transfer':
        return 'fas fa-university';
      default:
        return '';
    }
  };

  const formatLabel = (label: string): string => {
    if (label === "LCM") return "Legal Case Mapping";
    return label.replace(/([A-Z])/g, ' $1').trim();
  };

  const handleCompleteOnboarding = async (id: string) => {
    const resp: any = await dispatch(createAccountLink(id))
    if (resp.url) {
      dispatch(showModal({
        type: "redirect",
        message: t("You are being redirected to stripe to complete the account onboarding process. Please wait..."),
        onConfirm: () => { console.log("redirected") },
        onCancel: () => hideModal(),
        showModal: true,
      }))
      window.location.href = resp.url;
    }
  }

  const ReceiverInformation: React.FC<{ receivers: ReceiverMethod[] }> = ({ receivers }) => (
    <div className="finance-settings__section quater">
      <div className="finance-settings-header">
        <h2>{t('Receiver Information')}</h2>
        <div className="add-payment-method-container">
          <button onClick={addReceiverMethod}>
            {t('Add Receiver Account')}
          </button>
        </div>
      </div>
      <div style={{ display: "flex", overflowX: "auto", scrollbarWidth: "none", gap: '20px', padding: '10px', width: '95%' }}>
        {loadingExternalAccount ? (<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <ClipLoader color={"#fff"} size={20} />
        </div>) : (
          externalAccounts.length > 0 ? externalAccounts.map((method: any) => (
            <div key={method.ID ? method.ID : method.id} className={`payment-method-card ${method.default_for_currency ? "payment-selected" : ""}`} onClick={() => setDefaultReceiverMethod(method.id)}>
              <div className="method-info">
                <i className="fas fa-university" />
                <span>{"Bank Account"}</span>
                <span className="method-details">**** **** **** {method.last4}</span>
              </div>
              {method.hasOwnProperty('Details_submitted') && !method.Details_submitted && (
                <div className='cover-btn'>
                  <button className="complete-onboarding-btn" onClick={() => handleCompleteOnboarding(method.Stripe_connect_id)}>
                    {t('Complete Onboarding')}
                  </button>
                </div>
              )
              }
            </div>



          )) : (
            <div className="payment-method-no-account-card">
              <i className="fa-solid fa-circle-info"></i>
              <p>{t('No external accounts found.')}</p>
            </div>

          )
        )

        }
      </div>
    </div>
  );



  const UsageDetails: React.FC<{ included: ServiceUsage, used: ServiceUsage }> = ({ included, used }) => (
    <table className="finance-usage-details">
      <thead>
        <tr>
          <th style={{ fontSize: 24 }}>{t('Services')}</th>
          <th>{t('Included')}</th>
          <th>{t('Used')}</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(included).map((key) => (
          <tr key={key}>
            <td className='finance-usage-detail__label'>{t(formatLabel(key))}</td>
            <td className='finance-usage-detail__tag'>{t(included[key as keyof ServiceUsage] as string)}</td>
            <td className='finance-usage-detail__tag'>{used[key as keyof ServiceUsage] ?? 0}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  interface PlanAmountRowProps {
    label: string;
    total: string;
    remaining: string;
    pricingName: string;
  }

  const PlanAmountRow: React.FC<PlanAmountRowProps> = ({ label, total, remaining, pricingName }) => (
    <table className="finance-usage-details">
      <thead>
        <tr>
          <th style={{ fontSize: 24 }}>{t('Services')}</th>
          <th>{t(`${label}`)}</th>
          <th>{t('Remaining')}</th>
        </tr>
      </thead>
      <tbody>
        <td className='finance-usage-detail__label'>{t(`${pricingName}`)}</td>
        <td className='finance-usage-detail__tag'>{total}</td>
        <td className='finance-usage-detail__tag'>{remaining}</td>
      </tbody>
    </table>
  );


  const renderPlanDetails = (plan: Pricing, isAnnual: boolean) => {
    let displayPrice = plan.Price ? `${plan.CurrencySymbol}${plan.Price}` : t("Not Available");
    let details = [];
    let features: string[] = [];
    let includes: string[] = [];

    if (isSessionPricing(plan)) {
      details.push(<PlanAmountRow label="Inquires" total={plan.Inqueries} remaining="2" pricingName={plan.PricingName} />);
    } else if (isCasePricing(plan)) {
      details.push(<PlanAmountRow label="Cases" total={plan.Cases} remaining="2" pricingName={plan.PricingName} />);
    } else if (isAnalysisPricing(plan)) {
      details.push(<PlanAmountRow label="Analysis" total={plan.Analysis} remaining="2" pricingName={plan.PricingName} />);
    } else if (isContractPricing(plan)) {
      details.push(<PlanAmountRow label="Contracts" total={plan.Contracts} remaining="2" pricingName={plan.PricingName} />);
    } else if (isSubscriptionPricing(plan) || isLicensePricing(plan)) {
      const priceType = plan.AnnualFee && isAnnual ? "AnnualFee" : "MonthlyFee";
      displayPrice = `${plan.CurrencySymbol}${plan[priceType]}`;
      includes.push(`Fee: ${displayPrice} per ${isAnnual ? 'year' : 'month'}`);
      if ('Included' in plan && 'Used' in plan) {
        details.push(<UsageDetails included={plan.Included} used={plan.Used} />);
      }
      includes.push(`Includes: ${plan.IncludedServices.split(", ").join(", ")}`);
      if ("FeatureList" in plan) {
        features = plan.FeatureList.split(", ").map(feature => feature.trim());
      }
    } else if (isLawfirmPricing(plan)) {
      details.push(`Custom Pricing: ${plan.CustomPricing}`);
      details.push(`Features: ${plan.AdditionalFeatures}`);
    }

    return (
      <>
        <div className="finance-settings-header">
          <div>
            <h4>{plan.PricingName}</h4>
            <span style={{ color: '#ccc', fontStyle: 'italic' }}>{plan.Type}</span>
          </div>
          <button>{t('Change Plan')}</button>
        </div>
        <div className='finance-pricing-plan__contanier'>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <div className="pricing-header">
                <p className="pricing-price">{displayPrice}</p>
              </div>
              {details.length > 0 && (
                <div className="plan-amount-details">
                  {details}
                </div>
              )}
            </div>
            <div>
              {(isSubscriptionPricing(plan) && plan.FeatureList) && (
                <div className="features-includes-container">
                  <span>{t('Features & Tools')}</span>
                  <div className="features-list">
                    {features.map((feature, index) => <li key={index}><i className="fas fa-check"></i> {feature}</li>)}
                  </div>
                </div>
              )}
            </div>
          </div>
          {plan.Description && (
            <p className="pricing-description">{plan.Description}</p>
          )}
        </div>
      </>
    );
  };




  return (
    <StripeContext>
      <div className="finance-settings">
        {
          isDemoEnvironment ? (<div className="non-access" style={{ margin: 'auto' }}>{t('Coming in Q4')}</div>) : (
            <div className="section-row">
              <div className='section-information' style={{ flex: 0.5 }}>
                <div className='finance-settings__section'>

                  <div className="finance-section">
                    {currentPlan ? (
                      <>
                        {renderPlanDetails(currentPlan, isAnnual)}
                      </>
                    ) : (
                      <p>{t('No plan selected')}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="section-information" style={{ flex: 0.6, overflow: "hidden" }}>
                {(rawUserType === "lawfirmadmin" || rawUserType === "businessadmin") && (
                  <CompanySettings />
                )}

                <div className={`finance-settings__section ${userType === "lawyer" ? t("quater") : t("half")}`}>
                  <div className="finance-settings-header">
                    <h2>{t('Payment Information')}</h2>
                    <div className="add-payment-method-container">
                      <button onClick={addPaymentMethod}>
                        {t('Add Payment Method')}
                      </button>
                    </div>
                  </div>
                  <div style={{ display: "flex", overflowX: "auto", scrollbarWidth: "none", gap: '20px', padding: '10px', width: '95%' }}>
                    {paymentMethods.map((method) => (
                      <div key={method.id} className={`payment-method-card ${method.isDefault ? "payment-selected" : ""}`} onClick={() => setDefaultPaymentMethod(method.id)}>
                        <div className="method-info">
                          <i className={getPaymentIconClass(method.method)} />
                          <span>{method.method}</span>
                          <span className="method-details">{method.details}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
                {userType === "lawyer" && <ReceiverInformation receivers={receiverMethods} />}

                <div className={`finance-settings__section ${userType === "lawyer" && "half"}`}>
                  <div className="finance-settings-header">
                    <h2>{t('Billing History')}</h2>
                  </div>
                  <div className="finance-section">
                    <ul className='billing-scroll-list'>
                      {randomPricingPlans.map(plan => (
                        <div className='finance-pricing__card' key={plan.PricingID}>
                          <div>
                            <p>{plan.PricingName}</p>
                            <p style={{ color: '#ccc', fontStyle: 'italic', fontWeight: '500' }}>{plan.Type}</p>
                          </div>
                          <div className='finance-pricing__card--end'>
                            <p>{plan.CurrencySymbol} {!isSubscriptionPricing(plan) ? plan.Price : plan.MonthlyFee}</p>
                            <button>{t('Download')}</button>
                          </div>
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </StripeContext>
  );
}

export default FinanceSettings;
