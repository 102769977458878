import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import {
  deleteCustomBlobDocument,
  getCustomBlobDocuments,
  getCustomIndexInfo,
  reRunCustomIndex,
  uploadAdditionalCustomFiles,
  uploadCustomFiles
} from '../../../store/actions/CustomAgentAction';
import { ClipLoader } from 'react-spinners';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import Button from '../../shared/TailwindComponents/Button';
import Input from '../../shared/TailwindComponents/Input';
import Badge from '../../shared/TailwindComponents/Badge';
import { AiOutlineFolderAdd, AiOutlineImport } from 'react-icons/ai';

interface UploadedFile {
  fileName: string;
  documentURL: string;
  title?: string;
  file?: File;
}

const CustomAgent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const indexExists = useSelector((state: RootState) => state.customAgent.indexExists);
  const indexInfo = useSelector((state: RootState) => state.customAgent.indexInfo);
  const blobDocuments = useSelector((state: RootState) => state.customAgent.blobDocuments);
  const loading = useSelector((state: RootState) => state.customAgent.loading);
  const polling = useSelector((state: RootState) => state.customAgent.polling);
  const statusMessage = useSelector((state: RootState) => state.customAgent.statusMessage);

  useEffect(() => {
    dispatch(getCustomIndexInfo());
    dispatch(getCustomBlobDocuments());
  }, [dispatch]);

  const handleDeleteClick = (fileName: string) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this document from your Custom AI?'),
        onConfirm: () => handleConfirmDelete(fileName),
        onCancel: () => dispatch(hideModal()),
        showModal: true,
      })
    );
  };

  const handleConfirmDelete = async (fileName: string) => {
    dispatch(hideModal());
    try {
      await dispatch(deleteCustomBlobDocument(fileName));
      dispatch(getCustomBlobDocuments());
      dispatch(getCustomIndexInfo());
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Document deleted successfully.'),
          modalType: 'success',
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to delete document.'),
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>, isZip: boolean = false) => {
    const files = Array.from(e.target.files || []);
    const validFiles = files.filter((file) => (isZip ? file.type === 'application/zip' : true));
    const uploadedFileObjects = validFiles.map((file) => ({
      fileName: file.name,
      documentURL: URL.createObjectURL(file),
      file,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...uploadedFileObjects]);
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    try {
      const files = Array.from(e.dataTransfer.files);

      const validFiles = files.map((file) => ({
        fileName: file.name,
        documentURL: URL.createObjectURL(file),
        file,
      }));

      const uniqueFiles = validFiles.filter(
        (file) => !uploadedFiles.some((uploadedFile) => uploadedFile.fileName === file.fileName)
      );

      setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    } catch (error) {
      console.error('Error handling file drop:', error);
    }
  };

  const handleDragOverCustom = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeaveCustom = () => {
    setIsDragOver(false);
  };

  const removeUploadedFile = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (uploadedFiles.length > 0) {
      try {
        for (const file of uploadedFiles) {
          if (file.file) {
            if (indexExists) {
              await dispatch(uploadAdditionalCustomFiles(file.file));
            } else {
              await dispatch(uploadCustomFiles(file.file));
            }
          } else {
            console.error('File object is missing for file:', file.fileName);
          }
        }
        setUploadedFiles([]);
        dispatch(getCustomBlobDocuments());
        dispatch(getCustomIndexInfo());
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: indexExists
              ? t('Custom AI updated successfully.')
              : t('Custom AI created successfully.'),
            modalType: 'success',
            duration: 3000,
          })
        );
      } catch (error) {
        console.error('Error uploading files:', error);
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to upload files.'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } else if (indexExists) {
      try {
        await dispatch(reRunCustomIndex());
        dispatch(getCustomIndexInfo());
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Custom AI re-indexed successfully.'),
            modalType: 'success',
            duration: 3000,
          })
        );
      } catch (error) {
        console.error('Error re-running index:', error);
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to re-index Custom AI.'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } else {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('No files to upload.'),
          modalType: 'warning',
          duration: 3000,
        })
      );
    }
  };

  const groupedFiles = uploadedFiles.reduce((acc: { [key: string]: UploadedFile[] }, file) => {
    const path = file.file?.webkitRelativePath ? file.file.webkitRelativePath.split('/')[0] : 'root';
    if (!acc[path]) acc[path] = [];
    acc[path].push(file);
    return acc;
  }, {});

  const filteredDocuments = blobDocuments?.filter((document: string) =>
    document.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 w-full shadow rounded-lg max-w-3xl flex flex-row justify-center space-x-2">
      {loading || polling ? (
        <div className="flex items-center justify-center">
          <ClipLoader size={30} color="#4A90E2" />
          <p className="ml-2 text-gray-700 dark:text-gray-300">{statusMessage}</p>
        </div>
      ) : (
        <>
          <div className="flex-1">
            {/* Header Row */}
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold">{t('Custom Agent')}</h3>
              <Button variant="neutral" onClick={() => fileInputRef.current?.click()}>
                <AiOutlineImport className="inline-block mr-2" />
                {t('Import')}
              </Button>
            </div>
            <hr className="border-gray-300 dark:border-gray-700 mb-4" />

            {/* File Upload Section */}
            <div
              className={`flex flex-col items-center justify-center rounded-lg p-6 ${
                isDragOver ? 'bg-gray-200 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-800'
              }`}
              onDragOver={handleDragOverCustom}
              onDragLeave={handleDragLeaveCustom}
              onDrop={handleFileDrop}
              onClick={() => fileInputRef.current?.click()}
              style={{ cursor: 'pointer' }}
            >
              <input
                type="file"
                ref={fileInputRef}
                multiple
                className="hidden"
                onChange={(e) => handleFileInput(e)}
              />
              {(uploadedFiles.length === 0 || indexInfo.document_count === 0) ? (
                <>
                  <AiOutlineFolderAdd className="text-6xl text-blue-600 dark:text-gray-400 mb-2" />
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {t('Drag and drop files here')}
                  </p>
                </>
              ) : (
                <div className="w-full">
                  <ul className="space-y-2">
                    {Object.entries(groupedFiles).map(([path, files], index) => (
                      <div key={index}>
                        {path !== 'root' && (
                          <h4 className="text-md font-medium text-gray-700 dark:text-gray-300">
                            {path}
                          </h4>
                        )}
                        {files.map((file, fileIndex) => (
                          <li
                            key={fileIndex}
                            className="flex items-center justify-between bg-gray-200 dark:bg-gray-700 rounded p-2"
                          >
                            <span>{file.fileName}</span>
                            <Button
                              variant="destructive"
                              onClick={() => removeUploadedFile(uploadedFiles.indexOf(file))}
                            >
                              {t('Remove')}
                            </Button>
                          </li>
                        ))}
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Agent Size Badge */}
            {indexInfo && indexInfo.total_storage_size && (
              <div className="mt-4">
                <Badge color="gray">{indexInfo.total_storage_size}</Badge>
              </div>
            )}

            {/* Submit Button */}
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={
                loading ||
                (uploadedFiles.length === 0 &&
                  blobDocuments?.length === indexInfo?.document_count)
              }
              className="mt-6 w-full"
            >
              {indexExists ? t('Update Agent') : t('Create Agent')}
            </Button>
          </div>

          {/* Existing Documents */}
          {indexExists && blobDocuments && blobDocuments.length > 0 && (
            <div className="flex-1">
              <h3 className="text-lg font-semibold">{t('Existing Documents')}</h3>
              <Input
                type="text"
                id="search-documents"
                placeholder={t('Search documents...')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mt-2"
              />
              <ul className="mt-2 space-y-2">
                {filteredDocuments && filteredDocuments.length > 0 ? (
                  filteredDocuments.map((document: string, index: number) => (
                    <li
                      key={index}
                      className="flex items-center justify-between bg-gray-100 dark:bg-gray-700 rounded p-2"
                    >
                      <span>{document.split('/').pop()}</span>
                      <Button
                        variant="destructive"
                        onClick={() => handleDeleteClick(document)}
                      >
                        {t('Delete')}
                      </Button>
                    </li>
                  ))
                ) : (
                  <li className="text-sm text-gray-500">{t('No files found')}</li>
                )}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomAgent;
