import React, {  useState  } from 'react';
import LoginSection from './LoginSection';
import SignUpSection from './SignUpSection';
import { isBetaEnv } from '../../utils/authUtils';

const AuthPage: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
  };

  return (
    <>
      {isLogin  ? (
        <LoginSection
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          toggleAuthMode={toggleAuthMode}
        />
      ) : (!isBetaEnv) && (
        <SignUpSection
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          toggleAuthMode={toggleAuthMode}
        />
      )}
    </>
  );
};

export default AuthPage;
