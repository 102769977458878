import React, { useEffect, useState } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { RootState, useAppDispatch } from '../../store';
import { createCase } from '../../store/actions/marketplace2/marketplaceActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { fetchAllClients } from '../../store/actions/ClientAction';
import { useSelector } from 'react-redux';
import { fetchCaseTypes } from '../../store/actions/DropdownActions';
import { showAddClientSidePanel } from '../../store/actions/sidePanelActions';
import { getUserType } from '../../utils/authUtils';

interface AddCaseFormProps {
  clientId?: string;
  clientUserType?: string;
  onClose: () => void;
  setIsAddingCase: (isAddingCase: boolean) => void;
}

const AddCaseForm: React.FC<AddCaseFormProps> = ({ clientId, clientUserType, onClose, setIsAddingCase }) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({
    CaseType: '',
    issue_description: '',
    events: '',
    timeline: '',
    witness_statements: '',
    communications: '',
    evidence: '',
    // Business-specific fields
    company_size: '',
    industry: '',
    internal_policies: '',
    stakeholder_info: '',
    financial_implications: '',
    third_party_relationships: '',
    // Custom fields
    custom_fields: [],
  });
  const clients = useSelector((state: RootState) => state.client.allClients);
  const [selectedClientID, setSelectedClientID] = useState(clientId || '');
  const [selectedClientUserType, setSelectedClientUserType] = useState(clientUserType || '');
  const caseTypeOptions = useSelector((state: RootState) => state.dropdown.caseTypes);
  const userType = getUserType();
  const [clientTypeSelection, setClientTypeSelection] = useState<
  'IndividualClient' | 'BusinessAdmin' | 'BusinessEmployee'>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (clients.length === 0) {
      dispatch(fetchAllClients());
    }
    dispatch(fetchCaseTypes());
  }, [dispatch, clients.length]);

  useEffect(() => {
    if (clientId) {
      setSelectedClientID(clientId);
    }
    if (clientUserType) {
      setSelectedClientUserType(clientUserType);
    }
  }, [clientId, clientUserType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelect = (option: DropdownOption) => {
    setFormData((prev: any) => ({
      ...prev,
      CaseType: option.value,
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.CaseType) newErrors.CaseType = 'Case type is required';
    if (!formData.issue_description) {
      newErrors.issue_description = 'Issue description is required';
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleAddClientClick = () => {
    dispatch(showAddClientSidePanel());
  };

  const handleAddCustomField = () => {
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: [...prev.custom_fields, { label: '', value: '' }],
    }));
  };

  const handleCustomFieldChange = (index: number, field: string, value: string) => {
    const updatedCustomFields = [...formData.custom_fields];
    updatedCustomFields[index][field] = value;
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: updatedCustomFields,
    }));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Transform custom_fields from array to object
    const customFieldsObject = formData.custom_fields.reduce(
      (obj: { [key: string]: string }, field: any) => {
        if (field.label) {
          obj[field.label] = field.value;
        }
        return obj;
      },
      {}
    );

    // Prepare the payload based on user type
    const payload: any = {
      ...formData,
      custom_fields: customFieldsObject, // Use the object instead of array
      ClientID: selectedClientID || clientId || null,
      ClientUserType:
        selectedClientUserType || clientUserType || clientTypeSelection || userType,
      IsPublishedToMarketplace: false,
    };

    try {
      await dispatch(createCase(payload));
      dispatch(fetchCases());
      setIsAddingCase(false);
    } catch (error) {
      console.error('Error creating case:', error);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">Add New Case</h2>
      <form onSubmit={handleSubmit}>
        {/* Client Selection for Lawyers */}
        {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && (
            <div className="mb-4 flex items-center space-x-4">
              <div className="w-full">
                <Dropdown
                  label="Select Client"
                  options={clients.map((client) => ({
                    value: client.ClientID || 'unknown',
                    label:
                      client.BusinessName ||
                      `${client.FirstName} ${client.LastName}`.trim() ||
                      client.Email ||
                      'Unknown Client',
                  }))}
                  placeholder="Select a client"
                  onSelect={(option) => {
                    const selectedClient = clients.find((c) => c.ClientID === option.value);
                    setSelectedClientID(option.value);
                    if (selectedClient) {
                      setSelectedClientUserType(selectedClient.UserTypeName);
                    }
                  }}
                  value={selectedClientID}
                  error={errors.selectedClientID}
                />
              </div>
              <Button variant="secondary" onClick={handleAddClientClick}>
                Add New Client
              </Button>
            </div>
          )}
              {!selectedClientID && (
        <div className="mb-4">
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium text-gray-700">Select Client Type:</span>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="clientType"
                value="IndividualClient"
                checked={clientTypeSelection === 'IndividualClient'}
                onChange={(e) => setClientTypeSelection(e.target.value as any)}
              />
              <span className="ml-2">Individual Client</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="clientType"
                value="BusinessAdmin"
                checked={clientTypeSelection === 'BusinessAdmin'}
                onChange={(e) => setClientTypeSelection(e.target.value as any)}
              />
              <span className="ml-2">Business</span>
            </label>
          </div>
          <hr className="my-4" />
        </div>
      )}

        {/* Case Type */}
        {/* {!selectedClientID && ( */}
          <div className="mb-4">
            <Dropdown
              label="Case Type"
              options={caseTypeOptions}
              placeholder="Select case type"
              onSelect={handleSelect}
              error={errors.CaseType}
            />
          </div>
        {/* )} */}

        {/* Issue Description */}
        <div className="mb-4">
          <Textarea
            id="issue_description"
            name="issue_description"
            label="Issue Description"
            placeholder="Describe the legal issue..."
            value={formData.issue_description}
            onChange={handleChange}
            error={errors.issue_description}
            description='Provide a detailed description of the issue.'
          />
        </div>

        {/* Conditional Form Fields Based on User Type */}
        {(() => {
const currentType = selectedClientUserType || clientTypeSelection || userType;
if (['BusinessAdmin', 'BusinessEmployee'].includes(currentType)) {
            // Business Form Fields
            return (
              <>
                {/* Company Size */}
                <div className="mb-4">
                  <Input
                    id="company_size"
                    name="company_size"
                    label="Company Size"
                    placeholder="Enter company size..."
                    value={formData.company_size}
                    onChange={handleChange}
                    type='text'
                    description='Specify the size of the company.'
                  />
                </div>
                {/* Industry */}
                <div className="mb-4">
                  <Input
                    id="industry"
                    name="industry"
                    label="Industry"
                    placeholder="Enter industry..."
                    value={formData.industry}
                    onChange={handleChange} type={'text'}                  
                    description='Specify the industry sector.'
                    />
                </div>
                {/* Internal Policies */}
                <div className="mb-4">
                  <Textarea
                    id="internal_policies"
                    name="internal_policies"
                    label="Internal Policies"
                    placeholder="Describe internal policies..."
                    value={formData.internal_policies}
                    onChange={handleChange}
                    description='Provide details about internal policies relevant to the case.'
                  />
                </div>
                {/* Stakeholder Info */}
                <div className="mb-4">
                  <Textarea
                    id="stakeholder_info"
                    name="stakeholder_info"
                    label="Stakeholder Information"
                    placeholder="Details about stakeholders..."
                    value={formData.stakeholder_info}
                    onChange={handleChange}
                    description='Provide information about stakeholders involved.'
                  />
                </div>
                {/* Financial Implications */}
                <div className="mb-4">
                  <Textarea
                    id="financial_implications"
                    name="financial_implications"
                    label="Financial Implications"
                    placeholder="Describe financial implications..."
                    value={formData.financial_implications}
                    onChange={handleChange}
                    description='Explain any financial impacts related to the case.'
                  />
                </div>
                {/* Third Party Relationships */}
                <div className="mb-4">
                  <Textarea
                    id="third_party_relationships"
                    name="third_party_relationships"
                    label="Third-Party Relationships"
                    placeholder="Details about third parties..."
                    value={formData.third_party_relationships}
                    onChange={handleChange}
                    description='Provide information about third parties involved.'
                  />
                </div>
              </>
            );
          } else {
            // Individual Client Form Fields
            return (
              <>
                {/* Events */}
                <div className="mb-4">
                  <Textarea
                    id="events"
                    name="events"
                    label="Events"
                    placeholder="Details of the events..."
                    value={formData.events}
                    onChange={handleChange}
                    description='Describe the events related to the case.'
                  />
                </div>
                {/* Timeline */}
                <div className="mb-4">
                  <Textarea
                    id="timeline"
                    name="timeline"
                    label="Timeline"
                    placeholder="Timeline of events..."
                    value={formData.timeline}
                    onChange={handleChange}
                    description='Provide a timeline of the events.'
                  />
                </div>
                {/* Witness Statements */}
                <div className="mb-4">
                  <Textarea
                    id="witness_statements"
                    name="witness_statements"
                    label="Witness Statements"
                    placeholder="Witness statements..."
                    value={formData.witness_statements}
                    onChange={handleChange}
                    description='Include any witness statements.'
                  />
                </div>
                {/* Communications */}
                <div className="mb-4">
                  <Textarea
                    id="communications"
                    name="communications"
                    label="Communications"
                    placeholder="Relevant communications..."
                    value={formData.communications}
                    onChange={handleChange}
                    description='Provide relevant communications.'
                  />
                </div>
                {/* Evidence */}
                <div className="mb-4">
                  <Textarea
                    id="evidence"
                    name="evidence"
                    label="Evidence"
                    placeholder="Evidence details..."
                    value={formData.evidence}
                    onChange={handleChange}
                    description='Provide any evidence related to the case.'
                  />
                </div>
              </>
            );
          }
        })()}

        {/* Custom Fields */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Custom Fields</label>
          <p className="text-sm text-gray-500 mb-2">
            Add any additional information with custom labels.
          </p>
          {formData.custom_fields.map((field: any, index: number) => (
            <div key={index} className="mb-2 flex flex-col space-y-2">
              <Input
                name={`custom_field_label_${index}`}
                placeholder="Label"
                value={field.label}
                onChange={(e) => handleCustomFieldChange(index, 'label', e.target.value)}
                type='text'
              />
              <Textarea
                name={`custom_field_value_${index}`}
                placeholder="Value"
                value={field.value}
                onChange={(e) => handleCustomFieldChange(index, 'value', e.target.value)} 
                id={''} 
                label={field.label}                              
                              />
            </div>
          ))}
          <Button variant="secondary" onClick={handleAddCustomField}>
            Add Custom Field
          </Button>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button type="submit" variant="primary">
            Create Case
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCaseForm;
