import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..'; // Adjust the path as needed
import {
  PUBLISH_CASE_SUCCESS,
  PUBLISH_CASE_FAILURE,
  GET_PUBLISHED_CASES_SUCCESS,
  GET_PUBLISHED_CASES_FAILURE,
  SUBMIT_BID_SUCCESS,
  SUBMIT_BID_FAILURE,
  GET_CASE_BIDS_SUCCESS,
  GET_CASE_BIDS_FAILURE,
  ACCEPT_BID_SUCCESS,
  ACCEPT_BID_FAILURE,
  REJECT_BID_SUCCESS,
  REJECT_BID_FAILURE,
  UPLOAD_FINAL_DOCUMENT_SUCCESS,
  UPLOAD_FINAL_DOCUMENT_FAILURE,
  GET_FINAL_DOCUMENTS_SUCCESS,
  GET_FINAL_DOCUMENTS_FAILURE,
  SUBMIT_LAWYER_RATING_SUCCESS,
  SUBMIT_LAWYER_RATING_FAILURE,
  GET_LAWYER_PROFILE_SUCCESS,
  GET_LAWYER_PROFILE_FAILURE,
  GET_LAWYERS_FAILURE,
  GET_LAWYERS_REQUEST,
  GET_LAWYERS_SUCCESS,
  GET_LAWYER_PROFILE_REQUEST,
  PROPOSE_CASE_FAILURE,
  PROPOSE_CASE_REQUEST,
  PROPOSE_CASE_SUCCESS,
  SET_MARKETPLACE_ACTIVE_SUCCESS,
  SET_MARKETPLACE_ACTIVE_FAILURE,
} from '../../types';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';

// **1. Publish Case to Marketplace**

export const publishCaseSuccess = (): AnyAction => ({
  type: PUBLISH_CASE_SUCCESS,
});

export const publishCaseFailure = (error: string): AnyAction => ({
  type: PUBLISH_CASE_FAILURE,
  payload: error,
});

export const setMarketplaceActiveSuccess = (): AnyAction => ({
  type: SET_MARKETPLACE_ACTIVE_SUCCESS,
});
export const setMarketplaceActiveFailure = (error: string): AnyAction => ({
  type: SET_MARKETPLACE_ACTIVE_FAILURE,
  payload: error,
});

export const publishCase = (caseData: {
  CaseID: string;
  AnonymizedDescription: string;
  Budget: number;
  Deadline: string;
  AdditionalNotes?: string;
  Categories?: string[];
  Tags?: string[];
}) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/publish_case',
        data: caseData,
      });
      dispatch(publishCaseSuccess());
    } catch (error: any) {
      dispatch(publishCaseFailure(error.toString()));
    }
  };
};

// **2. Get Published Cases**

export const getPublishedCasesSuccess = (cases: any[]): AnyAction => ({
  type: GET_PUBLISHED_CASES_SUCCESS,
  payload: cases,
});

export const getPublishedCasesFailure = (error: string): AnyAction => ({
  type: GET_PUBLISHED_CASES_FAILURE,
  payload: error,
});

export const getPublishedCases = () => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'GET',
        url: '/marketplace/published_cases',
      });
      const cases = response.data.cases;
      dispatch(getPublishedCasesSuccess(cases));
    } catch (error: any) {
      dispatch(getPublishedCasesFailure(error.toString()));
    }
  };
};

// **3. Get Lawyers**

export const getLawyersRequest = (): AnyAction => ({
  type: GET_LAWYERS_REQUEST,
});

export const getLawyersSuccess = (data: {
  lawyers: any[];
  currentPage: number;
  totalPages: number;
}): AnyAction => ({
  type: GET_LAWYERS_SUCCESS,
  payload: data,
});

export const getLawyersFailure = (error: string): AnyAction => ({
  type: GET_LAWYERS_FAILURE,
  payload: error,
});

interface GetLawyersParams {
  page?: number;
  per_page?: number;
  Specialization?: string;
  MinExperience?: number;
  MaxHourlyRate?: number;
}

export const getLawyers = (params: GetLawyersParams = {}) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(getLawyersRequest());
    try {
      const response = await apis({
        method: 'GET',
        url: '/marketplace/get_all_lawyers',
        params, 
      });
      const { lawyers, current_page, total_pages } = response.data;
      dispatch(
        getLawyersSuccess({
          lawyers,
          currentPage: current_page || 1,
          totalPages: total_pages || 1,
        })
      );
    } catch (error: any) {
      dispatch(getLawyersFailure(error.toString()));
    }
  };
};

// **3. Submit Bid**

export const submitBidSuccess = (): AnyAction => ({
  type: SUBMIT_BID_SUCCESS,
});

export const submitBidFailure = (error: string): AnyAction => ({
  type: SUBMIT_BID_FAILURE,
  payload: error,
});

export const submitBid = (bidData: {
  CaseID: string;
  BidAmount: number;
  BidDescription: string;
}) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/submit_bid',
        data: bidData,
      });
      dispatch(submitBidSuccess());
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: 'Bid submitted',
        showModal: true,
        duration: 3000
      }))
    } catch (error: any) {
      dispatch(submitBidFailure(error.toString()));
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: 'Bid failed. Please try',
        showModal: true,
        duration: 3000
      }))
    }
  };
};

// **4. Get Case Bids**

export const getCaseBidsSuccess = (caseId: string, bids: any[]): AnyAction => ({
  type: GET_CASE_BIDS_SUCCESS,
  payload: { caseId, bids },
});

export const getCaseBidsFailure = (error: string): AnyAction => ({
  type: GET_CASE_BIDS_FAILURE,
  payload: error,
});

export const getCaseBids = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/get_case_bids',
        data: { CaseID: caseId },
      });
      const bids = response.data;
      dispatch(getCaseBidsSuccess(caseId, bids));
    } catch (error: any) {
      dispatch(getCaseBidsFailure(error.toString()));
    }
  };
};

// **5. Accept Bid**

export const acceptBidSuccess = (): AnyAction => ({
  type: ACCEPT_BID_SUCCESS,
});

export const acceptBidFailure = (error: string): AnyAction => ({
  type: ACCEPT_BID_FAILURE,
  payload: error,
});

export const acceptBid = (bidId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/accept_bid',
        data: { BidID: bidId },
      });
      dispatch(acceptBidSuccess());
    } catch (error: any) {
      dispatch(acceptBidFailure(error.toString()));
    }
  };
};

// **6. Reject Bid**

export const rejectBidSuccess = (): AnyAction => ({
  type: REJECT_BID_SUCCESS,
});

export const rejectBidFailure = (error: string): AnyAction => ({
  type: REJECT_BID_FAILURE,
  payload: error,
});

export const rejectBid = (bidId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/reject_bid',
        data: { BidID: bidId },
      });
      dispatch(rejectBidSuccess());
    } catch (error: any) {
      dispatch(rejectBidFailure(error.toString()));
    }
  };
};

// **7. Upload Final Document**

export const uploadFinalDocumentSuccess = (): AnyAction => ({
  type: UPLOAD_FINAL_DOCUMENT_SUCCESS,
});

export const uploadFinalDocumentFailure = (error: string): AnyAction => ({
  type: UPLOAD_FINAL_DOCUMENT_FAILURE,
  payload: error,
});

export const uploadFinalDocument = (caseId: string, file: File) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const formData = new FormData();
      formData.append('CaseID', caseId);
      formData.append('file', file);

      await apis({
        method: 'POST',
        url: '/marketplace/upload_final_document',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      dispatch(uploadFinalDocumentSuccess());
    } catch (error: any) {
      dispatch(uploadFinalDocumentFailure(error.toString()));
    }
  };
};

// **8. Get Final Documents**

export const getFinalDocumentsSuccess = (caseId: string, documents: any[]): AnyAction => ({
  type: GET_FINAL_DOCUMENTS_SUCCESS,
  payload: { caseId, documents },
});

export const getFinalDocumentsFailure = (error: string): AnyAction => ({
  type: GET_FINAL_DOCUMENTS_FAILURE,
  payload: error,
});

export const getFinalDocuments = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/get_final_documents',
        data: { CaseID: caseId },
      });
      const documents = response.data;
      dispatch(getFinalDocumentsSuccess(caseId, documents));
    } catch (error: any) {
      dispatch(getFinalDocumentsFailure(error.toString()));
    }
  };
};

// **9. Submit Lawyer Rating**

export const submitLawyerRatingSuccess = (): AnyAction => ({
  type: SUBMIT_LAWYER_RATING_SUCCESS,
});

export const submitLawyerRatingFailure = (error: string): AnyAction => ({
  type: SUBMIT_LAWYER_RATING_FAILURE,
  payload: error,
});

export const submitLawyerRating = (ratingData: {
  CaseID: string;
  Rating: number;
  Review: string;
}) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/submit_lawyer_rating',
        data: ratingData,
      });
      dispatch(submitLawyerRatingSuccess());
    } catch (error: any) {
      dispatch(submitLawyerRatingFailure(error.toString()));
    }
  };
};

// **10. Get Lawyer Profile**

    export const getLawyerProfileRequest = (): AnyAction => ({
      type: GET_LAWYER_PROFILE_REQUEST,
    });

    export const getLawyerProfileSuccess = (lawyerId: string, profile: any): AnyAction => ({
      type: GET_LAWYER_PROFILE_SUCCESS,
      payload: { lawyerId, profile },
    });

    export const getLawyerProfileFailure = (error: string): AnyAction => ({
      type: GET_LAWYER_PROFILE_FAILURE,
      payload: error,
    });


export const getLawyerProfile = (lawyerId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/get_lawyer_profile',
        data: { LawyerID: lawyerId },
      });
      const profile = response.data.profile;
      dispatch(getLawyerProfileSuccess(lawyerId, profile));
    } catch (error: any) {
      dispatch(getLawyerProfileFailure(error.toString()));
    }
  };
};

// **11. Create Case**
  
  export const createCase = (caseData: any) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      try {
        const response = await apis({
          method: 'POST',
          url: '/cases/create',
          data: caseData,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        dispatch(showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: (`Case created`),
            duration: 3000
        }))
        return response.data;
      } catch (error: any) {
        dispatch(showFeedbackModal({
            modalType: 'error',
            showModal: true,
            message: (`Failed to create case`),
            duration: 3000
        }))
      }
    };
  };


  export const proposeCaseRequest = (): AnyAction => ({
    type: PROPOSE_CASE_REQUEST,
  });
  
  export const proposeCaseSuccess = (): AnyAction => ({
    type: PROPOSE_CASE_SUCCESS,
  });
  
  export const proposeCaseFailure = (error: string): AnyAction => ({
    type: PROPOSE_CASE_FAILURE,
    payload: error,
  });
  
  export const proposeCase = (data: { LawyerID: string; CaseID: string; Description: string }) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(proposeCaseRequest());
      try {
        const response = await apis({
          method: 'POST',
          url: '/marketplace/create_client_request',
          data,
        });
        dispatch(proposeCaseSuccess());
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: 'Client request created successfully',
            showModal: true,
            duration: 3000,
          })
        );
      } catch (error: any) {
        dispatch(proposeCaseFailure(error.toString()));
        dispatch(
          showFeedbackModal({
            modalType: 'error',
            message: 'Failed to create client request. Please try again.',
            showModal: true,
            duration: 3000,
          })
        );
        throw error; // Re-throw to handle in the component
      }
    };
  };


  export const setMarketplaceActive = (isActive: boolean) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      try {
        await apis({
          method: 'POST',
          url: '/marketplace/set_marketplace_active',
          data: { is_active: isActive },
        });
        dispatch(setMarketplaceActiveSuccess());
      } catch (error: any) {
        dispatch(setMarketplaceActiveFailure(error.toString()));
      }
    };
  };