import React from 'react';
import { FolderProps } from './types';
import { showCreateFolderModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import FolderListItem from './FolderListItem';
import { showAddFileSidePanel } from '../../store/actions/sidePanelActions';
import { useAppDispatch } from '../../store';
import ApiLoader from '../ApiLoader';

interface FolderListProps {
  folders: FolderProps[];
  onSelectFolder: (folderId: string) => void;
  selectedFolderId: string | null;
  handleCreateFolder: (folderName: string) => void;
  isLoading: boolean;
  onFileSelect?: (fileId: string) => void;
}

const FolderList: React.FC<FolderListProps> = ({
  folders,
  onSelectFolder,
  selectedFolderId,
  isLoading,
  handleCreateFolder,
  onFileSelect,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleAddFolder = () => {
    dispatch(showCreateFolderModal(handleCreateFolder));
  };

  const handleAddFile = () => {
    dispatch(showAddFileSidePanel(folders));
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-4">
        <h2 className="text-lg font-bold">{t('Folders')}</h2>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <ApiLoader />
        </div>
      ) : (
        <>
          <div className="flex-1 overflow-y-auto px-4">
            {folders.map((folder) => (
              <FolderListItem
                key={folder.FolderID}
                folder={folder}
                onSelectFolder={onSelectFolder}
                selectedFolderId={selectedFolderId}
              />
            ))}
          </div>
          <div className="border-t border-gray-300 dark:border-gray-700 p-4">
            <div className="flex flex-col space-y-2">
            <Button variant="primary" onClick={handleAddFolder} className="w-full">
              <span>{t('Create New Folder')}</span>
              <i className="fas fa-folder-plus"></i>
            </Button>
            <Button variant="primary" onClick={handleAddFile} className="w-full">
              <span>{t('Create New File')}</span>
              <i className="fas fa-file-plus"></i>
            </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FolderList;
