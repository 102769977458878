import { AnyAction } from 'redux';
import { HIDE_FEEDBACK_MODAL, SHOW_FEEDBACK_MODAL } from '../types';

export interface UserFeedBackState {
    showModal: boolean,
    message: string,
    modalType: 'success' | 'warning' | 'error' | 'info' | 'neutral';
    duration: number
}

const initialState: UserFeedBackState = {
  showModal: false,
  message: '',
  modalType: 'neutral',
  duration: 3000

};

export const userFeedbackReducer = (state = initialState, action: AnyAction): UserFeedBackState => {
  switch (action.type) {
    case SHOW_FEEDBACK_MODAL:
      return {
        ...state,
        ...action.payload,
        showModal: true,
        
      };
    case HIDE_FEEDBACK_MODAL:
      return {
        ...state,
        showModal: false
      };
    default:
      return state;
  }
};
