import { Dispatch } from "redux";
import { UserFeedBackState } from "../reducer/UserFeedbackReducer";
import { HIDE_FEEDBACK_MODAL, SHOW_FEEDBACK_MODAL } from "../types";


export const showFeedbackModal = (payload: UserFeedBackState) => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_FEEDBACK_MODAL,
    payload
  });

  setTimeout(() => {
    dispatch(hideFeedbackModal());
  }, payload.duration);
};

export const hideFeedbackModal = () => ({
  type: HIDE_FEEDBACK_MODAL,
});
