import { SpeechActions } from "../actions/SpeechAction";
import { SET_SPEECH_CONFIG, SET_VOICE } from "../types";


function getInitialSpeechSettings() {
  const savedSettings = localStorage.getItem('aiSettings');
  if (savedSettings) {
    const settings = JSON.parse(savedSettings);
    return {
      speechKey: settings.speechKey || '50e6582558fa4c02b217197961fe492c',
      serviceRegion: settings.serviceRegion || 'swedencentral',
      voice: settings.voice || 'en-US-AndrewMultilingualNeural', 
    };
  }
  return {
    speechKey: '50e6582558fa4c02b217197961fe492c',
    serviceRegion: 'swedencentral',
    voice: 'en-US-AndrewMultilingualNeural', 
  };
}

const initialState: SpeechState = getInitialSpeechSettings();

export interface SpeechState {
  speechKey: string;
  serviceRegion: string;
  voice: string;
}

export const speechReducer = (state = initialState, action: SpeechActions): SpeechState => {
  switch (action.type) {
    case SET_VOICE:
      return {
        ...state,
        voice: action.payload.voice,
      };
    case SET_SPEECH_CONFIG:
      return {
        ...state,
        speechKey: action.payload.speechKey,
        serviceRegion: action.payload.serviceRegion,
        voice: action.payload.voice,
      };
    default:
      return state;
  }
};
