import React from 'react';

const Waveform: React.FC = () => {
  return (
    <div className="flex items-center justify-center ml-2">
      <div className="flex space-x-1">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="w-1.5 h-4 bg-primary-500 rounded-full animate-wave"
            style={{ animationDelay: `${i * 0.15}s` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Waveform;
