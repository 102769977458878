import React, { useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import ClientRequestCard from '../../shared/TailwindComponents/ClientRequestCard';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { getIncomingClientRequests } from '../../../store/actions/marketplace2/marketplaceRequestsActions';

const IncomingClientRequestsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const requests = useSelector((state: RootState) => state.marketplaceRequests.incomingClientRequests);
  const isLoading = useSelector((state: RootState) => state.marketplaceRequests.isLoading);
  const error = useSelector((state: RootState) => state.marketplaceRequests.error);
  const currentPage = useSelector((state: RootState) => state.marketplaceRequests.currentPage);
  const totalPages = useSelector((state: RootState) => state.marketplaceRequests.totalPages);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(getIncomingClientRequests(page));
  }, [dispatch, page]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-4">Incoming Client Requests</h1>
      {requests && requests.length > 0 ? (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {requests.map((request) => (
              <ClientRequestCard key={request.RequestID} request={request} />
            ))}
          </div>
          {/* Pagination Controls */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </>
      ) : (
        <p>No incoming client requests available.</p>
      )}
    </div>
  );
};

export default IncomingClientRequestsList;
