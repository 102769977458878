import axios from 'axios';
import { config, isTokenExpired } from "./config";
import SocketServices from './SocketServices';
import store from '../store';
import { showFeedbackModal } from '../store/actions/UserFeedbackActions';
import { toast } from 'react-toastify';
const session_id = sessionStorage.getItem("session_id")

SocketServices.initializeSocket()
SocketServices.emit("user_join", { "username": "A", "caseid": "" })

if (session_id) {
    SocketServices.emit("re_setting_session", { "session_id": session_id })
}

const apis = axios.create({
    baseURL: `${config.baseUrl}`
    // baseURL: "https://dev.api.pons.io/"
});

export default apis;

apis.interceptors.request.use(async (config) => {
    const auth_token = localStorage.getItem("token") || sessionStorage.getItem("token");
    let accessToken = ""
    if (auth_token) {
        accessToken = JSON.parse(auth_token).token
    }

    if (accessToken) {
        if (isTokenExpired(accessToken)) {
            // Token is expired, try to refresh it
            try {

            } catch (error) {
                console.error('Failed to refresh token', error);
                // signOut()
                return Promise.reject(error);
            }
        } else {
            if (auth_token)
                Object.assign(config.headers, {
                    Authorization: `Bearer ${accessToken}`,
                })
        }
    }




    return config;
}, function (error) {
    return Promise.reject(error);
});


// Add a response interceptor
apis.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error, "interseptor");
        
        const dispatch = store.dispatch;
        if (error.response?.status === 500) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: (error.response?.message ? error.response?.message : error.response?.error || 'An unknown error occurred'),
                modalType: 'error',
                duration: 3000
            }))
        } else
            if (error.response?.status === 403) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: ("Unauthorized request,login with valid Credentials "),
                    modalType: 'error',
                    duration: 3000
                }))
            } else if (error.response?.status === 401) {
                console.log(error.response?.status, "status");
                
                // const url = window.location.href;

                // if (url.indexOf("login") === -1) {
                //     window.location.href = "/login";
                //     toast.error("Unauthorized request, Please Login Again...")
                // }
                // SocketServices.emit('logout_session', { "session_id": session_id })
                // window.localStorage.clear();
                // window.sessionStorage.clear()
                return error
            }
        return error;

    }
);
