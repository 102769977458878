import { AnyAction } from 'redux';
import {
  GET_PUBLISHED_CASES_SUCCESS,
  GET_PUBLISHED_CASES_FAILURE,
  GET_CASE_BIDS_SUCCESS,
  GET_CASE_BIDS_FAILURE,
  GET_FINAL_DOCUMENTS_SUCCESS,
  GET_FINAL_DOCUMENTS_FAILURE,
  GET_LAWYER_PROFILE_SUCCESS,
  GET_LAWYER_PROFILE_FAILURE,
  GET_LAWYERS_REQUEST,
  GET_LAWYERS_SUCCESS,
  GET_LAWYERS_FAILURE,
  GET_LAWYER_PROFILE_REQUEST,
} from '../../types';

interface MarketplaceState {
  publishedCases: any[];
  lawyers: any[];
  currentPage: number;
  totalPages: number;
  isLoadingLawyers: boolean;
  caseBids: { [caseId: string]: any[] };
  finalDocuments: { [caseId: string]: any[] };
  lawyerProfiles: { [lawyerId: string]: any };
  isLoadingLawyerProfile: boolean;
  error: string | null;
}

const initialState: MarketplaceState = {
  publishedCases: [],
  lawyers: [],
  currentPage: 1,
  totalPages: 1,
  isLoadingLawyers: false,
  caseBids: {},
  finalDocuments: {},
  lawyerProfiles: {},
  isLoadingLawyerProfile: false,
  error: null,
};

export const marketplaceReducer = (
  state = initialState,
  action: AnyAction
): MarketplaceState => {
  switch (action.type) {
    case GET_PUBLISHED_CASES_SUCCESS:
      return {
        ...state,
        publishedCases: action.payload,
        error: null,
      };
    case GET_PUBLISHED_CASES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case GET_LAWYERS_REQUEST:
        return {
          ...state,
          isLoadingLawyers: true,
          error: null,
        };
      case GET_LAWYERS_SUCCESS:
        return {
          ...state,
          lawyers: action.payload.lawyers,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          isLoadingLawyers: false,
          error: null,
        };
      case GET_LAWYERS_FAILURE:
        return {
          ...state,
          isLoadingLawyers: false,
          error: action.payload,
        };
    case GET_CASE_BIDS_SUCCESS:
      return {
        ...state,
        caseBids: {
          ...state.caseBids,
          [action.payload.caseId]: action.payload.bids,
        },
        error: null,
      };
    case GET_CASE_BIDS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_FINAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        finalDocuments: {
          ...state.finalDocuments,
          [action.payload.caseId]: action.payload.documents,
        },
        error: null,
      };
    case GET_FINAL_DOCUMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case GET_LAWYER_PROFILE_REQUEST:
        return {
          ...state,
          isLoadingLawyerProfile: true,
          error: null,
        };
      case GET_LAWYER_PROFILE_SUCCESS:
        return {
          ...state,
          lawyerProfiles: {
            ...state.lawyerProfiles,
            [action.payload.lawyerId]: action.payload.profile,
          },
          isLoadingLawyerProfile: false,
          error: null,
        };
      case GET_LAWYER_PROFILE_FAILURE:
        return {
          ...state,
          isLoadingLawyerProfile: false,
          error: action.payload,
        };
    // Handle other actions as necessary
    default:
      return state;
  }
};
