import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';

interface ProgressStep {
  step: string;
  status: string;
}

interface CaseProgressBarProps {
  steps: ProgressStep[];
}

const CaseProgressBar: React.FC<CaseProgressBarProps> = ({ steps = [] }) => {
  const totalSteps = steps.length;
  const completedSteps = steps.filter((step) => step.status === 'Completed').length;
  const progressPercentage = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;

  return (
    <div className="w-full p-4 bg-white dark:bg-gray-800 shadow">
      <div className="mb-4">
        <div className="relative">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
            <div
              style={{
                width: `${progressPercentage}%`,
                transition: 'width 0.5s ease-in-out',
              }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
            ></div>
          </div>
        </div>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          {completedSteps} of {totalSteps} steps completed
        </p>
      </div>
      <div className="flex space-x-4 overflow-x-auto">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center space-x-2">
            {step.status === 'Completed' ? (
              <CheckCircleIcon className="h-6 w-6 text-green-500 transition duration-500" />
            ) : step.status === 'In Progress' ? (
              <ArrowPathIcon className="h-6 w-6 text-blue-500 animate-spin" />
            ) : (
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-500 transition duration-500" />
            )}
            <p className="text-sm font-medium capitalize">{step.step.replace('_', ' ')}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseProgressBar;
