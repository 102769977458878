import { 
  ONBOARD_CLIENT, 
  CREATE_CASE, 
  UPDATE_CASE, 
  FETCH_CASE, 
  FETCH_CLIENT, 
  DELETE_CASE_FIELD, 
  ANALYZE_CASE, 
  FETCH_ALL_CLIENTS,
  GENERATE_DOCUMENT_FAILURE,
  GENERATE_DOCUMENT_REQUEST,
  GENERATE_DOCUMENT_SUCCESS,
  FETCH_ALL_EXTERNAL_CASES,
  FETCH_ALL_CLIENT_DETAILS_SUCCESS,
  FETCH_ALL_CLIENT_DETAILS_FAILURE
} from "../types";

export interface IndividualClientData {
  client_type?: 'individual';
  UserTypeName: 'IndividualClient';
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  IDVerificationStatus?: string;
  Address: string;
  UserID?: string;
  ClientID: string;
  attachments?: File[];
  BusinessName?: string;
}

export interface BusinessClientData {
  client_type?: 'business';
  UserTypeName: 'BusinessAdmin';
  BusinessName: string;
  FirstName: string;
  LastName: string;
  IDVerificationStatus?: string;
  Email: string;
  PhoneNumber: string;
  Address: string;
  BusinessID?: string;
  UserID?: string;
  ClientID: string;
  attachments?: File[];
}


export type ClientData = IndividualClientData | BusinessClientData;

export interface CaseData {
  client_id?: string;
  case_id?: string;
  business_id?: string;
  case_type?: string;
  case_status?: string;
  case_conclusion?: string;
  lawyer_id?: string;
  main_issue?: string;
  case_summary?: string;
  plaintiff_info?: string;
  defendant_info?: string;
  witness_statements?: string;
  evidence?: string;
  legal_arguments?: string;
  court_dates?: string;
  custom_fields?: Record<string, string>; 
}

interface ClientState {
  clientData: any;
  cases: Record<string, any>;
  selectedCase: any;
  clientDetails: { [key: string]: any }; // Store details by client ID
  selectedClient: any;
  allClients: ClientData[]; 
  generatingDocument: boolean;
  documentGenerationError: string | null;
  allExternalCases: any[];
}

const initialState: ClientState = {
  clientData: {},
  cases: {},
  selectedCase: {},
  clientDetails: {},
  selectedClient: {},
  allClients: [],
  generatingDocument: false,
  documentGenerationError: null, 
  allExternalCases: []
};

export const clientReducer = (state = initialState, action: any): ClientState => {
  switch (action.type) {
    case ONBOARD_CLIENT:
      return {
        ...state,
        clientData: action.payload,
      };
    case CREATE_CASE:
      return {
        ...state,
        cases: { ...state.cases, [action.payload.id]: action.payload },
      };
    case UPDATE_CASE:
      return {
        ...state,
        cases: {
          ...state.cases,
          [action.payload.caseId]: {
            ...state.cases[action.payload.caseId],
            [action.payload.field_name]: action.payload.field_value,
          },
        },
      };
    case FETCH_CASE:
      return {
        ...state,
        selectedCase: action.payload,
      };
    case FETCH_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    case FETCH_ALL_CLIENTS:
      return {
        ...state,
        allClients: action.payload || [], // Update the state with the fetched clients
      };
    case FETCH_ALL_EXTERNAL_CASES:
      return {
        ...state,
        allExternalCases: action.payload || [], 
      };
    case DELETE_CASE_FIELD:
      const { [action.payload.fieldName]: _, ...remainingFields } = state.cases[action.payload.caseId]?.fields || {};
      return {
        ...state,
        cases: {
          ...state.cases,
          [action.payload.caseId]: {
            ...state.cases[action.payload.caseId],
            fields: remainingFields,
          },
        },
      };
    case ANALYZE_CASE:
      return {
        ...state,
        cases: {
          ...state.cases,
          [action.payload.caseId]: {
            ...state.cases[action.payload.caseId],
            analysis: action.payload.analysis,
          },
        },
      };
      case GENERATE_DOCUMENT_REQUEST:
        return {
          ...state,
          generatingDocument: true,
          documentGenerationError: null,
        };
      case GENERATE_DOCUMENT_SUCCESS:
        return {
          ...state,
          generatingDocument: false,
          documentGenerationError: null,
        };
      case GENERATE_DOCUMENT_FAILURE:
        return {
          ...state,
          generatingDocument: false,
          documentGenerationError: action.payload,
        };
        case 'DELETE_CLIENT_SUCCESS':
          return {
            ...state,
            allClients: state.allClients.filter(client => client.UserID !== action.payload),
          };
        case 'DELETE_CASE_SUCCESS':
          const { case_id, client_id } = action.payload;
          return {
            ...state,
            cases: {
              ...state.cases,
              [client_id]: state.cases[client_id].filter((c: any) => c.CaseID !== case_id),
            },
          };
        case 'UPDATE_CASE_SUCCESS':
          const updatedCase = action.payload;
          return {
            ...state,
            cases: state.cases.map((caseItem: CaseData) =>
              caseItem.case_id === action.payload.case_id
                ? { ...caseItem, ...action.payload }
                : caseItem
            ),
          };
        case 'UPDATE_CASE_STATUS_SUCCESS':
          return {
            ...state,
            cases: {
              ...state.cases,
              [action.payload.case_id]: {
                ...state.cases[action.payload.case_id],
                case_status: action.payload.case_status,
              },
            },
          };
          case FETCH_ALL_CLIENT_DETAILS_SUCCESS:
            return {
              ...state,
              clientDetails: { ...state.clientDetails, [action.payload.client.ClientID]: action.payload },
            };
    case FETCH_ALL_CLIENT_DETAILS_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
