import { AnyAction } from 'redux';
import { IBusinessEmployeeState, IEmployee } from '../../actions/organization/BusinessActions';
import { FETCH_ALL_EMPLOYEES_REQUEST, FETCH_ALL_EMPLOYEES_SUCCESS, FETCH_ALL_EMPLOYEES_FAILURE } from '../../types';

const initialState: IBusinessEmployeeState = {
  employees: {},
  loading: false,
  error: null,
};

const businessEmployeeReducer = (state = initialState, action: AnyAction): IBusinessEmployeeState => {
  switch (action.type) {
    case FETCH_ALL_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case FETCH_ALL_EMPLOYEES_SUCCESS:
        const fetchedEmployees: Record<string, IEmployee> = action.payload.reduce(
          (acc: Record<string, IEmployee>, employee: IEmployee) => {
            acc[employee.EmployeeID] = employee;
            return acc;
          },
          {}
        );
        return {
          ...state,
          loading: false,
          employees: fetchedEmployees, 
        };
    case FETCH_ALL_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default businessEmployeeReducer;
