import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { SUBMIT_BID_SUCCESS, SUBMIT_BID_FAILURE, GET_CASE_BIDS_SUCCESS, GET_CASE_BIDS_FAILURE, ACCEPT_BID_SUCCESS, ACCEPT_BID_FAILURE, REJECT_BID_SUCCESS, REJECT_BID_FAILURE, WITHDRAW_BID_SUCCESS, WITHDRAW_BID_FAILURE, GET_CLIENT_BIDS_FAILURE, GET_CLIENT_BIDS_REQUEST, GET_CLIENT_BIDS_SUCCESS } from '../../types';
import { Bid } from '../../../components/Marketplace/Lawyer/LawyerSection';

// Action Creators
export const submitBidSuccess = (): AnyAction => ({ type: SUBMIT_BID_SUCCESS });
export const submitBidFailure = (error: string): AnyAction => ({
  type: SUBMIT_BID_FAILURE,
  payload: error,
});

export const getCaseBidsSuccess = (caseId: string, bids: any[]): AnyAction => ({
  type: GET_CASE_BIDS_SUCCESS,
  payload: { caseId, bids },
});
export const getCaseBidsFailure = (error: string): AnyAction => ({
  type: GET_CASE_BIDS_FAILURE,
  payload: error,
});

export const acceptBidSuccess = (): AnyAction => ({ type: ACCEPT_BID_SUCCESS });
export const acceptBidFailure = (error: string): AnyAction => ({
  type: ACCEPT_BID_FAILURE,
  payload: error,
});

export const rejectBidSuccess = (): AnyAction => ({ type: REJECT_BID_SUCCESS });
export const rejectBidFailure = (error: string): AnyAction => ({
  type: REJECT_BID_FAILURE,
  payload: error,
});

export const withdrawBidSuccess = (): AnyAction => ({ type: WITHDRAW_BID_SUCCESS });
export const withdrawBidFailure = (error: string): AnyAction => ({
  type: WITHDRAW_BID_FAILURE,
  payload: error,
});

export const getClientBidsRequest = (): AnyAction => ({
    type: GET_CLIENT_BIDS_REQUEST,
  });
  
  export const getClientBidsSuccess = (bids: Bid[]): AnyAction => ({
    type: GET_CLIENT_BIDS_SUCCESS,
    payload: bids,
  });
  
  export const getClientBidsFailure = (error: string): AnyAction => ({
    type: GET_CLIENT_BIDS_FAILURE,
    payload: error,
  });

  export const getClientBids = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(getClientBidsRequest());
      try {
        const response = await apis({
          method: 'GET',
          url: '/marketplace/my_bids',
        });
        dispatch(getClientBidsSuccess(response.data.bids));
      } catch (error: any) {
        dispatch(getClientBidsFailure(error.toString()));
      }
    };
  };
  

// Thunk Actions
export const submitBid = (bidData: {
  CaseID: string;
  BidAmount: number;
  BidDescription: string;
}) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/submit_bid',
        data: bidData,
      });
      dispatch(submitBidSuccess());
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          message: 'Bid submitted successfully',
          showModal: true,
          duration: 3000,
        })
      );
    } catch (error: any) {
      dispatch(submitBidFailure(error.toString()));
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: 'Failed to submit bid',
          showModal: true,
          duration: 3000,
        })
      );
    }
  };
};

export const getCaseBids = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/get_case_bids',
        data: { CaseID: caseId },
      });
      dispatch(getCaseBidsSuccess(caseId, response.data.bids));
    } catch (error: any) {
      dispatch(getCaseBidsFailure(error.toString()));
    }
  };
};

export const acceptBid = (bidId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/accept_bid',
        data: { BidID: bidId },
      });
      dispatch(acceptBidSuccess());
    } catch (error: any) {
      dispatch(acceptBidFailure(error.toString()));
    }
  };
};

export const rejectBid = (bidId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/reject_bid',
        data: { BidID: bidId },
      });
      dispatch(rejectBidSuccess());
    } catch (error: any) {
      dispatch(rejectBidFailure(error.toString()));
    }
  };
};

export const withdrawBid = (bidId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis({
        method: 'POST',
        url: '/marketplace/withdraw_bid',
        data: { BidID: bidId },
      });
      dispatch(withdrawBidSuccess());
    } catch (error: any) {
      dispatch(withdrawBidFailure(error.toString()));
    }
  };
};
