import React, { forwardRef } from 'react';
import clsx from 'clsx';

interface AlertProps {
  type: 'success' | 'warning' | 'error' | 'info' | 'neutral';
  message: string;
  subMessage?: string; 
  onClose?: () => void;
  actions?: Array<{
    label: string;
    onClick: () => void;
    variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  }>;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ type, message, subMessage, onClose, actions }, ref) => {
    // Define Tailwind classes based on the alert type
    const typeStyles = {
      success: 'bg-success-100 text-success-700 dark:bg-success-700 dark:text-success-100',
      warning: 'bg-warning-100 text-warning-700 dark:bg-warning-700 dark:text-warning-100',
      error: 'bg-danger-100 text-danger-700 dark:bg-danger-700 dark:text-danger-100',
      info: 'bg-info-100 text-info-700 dark:bg-info-700 dark:text-info-100',
      neutral: 'bg-neutral-100 text-neutral-700 dark:bg-neutral-700 dark:text-neutral-100',
    };

    return (
      <div
        ref={ref}
        className={clsx(
          'alert',
          typeStyles[type],
          'rounded-md p-4 shadow-lg'
        )}
        role="alert"
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center items-center">
          <div>
            <p className="font-medium">{message}</p>
            {subMessage && <p className="mt-1 text-sm">{subMessage}</p>}
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4 flex sm:flex-shrink-0">
            <div className="flex space-x-2">
              {actions &&
                actions.map((action, index) => (
                  <button
                    key={index}
                    onClick={action.onClick}
                    className={clsx(
                      'px-3 py-1 rounded-md text-sm font-medium',
                      {
                        'bg-primary-500 text-white hover:bg-primary-600':
                          action.variant === 'primary' || !action.variant,
                        'bg-secondary-500 text-white hover:bg-secondary-600':
                          action.variant === 'secondary',
                        'bg-success-500 text-white hover:bg-success-600':
                          action.variant === 'success',
                        'bg-warning-500 text-white hover:bg-warning-600':
                          action.variant === 'warning',
                        'bg-danger-500 text-white hover:bg-danger-600':
                          action.variant === 'error',
                      }
                    )}
                  >
                    {action.label}
                  </button>
                ))}
              {onClose && (
                <button
                  onClick={onClose}
                  className="ml-3 inline-flex rounded-md p-1 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white focus:outline-none"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  &#10005;
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

Alert.displayName = 'Alert';

export default Alert;
