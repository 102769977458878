import React from 'react';
import Dropdown from './Dropdown';
import Badge from './Badge';

interface FilterOption {
  label: string;
  options: { value: string; label: string }[];
}

interface FilterBarProps {
  filters: FilterOption[];
  sortOptions: { value: string; label: string }[];
  selectedFilters: { [key: string]: string[] };
  onFilterChange: (filterName: string, selectedValues: string[]) => void;
  onSortChange: (sortValue: string) => void;
  onRemoveFilter: (filterName: string, value: string) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  filters,
  sortOptions,
  selectedFilters,
  onFilterChange,
  onSortChange,
  onRemoveFilter,
}) => {
  return (
    <div className="bg-white dark:bg-gray-800 px-4 py-2 rounded-md shadow">
      <div className="flex flex-wrap items-center justify-between space-y-2">
        {/* Sort Dropdown */}
        <div className="flex items-center space-x-2">
          <Dropdown
            label="Sort by"
            options={sortOptions}
            placeholder="Select"
            onChange={(selectedValues) => {
              onSortChange(selectedValues[0]);
            }}
            className="w-48"
          />
        </div>
        {/* Filter Dropdowns */}
        <div className="flex items-center space-x-4">
          {filters.map((filter) => (
            <Dropdown
              key={filter.label}
              label={filter.label}
              options={filter.options}
              multiple
              placeholder={`Select ${filter.label}`}
              onChange={(selectedValues) => onFilterChange(filter.label, selectedValues as string[])}
              className="w-48"
            />
          ))}
        </div>
      </div>
      {/* Applied Filters */}
      {Object.keys(selectedFilters).length > 0 && (
        <div className="flex flex-wrap items-center mt-4 space-x-2">
          {Object.keys(selectedFilters).map((filterName) =>
            selectedFilters[filterName].map((value) => (
              <Badge
                key={`${filterName}-${value}`}
                color="gray"
                onClose={() => onRemoveFilter(filterName, value)}
              >
                {`${filterName}: ${value}`}
              </Badge>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default FilterBar;
