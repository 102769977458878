import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ResetPassword.css"
import { useDispatch } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { checkSessionValidation, resetPassword } from '../../store/actions/SignInAction';
import { useEffect, useState } from 'react';

interface RouteParams {
  token: string;
}

function ResetPassword() {
  const { token } = useParams<RouteParams>();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const history = useHistory();
  const [isSessionValid, setIsSessionValid] = useState(false);

  useEffect(() => {
    if (!token) {
      // If token is not present, redirect to home or show an error
      history.push('/');
      return;
    }

    const validateSession = async () => {
      const response = await dispatch(checkSessionValidation(token));
      if (response) {
        setIsSessionValid(response.session_expired);
        if (response.session_expired) {
          setTimeout(() => {
            history.push('/');
          }, 2000);
        }
      } else {
        setIsSessionValid(false);
      }
    };

    validateSession();

    const intervalId = setInterval(validateSession, 1 * 60 * 1000); // Refresh session every minute
    return () => clearInterval(intervalId);
  }, [token, dispatch, history]);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "8+ characters, uppercase, lowercase, number & special (!?&..)")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
        "8+ characters, uppercase, lowercase, number & special (!?&..)"
      )
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!token) {
        history.push('/');
        return;
      }

      const sendValues = {
        UID: token,
        Password: values.newPassword
      };

      try {
        const resp = await dispatch(resetPassword(sendValues));

        if (resp && resp.status === 200) {
          history.push("/");
        } else {
          // Handle reset password failure, show an error message
          // For example, set an error state and display it
        }
      } catch (error) {
        console.error('Password reset error:', error);
        // Handle unexpected errors, e.g., show a notification
      }
    },
  });

  return (
    <div className="reset-password-container">
      {
        !isSessionValid ? (
          <>
            <i className='company-logo fa-kit fa-logo'></i>
            <form onSubmit={formik.handleSubmit}>
              <h2>Reset Password</h2>
              <p>For your security, your new password must be at least 8 characters long and include a number and a special character.</p>
              <div className="form-group-reset">
                <label htmlFor="newPassword">New Password</label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  className={
                    `form-group-reset--input  ${formik.touched.newPassword && formik.errors.newPassword
                      ? 'error'
                      : ''}`
                  }
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="error-message">{formik.errors.newPassword}</div>
                ) : null}
              </div>

              <div className="form-group-reset">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  className={
                    `form-group-reset--input  ${formik.touched.confirmPassword && formik.errors.confirmPassword
                      ? 'error'
                      : ''}`
                  }
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="error-message">{formik.errors.confirmPassword}</div>
                ) : null}
              </div>

              <button type="submit" className="primary-button">
                Reset Password
              </button>
            </form>
          </>
        ) : (
          <h2>Session expired</h2>
        )
      }
    </div>
  );
}

export default ResetPassword;
