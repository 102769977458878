import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineAudio, AiOutlineSend, AiOutlineStop } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useMicrophone from './services/useMicrophone';
import Waveform from './services/Waveform';

interface PromptBarProps {
  onSend: (text: string) => void;
  onBrowsePrompts: () => void;
  className?: string;
  speechKey: string;
  serviceRegion: string;
  aiSettings: any;
}

const PromptBar: React.FC<PromptBarProps> = ({ onSend, onBrowsePrompts, className, speechKey,
  serviceRegion, aiSettings }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [initialInput, setInitialInput] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [wordCount, setWordCount] = useState(0);
  const wordLimit = 3000;
  const [isFocused, setIsFocused] = useState(false);
  const recognizerRef = useRef<SpeechSDK.SpeechRecognizer | null>(null);

  const {
    finalTranscript,
    interimTranscript,
    isListening,
    startListening,
    stopListening,
    error,
  } = useMicrophone({
    speechSubscriptionKey: speechKey,
    serviceRegion: serviceRegion,
    language:  'en-US', 
  });

  useEffect(() => {
    if (isListening) {
      setInput(initialInput + ' ' + finalTranscript + ' ' + interimTranscript);
    } else if (finalTranscript) {
      setInput(initialInput + ' ' + finalTranscript);
    }
  }, [finalTranscript, interimTranscript, isListening]);

  useEffect(() => {
    const words = input.trim().split(/\s+/).filter(Boolean);
    setWordCount(words.length);
    adjustTextareaHeight();
  }, [input]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const maxHeight = 200; // Maximum height in pixels
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleSend = () => {
    if (input.trim()) {
      onSend(input.trim());
      setInput('');
    }
  };

  const handleVoiceClick = () => {
    if (isListening) {
      stopListening();
    } else {
      setInitialInput(input);
      startListening();
    }
  };

  return (
    <div className={`p-4 border-t border-gray-200 dark:border-gray-700 bg-transparent flex-shrink-0 ${className}`}>
      <div
        className={`rounded-lg border ${
          isFocused
            ? 'border-primary-500'
            : 'border-gray-300 dark:border-gray-600'
        } bg-transparent transition-colors duration-300`}
      >
        {/* Top Half */}
        <div className="flex p-2">
          <div className="flex-1">
            <textarea
              ref={textareaRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={t('Type your message...')}
              className="w-full bg-transparent text-text-light dark:text-text-dark resize-none overflow-hidden overflow-y-auto focus:outline-none font-sans font-normal leading-relaxed"
              style={{ maxHeight: '200px' }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
            />
          </div>
          <button
            onClick={handleSend}
            disabled={wordCount > wordLimit || !input.trim()}
            className="p-2 text-primary-500 hover:text-primary-600 disabled:text-gray-400 self-end"
          >
            <AiOutlineSend size={24} title={t('Send')} />
          </button>
        </div>
        {/* Bottom Half */}
        <div className="flex items-center justify-between p-2 border-t border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 rounded-b-lg">
          <div className="flex items-center">
            <button
              className="flex items-center text-gray-700 dark:text-gray-300 hover:text-primary-500 focus:outline-none"
              onClick={handleVoiceClick}
            >
              {isListening ? (
                <>
                  <AiOutlineStop size={20} className="text-red-500" />
                  <Waveform />
                </>
              ) : (
                <>
                  <AiOutlineAudio size={20} />
                  <span className="ml-1">{t('Voice Message')}</span>
                </>
              )}
            </button>
            <div className="mx-2 h-4 border-l border-gray-400 dark:border-gray-500"></div>
            <button
              className="flex items-center text-gray-700 dark:text-gray-300 hover:text-primary-500 focus:outline-none"
              onClick={onBrowsePrompts}
            >
              <FaSearch size={16} />
              <span className="ml-1">{t('Browse Prompts')}</span>
            </button>
          </div>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {wordCount}/{wordLimit} {t('words')}
          </span>
        </div>
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-500 dark:text-red-400">{error}</p>
      )}
      <p
        style={{ fontSize: 10 }}
        className="mt-2 text-gray-500 dark:text-gray-400 text-center"
      >
        {t(
          'Some answers may be beneficial to check and you should never blindly trust an AI.'
        )}
      </p>
    </div>
  );
};

export default PromptBar;
