import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchProjectDetails } from '../../store/actions/ProjectActions';
import ProjectView from './ProjectView';
import { ClipLoader } from 'react-spinners';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

const ProjectDetailsPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  
  const projectDetails = useSelector((state: RootState) => state.projects.projectDetails);
  const loading = useSelector((state: RootState) => state.projects.loading);

  useEffect(() => {
    dispatch(fetchProjectDetails({ ProjectID: projectId }));
  }, [dispatch, projectId]);

  const handleClose = () => {
    history.push('/projects');
  };

  useEffect(() => {
    if (projectDetails) {
      setBreadcrumbs([
        { name: 'Projects', href: '/projects', current: false },
        { name: projectDetails.ProjectName || `Project ${projectId}`, href: `/projects/${projectId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [projectDetails, projectId, setBreadcrumbs]);

  if (loading || !projectDetails) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={loading} size={30} />
      </div>
    );
  }

  return (
    <div className="p-6">
      <ProjectView project={projectDetails} onClose={handleClose} />
    </div>
  );
};

export default ProjectDetailsPage;
