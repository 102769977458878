import React from 'react';
import clsx from 'clsx';

interface StatCardProps {
  title: string;
  value: string;
  icon?: React.ReactNode;
  change?: string;
  changeType?: 'increase' | 'decrease';
  description?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  icon,
  change,
  changeType = 'increase',
  description,}) => {
  const cardClasses = clsx(
    'p-4 rounded-lg shadow-md bg-white text-gray-800 dark:bg-gray-800 dark:text-white flex items-center'
  );

  const changeClasses = clsx(
    'text-sm font-medium flex items-center',
    changeType === 'increase' ? 'text-green-600' : 'text-red-600'
  );

  return (
    <div className={cardClasses}>
      {icon && <div className="mr-4">{icon}</div>}
      <div className="flex-1">
        <h3 className="text-sm font-medium">{title}</h3>
        <div className="flex items-baseline">
          <span className="text-2xl font-semibold">{value}</span>
          {change && (
            <span className={`ml-2 ${changeClasses}`}>
              {changeType === 'increase' ? (
                <svg
                  className="w-4 h-4 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M5 10l5-5 5 5H5z" />
                </svg>
              ) : (
                <svg
                  className="w-4 h-4 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M15 10l-5 5-5-5h10z" />
                </svg>
              )}
              {change}
            </span>
          )}
        </div>
        {description && (
          <p
            className={clsx(
              'text-sm text-gray-500 dark:text-gray-400'
            )}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default StatCard;
