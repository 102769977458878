import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext); // Consume the context
  const [isDark, setIsDark] = useState<boolean>(false);

  useEffect(() => {
    setIsDark(theme === 'dark');
  }, [theme]);
  
  return (
    <button
      onClick={toggleTheme}
      className="py-2 px-3 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition-colors duration-300 hover:bg-gray-300 dark:hover:bg-gray-600"
    >
      {/* Icon can be adjusted based on theme */}
      <i className="fa-duotone fa-solid fa-moon-stars"></i>
    </button>
  );
};

export default ThemeToggle;
