import { AnyAction } from 'redux';
import { RootState } from '../..';
import { ThunkDispatch } from 'redux-thunk';
import apis from '../../../utils/apis';
import { IAdminCaseItem } from '../../../components/Marketplace/Interfaces/IAdminCaseItem';
import { showFeedbackModal } from '../UserFeedbackActions';
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../SignInAction';
import { CREATE_TEAM_FAILURE, CREATE_TEAM_REQUEST, CREATE_TEAM_SUCCESS, FETCH_CASES_FIRM_FAILURE, FETCH_CASES_FIRM_REQUEST, FETCH_CASES_FIRM_SUCCESS, FETCH_LAWYERS_FIRM_FAILURE, FETCH_LAWYERS_FIRM_REQUEST, FETCH_LAWYERS_FIRM_SUCCESS, FETCH_LEGAL_SUBJECTS_TEAM_FAILURE, FETCH_LEGAL_SUBJECTS_TEAM_REQUEST, FETCH_LEGAL_SUBJECTS_TEAM_SUCCESS, FETCH_LEGAL_TEAMS_FAILURE, FETCH_LEGAL_TEAMS_REQUEST, FETCH_LEGAL_TEAMS_SUCCESS, FETCH_TASK_SUCCESS, FETCH_TEAM_FAILURE, FETCH_TEAM_REQUEST, FETCH_TEAM_SUCCESS, SET_LICENSE_DATA, SET_LICENSE_REQUESTS } from '../../types';
import { Option } from '../../../components/CustomDropdownLCM.tsx/CustomDropdownLCM';




export const fetchLawyersRequest = (): AnyAction => ({
  type: FETCH_LAWYERS_FIRM_REQUEST,
});

export const fetchLawyersSuccess = (lawyers: any[]): AnyAction => ({
  type: FETCH_LAWYERS_FIRM_SUCCESS,
  payload: lawyers,
});

export const fetchLawyersFailure = (error: string): AnyAction => ({
  type: FETCH_LAWYERS_FIRM_FAILURE,
  payload: error,
});

export const fetchCasesRequest = (): AnyAction => ({
  type: FETCH_CASES_FIRM_REQUEST,
});

export const fetchCasesSuccess = (cases: IAdminCaseItem[]): AnyAction => ({
  type: FETCH_CASES_FIRM_SUCCESS,
  payload: cases,
});

export const fetchCasesFailure = (error: string): AnyAction => ({
  type: FETCH_CASES_FIRM_FAILURE,
  payload: error,
});

export const fetchSubjectsRequest = (): AnyAction => ({
  type: FETCH_LEGAL_SUBJECTS_TEAM_REQUEST,
});

export const fetchSubjectsSuccess = (subjects: any[]): AnyAction => ({
  type: FETCH_LEGAL_SUBJECTS_TEAM_SUCCESS,
  payload: subjects,
});

export const fetchSubjectsFailure = (error: string): AnyAction => ({
  type: FETCH_LEGAL_SUBJECTS_TEAM_FAILURE,
  payload: error,
});

export const fetchTeamsRequest = (): AnyAction => ({
  type: FETCH_LEGAL_TEAMS_REQUEST,
});

export const fetchTeamsSuccess = (teams: any[]): AnyAction => ({
  type: FETCH_LEGAL_TEAMS_SUCCESS,
  payload: teams,
});

export const fetchTeamsFailure = (error: string): AnyAction => ({
  type: FETCH_LEGAL_TEAMS_FAILURE,
  payload: error,
});

export const fetchTeamRequest = (): AnyAction => ({
  type: FETCH_TEAM_REQUEST,
});

export const fetchTeamSuccess = (team: any[]): AnyAction => ({
  type: FETCH_TEAM_SUCCESS,
  payload: team,
});

export const fetchTeamFailure = (error: string): AnyAction => ({
  type: FETCH_TEAM_FAILURE,
  payload: error,
});

export const createTeamRequest = (): AnyAction => ({
  type: CREATE_TEAM_REQUEST,
});

export const createTeamSuccess = (team: any): AnyAction => ({
  type: CREATE_TEAM_SUCCESS,
  payload: team,
});

export const fetchTasksSuccess = (tasks: any): AnyAction => ({
  type: FETCH_TASK_SUCCESS,
  payload: tasks,
});

export const createTeamFailure = (error: string): AnyAction => ({
  type: CREATE_TEAM_FAILURE,
  payload: error,
});

export const setLicenseRequest = (LicenseRequest: boolean): AnyAction => ({
  type: SET_LICENSE_REQUESTS,
  payload: LicenseRequest
})

export const setLicenseData = (licenceLimitExceded: boolean, totalLicenseCount: number,licenseUsed: number): AnyAction => ({
  type: SET_LICENSE_DATA,
  payload: {
    licenceLimitExceded,
    totalLicenseCount,
    licenseUsed
  }
})

export const getAllLawyers = (userID: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchLawyersRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: 'lawfirm/getAllLawyers',
        data: { UserID: userID },
      });
      const lawyers = await response.data;
      dispatch(fetchLawyersSuccess(lawyers));
    } catch (error) {
      console.error(error)
    }
  };
};

export const getAllCases = (userID: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchCasesRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: 'lawfirm/getCases',
        data: { LawfirmID: userID },
      });
      const cases = response.data;
      dispatch(fetchCasesSuccess(cases));
    } catch (error) {
      console.error(error);
      dispatch(fetchCasesFailure('Error fetching cases'));
    }
  };
};

export const getLegalSubjects = () => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchSubjectsRequest());
    try {
      const response = await apis({
        method: 'GET',
        url: 'lawfirm/getLegalSubjects',
      });
      // if (response && response.status === 200 && Array.isArray(response.data.list)) {
      //   const dataList: Option[] = response.data.list.map((item: any) => {
      //     const active = item.active !== undefined ? item.active === true : undefined;
      //     return {
      //       value: item.Value ? item.Value.toLowerCase() : '',
      //       label: item.DisplayName || '',
      //     };
      //   });
      //   // dispatch({ type: successType, payload: dataList });
      // }
      const subjects = response.data;
      
      dispatch(fetchSubjectsSuccess(subjects));
    } catch (error) {
      console.error(error);
      dispatch(fetchSubjectsFailure('Error fetching subjects'));
    }
  };
};

export const getLegalTeams = (userID: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchTeamsRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: '/lawyerteams/get_legal_teams',
        data: { LawfirmID: userID }
      });
      const teams = response.data;
      dispatch(fetchTeamsSuccess(teams));
    } catch (error) {
      console.error(error);
      dispatch(fetchTeamsFailure('Error fetching subjects'));
    }
  };
};

export const getTeam = (TeamId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchTeamRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: '/lawyerteams/get_team_details',
        data: { LegalTeamID: TeamId }
      });
      const team = response.data;
      dispatch(fetchTeamSuccess(team));
    } catch (error) {
      console.error(error);
      dispatch(fetchTeamFailure('Error fetching Team'));
    }
  };
};

export const createTeam = (teamData: any) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(createTeamRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: '/lawyerteams/add_team',
        data: teamData,
      });

      if (response && response.status === 200) {
        const team = response.data;
        dispatch(createTeamSuccess(team));
        dispatch(showFeedbackModal({
          showModal: true,
          message: response.data.message || 'Team added',
          modalType: 'success',
          duration: 3000
        }));
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(createTeamFailure('Error creating team'));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Failed to add team! Please contact support.',
        modalType: 'error',
        duration: 3000
      }));
    }
  };
};

export const lawfirmAssignTask = (dataValues: { TaskID: string, UserType: string, TaskTitle: string, TaskDescription: string, TaskDeadline: string, CaseID?: string, Assignees?: string[] }) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/assignTask",
        data: dataValues
      });

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: (resp.data.message || 'Task assigned'),
          modalType: 'success',
          duration: 3000
        }));
        return true;
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse;
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: 'Network error, please try again',
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export const getLawfirmAssignTask = (UserType: string, TaskID: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/getTask",
        data: {
          UserType,
          TaskID
        }
      })

      if (resp && resp.status === 200) {
        return resp.data
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err)
    }

  }
}

export const getAllLawfirmAssignedTask = (UserType: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/getAllTask",
        data: {
          UserType,
        }
      })

      if (resp && resp.status === 200) {
        dispatch(fetchTasksSuccess(resp.data))

        return resp.data
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err)
    }

  }
}

export const deleteLawfirmAssignedTask = (UserType: string, TaskID: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/deleteTask",
        data: {
          UserType,
          TaskID
        }
      })

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: (resp.data.message || 'Task deleted'),
          modalType: 'success',
          duration: 3000
        }));
        return true
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err)
    }

  }
}


export const completeLawfirmAssignedTask = (UserType: string, TaskID: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/completetask",
        data: {
          UserType,
          TaskID
        }
      })

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: (resp.data.message || 'Task completed'),
          modalType: 'success',
          duration: 3000
        }));
        return true
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err)
    }

  }
}

export const getLawfirmLawyerTasks = (UserType: string, EmployeeEmail: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/fetchEmployeeAllTasks",
        data: {
          UserType,
          EmployeeEmail
        }
      })

      if (resp && resp.status === 200) {
        // dispatch(fetchTasksSuccess(resp))
        return resp.data
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err)
    }

  }
}

export const getLawfirmLawyerCases = (UserType: string, EmployeeEmail: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "market/lawfirm/fetchEmployeeCases",
        data: {
          UserType,
          EmployeeEmail
        }
      })

      if (resp && resp.status === 200) {
        return resp.data
      } else {
        if (axios.isAxiosError(resp)) {
          const axiosError = resp as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err)
    }

  }
}

export const getRoles = () => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'GET',
        url: 'lawfirm/getRoles',
      });
      const teams = response.data;
    } catch (error) {
      console.error(error);
    }
  };
};


export const RequestLicenses = (RequestContent: string, LicenseCount: number) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: 'lawfirm/license/request',
        data: {
          RequestContent, LicenseCount
        }
      });
      if (response && response.status === 200) {
        dispatch(FetchLicenseRequests())
        dispatch(showFeedbackModal({
          showModal: true,
          message: response.data.message,
          modalType: 'success',
          duration: 3000
        }))
      }
    } catch (e) {
      console.error(e);

    }
  }
}


export const FetchLicenseRequests = () => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'GET',
        url: 'lawfirm/fetch/license/request',
      });

      if (response && response.status === 200) {
        const data = await response.data
        dispatch(setLicenseRequest(data.request_exist))
        dispatch(setLicenseData(data.limit_exceded, data.Total_Licenses,data.license_used))
      }
    } catch (e) {
      console.error(e);

    }
  }
}