import React, { useCallback, useEffect, useState } from 'react';
import AIHeader from './AIHeader';
import AISidebar from './AISidebar';
import ChatBot from './ChatBot';
import PromptBar from './PromptBar';
import { ChatHistoryItem, ChatHistoryResponse, Message } from './types';
import { addChatGroup, AIModel, callServiceAPI, chatGroupApi, ChatGroupData, chatHistoryApi, deleteChatGroup, getAiModels, setChatGroupIDStore } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { FileProps } from '../Documents/types';
import { AiOutlineMenu } from 'react-icons/ai';
import { fetchDocumentContent } from '../../store/actions/DocumentsAction';
import DocumentViewer from './DocumentViewer';
import { hideModal, showModal } from '../../store/actions/modalActions';
import { getVoiceID } from '../../utils/functions';
import { fetchAILanguages } from '../../store/actions/DropdownActions';
import { getAISettings } from '../../store/actions/DataAction';
import { config } from '../../utils/config';


const ChatInterface: React.FC = () => {
  const speechKey = config.azure.speechKey;
  const serviceRegion = config.azure.speechRegion;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const dispatch = useAppDispatch();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const chatGroupID = useSelector((state: RootState) => state.model.chatGroupID);
  const { t } = useTranslation();
  const userId = getUserID();
  const userType = getUserType();
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const [currentView, setCurrentView] = useState<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>('Folders');
  const [showPrompts, setShowPrompts] = useState(false);
  const [files, setFiles] = useState<(File | null)[]>([null, null]);
  const [hasSentFiles, setHasSentFiles] = useState(false);
  const aiSettings = useSelector((state: RootState) => state.lists.preferences);
  const languages = useSelector((state: RootState) => state.dropdown.aiLanguages)
  // aiSettings.AIVoiceID = getVoiceID(aiSettings.AIVoice, languages);

  const handleDocumentSelect = (index: number, file: File | null) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = file;
      return updatedFiles;
    });
  };


  const [modelDetails, setModelDetails] = useState<AIModel[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const fetchServices = useCallback(async () => {
    try {
      const response = await dispatch(getAiModels());
      if (response) {
        const allModels = response as AIModel[];
        // Filter services based on userType
        const userServices = getUserServices(userType, allModels);
        setModels(userServices.map((model) => model.model_display_name));
        setModelDetails(userServices);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  },[dispatch, userType])

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const getUserServices = (userType: string, allModels: AIModel[]) => {
    const userServiceMapping: { [key: string]: string[] } = {
      shared: ['Law Research', 'Contract Analysis'],
      IndependentLawyer: ['Case Research', 'Custom Agent'],
      LawFirmEmployee: ['Case Research', 'Company Agent', 'Custom Agent'],
      LawFirmAdmin: ['Case Research', 'Company Agent', 'Custom Agent'],
      IndividualClient: ['Legal Case Mapping (Clients)'],
      BusinessEmployee: ['Legal Case Mapping (Business)', 'Custom Agent', 'Company Agent'],
      BusinessAdmin: ['Legal Case Mapping (Business)', 'Custom Agent', 'Company Agent'],
    };

    let services = [...userServiceMapping.shared];
    if (userServiceMapping[userType]) {
      services = services.concat(userServiceMapping[userType]);
    } else {
      console.warn(`User type '${userType}' not recognized. No additional services added.`);
    }

    // Filter models based on services
    return allModels.filter((model) => services.includes(model.model_display_name));
  };


  useEffect(() => {
    dispatch(chatGroupApi(removeSpaces(selectedModel)));
    // Clear chat when AI model changes
    setMessages([]);
    dispatch(setChatGroupIDStore(''));
    setShowPrompts(false);
    // Reset files when model changes
    setFiles([]);
    setHasSentFiles(false);
  }, [selectedModel]);

  useEffect(() => {
    dispatch(fetchAILanguages())
    dispatch(getAISettings())
  }, [dispatch]);

  const handleSendMessage = async (text: string) => {
    setShowPrompts(false);

    let filesToSend: File[] = [];

    if (selectedModel === 'Contract Analysis' && !hasSentFiles) {
      filesToSend = files.filter((file): file is File => file !== null);
    }
    if (selectedModel === 'Contract Analysis' && !hasSentFiles) {
      filesToSend = files.filter((file): file is File => file !== null);
    }

    const userMessage: Message = {
      id: Date.now().toString(),
      text,
      sender: 'user',
      timestamp: new Date(),
      files: filesToSend.length > 0 ? filesToSend : undefined,
    };
    setMessages((prev) => [...prev, userMessage]);
    setIsTyping(true);

    const typingMessage: Message = {
      id: (Date.now() + 1).toString(),
      text: '',
      sender: 'assistant',
      timestamp: new Date(),
      isTyping: true,
    };
    setMessages((prev) => [...prev, typingMessage]);

    try {
      let data: any = { Prompt: text };
      let response: any;

      switch (selectedModel) {
        case 'Law Research':
          response = await callServiceAPI('LawResearch', data, [], chatGroupID);
          break;
        case 'Tax Research':
          response = await callServiceAPI('TaxResearch', data, [], chatGroupID);
          break;
        case 'Case Research':
          response = await callServiceAPI('CaseResearch', data, [], chatGroupID);
          break;
        case 'Contract Analysis':
          response = await callServiceAPI('ContractAdvisor', data, filesToSend, chatGroupID);
          break;
        case 'Contract Generation':
          data = { ContractTypeID: null, Command: text };
          response = await callServiceAPI('ContractGenerator', data, [], chatGroupID);
          break;
        case 'Company Agent':
          response = await callServiceAPI('CompanyAgent', { Prompt: text }, [], chatGroupID);
          break;
        case 'Custom Agent':
          response = await callServiceAPI('CustomAgent', { Command: text }, [], chatGroupID);
          break;
        default:
          throw new Error(`Service ${selectedModel} not recognized`);
      }

      let responseText = '';
      switch (selectedModel) {
        case 'Law Research':
          responseText = response?.LawResearchResponse || t('No response');
          break;
        case 'Tax Research':
          responseText = response?.TaxResearchResponse || t('No response');
          break;
        case 'Case Research':
          responseText = response?.CaseResearchResponse || t('No response');
          break;
        case 'Contract Analysis':
          responseText = response?.ContractAnalysisResponse || t('No response');
          break;
        case 'Contract Generation':
          responseText = response?.ContractGenerationResponse || t('No response');
          break;
        case 'Company Agent':
        case 'Custom Agent':
          responseText = response?.ChatResponse || t('No response');
          break;
        default:
          responseText = t('No response');
      }

      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === typingMessage.id ? { ...msg, text: responseText } : msg
        )
      );
      setIsTyping(false);

      if (selectedModel === 'Contract Analysis' && !hasSentFiles) {
        setFiles([null, null]);
        setHasSentFiles(true);
      }

      if (response.ChatGroupID && !chatGroupID) {
        dispatch(setChatGroupIDStore(response.ChatGroupID));

        const newChatGroup: ChatGroupData = {
          ChatGroupID: response.ChatGroupID,
          ChatTitle: removeSpaces(selectedModel),
          AIType: removeSpaces(selectedModel),
          CreationDate: '',
          LastModified: '',
          UserID: getUserID(),
          id: response.ChatID,
          status: ''
        };

        dispatch(addChatGroup(newChatGroup));
      }
    } catch (error: any) {
      console.error('Error sending message:', error);
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === typingMessage.id
            ? { ...msg, text: t('An error occurred. Please try again.')}
            : msg
        )
      );
      setIsTyping(false);
    }
  };

  const handleFileSelect = async (file: FileProps) => {
    console.log('Selected file:', file);
    try {
      const response = await dispatch(fetchDocumentContent(file.FileID));
      if (response) {
        setSelectedFile({
          ...file,
          content: response || '',
        });
      }
    } catch (error) {
      console.error('Error fetching document content:', error);
    }
  };

  const handleBrowsePrompts = () => {
    setShowPrompts(true);
  };

  const handleSelectChatGroup = async (chatGroupId: string) => {
    setIsTyping(false);
    setMessages([]);
    dispatch(setChatGroupIDStore(chatGroupId));

    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const response: ChatHistoryResponse = await dispatch(
        chatHistoryApi(selectedModel, chatGroupId, signal)
      );

      if (response && response.History) {
        const fetchedMessages: Message[] = response.History.flatMap(
          (historyItem: ChatHistoryItem) => [
            {
              id: historyItem.ChatID + '_user',
              text: historyItem.Prompt,
              sender: 'user' as 'user',
              timestamp: new Date(`${historyItem.Date}T${historyItem.Time}`),
              disableTypingEffect: true,
            },
            {
              id: historyItem.ChatID + '_assistant',
              text: historyItem.Reply,
              sender: 'assistant' as 'assistant',
              timestamp: new Date(`${historyItem.Date}T${historyItem.Time}`),
              disableTypingEffect: true,
            },
          ]
        );

        fetchedMessages.sort(
          (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
        );

        setMessages(fetchedMessages);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const handleDeleteChat = (chatGroupId: string) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this chat?'),
        subMsg: t('This action cannot be undone.'),
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await dispatch(deleteChatGroup(chatGroupId));
  
            setMessages([]);
            dispatch(setChatGroupIDStore(''));
            dispatch(chatGroupApi(removeSpaces(selectedModel)));
          } catch (error) {
            console.error('Failed to delete chat group:', error);
          }
          dispatch(chatGroupApi(removeSpaces(selectedModel)));
        },
        onCancel: () => {
          dispatch(hideModal());
        },
        showModal: true,
      })
    );
  };

  const handleNewChat = () => {
    setMessages([]);
    dispatch(setChatGroupIDStore(''));
    setShowPrompts(false);
  };

  function removeSpaces(str: string): string {
    return str.replace(/\s+/g, '');
  }

  return (
    <div className="flex overflow-hidden h-full">
      <div className="flex flex-col flex-1">
        <AIHeader
          onToggleSidebar={handleToggleSidebar}
          currentView={currentView}
          setCurrentView={setCurrentView}
          onDeleteChat={handleDeleteChat}
          onNewChat={handleNewChat}
          selectedModel={selectedModel}
          modelDetails={modelDetails}
          models={models}
        />
        <div className="flex flex-1 overflow-hidden">
          {/* ChatBot */}
          <div className="flex flex-1 flex-col overflow-hidden">
            <ChatBot
              messages={messages}
              setMessages={setMessages}
              isTyping={isTyping}
              showPrompts={showPrompts}
              onSendMessage={handleSendMessage}
              onFileSelect={handleDocumentSelect}
              files={files}
              speechKey={speechKey}
              serviceRegion={serviceRegion}
              aiSettings={aiSettings}
              className="flex-1 overflow-hidden"
            />
            <PromptBar
              onSend={(text) => handleSendMessage(text)}
              onBrowsePrompts={handleBrowsePrompts}
              speechKey={speechKey}
              serviceRegion={serviceRegion}
              aiSettings={aiSettings}
            />
          </div>
          {selectedFile && (
            <DocumentViewer
              file={selectedFile}
              onClose={() => setSelectedFile(null)}
              onUpdateFile={(updatedFile) => setSelectedFile(updatedFile)}
            />
          )}
        </div>
      </div>

      {isSidebarOpen && (
        <AISidebar
          onFileSelect={handleFileSelect}
          currentView={currentView}
          setCurrentView={setCurrentView}
          onSelectChatGroup={handleSelectChatGroup}
          handleDeleteChat={handleDeleteChat}
          removeSpaces={removeSpaces}
        />
      )}
    </div>
  );
};

export default ChatInterface;