import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  editDocument,
  fetchDocumentContent,
  deleteDocument,
  resetSelectedDocument,
  fetchDocumentVersion,
  fetchAnonymizationDatasets,
  fetchFileDetails,
  editDocumentSharedAccess,
  addDocumentDeadline,
  editDocumentApprover,
  signFile,
  fetchRecommendedDocuments,
  editDocumentAssignees,
  downloadFile,
  renameFile,
  uploadAndProcessFile,
} from '../../store/actions/DocumentsAction';
import { RootState, useAppDispatch } from '../../store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { ThemeProvider, Tooltip } from '@mui/material';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import { ToggleSwitchTheme } from './ToggleSwitchTheme';
import DocumentViewer from './DocumentViewer';
import Button from '../shared/TailwindComponents/Button';
import Input from '../shared/TailwindComponents/Input';
import './Editor.css'
import { FileProps } from './types';
import { modules } from './QuillToolBar';
import { fetchStructure } from '../../store/actions/FolderAction';
import { PencilIcon } from '@heroicons/react/20/solid';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';

dayjs.extend(customParseFormat);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'times-new-roman',
  'sans-serif',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
  'cursive',
  'monospace',
  'serif',
  'tahoma',
  'verdana',
  'impact',
  'palatino',
  'trebuchet-ms',
  'garamond',
  'brush-script-mt',
  'copperplate',
  'marker-felt',
];
Quill.register(Font, true);

// Interfaces
interface EditorProps {
  file: FileProps;
  onClose: () => void;
  setEditorOpen: (value: boolean) => void;
  onFileDeleted: () => void;
}

interface HeaderStructure {
  level: number;
  title: string;
}

const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    file.Deadline && dayjs(file.Deadline, 'DD-MM-YYYY HH:mm').isValid()
      ? dayjs(file.Deadline, 'DD-MM-YYYY HH:mm')
      : null
  );
  const [isOutlineOpen, setIsOutlineOpen] = useState(true);
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showApproverDropdown, setApproverDropdown] = useState(false);
  const [showVersionControlDropdown, setShowVersionControlDropdown] = useState(false);
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [newFile, setNewFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [content, setContent] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [comfortView, setComfortView] = useState(false);
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const [isFocusMode, setIsFocusMode] = useState(false);
  const [outline, setOutline] = useState<HeaderStructure[]>([]);

  const [emailShareInput, setEmailShareInput] = useState('');
  const [emailAssigneeInput, setEmailAssigneeInput] = useState('');
  const [emailApproverInput, setEmailApproverInput] = useState('');
  const [isDeadlineOpen, setIsDeadlineOpen] = useState(false);
  const [fileVersions, setFileVersions] = useState<any[]>([]);
  const { t } = useTranslation();
  const fileId = file.FileID;
  const selectedFile = useSelector((state: RootState) => state.documents.selectedFile);
  const [loadingAssignee, setLoadingAssignee] = useState(false);
  const [loadingAccess, setLoadingAccess] = useState(false);
  const [loadingApprover, setLoadingApprover] = useState(false);
  const [loadingDeadline, setLoadingDeadline] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const [currentVersionID, setCurrentVersionID] = useState<string | null>('latest');
  const fileDetails = selectedFile;
  const [isRenamingFile, setIsRenamingFile] = useState(false);
  const [newFileName, setNewFileName] = useState(file.FileName);
  const [isUploading, setIsUploading] = useState(false);
  const [newUpload, setNewUpload] = useState<File | null>(null);
  
  const sanitizeEmails = (emails: string[]) => {
    return emails.map((email) => email.replace(/^['"]|['"]+$/g, '').trim());
  };

  const assignees = React.useMemo(() => {
    if (!selectedFile) return [];
    const assigneesData = Array.isArray(selectedFile.Signatories)
      ? selectedFile.Signatories
      : typeof selectedFile.Signatories === 'string'
      ? selectedFile.Signatories.split(',').map((email: string) => email.trim())
      : [];
    return sanitizeEmails(assigneesData);
  }, [selectedFile]);
  
  // Similar for sharedAccess and approvers
  const sharedAccess = React.useMemo(() => {
    if (!selectedFile) return [];
    const sharedAccessData = Array.isArray(selectedFile.SharedWith)
      ? selectedFile.SharedWith
      : typeof selectedFile.SharedWith === 'string'
      ? selectedFile.SharedWith.split(',').map((email: string) => email.trim())
      : [];
    return sanitizeEmails(sharedAccessData);
  }, [selectedFile]);
  
  const approvers = React.useMemo(() => {
    if (!selectedFile) return [];
    const approversData = Array.isArray(selectedFile.Approvers)
      ? selectedFile.Approvers
      : typeof selectedFile.Approvers === 'string'
      ? selectedFile.Approvers.split(',').map((email: string) => email.trim())
      : [];
    return sanitizeEmails(approversData);
  }, [selectedFile]);

  const [paginatedContent, setPaginatedContent] = useState<string[]>([]);

  const editorContainerRef = useRef<HTMLDivElement>(null);

  // Function to split content into pages
  const paginateContent = () => {
    if (!editorContainerRef.current) return;

    const editorElement = editorContainerRef.current.querySelector('.ql-editor');

    if (!editorElement) return;

    const pageHeight = 1122; // Same as defined in CSS for .page height
    const contentClone = editorElement.cloneNode(true) as HTMLElement;
    const totalHeight = contentClone.scrollHeight;

    let currentPage = document.createElement('div');
    currentPage.className = 'page';
    let currentHeight = 0;
    let pages: string[] = [];
    let currentContent = '';

    const nodes = Array.from(contentClone.childNodes);

    nodes.forEach((node) => {
      const nodeHeight = getNodeHeight(node);
    
      if (currentHeight + nodeHeight > pageHeight) {
        pages.push(currentContent);
        currentContent = '';
        currentHeight = 0;
      }
    
      if (node instanceof Element) {
        currentContent += node.outerHTML;
      } else if (node.textContent) {
        currentContent += node.textContent;
      }
    
      currentHeight += nodeHeight;
    });    

    if (currentContent) {
      pages.push(currentContent);
    }

    setPaginatedContent(pages);
  };

  // Helper function to get node height
  const getNodeHeight = (node: ChildNode) => {
    const tempDiv = document.createElement('div');
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.position = 'absolute';
    tempDiv.style.width = editorContainerRef.current?.offsetWidth + 'px';
    tempDiv.appendChild(node.cloneNode(true));
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    return height;
  };


  useEffect(() => {
    const getFileDetails = async () => {
      await dispatch(fetchFileDetails( file.FileID));
    };
    getFileDetails();
  }, [dispatch, userType, file.FileID]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClose = () => {
    dispatch(resetSelectedDocument());
    onClose();
  };

  const handleFetchVersions = useCallback(async () => {
    const resp: any = await dispatch(fetchDocumentVersion(file.FileID));
    if (resp && Array.isArray(resp)) {
      setFileVersions(resp);
    }
  }, [file.FileID, dispatch, userType]);

  useEffect(() => {
    handleFetchVersions();
    if (file && file.FileUrl) {
      const urls = typeof file.FileUrl === 'string' ? [file.FileUrl] : file.FileUrl;
      const docs = urls.map((url) => ({ uri: url }));
    }
  }, [file, handleFetchVersions]);

  useEffect(() => {
    handleFetchVersions();
  }, [file, handleFetchVersions]);

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this document?'),
        onConfirm: handleConfirmDelete,
        onCancel: handleCancel,
        showModal: true,
      })
    );
  };

  const handleConfirmDelete = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    dispatch(
      deleteDocument(file.FileID, () => {
        dispatch(fetchRecommendedDocuments())
        dispatch(fetchStructure())
        setEditorOpen(false);
        onFileDeleted();
      })
    );

    dispatch(hideModal());
  };

  const handleCancel = () => {
    dispatch(hideModal());
    setEditorOpen(true);
  };
  const toggleAssigneeDropdown = () => {
    setShowAssigneeDropdown(!showAssigneeDropdown);
  };

  const toggleShareDropdown = () => {
    setShowShareDropdown(!showShareDropdown);
  };

  const toggleDeadline = () => {
    setIsDeadlineOpen(!isDeadlineOpen);
  };

  const toggleVersionControl = () => {
    setShowVersionControlDropdown(!showVersionControlDropdown);
  };

  const toggleFocusMode = () => {
    setIsFocusMode(!isFocusMode);
  };
  

  const toggleApproverDropdown = () => {
    setApproverDropdown(!showApproverDropdown);
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      // Show modal to choose between Append or Replace
      dispatch(
        showModal({
          type: 'confirm-upload',
          message: t('Choose an action for the uploaded file'),
          onConfirm: (action) => handleUploadAndProcessFile(selectedFile, action),
          showModal: true,
        })
      );
    }
  };


  const handleUploadAndProcessFile = async (selectedFile: File, action: string) => {
    if (!fileId || !selectedFile || !action) return;
  
    try {
      await dispatch(uploadAndProcessFile(selectedFile, action as 'append' | 'replace', fileId));
      // After successful upload and processing, fetch the file content
      const content: string = await dispatch(fetchDocumentContent(fileId));
      if (content) {
        setContent(content);
        setCurrentVersionID('latest');
      }
    } catch (error) {
      console.error('Failed to upload and process file:', error);
      // Handle error (e.g., show a notification)
    }
    dispatch(hideModal())
  };
  

  useEffect(() => {
    updateOutline(content);
  }, [content]);

  const toggleOutline = () => {
    setIsOutlineOpen(!isOutlineOpen);
  };

  const updateOutline = (editorContent: string) => {
    const headers = extractHeaders(editorContent);
    setOutline(headers);
  };

  const extractHeaders = (content: string): HeaderStructure[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const elements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');

    const headerStructure: HeaderStructure[] = [];

    elements.forEach((element) => {
      const level = parseInt(element.tagName.substring(1));
      headerStructure.push({
        level: level,
        title: element.textContent || '',
      });
    });

    return headerStructure;
  };

  useEffect(() => {
    if (file && file.FileCategory !== 'Template') {
      DocumentContent();
    }
  }, [file]);  

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const scrollToHeader = (headerText: string) => {
    const editor = document.querySelector('.ql-editor');
    if (editor) {
      const headers = editor.querySelectorAll('h1, h2, h3, h4, h5, h6');
      for (let header of headers) {
        if (header.textContent === headerText) {
          header.scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        }
      }
    }
  };

  const DocumentContent = async () => {
    try {
      const content: string = await dispatch(fetchDocumentContent(file.FileID));
      if (content) {
        setContent(content);
        setCurrentVersionID('latest');
      }
    } catch (error) {
      console.error('Error fetching document content:', error);
    }
  };
  

  const handleSave = async () => {
    if (newFile) {
      dispatch(
        showModal({
          type: 'confirm-upload',
          message: t('Upload File, Choose what this file will do!'),
          onConfirm: (selectedOption?: string | File) => {},
          showModal: true,
        })
      );
    } else {
      await dispatch(editDocument(file.FileID, file.FileName, content));
      setIsModified(false);
      await handleFetchVersions();
      setCurrentVersionID('latest');
    }
  };  

  const handleContent = (html: string) => {
    setIsModified(true);
    setContent(html);
  };

  const handleTranslateDocument = async () => {
    if (!file.FileID) {
      console.error('No file ID available for translation');
      return;
    }
    dispatch(
      showModal({
        type: 'translate',
        showModal: true,
        message: 'Translate Document',
        fileID: file.FileID,
        width: '30%',
        subMsg: t('Select which language you would like to translate your document to.'),
      })
    );
  };

  const handleAnonymizeDocument = async () => {
    if (!file.FileID) {
      console.error('No file ID available for anonymization');
      return;
    }

    try {
      await dispatch(fetchAnonymizationDatasets());
      dispatch(
        showModal({
          type: 'anonymize',
          showModal: true,
          message: 'Anonymize Document',
          fileID: file.FileID,
          width: '50%',
          subMsg: t('Select which parts of the document you want to anonymize.'),
        })
      );
    } catch (error) {
      console.error('Error fetching anonymization datasets:', error);
    }
  };

  const addAssignee = () => {
    if (emailAssigneeInput.trim()) {
      setLoadingAssignee(true);
      const updatedAssignees = sanitizeEmails([...assignees, emailAssigneeInput]);

      dispatch(editDocumentAssignees( fileId, updatedAssignees))
        .then(() => {
          setEmailAssigneeInput('');
          dispatch(fetchFileDetails( fileId));
        })
        .finally(() => setLoadingAssignee(false));
    }
  };

  const removeAssignee = (assignee: string) => {
    setLoadingAssignee(true);
    const updatedAssignees = sanitizeEmails(assignees.filter((item) => item !== assignee));

    dispatch(editDocumentAssignees( fileId, updatedAssignees))
      .then(() => {
        dispatch(fetchFileDetails(fileId));
      })
      .finally(() => setLoadingAssignee(false));
  };

  const addAccess = () => {
    if (emailShareInput.trim()) {
      setLoadingAccess(true);
      const updatedSharedAccess = sanitizeEmails([...sharedAccess, emailShareInput]);

      dispatch(editDocumentSharedAccess(fileId, updatedSharedAccess))
        .then(() => {
          setEmailShareInput('');
          dispatch(fetchFileDetails(fileId));
        })
        .finally(() => setLoadingAccess(false));
    }
  };

  const removeAccess = (email: string) => {
    setLoadingAccess(true);
    const updatedSharedAccess = sanitizeEmails(sharedAccess.filter((item) => item !== email));

    dispatch(editDocumentSharedAccess(fileId, updatedSharedAccess))
      .then(() => {
        dispatch(fetchFileDetails(fileId));
      })
      .finally(() => setLoadingAccess(false));
  };

  const addApprover = () => {
    if (emailApproverInput.trim()) {
      setLoadingApprover(true);
      const updatedApprovers = sanitizeEmails([...approvers, emailApproverInput]);

      dispatch(editDocumentApprover(fileId, updatedApprovers))
        .then(() => {
          setEmailApproverInput('');
          dispatch(fetchFileDetails(fileId));
        })
        .finally(() => setLoadingApprover(false));
    }
  };

  const removeApprover = (email: string) => {
    setLoadingApprover(true);
    const updatedApprovers = sanitizeEmails(approvers.filter((item) => item !== email));

    dispatch(editDocumentApprover(fileId, updatedApprovers))
      .then(() => {
        dispatch(fetchFileDetails(fileId));
      })
      .finally(() => setLoadingApprover(false));
  };

  const handleDeadlineChange = () => {
    if (selectedDate) {
      setLoadingDeadline(true);
      const deadlineString = selectedDate.format('YYYY-MM-DDTHH:MM:00');
      dispatch(addDocumentDeadline(fileId, deadlineString)).finally(() =>
        setLoadingDeadline(false)
      );
    }
  };

  const handleDownload = () => {
    if (!file || !file.FileID) {
      console.error('No file selected or file ID missing');
      return;
    }
    dispatch(downloadFile(file.FileID));
  };


  const handleSignClick = () => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Sign Document'),
        onConfirm: handleConfirmSign,
        onCancel: handleCancel,
        showModal: true,
        subMsg: t(
          'Are you sure you want to sign this document?<br />Other people with access to this document will be notified.'
        ),
      })
    );
  };
  
  const handleConfirmSign = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }
    const fileId = file.FileID;
    dispatch(hideModal());
  
    try {
      await dispatch(signFile(fileId, assignees));
      // Optionally, refresh the file data or update the UI to reflect that the document is signed
    } catch (error: any) {
      console.error('Error signing the document:', error);
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: error.message || 'Failed to sign the document.',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };
  const handleVersionClick = (version: any) => {
    setContent(version.Content);
    setCurrentVersionID(version.VersionID);
    setIsModified(false);
  };

  const handleLatestVersionClick = async () => {
    await DocumentContent();
    setCurrentVersionID('latest');
  };

    const handleRenameFile = () => {
      if (newFileName.trim() && newFileName !== file.FileName) {
        dispatch(renameFile(file.FileID, newFileName))
          .then(() => {
            setIsRenamingFile(false);
            dispatch(fetchFileDetails(file.FileID)); 
          })
          .catch((error) => console.error('Failed to rename file', error));
      }
    };

  return (
    <>
      {isFocusMode && (
        <div
          className="fixed inset-0 z-50 bg-white dark:bg-gray-900 flex flex-col"
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setIsFocusMode(false);
            }
          }}
          tabIndex={-1}
        >
          {/* Ellipsis Button */}
          <div className="absolute top-4 left-4">
            <button
              onClick={() => setIsFocusMode(false)}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>

          {/* Centered Toolbar and Editor */}
          <div className="flex-1 flex flex-col items-center justify-center overflow-auto">
            {/* Toolbar */}
            <div
              id="toolbar"
              className="flex flex-wrap items-center bg-gray-100 dark:bg-gray-800 p-2 justify-center mb-4 rounded-md"
              style={{ width: '80%' }}
            >
          {/* Font Selector */}
          <span className="ql-formats">
            <select className="ql-font">
              <option value="arial">{t('Arial')}</option>
              <option value="times-new-roman">{t('Times New Roman')}</option>
              <option value="comic-sans">{t('Comic Sans')}</option>
              <option value="courier-new">{t('Courier New')}</option>
              <option value="georgia">{t('Georgia')}</option>
              <option value="helvetica">{t('Helvetica')}</option>
              <option value="lucida">{t('Lucida')}</option>
              <option value="cursive">{t('Cursive')}</option>
              <option value="grey-qo">{t('Grey Qo')}</option>
            </select>
            <select className="ql-size">
              <option value="10px">10{t('px')}</option>
              <option value="12px">12{t('px')}</option>
              <option value="14px">14{t('px')}</option>
              <option value="16px">16{t('px')}</option>
              <option value="18px">18{t('px')}</option>
              <option value="20px">20{t('px')}</option>
              <option value="24px">24{t('px')}</option>
              <option value="30px">30{t('px')}</option>
            </select>
            <select className="ql-header" defaultValue="">
              <option value="1">{t('Heading 1')}</option>
              <option value="2">{t('Heading 2')}</option>
              <option value="3">{t('Heading 3')}</option>
              <option value="4">{t('Heading 4')}</option>
              <option value="5">{t('Heading 5')}</option>
              <option value="6">{t('Heading 6')}</option>
              <option value="">{t('Normal')}</option>
            </select>
          </span>
          {/* Text Formatting */}
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
          </span>
          {/* List and Indent */}
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
          </span>
          {/* Alignment */}
          <span className="ql-formats">
            <select className="ql-align" />
          </span>
          {/* Color and Background */}
          <span className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
          {/* Links and Media */}
          <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
          </span>
          {/* Clear Formatting */}
          <span className="ql-formats">
            <button className="ql-clean" />
          </span>
          <button className="ql-pageBreak">
            <i className="fas fa-file-alt"></i> {/* Use any icon you prefer */}
          </button>
        </div>
              
                    {/* Editor */}
                    <div className="w-3/4 h-full quill-editor bg-white shadow-md dark:shadow-dark-md rounded">
                    <ReactQuill
                  theme="snow"
                  value={content}
                  onChange={handleContent}
                  modules={modules} // Use the modules with the custom pageBreak handler
                  className={`h-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'} rounded-md`}
                />
      </div>
    </div>
                </div>
              )}
    <div className="flex flex-1 max-h-[calc(100vh-115px)] min-h-[calc(100vh-115px)]">
      {/* Outline Sidebar */}
      {isOutlineOpen && (
        <div className="w-64 bg-gray-100 dark:bg-gray-800 p-4 overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">{t('Outline')}</h2>
            <button onClick={toggleOutline} className="text-gray-600 hover:text-gray-800">
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
          {outline.map((header, index) => (
            <div
              key={index}
              className={`pl-${(header.level - 1) * 4} py-1 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700`}
              onClick={() => scrollToHeader(header.title)}
            >
              <h3 className={`text-base font-semibold`}>{header.title}</h3>
            </div>
          ))}
        </div>
      )}

      {/* Outline Toggle Button */}
      {!isOutlineOpen && (
        <button
          onClick={toggleOutline}
          className=" transform -translate-y-1/2 bg-gray-100 dark:bg-gray-800 p-2 rounded-l-md shadow-md hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none h-10 mt-5"
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      )}
  
      {/* Editor Content */}
      <div className="flex-1 flex flex-col bg-white dark:bg-gray-900">
        {/* Toolbar */}
        <div
          id="toolbar"
          className={`flex flex-wrap items-center bg-gray-100 dark:bg-gray-800 p-2 
          `}
        >
          {/* Font Selector */}
          <span className="ql-formats">
            <select className="ql-font">
              <option value="arial">{t('Arial')}</option>
              <option value="times-new-roman">{t('Times New Roman')}</option>
              <option value="comic-sans">{t('Comic Sans')}</option>
              <option value="courier-new">{t('Courier New')}</option>
              <option value="georgia">{t('Georgia')}</option>
              <option value="helvetica">{t('Helvetica')}</option>
              <option value="lucida">{t('Lucida')}</option>
              <option value="cursive">{t('Cursive')}</option>
              <option value="grey-qo">{t('Grey Qo')}</option>
            </select>
            <select className="ql-size">
              <option value="10px">10{t('px')}</option>
              <option value="12px">12{t('px')}</option>
              <option value="14px">14{t('px')}</option>
              <option value="16px">16{t('px')}</option>
              <option value="18px">18{t('px')}</option>
              <option value="20px">20{t('px')}</option>
              <option value="24px">24{t('px')}</option>
              <option value="30px">30{t('px')}</option>
            </select>
            <select className="ql-header" defaultValue="">
              <option value="1">{t('Heading 1')}</option>
              <option value="2">{t('Heading 2')}</option>
              <option value="3">{t('Heading 3')}</option>
              <option value="4">{t('Heading 4')}</option>
              <option value="5">{t('Heading 5')}</option>
              <option value="6">{t('Heading 6')}</option>
              <option value="">{t('Normal')}</option>
            </select>
          </span>
          {/* Text Formatting */}
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
          </span>
          {/* List and Indent */}
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
          </span>
          {/* Alignment */}
          <span className="ql-formats">
            <select className="ql-align" />
          </span>
          {/* Color and Background */}
          <span className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
          {/* Links and Media */}
          <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
          </span>
          {/* Clear Formatting */}
          <span className="ql-formats">
            <button className="ql-clean" />
          </span>
        </div>
  
        {/* Editor Area */}
        <div className="flex-1 overflow-auto p-4">
            {file.FileCategory === 'Template' ? (
                <DocumentViewer documentUrl={file.FileUrl} />
            ) : (
                <div className="h-full max-w-3xl mx-auto">
              <ReactQuill
                theme="snow"
                value={content}
                onChange={handleContent}
                modules={{ toolbar: '#toolbar'}}
                className={`h-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'} rounded-md`}
              />
                </div>
            )}
            </div>
      </div>
  
      {/* Actions Sidebar */}
      <div
        className={`w-64 bg-gray-100 dark:bg-gray-800 p-4 flex flex-col justify-between ${
          file.FileCategory === 'Template' ? 'hidden' : ''
        }`}
      >
        {/* Header */}
        <div>
        <div className="flex items-center justify-between mb-4">
              {isRenamingFile ? (
                <input
                  type="text"
                  value={newFileName}
                  onChange={(e) => setNewFileName(e.target.value)}
                  onBlur={handleRenameFile}
                  onKeyPress={(e) => e.key === 'Enter' && handleRenameFile()}
                  className="bg-gray-100 dark:bg-gray-700 rounded p-2"
                />
              ) : (
                <h2 className="text-lg font-bold">{fileDetails?.FileName}</h2>
              )}
              <button onClick={() => setIsRenamingFile(!isRenamingFile)} className="text-gray-500 hover:text-gray-700">
                <PencilIcon className="h-5 w-5" />
              </button>
            </div>
            {currentVersionID !== 'latest' && (
              <span className="text-red-500 text-sm">{t('Old version')}</span>
            )}
              {/* Save Button */}
          <div className="justify-between flex flex-row">
          <div className="space-x-2 flex flex-row">
              {/* Delete Button */}
              <Button
                title="Delete file"
                variant='destructive'
                onClick={handleDeleteClick}
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
              {/* Download Button */}
              <Button
                title="Download file"
                variant='success'
                onClick={handleDownload}
              >
                <i className="fas fa-download"></i>
              </Button>
              </div>
              {/* Close Button */}
              <Button
                title="Close file manager"
                onClick={handleClose}
                variant='neutral'
              >
                <i className="fas fa-times-circle"></i>
              </Button>
            </div>
              <Button
                onClick={handleSave}
                variant='primary'
                disabled={!isModified}
                className='w-full mt-2'
              >
                {t('Save')}
              </Button>
  
          {/* Action Buttons */}
          <div className="mt-4 space-y-2">
            {/* Assignees */}
            <div className="relative">
              <Button
                variant='neutral'
                onClick={toggleAssigneeDropdown}
                className='w-full justify-between'
              >
                <span>{t('Assignees')}</span>
                <i className="fas fa-users"></i>
              </Button>
              {showAssigneeDropdown && (
                <div className="absolute z-10 bg-white dark:bg-gray-800 w-full mt-2 rounded-md shadow-lg p-2">
                  <div className="flex items-center mb-2">
                    <Input
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={t('Assignee E-Mail...')}
                      value={emailAssigneeInput}
                      onChange={(e) => setEmailAssigneeInput(e.target.value)}
                    />
                    <Button
                      variant='success'
                      className='ml-1'
                      onClick={addAssignee}
                    >
                      {loadingAssignee ? (
                        <ClipLoader size={15} color="#ffffff" />
                      ) : (
                        <i className="fas fa-plus"></i>
                      )}
                    </Button>
                  </div>
                  <ul>
                    {assignees.length > 0 ? (
                      assignees.map((assignee, index) => (
                        <li
                          key={index}
                          className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
                        >
                          <span>{assignee}</span>
                          <button
                            onClick={() => removeAssignee(assignee)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">{t('No assignees')}</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
  
            {/* Shared Access */}
            <div className="relative">
              <Button
                className='w-full justify-between'
                variant='neutral'
                onClick={toggleShareDropdown}
              >
                <span>{t('Shared Access')}</span>
                <i className="fas fa-share-alt"></i>
              </Button>
              {showShareDropdown && (
                <div className="absolute z-10 bg-white dark:bg-gray-800 w-full mt-2 rounded-md shadow-lg p-2">
                  <div className="flex items-center mb-2">
                    <Input
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={t('Enter E-Mail...')}
                      value={emailShareInput}
                      onChange={(e) => setEmailShareInput(e.target.value)}
                    />
                    <Button
                      variant='success'
                      className='ml-1'
                      onClick={addAccess}
                    >
                      {loadingAccess ? (
                        <ClipLoader size={15} color="#ffffff" />
                      ) : (
                        <i className="fas fa-plus"></i>
                      )}
                    </Button>
                  </div>
                  <ul>
                    {sharedAccess.length > 0 ? (
                      sharedAccess.map((email, index) => (
                        <li
                          key={index}
                          className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
                        >
                          <span>{email}</span>
                          <button
                            onClick={() => removeAccess(email)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">{t('No shared access')}</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
  
            {/* Version Control */}
            <div className="relative">
              <Button
                className='w-full justify-between'
                variant='neutral'
                onClick={toggleVersionControl}
              >
                <span>{t('Version Control')}</span>
                <i className="fas fa-code-branch"></i>
              </Button>
              {showVersionControlDropdown && (
                <div className='absolute z-10 bg-white dark:bg-gray-800 w-full mt-2 rounded-md shadow-lg p-2'>
                    <li
                      className={`marker:text-success-500 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer ${
                        currentVersionID === 'latest' ? 'bg-gray-200 dark:bg-gray-700' : ''
                      }`}
                      onClick={handleLatestVersionClick}
                    >
                      {t('Latest Version')}
                    </li>
                <div className="max-h-64 overflow-auto">
                  <ul>
                    {fileVersions && fileVersions.length > 0 ? (
                      fileVersions.map((version: any, index) => (
                        <li
                          key={index}
                          className={`p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer ${
                            currentVersionID === version.VersionID ? 'bg-gray-200 dark:bg-gray-700' : ''
                          }`}
                          onClick={() => handleVersionClick(version)}
                        >
                          {dayjs(version.LastModified).format('DD MMM YYYY, HH:mm')}
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">{t('No versions available')}</li>
                    )}
                  </ul>
                </div>
                </div>
              )}
            </div>
  
            {/* Deadline */}
            <div className="relative">
              <Button
                className='w-full justify-between'
                variant='neutral'
                onClick={toggleDeadline}
              >
                <span>{t('Set Deadline')}</span>
                <i className="fas fa-calendar-alt"></i>
              </Button>
              {isDeadlineOpen && (
                <div className="absolute z-10 bg-white dark:bg-gray-800 w-full mt-2 rounded-md shadow-lg p-4">
                 <div className="mt-2">
                      <CustomDateTimePicker
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        minDateTime={dayjs()}
                        dateOnly={false}
                      />
                      <Button
                        onClick={handleDeadlineChange}
                        variant="primary"
                        className="w-full mt-2"
                      >
                        <i className="fas fa-check"></i> {t('Set Deadline')}
                      </Button>
                    </div>
                </div>
              )}
            </div>

            <Button
              onClick={handleUploadButtonClick}
              className='w-full justify-between'
              variant='neutral'
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <span>{t('Upload File')}</span>
              <i className="fas fa-upload"></i>
            </Button>
  

            {/* Focus Mode */}
                <Button
                  onClick={toggleFocusMode}
                  className="w-full justify-between"
                  variant="neutral"
                >
                  <span>{isFocusMode ? t('Exit Focus Mode') : t('Focus Mode')}</span>
                  <i className="fas fa-eye"></i>
                </Button>

  
            {/* Translate Document */}
            <Button
              onClick={handleTranslateDocument}
              className='w-full justify-between'
              variant='neutral'
            >
              <span>{t('Translate')}</span>
              <i className="fas fa-globe"></i>
            </Button>
  
            {/* Anonymize Document */}
            <Button
              onClick={handleAnonymizeDocument}
              className='w-full justify-between'
              variant='neutral'
              disabled
            >
              <span>{t('Anonymize')}</span>
              <i className="far fa-brackets-curly"></i>
            </Button>
  
            {/* Sign Document */}
            <Button
              onClick={handleSignClick}
              className='w-full justify-between'
              variant='neutral'
            >
              <span>{t('Sign Document')}</span>
              <i className="fas fa-signature"></i>
            </Button>
          </div>
        </div>
  
        {/* Theme Toggle */}
        <div className="mt-4">
          <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} />
        </div>
      </div>
    </div>
    </>
  );
  
};


export default Editor;