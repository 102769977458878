import React from 'react';
import { useHistory } from 'react-router-dom';
import { Bid } from '../../Marketplace2/shared/types';
import Badge from './Badge';
import Button from './Button';

interface BidCardProps {
  bid: Bid;
}

const BidCard: React.FC<BidCardProps> = ({ bid }) => {
  const history = useHistory();

  const handleViewBid = () => {
    history.push(`/bids/${bid.BidID}`);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <h3 className="text-xl font-bold mb-2">Bid Amount: ${bid.BidAmount}</h3>
      <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">{bid.BidDescription}</p>
      <Badge color="blue" className="mb-2">Case ID: {bid.CaseID}</Badge>
      <Button
        onClick={handleViewBid}
        variant='primary'
      >
        View Details
      </Button>
    </div>
  );
};

export default BidCard;
