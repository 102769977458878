import { ADD_DOCUMENT_APPROVER, ADD_DOCUMENT_ASSIGNEES, ADD_DOCUMENT_DEADLINE, ADD_DOCUMENT_SHARED_ACCESS, ADD_SIGNATURE_FAILURE, ADD_SIGNATURE_REQUEST, ADD_SIGNATURE_SUCCESS, ANONYMIZE_DOCUMENT_FAILURE, ANONYMIZE_DOCUMENT_REQUEST, ANONYMIZE_DOCUMENT_SUCCESS, AppThunk, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE, DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS, FETCH_ANONYMIZATION_DATASETS_FAILURE, FETCH_ANONYMIZATION_DATASETS_REQUEST, FETCH_ANONYMIZATION_DATASETS_SUCCESS, FETCH_CATEGORIES_FAILURE, FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_DOCUMENT_VERSIONS_FAILURE, FETCH_DOCUMENT_VERSIONS_REQUEST, FETCH_DOCUMENT_VERSIONS_SUCCESS, FETCH_DOCUMENTS_FAILURE, FETCH_DOCUMENTS_REQUEST, FETCH_DOCUMENTS_SUCCESS, FETCH_FILE_DETAILS_FAILURE, FETCH_FILE_DETAILS_REQUEST, FETCH_FILE_DETAILS_SUCCESS, FETCH_RECOMMENDED_DOCUMENTS_FAILURE, FETCH_RECOMMENDED_DOCUMENTS_REQUEST, FETCH_RECOMMENDED_DOCUMENTS_SUCCESS, FETCH_TRANSLATION_LANGUAGES_FAILURE, FETCH_TRANSLATION_LANGUAGES_REQUEST, FETCH_TRANSLATION_LANGUAGES_SUCCESS, RESET_SELECTED_DOCUMENT, SELECT_DOCUMENT_FOR_EDITING, UPDATE_FOLDERS_WITH_DOCUMENTS, UPLOAD_DOCUMENT_FAILURE, UPLOAD_DOCUMENT_SUCCESS, SIGN_FILE_FAILURE, SIGN_FILE_REQUEST, SIGN_FILE_SUCCESS  } from '../types';
import { getUserID, getUserType } from './ChatAction';
import axios, { AxiosError } from 'axios';
import apis from '../../utils/apis';
import { ErrorResponse } from './SignInAction';
import { Dispatch, AnyAction } from 'redux';
import { showFeedbackModal } from './UserFeedbackActions';
import { FileProps, FolderProps } from '../../components/Documents/types';



export const addSignatureRequest = () => ({
  type: ADD_SIGNATURE_REQUEST,
});

export const addSignatureSuccess = (signature: string) => ({
  type: ADD_SIGNATURE_SUCCESS,
  payload: signature,
});

export const addSignatureFailure = (error: string) => ({
  type: ADD_SIGNATURE_FAILURE,
  payload: error,
});


export const deleteDocumentRequest = (): { type: string } => ({
  type: DELETE_DOCUMENT_REQUEST,
});

export const deleteDocumentSuccess = (documentId: string): { type: string; payload: string } => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: documentId,
});

export const deleteDocumentFailure = (error: string): { type: string; payload: string } => ({
  type: DELETE_DOCUMENT_FAILURE,
  payload: error,
});

export const createDocumentRequest = (): { type: string } => ({
  type: CREATE_DOCUMENT_REQUEST,
});

export const createDocumentSuccess = (document: FileProps): { type: string; payload: FileProps } => ({
  type: CREATE_DOCUMENT_SUCCESS,
  payload: document,
});

export const createDocumentFailure = (error: string): { type: string; payload: string } => ({
  type: CREATE_DOCUMENT_FAILURE,
  payload: error,
});


export const selectDocumentForEditing = (document: FileProps) => ({
  type: SELECT_DOCUMENT_FOR_EDITING,
  payload: document,
});

export const resetSelectedDocument = () => ({
  type: RESET_SELECTED_DOCUMENT,
});


export const fetchDocumentsRequest = (): { type: string } => ({
  type: FETCH_DOCUMENTS_REQUEST,
});

export const fetchDocumentsSuccess = (documents: FileProps[]): { type: string; payload: FileProps[] } => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const fetchDocumentsFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_DOCUMENTS_FAILURE,
  payload: error,
});

export const fetchRecommendedDocumentsRequest = (): { type: string } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_REQUEST,
});

export const fetchRecommendedDocumentsSuccess = (recommendedDocuments: any[]): { type: string; payload: FileProps[] } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_SUCCESS,
  payload: recommendedDocuments,
});

export const fetchRecommendedDocumentsFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_FAILURE,
  payload: error,
});

export const updateFoldersWithDocuments = (folders: FolderProps[]): { type: string; payload: FolderProps[] } => ({
  type: UPDATE_FOLDERS_WITH_DOCUMENTS,
  payload: folders
});

export const getDocumentList = (userId: string, folderId: string, userType: string) => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  try {
    const response = await apis({
      method: "POST",
      url: "document/getDocumentList",
      data: { UserID: userId, FolderID: folderId, UserType: userType },
    });

    if (response && response.status === 200) {
      dispatch(fetchDocumentsSuccess(response.data));
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(new Error('Failed to fetch documents'));
    }
  } catch (error) {
    console.error("Error in fetching documents:", error);
    dispatch(fetchDocumentsFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    return Promise.reject(error);
  }
};

export const fetchRecommendedDocuments = () => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  dispatch(fetchRecommendedDocumentsRequest())
  try {
    const response = await apis({
      method: "GET",
      url: "/files/recommended_files",
    });

    if (response && response.status === 200) {
      dispatch(fetchRecommendedDocumentsSuccess(response.data.RecommendedFiles));
      return Promise.resolve(response.data.RecommendedFiles);
    } else {
      return Promise.reject(new Error('Failed to fetch documents'));
    }
  } catch (error) {
    console.error("Error in fetching documents:", error);
    dispatch(fetchRecommendedDocumentsFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    return Promise.reject(error);
  }
};



export const createNewDocument = (
  FileCategory: string,
  FolderID: string,
  file: File | undefined,
  additionalFields: { [key: string]: string | string[] }
): AppThunk => async (dispatch) => {
  dispatch(createDocumentRequest());
  const UserID = getUserID();
  const UserType = getUserType();

  try {
    const formData = new FormData();
    formData.append('UserID', UserID);
    formData.append('UserType', UserType);
    formData.append('FileCategory', FileCategory);
    formData.append('FolderID', FolderID);

    if (file) {
      formData.append('UploadedFile', file);
    }

    Object.keys(additionalFields).forEach((key) => {
      const feildValues = additionalFields[key]
      if (Array.isArray(feildValues)) {
        if (feildValues.length === 0) {
          formData.append(key, JSON.stringify(feildValues));
        } else {
          formData.append(key, JSON.stringify(feildValues));
          // (additionalFields[key] as string[]).forEach((value, index) => {
          // });
        }

      } else {
        formData.append(key, additionalFields[key] as string);
      }
    });

    const response = await apis({
      method: 'POST',
      url: '/files/create_file',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response && response.status === 200) {
      const newDocument = response.data;
      dispatch(createDocumentSuccess(newDocument));
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Document created',
          modalType: 'success',
          duration: 3000,
        })
      );
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse;
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: errorMessage.message || 'An unknown error occurred',
              modalType: 'error',
              duration: 3000,
            })
          );
        } else {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: 'Network error. Please try again later.',
              modalType: 'error',
              duration: 3000,
            })
          );
        }
      }
    }
  } catch (error) {
    console.error('Error in creating document:', error);
    dispatch(createDocumentFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Failed to create document.',
        modalType: 'error',
        duration: 3000,
      })
    );
  }
};


export const deleteDocument = (fileId: string, callback?: () => void): AppThunk => async (dispatch) => {

  try {
    const response = await apis({
      method: 'POST',
      url: "/files/delete_file",
      data: { FileID: fileId }
    });

    if (response && response.status === 200) {
      dispatch(deleteDocumentSuccess(fileId));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Document deleted',
        modalType: 'success',
        duration: 3000
      }));
      if (callback) callback();
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error. Please try again later',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (error) {
    console.error("Error in deleting document:", error);
    dispatch(deleteDocumentFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Failed to delete document.',
      modalType: 'error',
      duration: 3000
    }));
  }
};


export const editDocument = ( FileID: string, Title: string, Content: string): AppThunk => async (dispatch) => {
  try {

    let response = await apis({
      method: 'POST',
      url: "/files/edit_file",
      headers: { 'Content-Type': 'application/json' },
      data: { FileID: FileID, FileName: Title, Content: Content }
    })

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: 'File updated',
        showModal: true,
        duration: 3000
      }))
      return true
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error. Please try again.',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }

  } catch (err) {
    console.error(err);
    dispatch(showFeedbackModal({
      modalType: 'error',
      message: 'An error occuered',
      showModal: true,
      duration: 3000
    }))
  }
}


export const fetchDocumentContent = (
  FileID: string,
): AppThunk<Promise<string>> => async (dispatch) => {
  try {
    let response = await apis({
      method: 'POST',
      url: 'files/get_file_html_content',
      headers: { 'Content-Type': 'application/json' },
      data: {  FileID },
    });

    if (response.status === 200) {
      return response.data.html_content;
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
        }));
      } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
        }));
        }
      }
    }

  } catch (error) {
    if (error instanceof AxiosError) {

      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
    }
    throw error;
  }
}

export const renameFile = (
  fileId: string,
  newFileName: string
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const response = await apis({
      method: 'POST',
      url: '/files/rename_file',
      data: { FileID: fileId, NewFileName: newFileName },
    });

    if (response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'File renamed',
        modalType: 'success',
        duration: 3000,
      }));
    } else {
      throw new Error('Failed to rename file');
    }
  } catch (error: any) {
    console.error('Error in renameFile:', error);
    dispatch(showFeedbackModal({
      showModal: true,
      message: error.message || 'An unknown error occurred',
      modalType: 'error',
      duration: 3000,
    }));
    throw error;
  }
}

export const callAddSignatureAPI = async (formData: FormData): Promise<any> => {
  try {
    const response = await apis({
      method: 'POST',
      url: 'users/upload_signature',
      data: formData
    });
    return response.data;
  } catch (error) {
    console.error('Error calling Add Signature API:', error);
    throw error;
  }
};

export const addSignature = ( signature: File) => {
  return async (dispatch: any) => {
    dispatch(addSignatureRequest());
    const formData = new FormData();
    formData.append('SignatureFile', signature);

    try {
      const response = await callAddSignatureAPI(formData);
      dispatch(addSignatureSuccess(response.signature));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Signature added',
        modalType: 'success',
        duration: 3000
      }));
    } catch (error: any) {
      dispatch(addSignatureFailure(error.message));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Failed to add signature! Please contact support.',
        modalType: 'error',
        duration: 3000
      }));
    }
  };
}
;
export const getSignature = () => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: 'GET',
        url: 'users/get_user_signature',
      });
      return response.data.SignatureSASUrl;
    } catch (error: any) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Failed to get signature! Please contact support.',
          modalType: 'error',
          duration: 3000,
        })
      );
      return null;
    }
  };
};



export const updateDocumentOrder = (documentsWithNewOrder: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await apis({
        method: "POST",
        url: "/files/file-order",
        data: documentsWithNewOrder
      })

    } catch (err) {
      console.error(err)
    }
  }
}

export const updateFolderOrder = (updateFolderOrder: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await apis({
        method: "POST",
        url: "/files/folder-order",
        data: updateFolderOrder
      })

    } catch (err) {
      console.error(err)
    }
  }
}

export const fetchDocumentVersion = (FileID: string) => {
  return async (dispatch: Dispatch) => {
      dispatch({ type: FETCH_DOCUMENT_VERSIONS_REQUEST });
      try {
          const resp: any = await apis({
              method: "POST",
              url: "/files/get-file-versions",
              data: { FileID }
          });

          if (resp && resp.status === 200) {
              dispatch({
                  type: FETCH_DOCUMENT_VERSIONS_SUCCESS,
                  payload: resp.data
              });
              return resp.data;
          } else {
              dispatch({
                  type: FETCH_DOCUMENT_VERSIONS_FAILURE,
                  payload: 'Unexpected response format'
              });
              return null;
          }
      } catch (err: any) {
          console.error(err);
          dispatch({
              type: FETCH_DOCUMENT_VERSIONS_FAILURE,
              payload: err.message || 'Error fetching versions'
          });
          return null;
      }
    }
  }

export const translateDocument = (fileID: string, desiredLanguage: string | string[]) => {
  return async (dispatch: any) => {
    try {
      const payload = {
        FileID: fileID,
        TargetLanguage: desiredLanguage
      };

      const resp: any = await apis({
        method: "POST",
        url: "/files/translate_file",
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: `The document has been translated and added to files. Filename: ${resp.data.filename}, URL: ${resp.data.url}`,
          modalType: 'info',
          duration: 6000
        }));
        // alert(`The document has been translated and added to files. Filename: ${resp.data.filename}, URL: ${resp.data.url}`);
        return resp.data;
      } else {
        // handleAxiosError(resp, dispatch);
      }

    } catch (err) {
      console.error(err);
      handleGenericError(err, dispatch);
    }
  };
};

export const fetchTranslationLanguages = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_TRANSLATION_LANGUAGES_REQUEST });

    try {
      const resp = await apis({
        method: 'GET',
        url: '/dropdown/translation_codes'
    });

      if (resp.status === 200) {
        dispatch({
          type: FETCH_TRANSLATION_LANGUAGES_SUCCESS,
          payload: resp.data, 
        });
        return { payload: resp.data };
      } else {
        dispatch({
          type: FETCH_TRANSLATION_LANGUAGES_FAILURE,
          payload: 'Failed to fetch translation languages',
        });
        return { payload: null };
      }
    } catch (error: any) {
      console.error('Error fetching translation languages:', error);
      dispatch({
        type: FETCH_TRANSLATION_LANGUAGES_FAILURE,
        payload: error.message || 'Error fetching translation languages',
      });
      return { payload: null };
    }
  };
};


export const fetchAnonymizationDatasets = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ANONYMIZATION_DATASETS_REQUEST });
    try {
      const resp = await apis({
        method: "GET",
        url: "/anonymize/get_choices",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch({
          type: FETCH_ANONYMIZATION_DATASETS_SUCCESS,
          payload: resp.data,
        });
      } else {
        dispatch({ type: FETCH_ANONYMIZATION_DATASETS_FAILURE, payload: resp.data.message });
      }
    } catch (err) {
      dispatch({ type: FETCH_ANONYMIZATION_DATASETS_FAILURE});
      handleGenericError(err, dispatch);
    }
  };
};

export const anonymizeDocument = (fileID: string, anonymizationOptions: string[]) => {
  return async (dispatch: any) => {
    dispatch({ type: ANONYMIZE_DOCUMENT_REQUEST });
    try {
      const resp = await apis({
        method: "POST",
        url: "/files/anonymize_file",
        data: JSON.stringify({
          AnonymizationOptions: anonymizationOptions,
          FileID: fileID,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch({
          type: ANONYMIZE_DOCUMENT_SUCCESS,
          payload: resp.data,
        });

        dispatch(showFeedbackModal({
          showModal: true,
          message: (resp.data.message || 'Document anonymized'),
          modalType: 'success',
          duration: 3000, 
        }));

        // Optionally, refresh the documents list
        // dispatch(fetchDocuments());

      } else {
        dispatch({ type: ANONYMIZE_DOCUMENT_FAILURE, payload: resp.data.message });
      }

    } catch (err) {
      dispatch({ type: ANONYMIZE_DOCUMENT_FAILURE });
      handleGenericError(err, dispatch);
    }
  };
};

const handleGenericError = (err: any, dispatch: any) => {
  dispatch(showFeedbackModal({
    showModal: true,
    message: 'An error occurred, please try again later.',
    modalType: 'error',
    duration: 3000
  }));
};

export const editDocumentAssignees = (fileId: string, assignees: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    const sanitizedAssignees = assignees.map(email => email.replace(/^['"]|['"]+$/g, '').trim());
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/add_signatories',
        data: { FileID: fileId, Signatories: sanitizedAssignees },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_ASSIGNEES, payload: { fileId, assignees } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Assignees updated',
            modalType: 'success',
            duration: 3000,
          }) as any 
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (error) {
      console.error(error);
      handleError(dispatch, error);
    }
  };
};

// Add Deadline to a Document
export const addDocumentDeadline = ( fileId: string, deadline: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/set_deadline',
        data: { FileID: fileId, Expiry: deadline },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_DEADLINE, payload: { fileId, deadline } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Deadline updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (error) {
      console.error(error);
      handleError(dispatch, error);
    }
  };
};

// Add Shared Access to a Document
export const editDocumentSharedAccess = ( fileId: string, sharedAccess: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/edit_shared_access',
        data: { FileID: fileId, SharedWith: sharedAccess },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_SHARED_ACCESS, payload: { fileId, sharedAccess } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Access updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (error) {
      console.error(error);
      handleError(dispatch, error);
    }
  };
};

// Add Shared Access to a Document
export const editDocumentApprover = ( fileId: string, approvers: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/edit_approvers',
        data: { FileID: fileId, Approvers: approvers },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_APPROVER, payload: { fileId, approvers } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Approvers updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (error) {
      console.error(error);
      handleError(dispatch, error);
    }
  };
};

// Error handling
const handleError = (dispatch: Dispatch<any>, error: any) => {
  if (error.response && error.response.data) {
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: error.response.data.message || 'An error occurred, please try again',
        modalType: 'error',
        duration: 3000,
      })
    );
  } else {
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Network error, please try again',
        modalType: 'error',
        duration: 3000,
      })
    );
  }
};

export const uploadAndProcessFile = (
  uploadedFile: File,
  action: 'append' | 'replace',
  targetFileId: string
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('UploadedFile', uploadedFile);
    formData.append('Action', action);
    formData.append('TargetFileID', targetFileId);

    const response = await apis({
      method: 'POST',
      url: '/files/upload_and_process_file',
      data: formData,
    });

    if (response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'File processed successfully',
        modalType: 'success',
        duration: 3000,
      }));
    } else {
      throw new Error('Failed to process file');
    }
  } catch (error: any) {
    console.error('Error in uploadAndProcessFile:', error);
    dispatch(showFeedbackModal({
      showModal: true,
      message: error.message || 'An unknown error occurred',
      modalType: 'error',
      duration: 3000,
    }));
    throw error;
  }
};

export const fetchFileDetails = ( fileId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FILE_DETAILS_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/get-file',
        data: { FileID: fileId },
      });

      if (resp && resp.status === 200) {
        const fileData = resp.data; 
        dispatch({ type: FETCH_FILE_DETAILS_SUCCESS, payload: fileData });
        return fileData; 
      } else {
        dispatch({ type: FETCH_FILE_DETAILS_FAILURE, payload: 'No file details found.' });
        handleError(dispatch, resp);
        return null;
      }
    } catch (error: any) {
      dispatch({ type: FETCH_FILE_DETAILS_FAILURE, payload: error.message });
      handleError(dispatch, error);
    }
  };
};

export const signFile = (fileId: string, assignees: string | string[]) => {
  return async (dispatch: any) => {
    dispatch({ type: SIGN_FILE_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: 'files/process_signature',
        data: { FileID: fileId },
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      if (resp && resp.status === 200) {
        const response = resp.data;
        dispatch({ type: SIGN_FILE_SUCCESS, payload: response });

        // Show success feedback
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: response.message || 'Document signed successfully.',
            modalType: 'success',
            duration: 3000,
          })
        );
      } else {
        dispatch({ type: SIGN_FILE_FAILURE, payload: 'Not able to sign file.' });
        handleError(dispatch, resp);
      }
    } catch (error: any) {
      dispatch({ type: SIGN_FILE_FAILURE, payload: error.message });
      handleError(dispatch, error);
    }
  };
};

export const downloadFile = (fileId: string): AppThunk => async (dispatch) => {
  try {
    // API endpoint to generate the SAS token
    const response = await apis({
      method: 'POST',
      url: '/files/download_file',
      data: { FileID: fileId }
    });

    if (response && response.status === 200) {
      const downloadUrl = response.data.SAS_URL[1] || response.data.SAS_URL[1]; // Adjust according to API response
      const fileName = response.data.SAS_URL[0] || 'document'; // Optionally get the filename from the response

      // Create a temporary anchor element to initiate the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName; // Set the default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Optionally, dispatch a success action
      dispatch({ type: 'DOWNLOAD_DOCUMENT_SUCCESS' });
    } else {
      // Handle error response
      console.error('Failed to download document:', response);
      // Optionally, dispatch a failure action
      dispatch({ type: 'DOWNLOAD_DOCUMENT_FAILURE', payload: 'Failed to download document' });
    }
  } catch (error: any) {
    console.error('Error downloading document:', error);
    // Optionally, dispatch a failure action
    dispatch({ type: 'DOWNLOAD_DOCUMENT_FAILURE', payload: error.message });
  }
};