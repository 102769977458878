import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectView.css';
import { IEditProjectPayload, IProject } from './ProjectsInterfaces';
import { useAppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  fetchProjectCategory,
  fetchPriority,
  fetchProjectStatus,
} from '../../store/actions/DropdownActions';
import {
  fetchAllBusinessEmployees,
  IEmployee,
} from '../../store/actions/organization/BusinessActions';
import GlobalDropdown, { DropdownOption } from '../shared/GlobalDropdown';
import { editProject, deleteProject, approveProject, fetchProjectDetails } from '../../store/actions/ProjectActions';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Avatar, Tooltip } from '@mui/material';
import { getAiModels, getUserID, getUserType } from '../../store/actions/ChatAction';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';

interface ProjectViewProps {
  project: IProject;
  onClose: () => void;
}

const ProjectView: React.FC<ProjectViewProps> = ({ project, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isEditing, setIsEditing] = useState<boolean>(false);
  
    const userType = getUserType();
    const userId = getUserID();
  
    // Fetch project details from Redux store
    const projectDetails = useSelector((state: RootState) => state.projects.projectDetails)!;
    const [localProjectDetails, setLocalProjectDetails] = useState<IProject | null>(projectDetails);
  
    // Fetch necessary data from Redux store
    const categoriesData = useSelector((state: RootState) => state.dropdown.projectCategory) || [];
    const prioritiesData = useSelector((state: RootState) => state.dropdown.priority) || [];
    const projectStatus = useSelector((state: RootState) => state.dropdown.projectStatus) || [];
    const employees = useSelector((state: RootState) => Object.values(state.business.employees)) || [];
    const servicesData = useSelector((state: RootState) => state.model.services) || [];
  
    // Determine if the user is the project lead or an admin
    const isProjectLead = localProjectDetails?.ProjectLead?.LeadID === userId;
    const isAdmin = userType === 'BusinessAdmin';
  
    // Determine if the user can approve the project
    const canApprove = isAdmin || isProjectLead;
  
    // Determine if the user can edit the project
    const canEdit = isAdmin || isProjectLead;
  
    // Determine if the user can delete the project
    const canDelete = isAdmin;
  
    // Fetch project details on component mount
    useEffect(() => {
      dispatch(fetchProjectDetails({ ProjectID: project.ProjectID }));
    }, [dispatch, project.ProjectID]);
  
    // Update local state when project details are fetched
    useEffect(() => {
      if (projectDetails) {
        setLocalProjectDetails(projectDetails);
      }
    }, [projectDetails]);
  
    // Fetch dropdown options and employees if not already available
    useEffect(() => {
      if (categoriesData.length === 0) {
        dispatch(fetchProjectCategory());
      }
      if (prioritiesData.length === 0) {
        dispatch(fetchPriority());
      }
      if (projectStatus.length === 0) {
        dispatch(fetchProjectStatus());
      }
      if (employees.length === 0) {
        dispatch(fetchAllBusinessEmployees());
      }
      if (servicesData.length === 0) {
        dispatch(getAiModels());
      }
    }, [dispatch, categoriesData.length, prioritiesData.length, projectStatus.length, employees.length, servicesData.length]);
  
    // Function to save changes (used when editing multiple fields)
    const handleSaveChanges = async () => {
      if (!canEdit) return;
      if (!localProjectDetails) return;
  
      const data: IEditProjectPayload = {
        ProjectID: localProjectDetails.ProjectID,
        UserType: userType,
        ServiceAllocation: localProjectDetails.AllocatedServices.map((service) => ({
          ServiceAmount: service.ServiceAmount,
          ServiceType: service.ServiceType,
        })),
        ProjectDescription: localProjectDetails.ProjectDescription,
        CategoryType: localProjectDetails.CategoryType,
        EndDate: dayjs(localProjectDetails.EndDate).format('DD/MM/YYYY HH:mm:ss'),
        PriorityLevel: localProjectDetails.PriorityLevel,
        StatusID: localProjectDetails.StatusID,
        ProjectApprover: localProjectDetails.ProjectApprover?.ApproverID || '',
        ProjectLead: localProjectDetails.ProjectLead?.LeadID || '',
        ProjectMembers: localProjectDetails.ProjectMembers.map((member) => member.MemberID),
        ProjectName: localProjectDetails.ProjectName,
        StartDate: dayjs(localProjectDetails.StartDate).format('DD/MM/YYYY HH:mm:ss'),
      };
      try {
        const result = await dispatch(editProject(data));
        if (result) {
          setIsEditing(false);
          dispatch(fetchProjectDetails({ ProjectID: localProjectDetails.ProjectID }));
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: t('Project updated successfully!'),
              modalType: 'success',
              duration: 3000,
            })
          );
        }
      } catch (error) {
        console.error('Error updating project:', error);
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to update project.'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    };
  
    // Function to handle field changes
    const handleFieldChange = (field: string, value: any) => {
      if (!canEdit) return;
      if (localProjectDetails) {
        setLocalProjectDetails({
          ...localProjectDetails,
          [field]: value,
        });
      }
    };
  
    // Function to update a single field immediately
    const handleFieldUpdate = async (field: string, value: any) => {
      if (!canEdit) return;
      if (!localProjectDetails) return;
  
      const updatedProject = {
        ...localProjectDetails,
        [field]: value,
      };
  
      const data: IEditProjectPayload = {
        ProjectID: updatedProject.ProjectID,
        UserType: userType,
        ServiceAllocation: updatedProject.AllocatedServices.map((service) => ({
          ServiceAmount: service.ServiceAmount,
          ServiceType: service.ServiceType,
        })),
        ProjectDescription: updatedProject.ProjectDescription,
        CategoryType: updatedProject.CategoryType,
        EndDate: dayjs(updatedProject.EndDate).format('DD/MM/YYYY HH:mm:ss'),
        PriorityLevel: updatedProject.PriorityLevel,
        StatusID: updatedProject.StatusID,
        ProjectApprover: updatedProject.ProjectApprover?.ApproverID || '',
        ProjectLead: updatedProject.ProjectLead?.LeadID || '',
        ProjectMembers: updatedProject.ProjectMembers.map((member) => member.MemberID),
        ProjectName: updatedProject.ProjectName,
        StartDate: dayjs(updatedProject.StartDate).format('DD/MM/YYYY HH:mm:ss'),
      };
  
      try {
        const result = await dispatch(editProject(data));
        if (result) {
          dispatch(fetchProjectDetails({ ProjectID: updatedProject.ProjectID }));
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: t('Project updated successfully!'),
              modalType: 'success',
              duration: 3000,
            })
          );
        }
      } catch (error) {
        console.error('Error updating project:', error);
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to update project.'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    };
  
    // Function to handle deleting the project
    const handleDeleteProject = async () => {
      if (!canDelete) return;
      const confirmDelete = window.confirm(t('Are you sure you want to delete this project?'));
      if (confirmDelete) {
        const success = await dispatch(deleteProject({ ProjectID: projectDetails.ProjectID }));
        if (success) {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: t('Project deleted successfully!'),
              modalType: 'success',
              duration: 3000,
            })
          );
          onClose();
        }
      }
    };
  
    // Function to handle approving the project
    const handleApproveProject = async () => {
      if (!canApprove) return;
      const success = await dispatch(
        approveProject({ ProjectID: projectDetails.ProjectID })
      );
      if (success) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Project approved successfully!'),
            modalType: 'success',
            duration: 3000,
          })
        );
      }
    };
  
    // Functions to handle adding/removing members
    const handleRemoveMember = (memberID: string) => {
      if (!canEdit) return;
      setLocalProjectDetails((prevDetails) => {
        if (prevDetails) {
          return {
            ...prevDetails,
            ProjectMembers: prevDetails.ProjectMembers.filter(
              (member) => member.MemberID !== memberID
            ),
          };
        }
        return prevDetails;
      });
    };
  
    const handleAddMember = (employeeID: string) => {
      if (!canEdit) return;
      const employee = employees.find((emp: any) => emp.EmployeeID === employeeID);
      if (employee) {
        setLocalProjectDetails((prevDetails) => {
          if (prevDetails) {
            return {
              ...prevDetails,
              ProjectMembers: [
                ...prevDetails.ProjectMembers,
                {
                  MemberID: employee.EmployeeID,
                  MemberName: `${employee.FirstName} ${employee.LastName}`,
                },
              ],
            };
          }
          return prevDetails;
        });
      }
    };
  
    // Function to handle service amount change
    const handleServiceAmountChange = (index: number, amount: number) => {
      if (!canEdit) return;
      setLocalProjectDetails((prevDetails) => {
        if (prevDetails) {
          const updatedServices = [...prevDetails.AllocatedServices];
          updatedServices[index].ServiceAmount = amount;
          return {
            ...prevDetails,
            AllocatedServices: updatedServices,
          };
        }
        return prevDetails;
      });
    };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  // Prepare options for dropdowns
  const projectMemberOptions: DropdownOption[] = projectDetails.ProjectMembers.map((member) => ({
    id: member.MemberID,
    label: member.MemberName,
  }));

  return (
    <div className="project-view">
        <div>
      <div className="project-view-header">
      {isEditing ? (
            <input
              type="text"
              value={localProjectDetails?.ProjectName || ''}
              onChange={(e) => handleFieldChange('ProjectName', e.target.value)}
              className="project-name-input"
            />
          ) : (
            <h2>{localProjectDetails?.ProjectName}</h2>
          )}
          <div className="project-view-header-actions">
            {canEdit && !isEditing && (
              <button onClick={() => setIsEditing(true)} className="primary-button">
                {t('Edit')}
              </button>
            )}
            {isEditing && (
              <>
                <button onClick={handleSaveChanges} className="primary-button">
                  {t('Save Changes')}
                </button>
                <button onClick={() => setIsEditing(false)} className="destructive-button">
                  {t('Cancel')}
                </button>
              </>
            )}
          </div>
        </div>

      <div className="project-view-content">
        {/* Status, Priority, Category */}
        <div className="project-view-row">
        <div className="project-view-field">
                    <label>{t('Status')}</label>
                    {/* <GlobalDropdown
                        options={projectStatus}
                        onSelect={(id) => {
                        handleFieldChange('StatusID', id);
                        handleFieldUpdate('StatusID', id);
                        }}
                        selectedLabel={
                        projectStatus.find((option) => option.id === localProjectDetails?.StatusID)?.label ||
                        t('Select Status')
                        }
                        placeholder={t('Select Status')}
                    />
                    </div>

                    {/* Priority 
                    <div className="project-view-field">
                    <label>{t('Priority')}</label>
                    <GlobalDropdown
                        options={prioritiesData}
                        onSelect={(id) => {
                        handleFieldChange('PriorityLevel', id);
                        handleFieldUpdate('PriorityLevel', id);
                        }}
                        selectedLabel={
                        prioritiesData.find((option) => option.id === localProjectDetails?.PriorityLevel)?.label ||
                        t('Select Priority')
                        }
                        placeholder={t('Select Priority')}
                    />
                    </div>

                    {/* Category 
                    <div className="project-view-field">
                    <label>{t('Category')}</label>
                    <GlobalDropdown
                            options={categoriesData}
                            onSelect={(id) => {
                            handleFieldChange('CategoryType', id);
                            handleFieldUpdate('CategoryType', id);
                            }}
                            selectedLabel={
                            categoriesData.find((option) => option.id === localProjectDetails?.CategoryType)?.label ||
                            t('Select Category')
                            }
                            placeholder={t('Select Category')}
                        /> */}

                    </div>
        </div>

        {/* Description */}
        <div className="project-view-field">
          <label>{t('Description')}</label>
          {isEditing ? (
                <textarea
                    value={localProjectDetails?.ProjectDescription || ''}
                    onChange={(e) => handleFieldChange('ProjectDescription', e.target.value)}
                />
                ) : (
                <p className='project-detail--desc'>{localProjectDetails?.ProjectDescription}</p>
                )}
        </div>

        {/* Start Date and End Date */}
        <div className="project-view-row">
          {/* Start Date */}
          <div className="project-view-field">
                    <label>{t('Start Date')}</label>
                    <ThemeProvider theme={darkTheme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDateTimePicker
                            value={dayjs(localProjectDetails?.StartDate)}
                            onChange={(date: Dayjs | null) => {
                            const newDate = date ? date.toISOString() : '';
                            handleFieldChange('StartDate', newDate);
                            handleFieldUpdate('StartDate', newDate);
                            }}
                        />
                        </LocalizationProvider>
                    </ThemeProvider>
                    </div>

                    {/* End Date */}
                    <div className="project-view-field">
                    <label>{t('End Date')}</label>
                    <ThemeProvider theme={darkTheme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDateTimePicker
                            value={dayjs(localProjectDetails?.EndDate)}
                            onChange={(date: Dayjs | null) => {
                            const newDate = date ? date.toISOString() : '';
                            handleFieldChange('EndDate', newDate);
                            handleFieldUpdate('EndDate', newDate);
                            }}
                        />
                        </LocalizationProvider>
                    </ThemeProvider>
                    </div>
        </div>
        {/* Project Members */}
        <div className="project-view-field">
          <label>{t('Project Members')}</label>
          <div className="project-members">
            {projectDetails.ProjectMembers.map((member) => {
              const initials = member.MemberName
                .split(' ')
                .map((name) => name[0])
                .join('');
              return (
                <div key={member.MemberID} className="project-member">
                  <Tooltip title={member.MemberName}>
                    <Avatar
                     sx={{
                         width: 40,
                         height: 40,
                         fontSize: '1rem',
                         borderRadius: 2,
                         background: '#111',
                         color: '#64d2ff',
                         border: '1px solid #444',
                       }}
                       variant='square'
                    >{initials}</Avatar>
                  </Tooltip>
                  {isEditing && (
                    <button
                      type="button"
                      onClick={() => handleRemoveMember(member.MemberID)}
                      className="destructive-button small"
                    >
                      {t('Remove')}
                    </button>
                  )}
                </div>
              );
            })}
            {isEditing && (
              <GlobalDropdown
                options={employees
                  .filter(
                    (emp) =>
                      !projectDetails.ProjectMembers.some(
                        (member) => member.MemberID === emp.EmployeeID
                      )
                  )
                  .map((emp) => ({
                    id: emp.EmployeeID,
                    label: `${emp.FirstName} ${emp.LastName}`,
                  }))}
                onSelect={(id) => handleAddMember(id)}
                selectedLabel={t('Add Member')}
                placeholder={t('Add Member')}
              />
            )}
          </div>
        </div>
        {/* Project Lead and Approver */}
        <div className="project-view-row">
        <div className="project-view-field">
                <label>{t('Project Lead')}</label>
                <div className="project-member">
                    {localProjectDetails?.ProjectLead ? (
                    <Tooltip title={localProjectDetails.ProjectLead.LeadName}>
                        <Avatar
                        sx={{
                            width: 40,
                            height: 40,
                            fontSize: '1rem',
                            borderRadius: 2,
                            background: '#111',
                            color: '#64d2ff',
                            border: '1px solid #444',
                        }}
                        variant="square"
                        >
                        {localProjectDetails.ProjectLead.LeadName.split(' ').map((n) => n[0]).join('')}
                        </Avatar>
                    </Tooltip>
                    ) : (
                    <p>N/A</p>
                    )}
                </div>
                </div>

                {/* Project Approver */}
                <div className="project-view-field">
                <label>{t('Project Approver')}</label>
                <div className="project-member">
                    {localProjectDetails?.ProjectApprover ? (
                    <Tooltip title={localProjectDetails.ProjectApprover.ApproverName}>
                        <Avatar
                        sx={{
                            width: 40,
                            height: 40,
                            fontSize: '1rem',
                            borderRadius: 2,
                            background: '#111',
                            color: '#64d2ff',
                            border: '1px solid #444',
                        }}
                        variant="square"
                        >
                        {localProjectDetails.ProjectApprover.ApproverName.split(' ').map((n) => n[0]).join('')}
                        </Avatar>
                    </Tooltip>
                    ) : (
                    <p>N/A</p>
                    )}
                </div>
                </div>
        </div>

        {/* Services */}
        <div className="project-view-field">
          <label>{t('Services')}</label>
          <div className="services-list">
                    {localProjectDetails?.AllocatedServices.map((service, index) => {
                        const serviceName = servicesData.find((s: any) => s.id === service.ServiceName) || service.ServiceName;
                        return (
                        <div key={service.AllocatedServiceID} className="service-item">
                            <span>{serviceName}</span>
                            {isEditing ? (
                            <input
                                type="number"
                                min="1"
                                value={service.ServiceAmount}
                                onChange={(e) =>
                                handleServiceAmountChange(index, parseInt(e.target.value, 10))
                                }
                                className="service-quantity-input"
                            />
                            ) : (
                            <span>{service.ServiceAmount}</span>
                            )}
                        </div>
                        );
                    })}
                </div>
        </div>
        </div>
        {/* Action Buttons */}
      </div>
      {!isEditing && (
          <div className="project-view-actions">
            {canApprove && (
              <button onClick={handleApproveProject} className="secondary-button">
                {t('Approve')}
              </button>
            )}
            {canDelete && (
              <button onClick={handleDeleteProject} className="destructive-button">
                {t('Delete')}
              </button>
            )}
          </div>
        )}
    </div>
  );
};

export default ProjectView;
