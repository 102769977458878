import {
    FETCH_TEAMS_REQUEST,
    FETCH_TEAMS_SUCCESS,
    FETCH_TEAMS_FAILURE,
    ADD_TEAM_REQUEST,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_FAILURE,
  } from '../../types';
  
  interface TeamState {
    teams: any[];
    loading: boolean;
    error: string | null;
  }
  
  const initialState: TeamState = {
    teams: [],
    loading: false,
    error: null,
  };
  
  export const teamReducer = (
    state = initialState,
    action: any
  ): TeamState => {
    switch (action.type) {
      case FETCH_TEAMS_REQUEST:
      case ADD_TEAM_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TEAMS_SUCCESS:
        return {
          ...state,
          loading: false,
          teams: action.payload,
        };
      case ADD_TEAM_SUCCESS:
        return {
          ...state,
          loading: false,
          teams: [...state.teams, action.payload],
        };
      case FETCH_TEAMS_FAILURE:
      case ADD_TEAM_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  