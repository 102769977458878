import React, { useEffect, useState } from 'react';
import { fetchFeedbackTypes, sendFeedback } from '../../store/actions/FeedbackAction';
import { getUserType } from '../../store/actions/ChatAction';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { FeedbackType } from '../../store/reducer/feedbackReducer';
import { useTranslation } from 'react-i18next';
import ApiLoader from '../ApiLoader';
import { showSupportTicketSidePanel } from '../../store/actions/sidePanelActions';
import { fetchCategories } from '../../store/actions/HelpAction';
import Button from '../shared/TailwindComponents/Button';
import Textarea from '../shared/TailwindComponents/Textarea';

const Feedback: React.FC = () => {
  const [feedback, setFeedback] = useState('');
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackType | null>(null);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const userType = getUserType();
  const { t } = useTranslation();

  const feedbackTypes = useSelector((state: RootState) => state.feedback.feedbackTypes || []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(fetchFeedbackTypes());
      await dispatch(fetchCategories());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (feedbackTypes && feedbackTypes.length > 0 && !selectedFeedback) {
      setSelectedFeedback(feedbackTypes[0]);
    }
  }, [feedbackTypes, selectedFeedback]);

  const handleSubmitFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedFeedback && feedback.trim()) {
      try {
        await dispatch(
          sendFeedback({
            FeedbackContent: feedback,
            UserType: userType,
            FeedbackTypeID: selectedFeedback.FeedbackTypeID,
          })
        );
        setFeedback('');
      } catch (error) {
        console.error('Failed to send feedback:', error);
      }
    }
  };

  const handleFeedbackTypeClick = (type: FeedbackType) => {
    setSelectedFeedback(type);
  };

  const handleOpenSupport = () => {
    dispatch(showSupportTicketSidePanel());
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-3xl mx-auto mt-8 px-4">
      {isLoading ? (
        <ApiLoader />
      ) : (
        <>
          <div className="flex flex-col items-center mb-8 w-full">
            <i className="fa-kit fa-logo text-8xl text-primary-500 mb-4"></i>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 w-full">
              {feedbackTypes.map((type) => (
                <Button
                  key={type.FeedbackTypeID}
                  variant={
                    selectedFeedback && selectedFeedback.FeedbackTypeID === type.FeedbackTypeID
                      ? 'primary'
                      : 'secondary'
                  }
                  aria-label={t(type.Name)}
                  onClick={() => handleFeedbackTypeClick(type)}
                  className="w-full truncate"
                  >
                  {t(type.Name)}
                </Button>
              ))}
            </div>
          </div>
          <div className="w-full">
            <p className="text-left text-gray-600 dark:text-gray-300 mb-4">
              {selectedFeedback ? t(selectedFeedback.Description) : ''}
            </p>
            <form className="w-full" onSubmit={handleSubmitFeedback}>
              <Textarea
                id="feedback-input"
                label=""
                placeholder={t('Enter your feedback here...')}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                rows={6}
              />
              <div className="flex justify-between items-center mt-2">
                <p className="text-sm text-gray-500">
                  {feedback.split(/\s+/).filter(Boolean).length} / 500
                </p>
                {isOverLimit && (
                  <p className="text-sm text-danger-500">
                    {t('Your feedback is over the 500 words limit.')}
                  </p>
                )}
                {!isOverLimit && (
                  <Button type="submit" variant="primary">
                    {t('Send')}
                  </Button>
                )}
              </div>
            </form>
            {/* <div className="flex justify-end w-full mt-4">
              <Button variant="secondary" onClick={handleOpenSupport}>
                {t('Need support?')}
              </Button>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Feedback;
