// src/store/reducers/dropdownReducer.ts

import { AnyAction } from 'redux';
import {
  FETCH_AI_VOICES_REQUEST,
  FETCH_AI_VOICES_SUCCESS,
  FETCH_AI_VOICES_FAILURE,
  FETCH_AI_FONTSIZES_REQUEST,
  FETCH_AI_FONTSIZES_SUCCESS,
  FETCH_AI_FONTSIZES_FAILURE,
  FETCH_AI_JURISDICTIONS_REQUEST,
  FETCH_AI_JURISDICTIONS_SUCCESS,
  FETCH_AI_JURISDICTIONS_FAILURE,
  FETCH_AI_LANGUAGES_REQUEST,
  FETCH_AI_LANGUAGES_SUCCESS,
  FETCH_AI_LANGUAGES_FAILURE,
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_BUSINESSES_FAILURE,
  FETCH_CASE_STATUSES_REQUEST,
  FETCH_CASE_STATUSES_SUCCESS,
  FETCH_CASE_STATUSES_FAILURE,
  FETCH_CASE_TYPES_REQUEST,
  FETCH_CASE_TYPES_SUCCESS,
  FETCH_CASE_TYPES_FAILURE,
  FETCH_DESIRED_OUTCOMES_REQUEST,
  FETCH_DESIRED_OUTCOMES_SUCCESS,
  FETCH_DESIRED_OUTCOMES_FAILURE,
  FETCH_EXPECTED_OUTCOMES_REQUEST,
  FETCH_EXPECTED_OUTCOMES_SUCCESS,
  FETCH_EXPECTED_OUTCOMES_FAILURE,
  FETCH_LAWFIRM_ROLES_REQUEST,
  FETCH_LAWFIRM_ROLES_SUCCESS,
  FETCH_LAWFIRM_ROLES_FAILURE,
  FETCH_LEGAL_SUBJECTS_REQUEST,
  FETCH_LEGAL_SUBJECTS_SUCCESS,
  FETCH_LEGAL_SUBJECTS_FAILURE,
  FETCH_PLATFORM_CURRENCIES_REQUEST,
  FETCH_PLATFORM_CURRENCIES_SUCCESS,
  FETCH_PLATFORM_CURRENCIES_FAILURE,
  FETCH_PLATFORM_LANGUAGES_REQUEST,
  FETCH_PLATFORM_LANGUAGES_SUCCESS,
  FETCH_PLATFORM_LANGUAGES_FAILURE,
  FETCH_PRACTICE_AREAS_REQUEST,
  FETCH_PRACTICE_AREAS_SUCCESS,
  FETCH_PRACTICE_AREAS_FAILURE,
  FETCH_PREFERRED_LANGUAGES_REQUEST,
  FETCH_PREFERRED_LANGUAGES_SUCCESS,
  FETCH_PREFERRED_LANGUAGES_FAILURE,
  FETCH_URGENCY_STATUS_REQUEST,
  FETCH_URGENCY_STATUS_SUCCESS,
  FETCH_URGENCY_STATUS_FAILURE,
  FETCH_PROJECT_STATUS_REQUEST,
  FETCH_PROJECT_STATUS_SUCCESS,
  FETCH_PROJECT_STATUS_FAILURE,
  FETCH_PRIORITY_REQUEST,
  FETCH_PRIORITY_SUCCESS,
  FETCH_PRIORITY_FAILURE,
  FETCH_PROJECT_CATEGORY_FAILURE,
  FETCH_PROJECT_CATEGORY_REQUEST,
  FETCH_PROJECT_CATEGORY_SUCCESS,
} from '../types';
import { DropdownOption } from '../../components/shared/TailwindComponents/Dropdown';

interface DropdownState {
  aiVoices: DropdownOption[];
  aiVoicesLoading: boolean;
  aiVoicesError: string | null;

  aiFontSizes: DropdownOption[];
  aiFontSizesLoading: boolean;
  aiFontSizesError: string | null;

  aiJurisdictions: DropdownOption[];
  aiJurisdictionsLoading: boolean;
  aiJurisdictionsError: string | null;

  aiLanguages: DropdownOption[];
  aiLanguagesLoading: boolean;
  aiLanguagesError: string | null;

  businesses: DropdownOption[];
  businessesLoading: boolean;
  businessesError: string | null;

  caseStatuses: DropdownOption[];
  caseStatusesLoading: boolean;
  caseStatusesError: string | null;

  caseTypes: DropdownOption[];
  caseTypesLoading: boolean;
  caseTypesError: string | null;

  desiredOutcomes: DropdownOption[];
  desiredOutcomesLoading: boolean;
  desiredOutcomesError: string | null;

  expectedOutcomes: DropdownOption[];
  expectedOutcomesLoading: boolean;
  expectedOutcomesError: string | null;

  lawfirmRoles: DropdownOption[];
  lawfirmRolesLoading: boolean;
  lawfirmRolesError: string | null;

  legalSubjects: DropdownOption[];
  legalSubjectsLoading: boolean;
  legalSubjectsError: string | null;

  platformCurrencies: DropdownOption[];
  platformCurrenciesLoading: boolean;
  platformCurrenciesError: string | null;

  platformLanguages: DropdownOption[];
  platformLanguagesLoading: boolean;
  platformLanguagesError: string | null;

  practiceAreas: DropdownOption[];
  practiceAreasLoading: boolean;
  practiceAreasError: string | null;

  preferredLanguages: DropdownOption[];
  preferredLanguagesLoading: boolean;
  preferredLanguagesError: string | null;

  urgencyStatus: DropdownOption[];
  urgencyStatusLoading: boolean;
  urgencyStatusError: string | null;

  projectStatus: DropdownOption[];
  projectStatusLoading: boolean;
  projectStatusError: string | null;

  priority: DropdownOption[];
  priorityLoading: boolean;
  priorityError: string | null;

  projectCategory: DropdownOption[];
  projectCategoryLoading: boolean;
  projectCategoryError: string | null;
}

const initialState: DropdownState = {
  aiVoices: [],
  aiVoicesLoading: false,
  aiVoicesError: null,

  aiFontSizes: [],
  aiFontSizesLoading: false,
  aiFontSizesError: null,

  aiJurisdictions: [],
  aiJurisdictionsLoading: false,
  aiJurisdictionsError: null,

  aiLanguages: [],
  aiLanguagesLoading: false,
  aiLanguagesError: null,

  businesses: [],
  businessesLoading: false,
  businessesError: null,

  caseStatuses: [],
  caseStatusesLoading: false,
  caseStatusesError: null,

  caseTypes: [],
  caseTypesLoading: false,
  caseTypesError: null,

  desiredOutcomes: [],
  desiredOutcomesLoading: false,
  desiredOutcomesError: null,

  expectedOutcomes: [],
  expectedOutcomesLoading: false,
  expectedOutcomesError: null,

  lawfirmRoles: [],
  lawfirmRolesLoading: false,
  lawfirmRolesError: null,

  legalSubjects: [],
  legalSubjectsLoading: false,
  legalSubjectsError: null,

  platformCurrencies: [],
  platformCurrenciesLoading: false,
  platformCurrenciesError: null,

  platformLanguages: [],
  platformLanguagesLoading: false,
  platformLanguagesError: null,

  practiceAreas: [],
  practiceAreasLoading: false,
  practiceAreasError: null,

  preferredLanguages: [],
  preferredLanguagesLoading: false,
  preferredLanguagesError: null,

  urgencyStatus: [],
  urgencyStatusLoading: false,
  urgencyStatusError: null,

  projectStatus: [],
  projectStatusLoading: false,
  projectStatusError: null,

  priority: [],
  priorityLoading: false,
  priorityError: null,

  projectCategory: [],
  projectCategoryLoading: false,
  projectCategoryError: null,
};

// Define DropdownOption interface

const dropdownReducer = (state = initialState, action: AnyAction): DropdownState => {
  switch (action.type) {
    // AI Voices
    case FETCH_AI_VOICES_REQUEST:
      return { ...state, aiVoicesLoading: true, aiVoicesError: null };
    case FETCH_AI_VOICES_SUCCESS:
      return { ...state, aiVoicesLoading: false, aiVoices: action.payload };
    case FETCH_AI_VOICES_FAILURE:
      return { ...state, aiVoicesLoading: false, aiVoicesError: action.payload };

    // AI Font Sizes
    case FETCH_AI_FONTSIZES_REQUEST:
      return { ...state, aiFontSizesLoading: true, aiFontSizesError: null };
    case FETCH_AI_FONTSIZES_SUCCESS:
      return { ...state, aiFontSizesLoading: false, aiFontSizes: action.payload };
    case FETCH_AI_FONTSIZES_FAILURE:
      return { ...state, aiFontSizesLoading: false, aiFontSizesError: action.payload };

    // AI Jurisdictions
    case FETCH_AI_JURISDICTIONS_REQUEST:
      return { ...state, aiJurisdictionsLoading: true, aiJurisdictionsError: null };
    case FETCH_AI_JURISDICTIONS_SUCCESS:
      return { ...state, aiJurisdictionsLoading: false, aiJurisdictions: action.payload };
    case FETCH_AI_JURISDICTIONS_FAILURE:
      return { ...state, aiJurisdictionsLoading: false, aiJurisdictionsError: action.payload };

    // AI Languages
    case FETCH_AI_LANGUAGES_REQUEST:
      return { ...state, aiLanguagesLoading: true, aiLanguagesError: null };
    case FETCH_AI_LANGUAGES_SUCCESS:
      return { ...state, aiLanguagesLoading: false, aiLanguages: action.payload };
    case FETCH_AI_LANGUAGES_FAILURE:
      return { ...state, aiLanguagesLoading: false, aiLanguagesError: action.payload };

    // Businesses
    case FETCH_BUSINESSES_REQUEST:
      return { ...state, businessesLoading: true, businessesError: null };
    case FETCH_BUSINESSES_SUCCESS:
      return { ...state, businessesLoading: false, businesses: action.payload };
    case FETCH_BUSINESSES_FAILURE:
      return { ...state, businessesLoading: false, businessesError: action.payload };

    // Case Statuses
    case FETCH_CASE_STATUSES_REQUEST:
      return { ...state, caseStatusesLoading: true, caseStatusesError: null };
    case FETCH_CASE_STATUSES_SUCCESS:
      return { ...state, caseStatusesLoading: false, caseStatuses: action.payload };
    case FETCH_CASE_STATUSES_FAILURE:
      return { ...state, caseStatusesLoading: false, caseStatusesError: action.payload };

    // Case Types
    case FETCH_CASE_TYPES_REQUEST:
      return { ...state, caseTypesLoading: true, caseTypesError: null };
    case FETCH_CASE_TYPES_SUCCESS:
      return { ...state, caseTypesLoading: false, caseTypes: action.payload };
    case FETCH_CASE_TYPES_FAILURE:
      return { ...state, caseTypesLoading: false, caseTypesError: action.payload };

    // Desired Outcomes
    case FETCH_DESIRED_OUTCOMES_REQUEST:
      return { ...state, desiredOutcomesLoading: true, desiredOutcomesError: null };
    case FETCH_DESIRED_OUTCOMES_SUCCESS:
      return { ...state, desiredOutcomesLoading: false, desiredOutcomes: action.payload };
    case FETCH_DESIRED_OUTCOMES_FAILURE:
      return { ...state, desiredOutcomesLoading: false, desiredOutcomesError: action.payload };

    // Expected Outcomes
    case FETCH_EXPECTED_OUTCOMES_REQUEST:
      return { ...state, expectedOutcomesLoading: true, expectedOutcomesError: null };
    case FETCH_EXPECTED_OUTCOMES_SUCCESS:
      return { ...state, expectedOutcomesLoading: false, expectedOutcomes: action.payload };
    case FETCH_EXPECTED_OUTCOMES_FAILURE:
      return { ...state, expectedOutcomesLoading: false, expectedOutcomesError: action.payload };

    // Lawfirm Roles
    case FETCH_LAWFIRM_ROLES_REQUEST:
      return { ...state, lawfirmRolesLoading: true, lawfirmRolesError: null };
    case FETCH_LAWFIRM_ROLES_SUCCESS:
      return { ...state, lawfirmRolesLoading: false, lawfirmRoles: action.payload };
    case FETCH_LAWFIRM_ROLES_FAILURE:
      return { ...state, lawfirmRolesLoading: false, lawfirmRolesError: action.payload };

    // Legal Subjects
    case FETCH_LEGAL_SUBJECTS_REQUEST:
      return { ...state, legalSubjectsLoading: true, legalSubjectsError: null };
    case FETCH_LEGAL_SUBJECTS_SUCCESS:
      return { ...state, legalSubjectsLoading: false, legalSubjects: action.payload };
    case FETCH_LEGAL_SUBJECTS_FAILURE:
      return { ...state, legalSubjectsLoading: false, legalSubjectsError: action.payload };

    // Platform Currencies
    case FETCH_PLATFORM_CURRENCIES_REQUEST:
      return { ...state, platformCurrenciesLoading: true, platformCurrenciesError: null };
    case FETCH_PLATFORM_CURRENCIES_SUCCESS:
      return { ...state, platformCurrenciesLoading: false, platformCurrencies: action.payload };
    case FETCH_PLATFORM_CURRENCIES_FAILURE:
      return { ...state, platformCurrenciesLoading: false, platformCurrenciesError: action.payload };

    // Platform Languages
    case FETCH_PLATFORM_LANGUAGES_REQUEST:
      return { ...state, platformLanguagesLoading: true, platformLanguagesError: null };
    case FETCH_PLATFORM_LANGUAGES_SUCCESS:
      return { ...state, platformLanguagesLoading: false, platformLanguages: action.payload };
    case FETCH_PLATFORM_LANGUAGES_FAILURE:
      return { ...state, platformLanguagesLoading: false, platformLanguagesError: action.payload };

    // Practice Areas
    case FETCH_PRACTICE_AREAS_REQUEST:
      return { ...state, practiceAreasLoading: true, practiceAreasError: null };
    case FETCH_PRACTICE_AREAS_SUCCESS:
      return { ...state, practiceAreasLoading: false, practiceAreas: action.payload };
    case FETCH_PRACTICE_AREAS_FAILURE:
      return { ...state, practiceAreasLoading: false, practiceAreasError: action.payload };

    // Preferred Languages
    case FETCH_PREFERRED_LANGUAGES_REQUEST:
      return { ...state, preferredLanguagesLoading: true, preferredLanguagesError: null };
    case FETCH_PREFERRED_LANGUAGES_SUCCESS:
      return { ...state, preferredLanguagesLoading: false, preferredLanguages: action.payload };
    case FETCH_PREFERRED_LANGUAGES_FAILURE:
      return { ...state, preferredLanguagesLoading: false, preferredLanguagesError: action.payload };

    // Urgency Status
    case FETCH_URGENCY_STATUS_REQUEST:
      return { ...state, urgencyStatusLoading: true, urgencyStatusError: null };
    case FETCH_URGENCY_STATUS_SUCCESS:
      return { ...state, urgencyStatusLoading: false, urgencyStatus: action.payload };
    case FETCH_URGENCY_STATUS_FAILURE:
      return { ...state, urgencyStatusLoading: false, urgencyStatusError: action.payload };

    // Project status
    case FETCH_PROJECT_STATUS_REQUEST:
      return { ...state, projectStatusLoading: true, projectStatusError: null };
      case FETCH_PROJECT_STATUS_SUCCESS:
        return { ...state, projectStatusLoading: false, projectStatus: action.payload };    
    case FETCH_PROJECT_STATUS_FAILURE:
      return { ...state, projectStatusLoading: false, projectStatusError: action.payload };

      // Priority
    case FETCH_PRIORITY_REQUEST:
      return { ...state, priorityLoading: true, priorityError: null };
    case FETCH_PRIORITY_SUCCESS:
      return { ...state, priorityLoading: false, priority: action.payload };
    case FETCH_PRIORITY_FAILURE:
      return { ...state, priorityLoading: false, priorityError: action.payload };

      // Project Category
    case FETCH_PROJECT_CATEGORY_REQUEST:
      return { ...state, projectCategoryLoading: true, projectCategoryError: null };
    case FETCH_PROJECT_CATEGORY_SUCCESS:
      return { ...state, projectCategoryLoading: false, projectCategory: action.payload };
    case FETCH_PROJECT_CATEGORY_FAILURE:
      return { ...state, projectCategoryLoading: false, projectCategoryError: action.payload };

    default:
      return state;
  }
};

export default dropdownReducer;
