import { ThunkDispatch } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import apis from "../../utils/apis";
import { getUserID, getUserType } from "./ChatAction";
import { DELETE_BLOB_DOCUMENT_FAILURE, DELETE_BLOB_DOCUMENT_SUCCESS, DELETE_INDEX_FAILURE, DELETE_INDEX_REQUEST, DELETE_INDEX_SUCCESS, FETCH_BLOB_DOCUMENTS_FAILURE, FETCH_BLOB_DOCUMENTS_REQUEST, FETCH_BLOB_DOCUMENTS_SUCCESS, FETCH_INDEX_INFO_FAILURE, FETCH_INDEX_INFO_REQUEST, FETCH_INDEX_INFO_SUCCESS, POLL_INDEX_STATUS_START, POLL_INDEX_STATUS_STOP, POST_ADDITIONAL_COMPANY_FILES_FAILURE, POST_ADDITIONAL_COMPANY_FILES_REQUEST, POST_ADDITIONAL_COMPANY_FILES_SUCCESS, POST_COMPANY_FILES_FAILURE, POST_COMPANY_FILES_REQUEST, POST_COMPANY_FILES_SUCCESS, RERUN_INDEX_FAILURE, RERUN_INDEX_REQUEST, RERUN_INDEX_SUCCESS, UPDATE_INDEX_STATUS_MESSAGE } from "../types";


export const uploadCompanyFiles = (file: File) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    const formData = new FormData();
    formData.append('File', file);
    dispatch({ type: POST_COMPANY_FILES_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: 'ai/company_agent/upload_files',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        dispatch({
            type: POST_COMPANY_FILES_SUCCESS,
            payload: response.data
        });
        dispatch(startPollingIndexStatus());
    } catch (error: any) {
        dispatch({
            type: POST_COMPANY_FILES_FAILURE,
            payload: error
        });
    }
};


export const uploadAdditionalCompanyFiles = (file: File) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    const formData = new FormData();
    formData.append('UserType', getUserType());
    formData.append('File', file);
    dispatch({ type: POST_ADDITIONAL_COMPANY_FILES_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: 'ai/company_agent/additional-upload',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        dispatch({
            type: POST_ADDITIONAL_COMPANY_FILES_SUCCESS,
            payload: response.data
        });
        dispatch(startPollingIndexStatus());
    } catch (error: any) {
        dispatch({
            type: POST_ADDITIONAL_COMPANY_FILES_FAILURE,
            payload: error
        });
    }
};


export const getIndexInfo = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: FETCH_INDEX_INFO_REQUEST });
    try {
        const response = await apis({
            method: 'GET',
            url: 'ai/company_agent/index_info',
        });

        if (response.data && response.data.index_name) {
            dispatch({
                type: FETCH_INDEX_INFO_SUCCESS,
                payload: response.data,
            });
        } else {
            dispatch({
                type: FETCH_INDEX_INFO_FAILURE,
                payload: { indexExists: false, error: 'Index does not exist' },
            });
        }
    } catch (error: any) {
        dispatch({
            type: FETCH_INDEX_INFO_FAILURE,
            payload: { indexExists: false, error: error.message },
        });
    }
};

    
export const getBlobDocuments = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: FETCH_BLOB_DOCUMENTS_REQUEST });
    try {
        const response = await apis({
            method: 'GET',
            url: 'ai/company_agent/list_files',
        });

        if (response.data.files) {
            dispatch({
                type: FETCH_BLOB_DOCUMENTS_SUCCESS,
                payload: response.data.files,
            });
        } else {
            dispatch({
                type: FETCH_BLOB_DOCUMENTS_SUCCESS,
                payload: [],
            });
        }
    } catch (error: any) {
        dispatch({
            type: FETCH_BLOB_DOCUMENTS_FAILURE,
            payload: { error: error.message },
        });
    }
};

    export const reRunIndex = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        dispatch({ type: RERUN_INDEX_REQUEST });
        try {
        const response = await apis({
            method: 'POST',
            url: 'companyai/re-run-index',
            data: {
            UserType: getUserType(),
            }
        });
        dispatch({
            type: RERUN_INDEX_SUCCESS,
            payload: response.data
        });
        } catch (error: any) {
        dispatch({
            type: RERUN_INDEX_FAILURE,
            payload: error
        });
        }
    };

    export const deleteBlobDocument = (fileName: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        try {
            const response = await apis({
                method: 'POST',
                url: 'ai/company_agent/delete_file',
                data: {
                    FileName: fileName
                }
            });
            dispatch({
                type: DELETE_BLOB_DOCUMENT_SUCCESS,
                payload: response.data
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_BLOB_DOCUMENT_FAILURE,
                payload: error
            });
        }
    };
    


    export const startPollingIndexStatus = () => (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        dispatch({ type: POLL_INDEX_STATUS_START });
        const pollInterval = setInterval(async () => {
            try {
                const response = await apis({
                    method: 'GET',
                    url: 'ai/company_agent/index_status',
                });
    
                const { status } = response.data; 
    
                if (status === "success") {
                    clearInterval(pollInterval);
                    dispatch({ type: POLL_INDEX_STATUS_STOP });
                    dispatch({
                        type: FETCH_INDEX_INFO_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    let statusMessage = "Loading...";
                    switch (status) {
                        case 'running':
                            statusMessage = "Loading Agent...";
                            break;
                        case 'InProgress':
                            statusMessage = "Building Agent...";
                            break;
                        case 'pending':
                            statusMessage = "Preparing Agent...";
                            break;
                        default:
                            statusMessage = "Loading...";
                            break;
                    }
    
                    dispatch({
                        type: UPDATE_INDEX_STATUS_MESSAGE,
                        payload: statusMessage,
                    });
                    dispatch({
                        type: FETCH_INDEX_INFO_SUCCESS,
                        payload: response.data,
                    });
                }
            } catch (error: any) {
                clearInterval(pollInterval);
                dispatch({ type: POLL_INDEX_STATUS_STOP });
                dispatch({
                    type: FETCH_INDEX_INFO_FAILURE,
                    payload: { indexExists: false, error: error.message },
                });
            }
        }, 5000);
    };
    

    export const deleteIndex = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        dispatch({ type: DELETE_INDEX_REQUEST });
        try {
            const response = await apis({
                method: 'POST',
                url: 'companyai/delete-index',
                data: {
                    UserType: getUserType(),
                }
            });
    
            if (response.status !== 200) {
                dispatch({
                    type: DELETE_INDEX_FAILURE,
                    payload: { indexExists: true, error: response.data.message },
                });
            } else {
                dispatch({
                    type: DELETE_INDEX_SUCCESS,
                    payload: { indexExists: false, error: null },
                });
            }
        } catch (error: any) {
            dispatch({
                type: DELETE_INDEX_FAILURE,
                payload: { indexExists: true, error: error.message },
            });
        }
    };
    