// src/components/Settings/Settings.tsx

import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Account from './AccountSettings/AccountSettings';
import Security from './Security/SecuritySettings';
import Finance from './FinanceSettings';
import PrivateRoute from '../../PrivateRoutes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect } from 'react';
import CompanySettings from './CompanySettings';

const Settings = () => {
    const { path } = useRouteMatch();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);

    useEffect(() => {
        // Initialize socket if needed
        // SocketServices.initializeSocket()
    }, []);

    return (
        <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 p-4">
            <Switch>
                <Route exact path={path} render={() => <Redirect to={`${path}/account`} />} />
                <Route exact path={`${path}/account`} component={Account} />
                <PrivateRoute path={`${path}/security`} component={Security} subscriptionId={userInfo.stripe_subscription_id} restricted={false} />
                <PrivateRoute path={`${path}/finance`} component={Finance} subscriptionId={userInfo.stripe_subscription_id} restricted={true} />
                <PrivateRoute path={`${path}/organization`} component={CompanySettings} subscriptionId={userInfo.stripe_subscription_id} restricted={false} />
            </Switch>
        </div>
    );      
}

export default Settings;
