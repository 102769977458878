import { useEffect, useState, useRef } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

interface UseMicrophoneProps {
  speechSubscriptionKey: string;
  serviceRegion: string;
  language: string;
}

const useMicrophone = ({
  speechSubscriptionKey,
  serviceRegion,
  language,
}: UseMicrophoneProps) => {
  const [finalTranscript, setFinalTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recognizerRef = useRef<SpeechSDK.SpeechRecognizer | null>(null);

  useEffect(() => {
    if (!speechSubscriptionKey || !serviceRegion) {
      setError('Azure Speech SDK credentials are missing.');
      return;
    }

    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      speechSubscriptionKey,
      serviceRegion
    );
    speechConfig.speechRecognitionLanguage = language;
    speechConfig.enableDictation(); // Improves punctuation and sentence structure

    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    recognizerRef.current = recognizer;

    recognizer.recognizing = (s, e) => {
      setInterimTranscript(e.result.text);
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        setFinalTranscript((prev) => `${prev} ${e.result.text}`);
        setInterimTranscript('');
      } else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
        console.log('No speech recognized.');
      }
    };

    recognizer.canceled = (s, e) => {
      console.error(`Canceled: Reason=${e.reason}`);
      if (e.reason === SpeechSDK.CancellationReason.Error) {
        console.error(`Error details: ${e.errorDetails}`);
        setError(`Speech recognition error: ${e.errorDetails}`);
      }
      setIsListening(false);
      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.sessionStopped = (s, e) => {
      setIsListening(false);
      recognizer.stopContinuousRecognitionAsync();
    };

    return () => {
      recognizer.close();
      recognizerRef.current = null;
    };
  }, [speechSubscriptionKey, serviceRegion, language]);

  const startListening = () => {
    setFinalTranscript('');
    setInterimTranscript('');
    setError(null); // Clear previous errors
    setIsListening(true);
    recognizerRef.current?.startContinuousRecognitionAsync(
      () => {
        // Started successfully
      },
      (err) => {
        console.error('Failed to start recognition.', err);
        setError('Failed to start speech recognition.');
        setIsListening(false);
      }
    );
  };

  const stopListening = () => {
    recognizerRef.current?.stopContinuousRecognitionAsync(
      () => {
        setIsListening(false);
        setInterimTranscript('');
      },
      (err) => {
        console.error('Failed to stop recognition.', err);
        setError('Failed to stop speech recognition.');
        setIsListening(false);
        setInterimTranscript('');
      }
    );
  };

  return {
    finalTranscript,
    interimTranscript,
    isListening,
    startListening,
    stopListening,
    error,
  };
};

export default useMicrophone;
