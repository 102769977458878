import React, { useEffect, useState } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchPriority, fetchProjectCategory, fetchProjectStatus } from '../../store/actions/DropdownActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { addProject, fetchProjects } from '../../store/actions/ProjectActions';
import { Employee } from '../Management/Employees/types';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { getAiModels } from '../../store/actions/ChatAction';

interface ProjectCreateProps {
  onCancel: () => void;
  onSuccess: () => void;
}

const ProjectCreate: React.FC<ProjectCreateProps> = ({ onCancel, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    projectName: '',
    projectDescription: '',
    projectDeadline: '',
    projectStartDate: '',
    category: '',
    priority: '',
    status: '',
    projectMembers: [] as string[], // Employee IDs
    projectLead: '',
    projectApprover: '',
    services: [] as { ServiceType: string; ServiceAmount: number }[],
  });

  const categoriesData = useSelector((state: RootState) => state.dropdown.projectCategory) || [];
  const prioritiesData = useSelector((state: RootState) => state.dropdown.priority) || [];
  const projectStatus = useSelector((state: RootState) => state.dropdown.projectStatus) || [];
  const employees = useSelector((state: RootState) => state.employees.employees) || {};

// Fetch services and handle loading state
const [loadingServices, setLoadingServices] = useState(true);

const [servicesData, setServicesData] = useState([]);

useEffect(() => {
  const fetchServices = async () => {
    try {
      const response = await dispatch(getAiModels());
      if (response) {
        setServicesData(response);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoadingServices(false);
    }
  };
  fetchServices();
}, [dispatch]);


// Prepare service options
const serviceOptions = servicesData.map((s: any) => ({
  value: s.model_id,
  label: s.model_display_name,
}));


  useEffect(() => {
    dispatch(fetchAllEmployees());
    dispatch(fetchProjectCategory());
    dispatch(fetchPriority());
    dispatch(fetchProjectStatus());
  }, [dispatch]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.projectName) newErrors.projectName = t('Project Name is required');
    if (!formData.projectDescription) newErrors.projectDescription = t('Project Description is required');
    if (formData.projectMembers.length === 0) newErrors.projectMembers = t('At least one project member is required');
    if (!formData.projectLead) newErrors.projectLead = t('Project Lead is required');
    if (formData.services.length === 0) newErrors.services = t('At least one service allocation is required');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelect = (field: string, option: DropdownOption) => {
    setFormData((prev) => ({
      ...prev,
      [field]: option.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const data = {
        ProjectName: formData.projectName,
        ProjectDescription: formData.projectDescription,
        ProjectLeadID: formData.projectLead,
        ProjectApproverID: formData.projectApprover || formData.projectLead,
        CategoryType: formData.category,
        PriorityLevel: formData.priority,
        StartDate: formData.projectStartDate,
        EndDate: formData.projectDeadline,
        ServiceAllocation: formData.services,
        UserType: 'BusinessAdmin',
        ProjectMembers: formData.projectMembers,
      };
      const project = await dispatch(addProject(data));
      if (project) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('New Project Created!'),
            modalType: 'success',
            duration: 3000,
          })
        );
        setFormData({
          projectName: '',
          projectDescription: '',
          projectDeadline: '',
          projectStartDate: '',
          category: '',
          priority: '',
          status: '',
          projectMembers: [] as string[],
          projectLead: '',
          projectApprover: '',
          services: [] as { ServiceType: string; ServiceAmount: number }[],
        });
        onSuccess();
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleAddService = () => {
    setFormData((prev) => ({
      ...prev,
      services: [...prev.services, { ServiceType: '', ServiceAmount: 0 }],
    }));
  };
  
  const handleServiceSelect = (index: number, option: DropdownOption) => {
    const updatedServices = [...formData.services];
    updatedServices[index].ServiceType = option.value;
    setFormData((prev) => ({
      ...prev,
      services: updatedServices,
    }));
  };
  
  const handleServiceAmountChange = (index: number, amount: number) => {
    const updatedServices = [...formData.services];
    updatedServices[index].ServiceAmount = amount;
    setFormData((prev) => ({
      ...prev,
      services: updatedServices,
    }));
  };
  
  const handleRemoveService = (index: number) => {
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    setFormData((prev) => ({
      ...prev,
      services: updatedServices,
    }));
  };
  

  // Employee options for dropdowns
  const employeeOptions = employees.map((employee: Employee) => ({
    value: employee.UserID.toString(),
    label: `${employee.User.FirstName} ${employee.User.LastName}`,
  }));

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">{t('Create New Project')}</h2>
      <form onSubmit={handleSubmit}>
        {/* Project Name */}
        <div className="mb-4">
          <Input
            type="text"
            name="projectName"
            label={t('Project Name')}
            placeholder={t('Enter project name')}
            value={formData.projectName}
            onChange={handleChange}
            error={errors.projectName}
          />
        </div>

        {/* Project Description */}
        <div className="mb-4">
          <Textarea
            id="projectDescription"
            name="projectDescription"
            label={t('Project Description')}
            placeholder={t('Enter project description')}
            value={formData.projectDescription}
            onChange={handleChange}
            error={errors.projectDescription}
          />
        </div>

        {/* Category */}
        <div className="mb-4">
          <Dropdown
            label={t('Category')}
            options={categoriesData}
            placeholder={t('Select Category')}
            onSelect={(option) => handleSelect('category', option)}
            error={errors.category}
          />
        </div>

        {/* Priority */}
        <div className="mb-4">
          <Dropdown
            label={t('Priority')}
            options={prioritiesData}
            placeholder={t('Select Priority')}
            onSelect={(option) => handleSelect('priority', option)}
            error={errors.priority}
          />
        </div>

        {/* Start Date */}
        <div className="mb-4">
          <Input
            type="date"
            name="projectStartDate"
            label={t('Start Date')}
            placeholder={t('Select start date')}
            value={formData.projectStartDate}
            onChange={handleChange}
            error={errors.projectStartDate}
          />
        </div>

        {/* Deadline */}
        <div className="mb-4">
          <Input
            type="date"
            name="projectDeadline"
            label={t('Deadline')}
            placeholder={t('Select deadline')}
            value={formData.projectDeadline}
            onChange={handleChange}
            error={errors.projectDeadline}
          />
        </div>

        {/* Project Members */}
        <div className="mb-4">
          <Dropdown
            label={t('Project Members')}
            options={employeeOptions.filter((option) => !formData.projectMembers.includes(option.value))}
            placeholder={t('Select Project Members')}
            onSelect={(option) => {
              setFormData((prev) => ({
                ...prev,
                projectMembers: [...prev.projectMembers, option.value],
              }));
            }}
            multiple={false}
            error={errors.projectMembers}
          />
          {/* Display selected members with option to remove */}
          <div className="selected-members flex flex-wrap mt-2">
            {formData.projectMembers.map((memberId: any) => {
              const member = employees[memberId];
              return (
                <div key={memberId} className="member-item flex items-center mr-2 mb-2">
                  <span className="mr-2">{member ? `${member.User.FirstName} ${member.User.LastName}` : t('Unknown Member')}</span>
                  <button
                    type="button"
                    onClick={() => {
                      const updatedMembers = formData.projectMembers.filter((id) => id !== memberId);
                      setFormData((prev) => ({ ...prev, projectMembers: updatedMembers }));
                    }}
                    className="destructive-button"
                  >
                    {t('Remove')}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {/* Project Lead */}
        <div className="mb-4">
          <Dropdown
            label={t('Project Lead')}
            options={formData.projectMembers.map((memberId: any) => {
              const member = employees[memberId];
              return {
                value: memberId,
                label: member ? `${member.User.FirstName} ${member.User.LastName}` : t('Unknown Member'),
              };
            })}
            placeholder={t('Select Project Lead')}
            onSelect={(option) => handleSelect('projectLead', option)}
            error={errors.projectLead}
          />
        </div>

        {/* Project Approver */}
        <div className="mb-4">
          <Dropdown
            label={t('Project Approver')}
            options={formData.projectMembers.map((memberId: any) => {
              const member = employees[memberId];
              return {
                value: memberId,
                label: member ? `${member.User.FirstName} ${member.User.LastName}` : t('Unknown Member'),
              };
            })}
            placeholder={t('Select Project Approver')}
            onSelect={(option) => handleSelect('projectApprover', option)}
            error={errors.projectApprover}
          />
        </div>

            {/* Service Allocation */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{t('Service Allocation')}</label>
              {errors.services && <p className="text-red-500 text-xs mt-1">{errors.services}</p>}
              {formData.services.map((service, index) => (
                <div key={index} className="flex items-center mt-2">
                  <Dropdown
                    options={serviceOptions}
                    placeholder={t('Select Service')}
                    onSelect={(option) => handleServiceSelect(index, option)}
                    value={service.ServiceType}
                  />
                  <Input
                    type="number"
                    name={`serviceAmount_${index}`}
                    placeholder={t('Amount')}
                    value={service.ServiceAmount}
                    onChange={(e) => handleServiceAmountChange(index, parseInt(e.target.value))}
                    className="ml-2"
                  />
                  <button type="button" onClick={() => handleRemoveService(index)} className="ml-2 text-red-500">
                    {t('Remove')}
                  </button>
                </div>
              ))}
            <button
              type="button"
              onClick={handleAddService}
              className="mt-2 text-blue-500"
              // disabled={servicesData.length === 0}
            >
              {t('Add Service')}
            </button>
            </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button type="submit" variant="primary">
            {t('Create')}
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel} className="ml-2">
            {t('Cancel')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProjectCreate;