import React from 'react';
import { useTranslation } from 'react-i18next';

interface ToggleSwitchThemeProps {
  isOn: boolean;
  onToggle: () => void;
}

export const ToggleSwitchTheme: React.FC<ToggleSwitchThemeProps> = ({ isOn, onToggle }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded-full p-1">
      <button
        className={`px-4 w-full py-2 rounded-full ${!isOn ? 'bg-white text-black' : 'text-gray-500'}`}
        onClick={onToggle}
      >
        {t('Light')}
      </button>
      <button
        className={`px-4 w-full py-2 rounded-full ${isOn ? 'bg-white text-black' : 'text-gray-500'}`}
        onClick={onToggle}
      >
        {t('Dark')}
      </button>
    </div>
  );
};
