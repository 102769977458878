import { AnyAction } from 'redux';
import { FETCH_CASES_FIRM_FAILURE, FETCH_CASES_FIRM_REQUEST, FETCH_CASES_FIRM_SUCCESS, FETCH_LAWYERS_FIRM_FAILURE, FETCH_LAWYERS_FIRM_REQUEST, FETCH_LAWYERS_FIRM_SUCCESS, FETCH_LEGAL_SUBJECTS_TEAM_FAILURE, FETCH_LEGAL_SUBJECTS_TEAM_REQUEST, FETCH_LEGAL_SUBJECTS_TEAM_SUCCESS, FETCH_LEGAL_TEAMS_FAILURE, FETCH_LEGAL_TEAMS_REQUEST, FETCH_LEGAL_TEAMS_SUCCESS, FETCH_TASK_SUCCESS, FETCH_TEAM_FAILURE, FETCH_TEAM_REQUEST, FETCH_TEAM_SUCCESS, SET_LICENSE_DATA, SET_LICENSE_REQUESTS } from '../../types';

import { IAdminCaseItem } from '../../../components/Marketplace/Interfaces/IAdminCaseItem';
import { Task } from '../../../components/Marketplace/Lawyer/LawyerSection';
import { Team } from '../../../components/Management/Employees/types';

interface LawfirmState {
  loading: boolean;
  lawyers: any[];
  cases: IAdminCaseItem[];
  subjects: any[],
  teams: any[],
  error: string | null;
  team: Team | null;
  tasks: Task[];
  activeLicenseRequest: boolean;
  totalLicenseCount: number,
  licenseUsed: number,
  licenceLimitExceded: boolean

}

const initialState: LawfirmState = {
  loading: false,
  lawyers: [],
  cases: [],
  subjects: [],
  teams: [],
  team: null,
  error: null,
  tasks: [],
  activeLicenseRequest: false,
  totalLicenseCount: 0,
  licenseUsed: 0,
  licenceLimitExceded: false
};

const lawfirmReducer = (state = initialState, action: AnyAction): LawfirmState => {
  switch (action.type) {
    case FETCH_LAWYERS_FIRM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LAWYERS_FIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        lawyers: action.payload,
      };
    case FETCH_LAWYERS_FIRM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CASES_FIRM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CASES_FIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        cases: action.payload,
      };
    case FETCH_CASES_FIRM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_LEGAL_SUBJECTS_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LEGAL_SUBJECTS_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
      };
    case FETCH_LEGAL_SUBJECTS_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_LEGAL_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LEGAL_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload,
      };
    case FETCH_LEGAL_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        team: action.payload
      };
    case FETCH_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: action.payload
      };
    case SET_LICENSE_REQUESTS:
      return {
        ...state,
        activeLicenseRequest: action.payload  ? true : false
      };
      case SET_LICENSE_DATA:
        return {
          ...state,
          licenceLimitExceded: action.payload.licenceLimitExceded,
          totalLicenseCount: action.payload.totalLicenseCount,
          licenseUsed: action.payload.licenseUsed,
        };
    default:
      return state;
  }
};

export default lawfirmReducer;
