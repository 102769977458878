import Settings from "../components/Settings/Settings";

function SettingsPage() {
    return (
        <div className="flex flex-col h-screen">
 <main className="h-calc-100vh-100px overflow-y-auto custom-scrollbar relative">
                <Settings />
            </main>
        </div>
    )
}

export default SettingsPage;
