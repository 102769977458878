import {
  FETCH_COMPANY_SETTINGS_REQUEST,
  FETCH_COMPANY_SETTINGS_SUCCESS,
  FETCH_COMPANY_SETTINGS_FAILURE,
  UPDATE_COMPANY_SETTINGS_REQUEST,
  UPDATE_COMPANY_SETTINGS_SUCCESS,
  UPDATE_COMPANY_SETTINGS_FAILURE,
} from '../../types';

interface CompanyState {
  loading: boolean;
  settings: any;
  error: string | null;
}

const initialState: CompanyState = {
  loading: false,
  settings: {},
  error: null,
};

const companyReducer = (state = initialState, action: any): CompanyState => {
  switch (action.type) {
    case FETCH_COMPANY_SETTINGS_REQUEST:
    case UPDATE_COMPANY_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_COMPANY_SETTINGS_SUCCESS:
    case UPDATE_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    case FETCH_COMPANY_SETTINGS_FAILURE:
    case UPDATE_COMPANY_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
