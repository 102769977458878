import { getUserType } from "../../actions/ChatAction";
import { SELECT_CASE_FOR_VIEWING, SET_DETAILS_CONTENT } from "../../types";



const userType = getUserType(); 
const defaultViewForUser = userType === 'IndependentLawyer' ? 'market' : 'cases';

const initialState = {
  activeView: defaultViewForUser,
  selectedCase: null,
  detailsContent: 'NONE',
};

export const activeViewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_ACTIVE_VIEW':
      return {
        ...state,
        activeView: action.payload
      };
    case 'RESET_ACTIVE_VIEW':
      return {
        ...state,
        activeView: ''
      };
      case SELECT_CASE_FOR_VIEWING:
        return {
          ...state,
          selectedCase: action.payload,
        };
        case SET_DETAILS_CONTENT:
          return {
            ...state,
            detailsContent: action.payload,
          };
    default:
      return state;
  }
};
