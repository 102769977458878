import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaBalanceScale, FaGavel, FaFileContract, FaBuilding, FaUserSecret } from 'react-icons/fa';
import { AiOutlineFileSearch, AiOutlineFileText, AiOutlineSolution } from 'react-icons/ai';
import clsx from 'clsx';

interface Prompt {
  id: number;
  title: string;
  description: string;
  icon: JSX.Element;
  backgroundColor: string;
  promptText: string;
}

interface PromptListProps {
  model: string;
  onPromptSelect: (promptText: string) => void;
}

const PromptList: React.FC<PromptListProps> = ({ model, onPromptSelect }) => {
  const { t } = useTranslation();

  // Define prompts based on the model
  const getPrompts = (): Prompt[] => {
    switch (model) {
      case 'Law Research':
        return [
          {
            id: 1,
            title: t('Find Case Law'),
            description: t('Search for case law related to a specific topic.'),
            icon: <FaBalanceScale className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-primary-500',
            promptText: t('Please find case law related to [topic].'),
          },
          {
            id: 2,
            title: t('Explain Legal Concept'),
            description: t('Get a detailed explanation of a legal concept.'),
            icon: <AiOutlineFileSearch className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-secondary-500',
            promptText: t('Explain the legal concept of [concept].'),
          },
          {
            id: 3,
            title: t('Compare Laws'),
            description: t('Compare laws between two jurisdictions.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-success-500',
            promptText: t('Compare the laws regarding [issue] between [jurisdiction A] and [jurisdiction B].'),
          },
          {
            id: 4,
            title: t('Legal Precedents'),
            description: t('Find precedents for a particular case.'),
            icon: <FaGavel className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-warning-500',
            promptText: t('Find legal precedents related to [case or issue].'),
          },
        ];
      case 'Tax Research':
        return [
          {
            id: 5,
            title: t('Tax Regulations'),
            description: t('Find tax regulations for a specific situation.'),
            icon: <FaGavel className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-success-500',
            promptText: t('What are the tax regulations for [situation]?'),
          },
          {
            id: 6,
            title: t('Tax Benefits'),
            description: t('Discover potential tax benefits.'),
            icon: <FaFileContract className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-warning-500',
            promptText: t('What tax benefits are available for [scenario]?'),
          },
          {
            id: 7,
            title: t('Tax Compliance'),
            description: t('Understand compliance requirements.'),
            icon: <AiOutlineFileText className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-danger-500',
            promptText: t('What are the tax compliance requirements for [business type]?'),
          },
          {
            id: 8,
            title: t('International Taxation'),
            description: t('Learn about international tax laws.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-info-500',
            promptText: t('Explain international tax laws regarding [topic].'),
          },
        ];
      case 'Case Research':
        return [
          {
            id: 9,
            title: t('Case Analysis'),
            description: t('Analyze a legal case in detail.'),
            icon: <FaBalanceScale className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-danger-500',
            promptText: t('Provide an analysis for the case of [case name].'),
          },
          {
            id: 10,
            title: t('Case Summaries'),
            description: t('Get summaries of important cases.'),
            icon: <AiOutlineFileSearch className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-info-500',
            promptText: t('Summarize the case of [case name].'),
          },
          {
            id: 11,
            title: t('Case Outcomes'),
            description: t('Find outcomes of similar cases.'),
            icon: <FaGavel className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-primary-500',
            promptText: t('What were the outcomes of cases similar to [case or situation]?'),
          },
          {
            id: 12,
            title: t('Legal Arguments'),
            description: t('Discover common legal arguments.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-secondary-500',
            promptText: t('What legal arguments are commonly used in cases involving [issue]?'),
          },
        ];
      case 'Contract Analysis':
        return [
          {
            id: 13,
            title: t('Identify Risks'),
            description: t('Identify potential risks in a contract.'),
            icon: <FaFileContract className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-warning-500',
            promptText: t('Please identify potential risks in this contract.'),
          },
          {
            id: 14,
            title: t('Compare Contracts'),
            description: t('Compare two contracts for differences.'),
            icon: <AiOutlineFileSearch className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-primary-500',
            promptText: t('Compare these two contracts and highlight the differences.'),
          },
          {
            id: 15,
            title: t('Clause Explanation'),
            description: t('Explain a specific clause in the contract.'),
            icon: <AiOutlineFileText className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-success-500',
            promptText: t('Explain the meaning of clause [number or title] in this contract.'),
          },
          {
            id: 16,
            title: t('Compliance Check'),
            description: t('Check contract compliance with regulations.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-danger-500',
            promptText: t('Is this contract compliant with [specific regulation or law]?'),
          },
        ];
      case 'Contract Generation':
        return [
          {
            id: 17,
            title: t('Draft NDA'),
            description: t('Generate a Non-Disclosure Agreement.'),
            icon: <FaFileContract className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-primary-500',
            promptText: t('Generate an NDA between [Party A] and [Party B] for [purpose].'),
          },
          {
            id: 18,
            title: t('Employment Contract'),
            description: t('Create an employment contract.'),
            icon: <AiOutlineFileText className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-secondary-500',
            promptText: t('Create an employment contract for a [position] at [company name].'),
          },
          {
            id: 19,
            title: t('Lease Agreement'),
            description: t('Draft a lease agreement.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-success-500',
            promptText: t('Draft a lease agreement for a property located at [address].'),
          },
          {
            id: 20,
            title: t('Service Agreement'),
            description: t('Generate a service agreement.'),
            icon: <FaGavel className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-warning-500',
            promptText: t('Create a service agreement between [Provider] and [Client] for [services].'),
          },
        ];
      case 'Company Agent':
        return [
          {
            id: 21,
            title: t('Company Policies'),
            description: t('Ask about company policies and procedures.'),
            icon: <FaBuilding className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-info-500',
            promptText: t('What is the policy on [topic] in our company?'),
          },
          {
            id: 22,
            title: t('Employee Handbook'),
            description: t('Inquire about employee guidelines.'),
            icon: <AiOutlineFileText className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-primary-500',
            promptText: t('Explain the guidelines for [situation] according to the employee handbook.'),
          },
          {
            id: 23,
            title: t('Company Procedures'),
            description: t('Learn about specific company procedures.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-secondary-500',
            promptText: t('What is the procedure for [action or process] in our company?'),
          },
          {
            id: 24,
            title: t('Shared Cases'),
            description: t('Access information on shared cases.'),
            icon: <FaGavel className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-success-500',
            promptText: t('Provide details on the case of [case name or number].'),
          },
        ];
      case 'Custom Agent':
        return [
          {
            id: 25,
            title: t('Personal Data Query'),
            description: t('Ask about your personal data.'),
            icon: <FaUserSecret className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-danger-500',
            promptText: t('Retrieve information on [topic] from my uploaded data.'),
          },
          {
            id: 26,
            title: t('Data Analysis'),
            description: t('Analyze your custom data set.'),
            icon: <AiOutlineFileSearch className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-info-500',
            promptText: t('Analyze the trends in my data regarding [topic].'),
          },
          {
            id: 27,
            title: t('Generate Report'),
            description: t('Generate a report from your data.'),
            icon: <AiOutlineFileText className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-primary-500',
            promptText: t('Generate a report on [topic] based on my uploaded documents.'),
          },
          {
            id: 28,
            title: t('Summarize Data'),
            description: t('Get a summary of your data.'),
            icon: <AiOutlineSolution className="h-6 w-6 text-white" />,
            backgroundColor: 'bg-secondary-500',
            promptText: t('Provide a summary of [document or topic] from my data.'),
          },
        ];
      default:
        return [];
    }
  };

  const prompts = getPrompts();

  return (
    <div className="p-4">
      <h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
        {t('Suggested Prompts')}
      </h2>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
        {t('Select a prompt to start your conversation.')}
      </p>
      <ul
        role="list"
        className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 dark:border-gray-600 py-6 sm:grid-cols-2"
      >
        {prompts.map((item) => (
          <li key={item.id} className="flow-root">
            <button
              onClick={() => onPromptSelect(item.promptText)}
              className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus:outline-none focus:ring-2 focus:ring-primary-500 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
            >
              <div
                className={clsx(
                  item.backgroundColor,
                  'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                )}
              >
                {item.icon}
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                  {item.title}
                </h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {item.description}
                </p>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PromptList;
