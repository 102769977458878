import React, { useState, useEffect, ReactNode } from 'react';
import { UserContext, UserContextProps } from './UserContext'; // Import the UserContext
import SocketServices from '../utils/SocketServices';
interface UserProviderProps {
    children: ReactNode;
  }

export const UserProvider:React.FC<UserProviderProps> = ({ children }) => {
  // State to manage user data
  const [userDataState, setUserDataState] = useState<UserContextProps>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: {
      streetAddress: '',
      country: '',
      zipCode: '',
      city: '',
      state: '',
    },
    setUserData: (data: Partial<UserContextProps>) => {
      setUserDataState((prevData) => ({ ...prevData, ...data }));
    },
  });

  return (
    <UserContext.Provider value={{ ...userDataState, setUserData: userDataState.setUserData }}>
      {children}
    </UserContext.Provider>
  );
};
