// import React from 'react';
// import { ArrowDownCircleIcon } from '@heroicons/react/24/solid';

// interface AttachmentPreviewProps {
//   attachment: Attachment;
// }

// export interface Attachment {
//     file: File;
//     fileName: string;
//     fileType: string;
//     fileSize: number;
//     fileUrl: string;
//   }
  

// const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({ attachment }) => {
//   // Determine file type and corresponding icon
//   const getFileIcon = (fileType: string) => {
//     if (fileType.includes('pdf')) {
//       return <i className="fa-solid fa-file-pdf text-red-500"></i>;
//     } else if (fileType.includes('word')) {
//       return <i className="fa-solid fa-file-word text-blue-500"></i>;
//     } else if (fileType.includes('excel')) {
//       return <i className="fa-solid fa-file-excel text-green-500"></i>;
//     } else if (fileType.includes('image')) {
//       return <i className="fa-solid fa-file-image text-yellow-500"></i>;
//     } else {
//       return <i className="fa-solid fa-file text-gray-500"></i>;
//     }
//   };

//   return (
//     <div className="flex items-center bg-gray-100 dark:bg-gray-800 p-2 rounded-md mb-1">
//       <div className="mr-2">
//         {getFileIcon(attachment.fileType)}
//       </div>
//       <div className="flex-grow">
//         <p className="text-sm">{attachment.fileName}</p>
//         <p className="text-xs text-gray-500">
//           {(attachment.fileSize / 1024).toFixed(2)} KB
//         </p>
//       </div>
//       <a
//         href={attachment.fileUrl}
//         download={attachment.fileName}
//         className="text-blue-500 hover:text-blue-700"
//       >
//         <ArrowDownCircleIcon className="h-5 w-5" />
//       </a>
//     </div>
//   );
// };


import React, { useState, useEffect } from 'react';
import FullscreenImageModal from './FullScreenImage';
interface ChatImagesProps {
  attachments: (string | File)[];
}

const AttachmentPreview: React.FC<ChatImagesProps> = ({ attachments }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<(string | File)[]>([]);
  
  // Limit display to only two attachments if there are more
  const displayAttachments = attachments.slice(0, 2);
  const remainingCount = attachments.length > 2 ? attachments.length - 2 : 0;

  const openModal = () => {
    const uniqueImages = Array.from(new Set(attachments)); // Filter unique images
    setSelectedImages(uniqueImages);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const renderAttachment = (file: string | File, index: number) => {
    const isImageUrl = typeof file === 'string' && (file.startsWith('http://') || file.startsWith('https://')) && (file.includes('.jpg') || file.includes('.jpeg') || file.includes('.png') || file.includes('.gif') || file.includes('.bmp') || file.includes('.svg'));
    const isDocumentUrl = typeof file === 'string' && (file.startsWith('http://') || file.startsWith('https://')) &&   (file.includes('.docx') || file.includes('.pdf') || file.includes('.xlsx') || file.includes('.txt')); // Add other file types as needed

    const file_name = isDocumentUrl ?  file.split('/').pop() : ''

    if (isImageUrl) {
      return (
        <div key={index} className="relative w-20 h-20" onClick={openModal}>
          <img src={file as string} alt="attachment" className="w-full h-full object-cover rounded-lg" />
          {remainingCount > 0 && index === 1 && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold rounded-lg">
              +{remainingCount}
            </div>
          )}
        </div>
      );
    } else if (isDocumentUrl) {
      return (
        <div key={index} className="flex items-center p-2 text-gray-700">
          <a href={file as string} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
            <i className="fa-solid fa-file text-gray-500" />
            <span className="truncate">{file_name?.split('?')[0]}</span>
          </a>
        </div>
      );
    } else if (file instanceof File) {
      if (file.type.startsWith('image/')) {
        return (
          <div key={index} className="relative w-20 h-20" onClick={openModal}>
            <img src={URL.createObjectURL(file)} alt={file.name} className="w-full h-full object-cover rounded-lg" />
            {remainingCount > 0 && index === 1 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold rounded-lg">
                +{remainingCount}
              </div>
            )}
          </div>
        );
      } else if (file.type.startsWith('audio/')) {
        return (
          <div key={index} className="p-2">
            <audio controls className="w-full">
              <source src={URL.createObjectURL(file)} type={file.type} />
              Your browser does not support the audio element.
            </audio>
          </div>
        );
      } else if (file.type === 'application/pdf') {
        return (
          <div key={index} className="flex items-center p-2 text-gray-700">
            <a href={URL.createObjectURL(file)} download={file.name} className="flex items-center space-x-2">
              <i className="fa-solid fa-file text-gray-500" />
              <span className="truncate">{file.name}</span>
            </a>
          </div>
        );
      } else {
        return (
          <div key={index} className="flex items-center p-2 text-gray-700">
            <a href={URL.createObjectURL(file)} download={file.name} className="flex items-center space-x-2">
              <i className="fa-solid fa-file text-gray-500" />
              <span className="truncate">{file.name}</span>
            </a>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    return () => {
      attachments.forEach((file) => {
        if (file instanceof File) {
          URL.revokeObjectURL(URL.createObjectURL(file));
        }
      });
    };
  }, [attachments]);

  return (
    <div className="flex flex-wrap gap-2">
      {displayAttachments.map((file, index) => renderAttachment(file, index))}

      {/* Modal component commented out if not defined */}
      {modalIsOpen && (
        <FullscreenImageModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          images={selectedImages}
        />
      )}
    </div>
  );
};

export default AttachmentPreview;