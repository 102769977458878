// src/utils/config.js
import {jwtDecode} from 'jwt-decode';

let baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
let socketUrl = process.env.REACT_APP_SOCKET_URL
let paymentUrl = process.env.REACT_APP_PAYMENTS_URL
let azureSpeechKey = process.env.REACT_APP_AZURE_SPEECH_KEY || ''  // Add default empty string
let azureSpeechRegion = process.env.REACT_APP_AZURE_SPEECH_REGION || '' // Add default empty string

export const config = {
    baseUrl: baseUrl,
    payment_url: paymentUrl,
    socket_url: socketUrl,
    azure: {
        speechKey: azureSpeechKey,
        speechRegion: azureSpeechRegion
    }
};

export const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
};