import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

interface SplitButtonProps {
  onSave?: () => void;
  onGenerate?: () => void;
  onDownloadPDF: () => void;
  onDownloadDocx: () => void;
  isGenerating?: boolean;
  pdfAvailable: boolean;
  docxAvailable: boolean;
}

const SplitButton: React.FC<SplitButtonProps> = ({
  onGenerate,
  onDownloadPDF,
  onDownloadDocx,
  isGenerating = false,
  pdfAvailable,
  docxAvailable,
}) => {
  return (
    <div className="relative inline-flex shadow-sm">
      <button
        type="button"
        className={clsx(
          'relative inline-flex items-center rounded-l-md bg-gray-200 dark:bg-gray-700 px-2.5 py-1.5 text-sm font-regular text-gray-900 dark:text-gray-100 shadow-sm hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
          { 'opacity-50 cursor-not-allowed': isGenerating }
        )}
        onClick={onGenerate}
        disabled={isGenerating}
      >
        {isGenerating ? 'Generating...' : 'Generate Document'}
      </button>
      <Menu as="div" className="relative">
        <Menu.Button className="relative inline-flex items-center rounded-r-md bg-gray-200 dark:bg-gray-700 px-2 py-2 text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 hover:bg-gray-300 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </Menu.Button>
        <Transition>
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onDownloadPDF}
                    disabled={!pdfAvailable}
                    className={clsx(
                      active ? 'bg-gray-100 dark:bg-gray-700' : '',
                      'block w-full text-left px-4 py-2 text-sm',
                      !pdfAvailable && 'opacity-50 cursor-not-allowed'
                    )}
                  >
                    Download as PDF
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onDownloadDocx}
                    disabled={!docxAvailable}
                    className={clsx(
                      active ? 'bg-gray-100 dark:bg-gray-700' : '',
                      'block w-full text-left px-4 py-2 text-sm',
                      !docxAvailable && 'opacity-50 cursor-not-allowed'
                    )}
                  >
                    Download as DOCX
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default SplitButton;