import { AnyAction } from "redux";
import { CLOSE_SIDEBAR, OPEN_SIDEBAR, TOGGLE_SIDEBAR } from "../types";


export const toggleSidebar = (): AnyAction => ({
  type: TOGGLE_SIDEBAR,
});

export const openSidebar = (): AnyAction => ({
  type: OPEN_SIDEBAR,
});

export const closeSidebar = (): AnyAction => ({
  type: CLOSE_SIDEBAR,
});
