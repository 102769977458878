import { IAdminCaseItem } from "../../../components/Marketplace/Interfaces/IAdminCaseItem";
import { ICaseItem } from "../../../components/Marketplace/Interfaces/ICaseItem";
import { SELECT_CASE_FOR_VIEWING, SET_DETAILS_CONTENT } from "../../types";


export const setActiveView = (view: string) => ({
    type: 'SET_ACTIVE_VIEW',
    payload: view,
  });
  
  export const resetActiveView = () => ({
    type: 'RESET_ACTIVE_VIEW',
  });



export const selectCaseForViewing = (selectedCase: ICaseItem | IAdminCaseItem | null) => ({
  type: SELECT_CASE_FOR_VIEWING,
  payload: selectedCase,
});



export const setDetailsContent = (content: string) => ({
  type: SET_DETAILS_CONTENT,
  payload: content,
});
