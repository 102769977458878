import React from 'react';
import { createPortal } from 'react-dom';
import Button from './Button';

interface ModalProps {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void; // Make 'onCancel' optional
  confirmLabel?: string;
  cancelLabel?: string;
  variant?: 'success' | 'warning' | 'danger';
  hideCancelButton?: boolean; // Add 'hideCancelButton' prop
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  subtitle,
  icon,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  variant = 'success',
  hideCancelButton = false, // Default to false
}) => {
  if (!isOpen) return null;

  const modalContent = (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md">
        <div className="text-center">
          {icon && <div className="mx-auto mb-4">{icon}</div>}
          <h2 className="text-xl font-semibold mb-2">{title}</h2>
          {subtitle && <p className="text-gray-600 dark:text-gray-300 mb-4">{subtitle}</p>}
          <div className="flex justify-center space-x-4 mt-6">
            <Button variant={variant} onClick={onConfirm}>
              {confirmLabel}
            </Button>
            {!hideCancelButton && onCancel && (
              <Button variant="secondary" onClick={onCancel}>
                {cancelLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default Modal;
