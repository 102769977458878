export const getUserData = () => {
    const tokenString = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (tokenString) {
      try {
        return JSON.parse(tokenString);
      } catch (e) {
        return null;
      }
    }
    return null;
  };
  
  export const getIsAdmin = () => {
    const userData = getUserData();
    return userData ? userData.isAdmin : null;
  };
  
  export const getRoles = () => {
    const userData = getUserData();
    return userData ? userData.roles || [] : [];
  };
  
  export const getUserType = () => {
    const userData = getUserData();
    return userData ? userData.userType : null;
  };

  export const isBetaEnv =
  window.location.hostname.includes('beta.pons.io') ||
  window.location.hostname.includes('demo.pons.io');