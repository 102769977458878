import React, { createContext } from 'react';

interface LayoutContextProps {
  isContentScrollable: boolean;
  setIsContentScrollable: (value: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
  isContentScrollable: true,
  setIsContentScrollable: () => {},
});
