import React, { useState } from "react";
import './GetReports.css'
import Charts from "./Charts";


const GetReports = () => {
    const [accountUpgraded, setAccountUpgraded] = useState(false)

    {/* {
        accountUpgraded ? ( 
    
        {/* ) : (
            <div className="get-reports__container">
                <div className="get-reports__content">

                    <h2 >To access Reports & Analytics, a Premium or Enterprise account is required.</h2>
                    <button onClick={() => setAccountUpgraded(true)}>Upgrade your account now</button>
                </div>
            </div>

        )
    } */}
    return (
            <div className="flex h-calc-100vh-90px  justify-center items-center text-neutral-400">Coming soon</div>
    )
}

export default GetReports;