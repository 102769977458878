import React, { useState, useEffect } from 'react';

interface FullscreenImageModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    images: (string | File)[];
}

const FullscreenImageModal: React.FC<FullscreenImageModalProps> = ({ isOpen, onRequestClose, images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [objectURLs, setObjectURLs] = useState<string[]>([]);

    useEffect(() => {
        // Create object URLs for any File objects
        const urls = images.map((image) =>
            typeof image === 'string' ? image : URL.createObjectURL(image)
        );
        setObjectURLs(urls);

        // Clean up object URLs on unmount or when modal closes
        return () => {
            urls.forEach((url) => {
                if (url.startsWith('blob:')) URL.revokeObjectURL(url);
            });
        };
    }, [images]);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    if (!isOpen) {
        return null;
    }

    const selectImage = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
            <div className="flex flex-col items-center">
                <div className="relative flex items-center justify-center space-x-4">
                    <button
                        type="button"
                        className="text-white text-3xl focus:outline-none"
                        onClick={goToPrevious}
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>

                    <img
                        src={objectURLs[currentIndex]}
                        alt="current"
                        className="w-[500px] h-[400px] object-contain rounded-md " // Fixed size for main image
                    />

                    <button
                        type="button"
                        className="text-white text-3xl focus:outline-none"
                        onClick={goToNext}
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>

                <div className="flex mt-4 space-x-2">
                    {objectURLs.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`thumbnail-${index}`}
                            className={`w-20 h-20 object-contain rounded-md cursor-pointer ${index === currentIndex ? 'border-4 border-blue-500' : ''}`} // Fixed size for thumbnails
                            onClick={() => selectImage(index)}
                        />
                    ))}
                </div>
            </div>

            <button
                className="absolute top-4 right-4 text-white text-4xl focus:outline-none"
                onClick={onRequestClose}
            >
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
    );
};

export default FullscreenImageModal;
