import { Task } from "../../components/Tasks/types";
import apis from "../../utils/apis";
import {
  FETCH_TASKS,
  FETCH_TASK_DETAILS,
  CREATE_TASK_SUCCESS,
  ASSIGN_TASK_SUCCESS,
  UPDATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  COMPLETE_TASK_SUCCESS,
} from "../types";
import { AppThunk } from "../types";
import { showFeedbackModal } from "./UserFeedbackActions";

// Fetch all tasks
export const fetchAllTasks = (): AppThunk => async (dispatch) => {
  try {
    const response = await apis.get("/tasks/get_all_tasks");
    if (response && response.status === 200) {
      dispatch({
        type: FETCH_TASKS,
        payload: response.data.Tasks,
      });
    }
  } catch (error) {
    console.error("Error fetching tasks", error);
  }
};

// Fetch task details
export const fetchTaskDetails = (taskId: string): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/get_task", { TaskID: taskId });
    if (response && response.status === 200) {
      dispatch({
        type: FETCH_TASK_DETAILS,
        payload: response.data.TaskDetails,
      });
    }
  } catch (error) {
    console.error("Error fetching task details", error);
  }
};

export const createTask = (data: {
  TaskTitle: string;
  TaskDescription: string;
  TaskDeadline: string;
  CaseID?: string;
  ProjectID?: string;
  TeamID?: string;
}): AppThunk<Promise<any>> => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/create_task", data);
    if (response && response.status === 200) {
      dispatch({
        type: CREATE_TASK_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }
  } catch (error) {
    console.error("Error creating task", error);
    throw error;
  }
};

  
  // Assign a task
  export const assignTask = (
    taskId: string,
    assignees: string[],
    projectId?: string,
    caseId?: string
  ): AppThunk => async (dispatch) => {
    try {
      const response = await apis.post("/tasks/assign_task", {
        TaskID: taskId,
        Assignees: assignees,
        ...(projectId && { ProjectID: projectId }),
        ...(caseId && { CaseID: caseId }),
      });
      if (response && response.status === 200) {
        dispatch({
          type: ASSIGN_TASK_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error("Error assigning task", error);
      throw error;
    }
  };

// Update a task
export const updateTask = (data: Partial<Task>): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/update_task", data);
    if (response && response.status === 200) {
      dispatch({
        type: UPDATE_TASK_SUCCESS,
        payload: response.data,
      });
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: "Task assigned",
        showModal: true,
        duration: 3000
      
  }));
    }
  } catch (error) {
    console.error("Error updating task", error);
  }
};

// Complete a task (sets status to "Completed")
export const completeTask = (taskId: string): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/update_task", {
      TaskID: taskId,
      Status: "Completed",
    });
    if (response && response.status === 200) {
      dispatch({
        type: COMPLETE_TASK_SUCCESS,
        payload: response.data,
      });
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: "Task completed",
        showModal: true,
        duration: 3000
      
  }));
    }
  } catch (error) {
    console.error("Error completing task", error);
  }
};

// Delete a task
export const deleteTask = (taskId: string): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/delete_task", { TaskID: taskId });
    if (response && response.status === 200) {
      dispatch({
        type: DELETE_TASK_SUCCESS,
        payload: taskId,
      });
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: "Task deleted",
        showModal: true,
        duration: 3000
      
  }));
    }
  } catch (error) {
    console.error("Error deleting task", error);
  }
};
