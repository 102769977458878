import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideSidePanel } from '../../store/actions/sidePanelActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { useAppDispatch } from '../../store';

// Import your design system components
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import Toggle from '../shared/TailwindComponents/Toggle';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TagInput from '../shared/TailwindComponents/TagInput';
import BudgetInput from '../shared/TailwindComponents/BudgetInput';
import { publishCase } from '../../store/actions/marketplace2/marketplaceActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';

interface PublishCaseFormProps {
  caseItem: any;
}

const PublishCaseForm: React.FC<PublishCaseFormProps> = ({ caseItem }) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    IsPublishedToMarketplace: caseItem.IsPublishedToMarketplace || false,
    MarketplaceData: {
      AnonymizedDescription: caseItem.MarketplaceData?.AnonymizedDescription || '',
      Budget: caseItem.MarketplaceData?.Budget || '',
      Deadline: caseItem.MarketplaceData?.Deadline ? new Date(caseItem.MarketplaceData.Deadline) : null,
      AdditionalNotes: caseItem.MarketplaceData?.AdditionalNotes || '',
      Categories: caseItem.MarketplaceData?.Categories || [],
      Tags: caseItem.MarketplaceData?.Tags || [],
    },
  });

  const [publishImmediately, setPublishImmediately] = useState(false);

  const handleMarketplaceDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        [name]: value,
      },
    }));
  };

  const handleDeadlineChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        Deadline: date,
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const caseData = {
        MarketplaceData: {
          ...formData.MarketplaceData,
          // Format the date to a string if needed
          Deadline: formData.MarketplaceData.Deadline
            ? formData.MarketplaceData.Deadline.toISOString().split('T')[0]
            : '',
        },
        IsPublishedToMarketplace: publishImmediately,
      };

      // Update the case with marketplace info

      if (publishImmediately) {
        const publishData = {
          CaseID: caseItem.CaseID,
          ...caseData.MarketplaceData,
        };
        await dispatch(publishCase(publishData));
      }

      // Refetch the updated case
      await dispatch(fetchCases());

      dispatch(hideSidePanel());
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          showModal: true,
          message: 'Case updated successfully.',
          duration: 3000,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          showModal: true,
          message: 'Failed to update case.',
          duration: 3000,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-6">
      <h2 className="text-2xl font-semibold mb-4">Publish Case to Marketplace</h2>

      {/* Anonymized Description */}
      <Textarea
        id="AnonymizedDescription"
        label="Anonymized Description"
        placeholder="Enter an anonymized description of your case..."
        name="AnonymizedDescription"
        value={formData.MarketplaceData.AnonymizedDescription}
        onChange={handleMarketplaceDataChange}
      />

      {/* Budget */}
      <BudgetInput
        id="Budget"
        label="Budget"
        value={formData.MarketplaceData.Budget}
        onChange={(value) =>
          setFormData((prevData) => ({
            ...prevData,
            MarketplaceData: {
              ...prevData.MarketplaceData,
              Budget: value || '',
            },
          }))
        }
      />

      {/* Deadline */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Deadline</label>
        <DatePicker
          selected={formData.MarketplaceData.Deadline}
          onChange={handleDeadlineChange}
          dateFormat="yyyy-MM-dd"
          className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all duration-300"
          placeholderText="Select a deadline"
        />
      </div>

      {/* Additional Notes */}
      <Textarea
        id="AdditionalNotes"
        label="Additional Notes"
        placeholder="Any additional notes..."
        name="AdditionalNotes"
        value={formData.MarketplaceData.AdditionalNotes}
        onChange={handleMarketplaceDataChange}
      />

      {/* Categories */}
      <TagInput
        id="Categories"
        label="Categories"
        placeholder="Type and press enter to add categories"
        tags={formData.MarketplaceData.Categories}
        onChange={(newTags) =>
          setFormData((prevData) => ({
            ...prevData,
            MarketplaceData: {
              ...prevData.MarketplaceData,
              Categories: newTags,
            },
          }))
        }
      />

      {/* Tags */}
      <TagInput
        id="Tags"
        label="Tags"
        placeholder="Type and press enter to add tags"
        tags={formData.MarketplaceData.Tags}
        onChange={(newTags) =>
          setFormData((prevData) => ({
            ...prevData,
            MarketplaceData: {
              ...prevData.MarketplaceData,
              Tags: newTags,
            },
          }))
        }
      />

      {/* Publish Immediately Toggle */}
      <div className="flex items-center mt-4">
        <Toggle enabled={publishImmediately} onToggle={() => setPublishImmediately(!publishImmediately)} />
        <label className="ml-3 text-sm font-medium text-gray-700">
          Publish to Marketplace Immediately
        </label>
      </div>

      {/* Form Actions */}
      <div className="flex justify-end mt-6 space-x-3">
        <Button variant="secondary" onClick={() => dispatch(hideSidePanel())}>
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          {publishImmediately ? 'Save and Publish' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default PublishCaseForm;
