import { 
    CREATE_LCM_CLIENT_CASE_REQUEST,
    CREATE_LCM_CLIENT_CASE_SUCCESS,
    CREATE_LCM_CLIENT_CASE_FAILURE,
    CREATE_LCM_BUSINESS_CASE_REQUEST,
    CREATE_LCM_BUSINESS_CASE_SUCCESS,
    CREATE_LCM_BUSINESS_CASE_FAILURE,
    FETCH_ALL_LCM_CLIENT_CASES_REQUEST,
    FETCH_ALL_LCM_CLIENT_CASES_SUCCESS,
    FETCH_ALL_LCM_CLIENT_CASES_FAILURE,
    FETCH_ALL_LCM_BUSINESS_CASES_REQUEST,
    FETCH_ALL_LCM_BUSINESS_CASES_SUCCESS,
    FETCH_ALL_LCM_BUSINESS_CASES_FAILURE,
    GET_PROGRESS_LCM_CLIENT_REQUEST,
    GET_PROGRESS_LCM_CLIENT_SUCCESS,
    GET_PROGRESS_LCM_CLIENT_FAILURE,
    GET_PROGRESS_LCM_BUSINESS_REQUEST,
    GET_PROGRESS_LCM_BUSINESS_SUCCESS,
    GET_PROGRESS_LCM_BUSINESS_FAILURE,
    RUN_ANALYSIS_LCM_CLIENT_REQUEST,
    RUN_ANALYSIS_LCM_CLIENT_SUCCESS,
    RUN_ANALYSIS_LCM_CLIENT_FAILURE,
    RUN_ANALYSIS_LCM_BUSINESS_REQUEST,
    RUN_ANALYSIS_LCM_BUSINESS_SUCCESS,
    RUN_ANALYSIS_LCM_BUSINESS_FAILURE,
    CLEAR_SELECTED_LCM_CASE,
    SET_SELECTED_LCM_CASE,
    SET_SELECTED_ANALYSIS,
    CLEAR_SELECTED_ANALYSIS,
  } from "../types";
  
  export interface IFullCaseData {
    CaseID: string;
    UserType: string;
    case_status: string;
    case_type: string;
    client_id: string;
    created_at: string;
    custom_fields: Record<string, any>;
    fields: Record<string, any>;
    legal_analysis?: string;
    recommended_strategy?: string;
}

  interface CaseState {
    cases: Record<string, IFullCaseData>;
    businessCases: Record<string, any>;
    loading: boolean;
    error: string | null;
    caseAnalysisProgress: Record<string, any>;
    selectedLCMCase: any | null;
    selectedAnalysis: boolean;
    
  }
  
  const initialState: CaseState = {
    cases: {},
    businessCases: {},
    loading: false,
    error: null,
    caseAnalysisProgress: {},
    selectedLCMCase: null,
    selectedAnalysis: false
  };
  
  export const caseManagementReducer = (state = initialState, action: any): CaseState => {
    switch (action.type) {
      // Client Cases
  
      case CREATE_LCM_CLIENT_CASE_REQUEST:
      case RUN_ANALYSIS_LCM_CLIENT_REQUEST:
      case GET_PROGRESS_LCM_CLIENT_REQUEST:
      case FETCH_ALL_LCM_CLIENT_CASES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case CREATE_LCM_CLIENT_CASE_SUCCESS:
        return {
          ...state,
          loading: false,
          cases: {
            ...state.cases,
            [action.payload.case_id]: action.payload,
          },
        };
  
      case RUN_ANALYSIS_LCM_CLIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          caseAnalysisProgress: {
            ...state.caseAnalysisProgress,
            [action.payload.case_id]: {
              status: 'started',
              ...action.payload,
            },
          },
        };
  
      case GET_PROGRESS_LCM_CLIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          caseAnalysisProgress: {
            ...state.caseAnalysisProgress,
            [action.payload.case_id]: {
              ...state.caseAnalysisProgress[action.payload.case_id],
              ...action.payload.progress,
            },
          },
        };
  
        case FETCH_ALL_LCM_CLIENT_CASES_SUCCESS:
            return {
              ...state,
              loading: false,
              cases: action.payload.cases.reduce((acc: any, caseItem: any) => {
                acc[caseItem.CaseID] = caseItem;
                return acc;
              }, {}),
            };
  
      case CREATE_LCM_CLIENT_CASE_FAILURE:
      case RUN_ANALYSIS_LCM_CLIENT_FAILURE:
      case GET_PROGRESS_LCM_CLIENT_FAILURE:
      case FETCH_ALL_LCM_CLIENT_CASES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload || 'An error occurred with client cases',
        };
  
      // Business Cases
  
      case CREATE_LCM_BUSINESS_CASE_REQUEST:
      case RUN_ANALYSIS_LCM_BUSINESS_REQUEST:
      case GET_PROGRESS_LCM_BUSINESS_REQUEST:
      case FETCH_ALL_LCM_BUSINESS_CASES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case CREATE_LCM_BUSINESS_CASE_SUCCESS:
        return {
          ...state,
          loading: false,
          businessCases: {
            ...state.businessCases,
            [action.payload.case_id]: action.payload,
          },
        };
  
      case RUN_ANALYSIS_LCM_BUSINESS_SUCCESS:
        return {
          ...state,
          loading: false,
          caseAnalysisProgress: {
            ...state.caseAnalysisProgress,
            [action.payload.case_id]: {
              status: 'started',
              ...action.payload,
            },
          },
        };
  
      case GET_PROGRESS_LCM_BUSINESS_SUCCESS:
        return {
          ...state,
          loading: false,
          caseAnalysisProgress: {
            ...state.caseAnalysisProgress,
            [action.payload.case_id]: {
              ...state.caseAnalysisProgress[action.payload.case_id],
              ...action.payload.progress,
            },
          },
        };
  
        case FETCH_ALL_LCM_BUSINESS_CASES_SUCCESS:
            const businessCasesById = action.payload.cases.reduce((acc: any, caseItem: any) => {
                acc[caseItem.CaseID] = caseItem; 
                return acc;
            }, {});
            return {
                ...state,
                loading: false,
                businessCases: {
                    ...state.businessCases,
                    ...businessCasesById,
                },
            };        
        
      case CREATE_LCM_BUSINESS_CASE_FAILURE:
      case RUN_ANALYSIS_LCM_BUSINESS_FAILURE:
      case GET_PROGRESS_LCM_BUSINESS_FAILURE:
      case FETCH_ALL_LCM_BUSINESS_CASES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload || 'An error occurred with business cases',
        };
        case SET_SELECTED_LCM_CASE:
            return {
              ...state,
              selectedLCMCase: action.payload,
            };
        case SET_SELECTED_ANALYSIS:
            return {
              ...state,
              selectedAnalysis: true,
            };
          case CLEAR_SELECTED_ANALYSIS:
            return {
              ...state,
              selectedAnalysis: false,
            };      
          case CLEAR_SELECTED_LCM_CASE:
            return {
              ...state,
              selectedLCMCase: null,
            };      
      default:
        return state;
    }
  };
  