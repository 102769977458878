import React from 'react';

interface DocumentViewerProps {
  documentUrl: string | string[];
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
  const url =
    typeof documentUrl === 'string' ? documentUrl : documentUrl.length > 0 ? documentUrl[0] : '';

  if (!url) return <div>No document to display</div>;

  const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
    url
  )}&embedded=true`;

  return (
    <iframe
      src={viewerUrl}
      onError={() => alert('Failed to load the document')}
      className="w-full h-full"
      frameBorder="0"
      title="Document Viewer"
    ></iframe>
  );
};

export default DocumentViewer;
