import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { RootState, useAppDispatch } from '../../store';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { assignTask, createTask, fetchAllTasks } from '../../store/actions/TasksActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import Input from '../shared/TailwindComponents/Input';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { Employee } from '../Management/Employees/types';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions'; 
import { fetchProjects } from '../../store/actions/ProjectActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { fetchAllTeams } from '../../store/actions/organization/teamActions';

interface AddTaskProps {
  onCancel?: () => void;
  onAdd?: () => void;
  selectedEmployeeEmail?: string;
}


const AddTask: React.FC<AddTaskProps> = ({ onCancel, onAdd }) => {
  const userType = getUserType();
  const dispatch = useAppDispatch();
  const userId = getUserID();

  const [caseOptions, setCaseOptions] = useState<DropdownOption[]>([]);
  const [projectOptions, setProjectOptions] = useState<DropdownOption[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<DropdownOption[]>([]);
  const [teamOptions, setTeamOptions] = useState<DropdownOption[]>([]);

  const employees = useSelector((state: RootState) => state.employees.employees || []);
  const teams = useSelector((state: RootState) => state.teams.teams || []);
  const cases = useSelector((state: RootState) => state.casesM.cases || []);
  const projects = useSelector((state: RootState) => Object.values(state.projects.projects || {}));

  // Fetch data based on user type
  useEffect(() => {
    // Fetch cases or projects
    if (
      ['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer'].includes(userType)
    ) {
      dispatch(fetchCases());
    } else if (['BusinessAdmin', 'BusinessEmployee'].includes(userType)) {
      dispatch(fetchProjects());
    }

    // Fetch employees and teams for admins
    if (['LawFirmAdmin', 'BusinessAdmin'].includes(userType)) {
      dispatch(fetchAllEmployees());
      dispatch(fetchAllTeams());
    }
  }, [userType, dispatch]);

  // Update options when data changes
  useEffect(() => {
    if (
      ['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer'].includes(userType)
    ) {
      const formattedCases = cases.map((caseItem: any) => ({
        value: caseItem.CaseID,
        label: `${caseItem.CaseType} - ${caseItem.CaseName}`,
      }));
      setCaseOptions(formattedCases);
    } else if (['BusinessAdmin', 'BusinessEmployee'].includes(userType)) {
      const formattedProjects = projects.map((projectItem: any) => ({
        value: projectItem.ProjectID,
        label: `${projectItem.ProjectName}`,
      }));
      setProjectOptions(formattedProjects);
    }
  }, [cases, projects, userType]);

  useEffect(() => {
    if (['LawFirmAdmin', 'BusinessAdmin'].includes(userType)) {
      const formattedEmployees = employees.map((employee: Employee) => ({
        value: employee.User.Email, // Ensure value is a string
        label: `${employee.User.FirstName} ${employee.User.LastName}`,
      }));
      setEmployeeOptions(formattedEmployees);

      const formattedTeams = teams.map((team: any) => ({
        value: team.TeamID,
        label: team.TeamName,
      }));
      setTeamOptions(formattedTeams);
    }
  }, [employees, teams, userType]);

  const validationSchema = Yup.object().shape({
    TaskTitle: Yup.string().required(t('Title is required')),
    TaskDescription: Yup.string().required(t('Description is required')),
    TaskDeadline: Yup.date().required(t('Deadline is required')),
    // Conditionally require AssigneeID if assigning to someone
    ...(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin'
      ? {
          AssigneeType: Yup.string(),
          AssigneeID: Yup.string().when('AssigneeType', {
            is: (AssigneeType: any) => AssigneeType === 'employee' || AssigneeType === 'team',
            then: schema => schema.required(t('Please select an assignee')),
          }),
        }
      : {}),
  });

  const formik = useFormik({
    initialValues: {
      TaskTitle: '',
      TaskDescription: '',
      TaskDeadline: '',
      CaseID: '',
      ProjectID: '',
      AssigneeType: '', // '', 'employee', 'team'
      AssigneeID: '', // UserID or TeamID
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // Create task payload
        const taskData: any = {
          TaskTitle: values.TaskTitle,
          TaskDescription: values.TaskDescription,
          TaskDeadline: values.TaskDeadline,
          ...(values.CaseID && { CaseID: values.CaseID }),
          ...(values.ProjectID && { ProjectID: values.ProjectID }),
          ...(values.AssigneeType === 'team' && { TeamID: values.AssigneeID }),
        };

        const createTaskResponse = await dispatch(createTask(taskData));
        const taskId = createTaskResponse?.TaskID;

        // Assign task if assigning to an employee
        if (values.AssigneeType === 'employee' && taskId) {
          await dispatch(assignTask(taskId, [values.AssigneeID]));
        }

        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: t('Task created successfully'),
            showModal: true,
            duration: 3000,
          })
        );

        if (onAdd) {
          onAdd();
        }

        await dispatch(fetchAllTasks());
        resetForm();
        if (onCancel) {
          onCancel();
        }
      } catch (error) {
        dispatch(
          showFeedbackModal({
            modalType: 'error',
            duration: 3000,
            message: t('Could not add task. Please contact support.'),
            showModal: true,
          })
        );
      }
    },
  });

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className="p-6">
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <h2 className="text-xl font-semibold">{t('Add New Task')}</h2>

        {/* Task Title */}
        <Input
          label={t('Title')}
          name="TaskTitle"
          value={formik.values.TaskTitle}
          onChange={formik.handleChange}
          error={formik.touched.TaskTitle && formik.errors.TaskTitle}
          placeholder={t('Enter task title...')} type={'text'}        />

        {/* Task Description */}
        <Textarea
          label={t('Description')}
          name="TaskDescription"
          value={formik.values.TaskDescription}
          onChange={formik.handleChange}
          // error={formik.touched.TaskDescription && formik.errors.TaskDescription}
          placeholder={t('Enter task description...')} 
          id={'554'}        />

        {/* Task Deadline */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('Deadline')}
          </label>
          <CustomDateTimePicker
            value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
            onChange={(newValue: Dayjs | null) => {
              formik.setFieldValue('TaskDeadline', newValue ? newValue.toISOString() : '');
            }}
            minDateTime={dayjs()}
          />
          {formik.touched.TaskDeadline && formik.errors.TaskDeadline && (
            <p className="mt-2 text-sm text-red-600">{formik.errors.TaskDeadline}</p>
          )}
        </div>

        {/* Case or Project Selection */}
        {['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer'].includes(userType) && (
          <Dropdown
            label={t('Select Case')}
            options={caseOptions}
            onSelect={(option) => formik.setFieldValue('CaseID', option.value)}
            value={formik.values.CaseID}
            placeholder={t('Select a case')}
          />
        )}
        {['BusinessAdmin', 'BusinessEmployee'].includes(userType) && (
          <Dropdown
            label={t('Select Project')}
            options={projectOptions}
            onSelect={(option) => formik.setFieldValue('ProjectID', option.value)}
            value={formik.values.ProjectID}
            placeholder={t('Select a project')}
          />
        )}

        {/* Assignment Options for Admins */}
        {['LawFirmAdmin', 'BusinessAdmin'].includes(userType) && (
          <>
            <Dropdown
              label={t('Assign To')}
              options={[
                { value: '', label: t('Assign to Self') },
                { value: 'employee', label: t('Assign to Employee') },
                { value: 'team', label: t('Assign to Team') },
              ]}
              onSelect={(option) => {
                formik.setFieldValue('AssigneeType', option.value);
                formik.setFieldValue('AssigneeID', '');
              }}
              value={formik.values.AssigneeType}
            />

            {formik.values.AssigneeType === 'employee' && (
              <Dropdown
                label={t('Select Employee')}
                options={employeeOptions}
                onSelect={(option) => formik.setFieldValue('AssigneeID', option.value)}
                value={formik.values.AssigneeID}
                placeholder={t('Select an employee')}
                error={(formik.touched.AssigneeID && formik.errors.AssigneeID) as string}
              />
            )}

            {formik.values.AssigneeType === 'team' && (
              <Dropdown
                label={t('Select Team')}
                options={teamOptions}
                onSelect={(option) => formik.setFieldValue('AssigneeID', option.value)}
                value={formik.values.AssigneeID}
                placeholder={t('Select a team')}
                error={(formik.touched.AssigneeID && formik.errors.AssigneeID) as string}
              />
            )}
          </>
        )}

        {/* Buttons */}
        <div className="flex justify-end space-x-4">
          <Button variant="secondary" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="primary">
            {t('Add Task')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTask;
