import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

function PerformanceSection() {
    const [lastFourYears, setLastFourYears] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState(lastFourYears[0]);
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');
    const { t } = useTranslation()

    useEffect(() => {
        let now = new Date();
        let lastFourYears = [];

        for (let i = 0; i < 3; i++) {
            const year = new Date(now.getFullYear() - i, now.getMonth()).toISOString().slice(0, 4);
            lastFourYears.push(year);
        }
        setLastFourYears(lastFourYears);
    }, [])

    const handleYearChange = (yearId: string) => {
        setSelectedYear(yearId);
    };


    return (
        <>
                        <div className="flex h-calc-100vh-90px  justify-center items-center text-neutral-400">Coming soon</div>
    </>
    )
}

export default PerformanceSection
