import React, { useEffect } from 'react';
import Badge from '../../shared/TailwindComponents/Badge';
import { RootState, useAppDispatch } from '../../../store';
import { fetchEmployeeDetails } from '../../../store/actions/organization/employeeActions';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';

interface EmployeeDetailParams {
  employeeId: string;
}

const EmployeeDetail: React.FC = () => {
  const { employeeId } = useParams<EmployeeDetailParams>();
  const dispatch = useAppDispatch();

  const { employee, loading, error } = useSelector((state: RootState) => state.employees);
  const { setBreadcrumbs } = useBreadcrumbs();

  const employeeStatus = employee?.is_deleted ? 'Inactive' : 'Active';

  useEffect(() => {
    if (!employee || employee.UserID !== Number(employeeId)) {
      dispatch(fetchEmployeeDetails(employeeId));
    }
  }, [dispatch, employeeId, employee]);

  useEffect(() => {
    if (employee) {
      const employeeName = `${employee.FirstName} ${employee.LastName}`;
      setBreadcrumbs([
        { name: 'Management', href: '/management', current: false },
        { name: 'Employees', href: '/management/employees', current: false },
        { name: employeeName, href: `/management/employees/${employeeId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [employee, employeeId, setBreadcrumbs]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader color="#33699f" loading={loading} size={30} />
      </div>
    );
  }

  if (error || !employee) {
    return <div className="text-red-600">Error: {error || 'Employee not found.'}</div>;
  }

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">
          {employee.FirstName} {employee.LastName}
        </h2>
        <Badge color={employeeStatus === 'Active' ? 'green' : 'gray'}>
          {employeeStatus}
        </Badge>
      </div>
      <p>
        <strong>Email:</strong> {employee.Email}
      </p>
      <p>
        <strong>Phone Number:</strong> {employee.PhoneNumber}
      </p>
      <p>
        <strong>Designation:</strong> {employee.Designation || 'N/A'}
      </p>
      <p>
        <strong>Company Role:</strong> {employee.CompanyRole || 'N/A'}
      </p>
      <p>
        <strong>Experience Years:</strong> {employee.ExperienceYears || 'N/A'}
      </p>
      <p>
        <strong>Completed Cases:</strong> {employee.CompletedCases || 'N/A'}
      </p>
      <p>
        <strong>Ratings:</strong> {employee.RatingsAverage || 'N/A'} (
        {employee.RatingsCount || 0} reviews)
      </p>
      <p>
        <strong>Specializations:</strong>{' '}
        {employee.Specializations ? employee.Specializations.join(', ') : 'N/A'}
      </p>
      <p>
        <strong>Bio:</strong> {employee.Bio || 'N/A'}
      </p>
    </div>
  );
};

export default EmployeeDetail;
