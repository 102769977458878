import React, { useContext, useEffect, useState } from 'react';
import PersonalInfoAndProfile, { UserProps } from './PersonalInfoAndProfile';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store';
import { AnyAction } from 'redux';
import { userInformation } from '../../../store/actions/DataAction';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import ApiLoader from '../../ApiLoader';
import { LayoutContext } from '../../../contexts/LayoutContext';
import { getActiveSessions } from '../../../store/actions/SecurityActions';

const AccountSettings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const [data, setData] = useState<UserProps>({
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    StreetAddress: "",
    Email: "",
    ZipCode: "",
    ProfilePicture: "",
    City: "",
    Country: "",
    CountryOfJurisdiction: "Norway",
    State: "",
    DateOfBirth: "",
    // Occupation: ""
  });
  const { setIsContentScrollable } = useContext(LayoutContext);

  useEffect(() => {
    setIsContentScrollable(true);
  
    return () => {
      setIsContentScrollable(true);
    };
  }, [setIsContentScrollable]);
  
  const userCred = localStorage.getItem("token") || sessionStorage.getItem("token");
  let userId = getUserID();
  let userType = getUserType();
  if (userCred) {
    const parsedData = JSON.parse(userCred);
    userId = parsedData.userId;
    userType = parsedData.userType;
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const resp = await dispatch(userInformation());
      dispatch(getActiveSessions())
      if (resp) {
        setData(resp);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId, userType, dispatch]);

  return (
    <div className="flex flex-col min-h-screen p-4 bg-background-light dark:bg-background-dark transition-colors duration-300 h-full">
      {isLoading ? (
        <div className="flex justify-center items-center flex-1">
          <ApiLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <PersonalInfoAndProfile />
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
