import React from 'react';
import Badge from '../../shared/TailwindComponents/Badge'; // Adjust the import path
import { useHistory } from 'react-router-dom';
import { Case } from '../../Marketplace2/shared/types';

interface CaseCardProps {
  caseItem: Case;
}

const CaseCard: React.FC<CaseCardProps> = ({ caseItem }) => {
  const history = useHistory();

  const handleViewCase = () => {
    history.push(`/marketplace/case/${caseItem.CaseID}`);
  };

  const {
    AnonymizedDescription,
    Budget,
    CaseType,
    Categories,
    Deadline,
    Tags,
  } = caseItem;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow hover:shadow-md transition-shadow duration-300 p-6">
      {/* Header with Case Type and Badges */}
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold">{CaseType || 'Case'}</h3>
        {/* Badges */}
        <div className="flex flex-wrap">
          {Budget && (
            <Badge color="green" className="mr-2 mb-2">
              ${Budget}
            </Badge>
          )}
          {Deadline && (
            <Badge color="yellow" className="mb-2">
              {new Date(Deadline).toLocaleDateString()}
            </Badge>
          )}
        </div>
      </div>
      {/* Anonymized Description */}
      <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">{AnonymizedDescription}</p>
      {/* Categories */}
      {Categories && Categories.length > 0 && (
        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-2">Categories</h4>
          <div className="flex flex-wrap">
            {Categories.map((category, index) => (
              <Badge key={index} color="blue" className="mr-2 mb-2">
                {category}
              </Badge>
            ))}
          </div>
        </div>
      )}
      {/* Tags */}
      {Tags && Tags.length > 0 && (
        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-2">Tags</h4>
          <div className="flex flex-wrap">
            {Tags.map((tag, index) => (
              <Badge key={index} color="indigo" className="mr-2 mb-2">
                {tag}
              </Badge>
            ))}
          </div>
        </div>
      )}
      {/* Call to Action */}
      <button
        onClick={handleViewCase}
        className="mt-4 px-4 py-2 bg-primary-500 hover:bg-primary-600 text-white rounded-md"
      >
        View Details
      </button>
    </div>
  );
};

export default CaseCard;
