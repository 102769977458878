import {
    GET_MARKETPLACE_SETTINGS_REQUEST,
    GET_MARKETPLACE_SETTINGS_SUCCESS,
    GET_MARKETPLACE_SETTINGS_FAILURE,
    UPDATE_MARKETPLACE_SETTINGS_REQUEST,
    UPDATE_MARKETPLACE_SETTINGS_SUCCESS,
    UPDATE_MARKETPLACE_SETTINGS_FAILURE,
    UPLOAD_FIRM_LOGO_REQUEST,
    UPLOAD_FIRM_LOGO_SUCCESS,
    UPLOAD_FIRM_LOGO_FAILURE,
    UPLOAD_PROFILE_PICTURE_REQUEST,
    UPLOAD_PROFILE_PICTURE_SUCCESS,
    UPLOAD_PROFILE_PICTURE_FAILURE,
    GET_LAWYER_TOS_REQUEST,
    GET_LAWYER_TOS_SUCCESS,
    GET_LAWYER_TOS_FAILURE,
    UPDATE_LAWYER_TOS_REQUEST,
    UPDATE_LAWYER_TOS_SUCCESS,
    UPDATE_LAWYER_TOS_FAILURE,
  } from '../../types';
  
  interface MarketplaceSettingsState {
    loading: boolean;
    settings: any;
    error: string | null;
    firmLogoUrl: string | null;
    profilePictureUrl: string | null;
    lawyerTOSUrl: string | null;
  }
  
  const initialState: MarketplaceSettingsState = {
    loading: false,
    settings: null,
    error: null,
    firmLogoUrl: null,
    profilePictureUrl: null,
    lawyerTOSUrl: null,
  };
  
  const marketplaceSettingsReducer = (state = initialState, action: any): MarketplaceSettingsState => {
    switch (action.type) {
      case GET_MARKETPLACE_SETTINGS_REQUEST:
      case UPDATE_MARKETPLACE_SETTINGS_REQUEST:
      case UPLOAD_FIRM_LOGO_REQUEST:
      case UPLOAD_PROFILE_PICTURE_REQUEST:
      case GET_LAWYER_TOS_REQUEST:
      case UPDATE_LAWYER_TOS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_MARKETPLACE_SETTINGS_SUCCESS:
      case UPDATE_MARKETPLACE_SETTINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          settings: action.payload,
        };
      case UPLOAD_FIRM_LOGO_SUCCESS:
        return {
          ...state,
          loading: false,
          firmLogoUrl: action.payload.url,
        };
      case UPLOAD_PROFILE_PICTURE_SUCCESS:
        return {
          ...state,
          loading: false,
          profilePictureUrl: action.payload.url,
        };
      case GET_LAWYER_TOS_SUCCESS:
      case UPDATE_LAWYER_TOS_SUCCESS:
        return {
          ...state,
          loading: false,
          lawyerTOSUrl: action.payload.url,
        };
      case GET_MARKETPLACE_SETTINGS_FAILURE:
      case UPDATE_MARKETPLACE_SETTINGS_FAILURE:
      case UPLOAD_FIRM_LOGO_FAILURE:
      case UPLOAD_PROFILE_PICTURE_FAILURE:
      case GET_LAWYER_TOS_FAILURE:
      case UPDATE_LAWYER_TOS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default marketplaceSettingsReducer;
  