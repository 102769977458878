import React, { useState } from 'react';
import { useAppDispatch } from '../../store';
import { getUserType } from '../../utils/authUtils';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import { updateCase } from '../../store/actions/marketplace2/caseActions';

interface FinancialDataSectionProps {
  financialData: any;
  caseId: string;
}

const FinancialDataSection: React.FC<FinancialDataSectionProps> = ({ financialData, caseId }) => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const isLawyer = userType === 'IndependentLawyer';

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(financialData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Dispatch action to update case with new financial data
    dispatch(updateCase(caseId, { FinancialData: formData }));
    setIsEditing(false);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Financial Data</h3>
        {isLawyer && (
          <Button variant="secondary" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        )}
      </div>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {Object.entries(formData || {}).map(([key, value]) => (
          <div key={key}>
            <p className="text-sm font-medium text-gray-500">{key.replace(/([A-Z])/g, ' $1')}</p>
            {isEditing ? (
              <Input
                id={key}
                name={key}
                value={value as string}
                onChange={handleInputChange}
                type="text"
              />
            ) : (
              <p className="text-sm">{value as string}</p>
            )}
          </div>
        ))}
      </div>
      {isEditing && (
        <div className="mt-4 flex justify-end">
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinancialDataSection;
