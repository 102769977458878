import SecuritySettings from "../components/Settings/Security/SecuritySettings";


const SecurityPage = () => {
  return (
    <div className="">
      <SecuritySettings />
    </div>
  );
};

export default SecurityPage;
