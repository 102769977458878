import { CaseData } from '../../../components/Marketplace/Interfaces/ICaseData';
import { ICaseItem } from '../../../components/Marketplace/Interfaces/ICaseItem';
import { IFullCaseData } from '../../../components/Marketplace/Interfaces/IMarketCase';
import { ProposeCaseData } from '../../actions/marketplace/caseActions';
import { SUBMIT_CASE_SUCCESS, SUBMIT_CASE_FAILURE, FETCH_CASE_LIST_SUCCESS, FETCH_CASE_LIST_FAILURE, UPDATE_CASE_STATUS_SUCCESS, UPDATE_CASE_STATUS_FAILURE, FETCH_LCM_LIST_SUCCESS, FETCH_BIDS_FAILURE, FETCH_BIDS_SUCCESS, FETCH_FULL_CASE_SUCCESS, FETCH_FULL_CASE_FAILURE, PROPOSE_CASE_FAILURE, PROPOSE_CASE_SUCCESS, FETCH_URGENCY_LIST, FETCH_STATUS_LIST } from '../../types';
import { BiddingDataProps } from './bidReducer';

export interface CaseState {
  urgencyList: string[];
  statusList: string[];
  caseData: ICaseItem | null;
  caseList: CaseData[];
  error: Error | null;
  proposalData: ProposeCaseData | null,
  LCMList: string[];
  bids: BiddingDataProps[];  
  fullCase: IFullCaseData | null;
}

const initialState: CaseState = {
  urgencyList: [],
  statusList: [],
  caseData: null,
  caseList: [],
  error: null,
  LCMList: [],
  bids: [],
  proposalData: null,
  fullCase: null
};

export const caseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_URGENCY_LIST:
      return {
        ...state,
        urgencyList: action.payload
      };
    case FETCH_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload
      };
    case SUBMIT_CASE_SUCCESS:
      return {
        ...state,
        caseData: action.payload,
      };
    case SUBMIT_CASE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CASE_LIST_SUCCESS:
      return {
        ...state,
        caseList: action.payload,
      };
    case FETCH_CASE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CASE_STATUS_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_CASE_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_LCM_LIST_SUCCESS: {
      return {
        ...state,
        LCMList: action.payload
      }
    }
    case FETCH_BIDS_SUCCESS:
      return {
        ...state,
        bids: action.payload
      };
    case FETCH_BIDS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case FETCH_FULL_CASE_SUCCESS:
      return {
        ...state,
        fullCase: action.payload
      };
    case FETCH_FULL_CASE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
      case PROPOSE_CASE_SUCCESS:
        return {
          ...state,
          proposalData: action.payload,
          error: null,
        };
      case PROPOSE_CASE_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
    default:
      return state;
  }
};
