import React from 'react';

interface ToggleSwitchProps {
  id?: string;
  name?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ id, name, checked, onChange }) => {
  return (
    <label htmlFor={id} className="inline-flex items-center cursor-pointer">
      <span className="relative">
        <input
          type="checkbox"
          id={id}
          name={name}
          className="sr-only"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <div
          className={`block w-10 h-6 rounded-full ${
            checked ? 'bg-primary-600' : 'bg-gray-400'
          }`}
        ></div>
        <div
          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
            checked ? 'transform translate-x-full' : ''
          }`}
        ></div>
      </span>
    </label>
  );
};

export default ToggleSwitch;
