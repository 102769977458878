import React, { useEffect } from 'react';
import { useTimer } from './TimeProvider';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { FaPlay, FaPause, FaStop } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Button from '../shared/TailwindComponents/Button';

const TimeTracker: React.FC<{ showTaskList?: boolean; showActiveListSelector?: boolean }> = ({
  showTaskList = true,
  showActiveListSelector = true,
}) => {
  const {
    time,
    isRunning,
    isPaused,
    selectedItems,
    tasks,
    cases,
    timeEntries,
    startTimer,
    stopTimer,
    pauseTimer,
    resumeTimer,
    setActiveList,
    handleItemClick,
    activeList,
  } = useTimer();

  const { t } = useTranslation();

  const formatTime = (seconds: number | string | null) => {
    if (seconds === null) return 'N/A';
    const totalSeconds = parseFloat(seconds as string);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = Math.floor(totalSeconds % 60);
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  const history = useHistory();

  const formatNumber = (value: string) => {
    return value.toString().replace(/\B(?=(\d{3})+\b)/g, ' ');
  };

  return (
    <div className="w-full mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      {/* Timer Header */}
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100">
            {t('Time Tracker')}
          </h2>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {activeList === 'tasks' ? t('Managing Tasks') : t('Managing Cases')}
          </div>
        </div>
        <div className="flex items-center space-x-4">
                {/* Start/Pause/Resume Button */}
                {isRunning && !isPaused ? (
                  // Timer is running, show Pause button
                  <button
                    onClick={pauseTimer}
                    className={clsx(
                      'flex items-center px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition'
                    )}
                  >
                    <FaPause />
                  </button>
                ) : !isRunning && !isPaused ? (
                  // Timer is stopped, show Start button
                    <button
                      onClick={startTimer}
                      disabled={selectedItems.length === 0}
                      className={clsx(
                        'flex items-center px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition',
                        { 'opacity-50 cursor-not-allowed': selectedItems.length === 0 }
                      )}
                    >
                      <FaPlay />
                    </button>
                ) : isPaused ? (
                  // Timer is paused, show Resume button
                  <button
                    onClick={resumeTimer}
                    className={clsx(
                      'flex items-center px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition'
                    )}
                  >
                    <FaPlay />
                  </button>
                ) : null}

                {/* Stop Button */}
                <button
                  onClick={() => stopTimer('Task completed')}
                  disabled={!isRunning && !isPaused}
                  className={clsx(
                    'flex items-center px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition',
                    { 'opacity-50 cursor-not-allowed': !isRunning && !isPaused }
                  )}
                >
                  <FaStop />
                </button>
              </div>
      </div>

      {/* Timer Display */}
      <div className="flex justify-start mb-6">
        <span
          className={`text-6xl font-mono ${isRunning ? 'text-gray-900 dark:text-white' : 'text-gray-400 dark:text-gray-500'
            }`}
        >
          {formatTime(time)}
        </span>
      </div>

      {/* Active List Selector */}
      {showActiveListSelector && (
        <div className="flex justify-center mb-6">
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveList('tasks')}
              className={clsx(
                'px-4 py-2 rounded-full transition',
                activeList === 'tasks'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              )}
            >
              {t('Tasks')}
            </button>
            <button
              onClick={() => setActiveList('cases')}
              className={clsx(
                'px-4 py-2 rounded-full transition',
                activeList === 'cases'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              )}
            >
              {t('Cases')}
            </button>
          </div>
        </div>
      )}

      {/* List of Tasks or Cases */}
      {showTaskList && !isRunning && !isPaused ? (
        <div className="flex flex-col gap-6">
          {(activeList === 'tasks' ? tasks : cases).length === 0 ? (
            <div className="text-center text-gray-500 dark:text-gray-400">
              {activeList === 'tasks' ? t('No tasks available') : t('No cases available.')}
            </div>
          ) : (
            (activeList === 'tasks' ? tasks : cases).map((item: any) => {
              const itemID = item.TaskID || item.CaseID;
              const isSelected = selectedItems.some((selectedItem) => {
                const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
                return selectedItemID === itemID;
              });

              return (
                <div
                  key={itemID}
                  onClick={() => handleItemClick(item)}
                  className={clsx(
                    'p-4 rounded-lg shadow-md cursor-pointer transition transform hover:scale-105',
                    isSelected
                      ? 'bg-blue-100 dark:bg-blue-900 border border-blue-500'
                      : 'bg-white dark:bg-gray-700 border border-transparent hover:border-gray-300 dark:hover:border-gray-600'
                  )}
                >
                  {/* Render item details here */}
                </div>
              );
            })
          )}
        </div>
      ) : (
        <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
          {timeEntries.length > 0 ? (
            <ul className="space-y-4">
              {timeEntries.filter((item: any) =>  item.status === 'Active').map((entry: any) => (
                <li key={entry.EntryID} className="border-b border-gray-200 dark:border-gray-600 pb-4">
                  <div className="flex justify-between">
                    <span className="font-semibold text-gray-800 dark:text-gray-100">{t('Start Time')}:</span>
                    <span className="text-gray-600 dark:text-gray-300">{entry.start_time || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between mt-2">
                    <span className="font-semibold text-gray-800 dark:text-gray-100">{t('End Time')}:</span>
                    <span className={`text-gray-600 dark:text-gray-300 ${!entry.end_time ? 'italic' : ''}`}>
                      {entry.end_time || t('In Progress')}
                    </span>
                  </div>
                  <div className="mt-2">
                    <span className="font-semibold text-gray-800 dark:text-gray-100">{t('Description')}:</span>
                    <p className="text-gray-600 dark:text-gray-300">{entry.description || t('No description provided')}</p>
                  </div>
                  <div className="mt-2">
                    <span className="font-semibold text-gray-800 dark:text-gray-100">{t('Status')}:</span>
                    <p className="text-gray-600 dark:text-gray-300">{entry.is_paused ? "Paused" : entry.status }</p>
                  </div>
                  {entry.Duration && (
                    <div className="mt-2 flex justify-between">
                      <span className="font-semibold text-gray-800 dark:text-gray-100">{t('Duration')}:</span>
                      <span className="text-gray-600 dark:text-gray-300">{entry.duration.toFixed(2)} {t('hours')}</span>
                    </div>
                  )}
                </li>
              ))
              }
            </ul>
          ) : (
            <div className="text-center text-gray-500 dark:text-gray-400">
              {t('No time entries available')}
            </div>
          )}
        </div>
      )}

    </div>
  );
};

export default TimeTracker;
