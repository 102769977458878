import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getOrganizationSettings, updateOrganizationSettings } from '../../store/actions/organization/CompanyAction';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import SingleFileInput from '../shared/TailwindComponents/SingleFileInput';
import ColorSwatch from '../shared/TailwindComponents/ColorSwatch';
import { getWhiteLabelSettings, updateWhiteLabelSettings } from '../../store/actions/organization/WhiteLabelActions';

interface OrganizationDetails {
  CompanyName: string;
  PhoneNumber: string;
  OfficeAddress: string;
  Website: string;
  ContactEmail: string;
  Description: string;
  Logo: string;
  DocumentLogo: string;
  HeaderLogo: string;
  SidePanelLogoLight: string;
  SidePanelLogoDark: string;
  FontType: string;
  CustomColors: {
    primary: string;
    secondary: string;
  };
}

const CompanySettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const organizationSettings = useSelector(
    (state: RootState) => state.company.settings
  );
  const whitelabelSettings = useSelector(
    (state: RootState) => state.whiteLabel.settings
  );
  const [editingOrgInfo, setEditingOrgInfo] = useState(false);
  const [editingWhitelabel, setEditingWhitelabel] = useState(false);

  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    dispatch(getOrganizationSettings());
    dispatch(getWhiteLabelSettings(userId));
  }, [dispatch, userId]);

  // Organization Information Formik
  const orgInfoFormik = useFormik({
    initialValues: {
      CompanyName: '',
      PhoneNumber: '',
      OfficeAddress: '',
      Website: '',
      ContactEmail: '',
      Description: '',
      CompanyLogo: null as File | null,
    },
    validationSchema: yup.object({
      CompanyName: yup.string().required(t('Company Name is required')),
      ContactEmail: yup
        .string()
        .email(t('Invalid email'))
        .required(t('Contact Email is required')),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('CompanyName', values.CompanyName);
      formData.append('PhoneNumber', values.PhoneNumber);
      formData.append('OfficeAddress', values.OfficeAddress);
      formData.append('Website', values.Website);
      formData.append('ContactEmail', values.ContactEmail);
      formData.append('Description', values.Description);
      if (values.CompanyLogo) {
        formData.append('CompanyLogo', values.CompanyLogo);
      }

      await dispatch(updateOrganizationSettings(formData));
      dispatch(getOrganizationSettings());
      setEditingOrgInfo(false);
    },
  });

  useEffect(() => {
    if (organizationSettings) {
      orgInfoFormik.setValues({
        CompanyName: organizationSettings.OrganizationName || '',
        PhoneNumber: organizationSettings.BusinessContact || '',
        OfficeAddress: organizationSettings.OfficeAddress || '',
        Website: organizationSettings.Website || '',
        ContactEmail: organizationSettings.ContactEmail || '',
        Description: organizationSettings.Description || '',
        CompanyLogo: null,
      });
    }
  }, [organizationSettings]);
  

  // Whitelabel Settings Formik
  const whitelabelFormik = useFormik({
    initialValues: {
      FontType: '',
      CustomColors: {
        primaryColor: '',
        secondaryColor: '',
        accentColor: '',
      },
      DocumentLogo: null as File | null,
      HeaderLogo: null as File | null,
      SidePanelLogoLight: null as File | null,
      SidePanelLogoDark: null as File | null,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      if (values.FontType) {
        formData.append('FontType', values.FontType);
      }
      if (values.CustomColors) {
        formData.append('CustomColors', JSON.stringify(values.CustomColors));
      }
      if (values.DocumentLogo) {
        formData.append('DocumentLogo', values.DocumentLogo);
      }
      if (values.HeaderLogo) {
        formData.append('HeaderLogo', values.HeaderLogo);
      }
      if (values.SidePanelLogoLight) {
        formData.append('SidePanelLogoLight', values.SidePanelLogoLight);
      }
      if (values.SidePanelLogoDark) {
        formData.append('SidePanelLogoDark', values.SidePanelLogoDark);
      }

      await dispatch(updateWhiteLabelSettings(formData));
      dispatch(getWhiteLabelSettings(userId));
      setEditingWhitelabel(false);
    },
  });

  useEffect(() => {
    if (whitelabelSettings) {
      whitelabelFormik.setValues({
        FontType: whitelabelSettings.FontType || '',
        CustomColors: whitelabelSettings.CustomColors || {
          primaryColor: '',
          secondaryColor: '',
          accentColor: '',
        },
        DocumentLogo: null,
        HeaderLogo: null,
        SidePanelLogoLight: null,
        SidePanelLogoDark: null,
      });
    }
  }, [whitelabelSettings]);

  const handleOrgLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      orgInfoFormik.setFieldValue('CompanyLogo', e.target.files[0]);
    }
  };

  const handleWhitelabelLogoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      whitelabelFormik.setFieldValue(field, e.target.files[0]);
    }
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2">
      {/* Organization Information Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Organization Information')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Manage your organization details and contact information.')}
          </p>
        </div>
        <div className="md:col-span-2">
          {editingOrgInfo ? (
            <form onSubmit={orgInfoFormik.handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                {/* Company Logo */}
                <div className="col-span-full">
                  <label className="block text-sm font-medium text-gray-900 dark:text-white">
                    {t('Company Logo')}
                  </label>
                  <div className="mt-2 flex items-center">
                    {orgInfoFormik.values.CompanyLogo ? (
                      <img
                        src={URL.createObjectURL(orgInfoFormik.values.CompanyLogo)}
                        alt="Company Logo"
                        className="h-20 w-20 object-cover rounded-full"
                      />
                    ) : organizationSettings?.Details?.Logo ? (
                      <img
                        src={organizationSettings.Details.Logo}
                        alt="Company Logo"
                        className="h-20 w-20 object-cover rounded-full"
                      />
                    ) : (
                      <span className="h-20 w-20 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-700">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 24H0V0h24v24z" fill="none" />
                          <path d="M12 12c2.76 0 5-2.24 5-5S14.76 2 12 2 7 4.24 7 7s2.24 5 5 5zm0 2c-2.33 0-7 1.17-7 3.5V20h14v-2.5c0-2.33-4.67-3.5-7-3.5z" />
                        </svg>
                      </span>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleOrgLogoChange}
                      className="ml-5 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 py-2 px-3 text-sm leading-4 font-medium text-gray-700 dark:text-gray-200 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    />
                  </div>
                </div>

                {/* Company Name */}
                <div className="sm:col-span-3">
                  <Input
                    label={t('Company Name')}
                    id="CompanyName"
                    name="CompanyName"
                    type="text"
                    value={orgInfoFormik.values.CompanyName}
                    onChange={orgInfoFormik.handleChange}
                    onBlur={orgInfoFormik.handleBlur}
                    error={
                      orgInfoFormik.touched.CompanyName &&
                      Boolean(orgInfoFormik.errors.CompanyName)
                    }
                    helperText={
                      orgInfoFormik.touched.CompanyName &&
                      orgInfoFormik.errors.CompanyName
                    }
                  />
                </div>

                {/* Contact Email */}
                <div className="sm:col-span-3">
                  <Input
                    label={t('Contact Email')}
                    id="ContactEmail"
                    name="ContactEmail"
                    type="email"
                    value={orgInfoFormik.values.ContactEmail}
                    onChange={orgInfoFormik.handleChange}
                    onBlur={orgInfoFormik.handleBlur}
                    error={
                      orgInfoFormik.touched.ContactEmail &&
                      Boolean(orgInfoFormik.errors.ContactEmail)
                    }
                    helperText={
                      orgInfoFormik.touched.ContactEmail &&
                      orgInfoFormik.errors.ContactEmail
                    }
                  />
                </div>

                {/* Phone Number */}
                <div className="sm:col-span-3">
                  <Input
                    label={t('Phone Number')}
                    id="PhoneNumber"
                    name="PhoneNumber"
                    type="text"
                    value={orgInfoFormik.values.PhoneNumber}
                    onChange={orgInfoFormik.handleChange}
                  />
                </div>

                {/* Office Address */}
                <div className="sm:col-span-3">
                  <Input
                    label={t('Office Address')}
                    id="OfficeAddress"
                    name="OfficeAddress"
                    type="text"
                    value={orgInfoFormik.values.OfficeAddress}
                    onChange={orgInfoFormik.handleChange}
                  />
                </div>

                {/* Website */}
                <div className="col-span-full">
                  <Input
                    label={t('Website')}
                    id="Website"
                    name="Website"
                    type="text"
                    value={orgInfoFormik.values.Website}
                    onChange={orgInfoFormik.handleChange}
                  />
                </div>

                {/* Description */}
                <div className="col-span-full">
                  <Textarea
                    label={t('Description')}
                    id="Description"
                    name="Description"
                    value={orgInfoFormik.values.Description}
                    onChange={orgInfoFormik.handleChange}
                  />
                </div>
              </div>
              <div className="mt-8 flex gap-x-4">
                <Button variant="primary" type="submit">
                  {t('Save')}
                </Button>
                <Button variant="destructive" onClick={() => setEditingOrgInfo(false)}>
                  {t('Cancel')}
                </Button>
              </div>
            </form>
          ) : (
            <div>
              {/* Display Organization Information */}
              <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                {/* Company Logo */}
                <div className="col-span-full">
                  {organizationSettings?.Details?.Logo ? (
                    <img
                      src={organizationSettings.Details.Logo}
                      alt="Company Logo"
                      className="h-20 w-20 object-cover rounded-full"
                    />
                  ) : null}
                </div>

                {/* Company Name */}
                <div className="sm:col-span-3">
                  <SectionDisplay
                    title={t('Company Name')}
                    value={organizationSettings?.OrganizationName}
                    />
                </div>

                {/* Contact Email */}
                <div className="sm:col-span-3">
                  <SectionDisplay
                    title={t('Contact Email')}
                    value={organizationSettings?.Details?.BusinessEmail}
                  />
                </div>

                {/* Phone Number */}
                <div className="sm:col-span-3">
                  <SectionDisplay
                    title={t('Phone Number')}
                    value={organizationSettings?.Details?.BusinessContact}
                  />
                </div>

                {/* Office Address */}
                <div className="sm:col-span-3">
                  <SectionDisplay
                    title={t('Office Address')}
                    value={organizationSettings?.Details?.OfficeAddress}
                  />
                </div>

                {/* Website */}
                <div className="col-span-full">
                  <SectionDisplay
                    title={t('Website')}
                    value={organizationSettings?.Details?.Website}
                  />
                </div>

                {/* Description */}
                <div className="col-span-full">
                  <SectionDisplay
                    title={t('Description')}
                    value={organizationSettings?.Details?.Description}
                  />
                </div>
              </div>
              <div className="mt-8 flex">
                <Button variant="primary" onClick={() => setEditingOrgInfo(true)}>
                  {t('Edit')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Whitelabel Settings Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Whitelabel Settings')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Customize the branding of your platform.')}
          </p>
        </div>
        <div className="md:col-span-2">
          {editingWhitelabel ? (
            <form onSubmit={whitelabelFormik.handleSubmit} className="space-y-6">
              {/* Document White Labels Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Document')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('Customize the logos that appear on your documents.')}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <SingleFileInput
                    label={t('Document Logo')}
                    accept="image/*"
                    file={whitelabelFormik.values.DocumentLogo}
                    setFile={(file) => whitelabelFormik.setFieldValue('DocumentLogo', file)}
                  />
                  <SingleFileInput
                    label={t('Document Header Logo')}
                    accept="image/*"
                    file={whitelabelFormik.values.HeaderLogo}
                    setFile={(file) => whitelabelFormik.setFieldValue('HeaderLogo', file)}
                  />
                </div>
              </section>

              {/* Platform White Labels Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Platform')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('Customize the logos and colors that appear on your platform.')}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <SingleFileInput
                    label={t('Side Panel Logo (Light Mode)')}
                    accept="image/*"
                    file={whitelabelFormik.values.SidePanelLogoLight}
                    setFile={(file) => whitelabelFormik.setFieldValue('SidePanelLogoLight', file)}
                  />
                  <SingleFileInput
                    label={t('Side Panel Logo (Dark Mode)')}
                    accept="image/*"
                    file={whitelabelFormik.values.SidePanelLogoDark}
                    setFile={(file) => whitelabelFormik.setFieldValue('SidePanelLogoDark', file)}
                  />
                </div>
              </section>

              {/* Font Type Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Font')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('Choose the font type that aligns with your brand identity.')}
                </p>
                <div>
                  <Dropdown
                    id="FontType"
                    label={t('Font Type')}
                    options={[
                      { value: '', label: t('Select Font') },
                      { value: 'Poppins', label: 'Poppins' },
                      { value: 'Mulish', label: 'Mulish' },
                      // Add more font options as needed
                    ]}
                    value={whitelabelFormik.values.FontType}
                    onChange={(value) => whitelabelFormik.setFieldValue('FontType', value)}
                  />
                </div>
              </section>

              {/* Custom Colors Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Custom Colors')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('Define the custom colors that reflect your brand.')}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Input
                    type="color"
                    id="primaryColor"
                    label={t('Primary Color')}
                    name="primaryColor"
                    value={whitelabelFormik.values.CustomColors.primaryColor || '#000000'}
                    onChange={(e) =>
                      whitelabelFormik.setFieldValue(
                        'CustomColors.primaryColor',
                        e.target.value
                      )
                    }
                  />
                  <Input
                    type="color"
                    id="secondaryColor"
                    label={t('Secondary Color')}
                    name="secondaryColor"
                    value={whitelabelFormik.values.CustomColors.secondaryColor || '#000000'}
                    onChange={(e) =>
                      whitelabelFormik.setFieldValue(
                        'CustomColors.secondaryColor',
                        e.target.value
                      )
                    }
                  />
                  <Input
                    type="color"
                    id="accentColor"
                    label={t('Accent Color')}
                    name="accentColor"
                    value={whitelabelFormik.values.CustomColors.accentColor || '#000000'}
                    onChange={(e) =>
                      whitelabelFormik.setFieldValue(
                        'CustomColors.accentColor',
                        e.target.value
                      )
                    }
                  />
                </div>
              </section>

              <div className="mt-8 flex gap-x-4">
                <Button variant="primary" type="submit">
                  {t('Save')}
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => {
                    setEditingWhitelabel(false);
                    whitelabelFormik.resetForm();
                  }}
                >
                  {t('Cancel')}
                </Button>
              </div>
            </form>
          ) : (
            <div>
              {/* Display Whitelabel Settings */}
              {/* Document White Labels Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Document')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('These logos are used on your documents.')}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Document Logo */}
                  <div>
                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t('Document Logo')}
                    </p>
                    {whitelabelSettings?.DocumentLogo ? (
                      <img
                        src={whitelabelSettings.DocumentLogo}
                        alt="Document Logo"
                        className="mt-1 h-20 object-contain"
                      />
                    ) : (
                      <p className="mt-1">{t('Not Set')}</p>
                    )}
                  </div>
                  {/* Document Header Logo */}
                  <div>
                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t('Document Header Logo')}
                    </p>
                    {whitelabelSettings?.HeaderLogo ? (
                      <img
                        src={whitelabelSettings.HeaderLogo}
                        alt="Document Header Logo"
                        className="mt-1 h-20 object-contain"
                      />
                    ) : (
                      <p className="mt-1">{t('Not Set')}</p>
                    )}
                  </div>
                </div>
              </section>

              {/* Platform White Labels Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Platform')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('These logos and colors are used across your platform.')}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Side Panel Logo (Light Mode) */}
                  <div>
                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t('Side Panel Logo (Light Mode)')}
                    </p>
                    {whitelabelSettings.SidePanelLogoLight ? (
                      <img
                        src={whitelabelSettings.SidePanelLogoLight}
                        alt="Side Panel Logo Light"
                        className="mt-1 h-20 object-contain"
                      />
                    ) : (
                      <p className="mt-1">{t('Not Set')}</p>
                    )}
                  </div>
                  {/* Side Panel Logo (Dark Mode) */}
                  <div>
                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t('Side Panel Logo (Dark Mode)')}
                    </p>
                    {whitelabelSettings.SidePanelLogoDark ? (
                      <img
                        src={whitelabelSettings.SidePanelLogoDark}
                        alt="Side Panel Logo Dark"
                        className="mt-1 h-20 object-contain"
                      />
                    ) : (
                      <p className="mt-1">{t('Not Set')}</p>
                    )}
                  </div>
                </div>
              </section>

              {/* Font Type Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Font')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('Chosen font type for your platform.')}
                </p>
                <div>
                  <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t('Font Type')}
                  </p>
                  <p className="mt-1">
                    {whitelabelSettings?.FontType || t('Not Set')}
                  </p>
                </div>
              </section>

              {/* Custom Colors Section */}
              <section className="mb-8">
                <h3 className="text-xl font-semibold mb-2">{t('Custom Colors')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                  {t('Defined custom colors for your platform.')}
                </p>
                <div className="mt-1">
                  {whitelabelSettings?.CustomColors ? (
                    (Object.entries(
                      whitelabelSettings?.CustomColors
                    ) as [string, string][]).map(([key, value]) => (
                      <ColorSwatch key={key} color={value} label={`${key}: ${value}`} />
                    ))
                  ) : (
                    <p>{t('Not Set')}</p>
                  )}
                </div>
              </section>

              <div className="mt-8 flex">
                <Button variant="primary" onClick={() => setEditingWhitelabel(true)}>
                  {t('Edit')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


interface SectionDisplayProps {
  title: string;
  value?: string;
}

const SectionDisplay: React.FC<SectionDisplayProps> = ({ title, value }) => (
  <div className="flex flex-col">
    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">{title}</label>
    <div className="mt-1 p-2 bg-gray-100 dark:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100">
      {value || 'N/A'}
    </div>
  </div>
);

export default CompanySettings;
