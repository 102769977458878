import React, { useContext, useState } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import Button from '../components/shared/TailwindComponents/Button';
import Alert from '../components/shared/TailwindComponents/Alert';
import Input from '../components/shared/TailwindComponents/Input';
import Textarea from '../components/shared/TailwindComponents/Textarea';
import Badge from '../components/shared/TailwindComponents/Badge';
import Pagination from '../components/shared/TailwindComponents/Pagination';
import Table from '../components/shared/TailwindComponents/Table';
import Dropdown from '../components/shared/TailwindComponents/Dropdown';
import Form from '../components/shared/TailwindComponents/Form';
import Toggle from '../components/shared/TailwindComponents/Toggle';
import Modal from '../components/shared/TailwindComponents/Modal';
import ExclamationIcon from '../components/shared/icons/ExclamationIcon';
import FilterBar from '../components/shared/TailwindComponents/FilterBar';
import Card from '../components/shared/TailwindComponents/Card';
import StatCard from '../components/shared/TailwindComponents/StatCard';
import BidComponent from '../components/shared/TailwindComponents/BidComponent';
import Shadows from '../components/shared/TailwindComponents/Shadows';
import { Tooltip } from '@mui/material';
import { colorHexes } from './colorHexes';
import ColorsSection from '../components/shared/TailwindComponents/ColorSection';
import Stats from '../components/shared/TailwindComponents/Stats';
import Header from '../components/shared/TailwindComponents/Header';
import ListItem from '../components/shared/TailwindComponents/ListItem';
import FilterSearchBar from '../components/shared/TailwindComponents/FilterSearchBar';

type StatItem = {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: 'increase' | 'decrease';
};

type FilterOption = {
  value: string;
  label: string;
  checked?: boolean;
};

type FilterSection = {
  id: string;
  name: string;
  options: FilterOption[];
};

const colors = [
  { name: 'primary', color: 'primary' },
  { name: 'secondary', color: 'secondary' },
  { name: 'success', color: 'success' },
  { name: 'warning', color: 'warning' },
  { name: 'danger', color: 'danger' },
  { name: 'destructive', color: 'destructive' },
  { name: 'disabled', color: 'disabled' },
  { name: 'grayscale', color: 'grayscale' }, 
  { name: 'info', color: 'info' }, 
  { name: 'neutral', color: 'neutral' }, 
];

const filters = [
  {
    label: 'Color',
    options: [
      { value: 'red', label: 'Red' },
      { value: 'blue', label: 'Blue' },
    ],
  },
  {
    label: 'Size',
    options: [  
      { value: 's', label: 'Small' },
      { value: 'm', label: 'Medium' },
      { value: 'l', label: 'Large' },
    ],
  },
];

const sortOptions = [
  { value: 'price_asc', label: 'Price: Low to High' },
  { value: 'price_desc', label: 'Price: High to Low' },
];


const DesignSystem: React.FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [toggleEnabled, setToggleEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('');

  const statsData: StatItem[] = [
    { name: 'Total Cases', stat: '150', previousStat: '120', change: '25%', changeType: 'increase' },
    { name: 'Open Cases', stat: '50', previousStat: '60', change: '16.67%', changeType: 'decrease' },
    { name: 'Closed Cases', stat: '100', previousStat: '60', change: '66.67%', changeType: 'increase' },
  ];

  const filterData: FilterSection[] = [
    {
      id: 'status',
      name: 'Status',
      options: [
        { value: 'open', label: 'Open' },
        { value: 'closed', label: 'Closed' },
        { value: 'pending', label: 'Pending' },
      ],
    },
    {
      id: 'priority',
      name: 'Priority',
      options: [
        { value: 'high', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'low', label: 'Low' },
      ],
    },
  ];

  const sortData = [
    { value: 'date_desc', label: 'Newest' },
    { value: 'date_asc', label: 'Oldest' },
  ];

  
  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };
  
  const handleSortChange = (value: string) => {
    setSortValue(value);
  };
  
  const handleRemoveFilter = (filterName: string, value: string) => {
    setSelectedFilters((prev) => {
      const updatedValues = prev[filterName].filter((v) => v !== value);
      if (updatedValues.length === 0) {
        const { [filterName]: _, ...rest } = prev;
        return rest;
      }
      return {
        ...prev,
        [filterName]: updatedValues,
      };
    });
  };

  const listItems = [
    {
      title: 'Case #12345',
      subtitle: 'Contract dispute',
      details: [
        { label: 'Client', value: 'John Doe' },
        { label: 'Status', value: 'Open' },
      ],
      link: '/cases/12345',
    },
    {
      title: 'Case #67890',
      subtitle: 'Intellectual property',
      details: [
        { label: 'Client', value: 'Jane Smith' },
        { label: 'Status', value: 'Closed' },
      ],
      link: '/cases/67890',
    },
    // Add more items as needed
  ];

  const handleBidSuccess = () => {
    // Additional actions on bid success if needed
  };

  const handleBidFailure = () => {
    // Additional actions on bid failure if needed
  };

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300">
      {/* Theme Toggle */}
      <div className="flex justify-end p-4">
        <Button variant="primary" onClick={toggleTheme}>
          Toggle to {theme === 'light' ? 'Dark' : 'Light'} Mode
        </Button>
      </div>

      {/* Scrollable Container */}
      <div className="container mx-auto p-4 overflow-y-auto max-h-[calc(100vh-160px)]">
      {/* Typography */}
      <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Typography</h2>
          <div className="space-y-4">
            <div>
              <h1 className="text-5xl font-heading">H1 Heading</h1>
              <p className="text-sm text-gray-500">Font Size: 5xl, Font Family: Mulish, Weight: Bold</p>
            </div>
            <div>
              <h2 className="text-4xl font-heading">H2 Heading</h2>
              <p className="text-sm text-gray-500">Font Size: 4xl, Font Family: Mulish, Weight: Bold</p>
            </div>
            <div>
              <h3 className="text-3xl font-heading">H3 Heading</h3>
              <p className="text-sm text-gray-500">Font Size: 3xl, Font Family: Mulish, Weight: Bold</p>
            </div>
            <div>
              <h4 className="text-2xl font-heading">H4 Heading</h4>
              <p className="text-sm text-gray-500">Font Size: 2xl, Font Family: Mulish, Weight: Bold</p>
            </div>
            <div>
              <h5 className="text-xl font-heading">H5 Heading</h5>
              <p className="text-sm text-gray-500">Font Size: xl, Font Family: Mulish, Weight: Bold</p>
            </div>
            <div>
              <h6 className="text-lg font-heading">H6 Heading</h6>
              <p className="text-sm text-gray-500">Font Size: lg, Font Family: Mulish, Weight: Bold</p>
            </div>
            <div>
              <p className="text-base font-sans">Paragraph text</p>
              <p className="text-sm font-sans text-gray-500">Font Size: base, Font Family: Poppins, Weight: Normal</p>
            </div>
            <div>
              <span className="text-sm font-sans">Span text</span>
              <p className="text-sm text-gray-500">Font Size: sm, Font Family: Poppins, Weight: Normal</p>
            </div>
          </div>
        </section>

        {/* Colors */}
        <section className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Colors</h2>
      <div className="space-y-4">
        {colors.map((color) => (
          <div key={color.name}>
            <h3 className="text-xl font-semibold mb-2">
              {color.name.charAt(0).toUpperCase() + color.name.slice(1)} Colors
            </h3>
            <div className="flex space-x-2 overflow-x-auto pb-2">
              {['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'].map((shade) => {
                const shadeClass = `bg-${color.color}-${shade}`;
                // const hexCode = colorHexes[color.color][shade] || '#FFFFFF'; // Fallback to white if not found
                return (
                  <Tooltip
                    key={shade}
                    title={`${color.name.charAt(0).toUpperCase() + color.name.slice(1)} ${shade}`}
                    arrow
                    placement="top"
                  >
                    <div
                      className={`flex-shrink-0 w-16 h-16 rounded ${shadeClass} border border-gray-300 dark:border-gray-700 cursor-pointer transition-transform transform hover:scale-105`}
                    >
                      <span className="sr-only">{`${color.name} ${shade}`}</span>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      {/* <ColorsSection colors={[]} /> */}
    </section>
        {/* Buttons */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Buttons</h2>
          <div className="flex flex-wrap gap-4">
            <Button variant="primary">Primary Button</Button>
            <Button variant="secondary">Secondary Button</Button>
            <Button variant="success">Success Button</Button>
            <Button variant="warning">Warning Button</Button>
            <Button variant="danger">Danger Button</Button>
            <Button variant="disabled" disabled>
              Disabled Button
            </Button>
          </div>
        </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Badges</h2>
            <div className="flex flex-wrap gap-2">
              <Badge color="gray">Gray</Badge>
              <Badge color="red">Red</Badge>
              <Badge color="yellow">Yellow</Badge>
              <Badge color="green">Green</Badge>
              <Badge color="blue">Blue</Badge>
              <Badge color="indigo">Indigo</Badge>
              <Badge color="purple">Purple</Badge>
              <Badge color="pink">Pink</Badge>
              <Badge color="gray" onClose={() => {}}>Dismissible</Badge>
            </div>
          </section>

        {/* Shadows */}

          <Shadows />

        {/* Alerts */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Alerts</h2>
          <div className="space-y-4">
            {/* Success Alert with Sub-message and Action */}
            <Alert
              type="success"
              message="Success! Your operation completed successfully."
              subMessage="You can view more details in your dashboard."
              actions={[
                { label: 'View Dashboard', onClick: () => alert('Navigating to Dashboard...'), variant: 'primary' },
              ]}
              onClose={() => {
                // Handle close action
                alert('Success alert closed.');
              }}
            />

            {/* Warning Alert with Action */}
            <Alert
              type="warning"
              message="Warning! Please check your input."
              actions={[
                { label: 'Review Inputs', onClick: () => alert('Reviewing Inputs...'), variant: 'secondary' },
              ]}
              onClose={() => {
                // Handle close action
                alert('Warning alert closed.');
              }}
            />

            {/* Danger Alert with Multiple Actions */}
            <Alert
              type="error"
              message="Error! Something went wrong."
              subMessage="Please try again or contact support."
              actions={[
                { label: 'Retry', onClick: () => alert('Retrying...'), variant: 'primary' },
                { label: 'Contact Support', onClick: () => alert('Contacting Support...'), variant: 'secondary' },
              ]}
              onClose={() => {
                // Handle close action
                alert('Danger alert closed.');
              }}
            />

            {/* Info Alert with No Actions */}
            <Alert
              type="info"
              message="FYI! Your profile was updated."
              subMessage="No further action is required."
            />

            {/* Neutral Alert with Dismiss Button */}
            <Alert
              type="neutral"
              message="Notice! New features have been added."
              actions={[
                { label: 'Learn More', onClick: () => alert('Learning More...'), variant: 'primary' },
              ]}
              onClose={() => {
                // Handle close action
                alert('Neutral alert closed.');
              }}
            />
          </div>
        </section>

      {/* Inputs */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Inputs and Textareas</h2>
        <div className="space-y-4">
          <Input type="text" id="text-input" label="Text Input" placeholder="Enter text here" />
          <Input type="password" id="password-input" label="Password Input" placeholder="Enter password" />
          <Input type="email" id="email-input" label="Email Input" placeholder="Enter email" />
          <Textarea id="message-input" label="Message" placeholder="Enter your message" />
        </div>
      </section>

        {/* Category Filters */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Category Filters</h2>
            <FilterBar
              filters={filters}
              sortOptions={sortOptions}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              onSortChange={handleSortChange}
              onRemoveFilter={handleRemoveFilter}
            />
          </section>
          
      {/* Tables */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Tables with Pagination</h2>
        <Table
          columns={['Name', 'Role', 'Practice Area', 'Email']}
          data={[
            {
              id: 1,
              Name: 'John Doe',
              Role: 'Senior Attorney',
              'Practice Area': 'Corporate Law',
              Email: 'john.doe@lawfirm.com',
              isCurrent: false,
            },
            {
              id: 2,
              Name: 'Jane Smith',
              Role: 'Associate Attorney',
              'Practice Area': 'Intellectual Property',
              Email: 'jane.smith@lawfirm.com',
              isCurrent: true,
            },
            {
              id: 3,
              Name: 'Michael Brown',
              Role: 'Paralegal',
              'Practice Area': 'Family Law',
              Email: 'michael.brown@lawfirm.com',
              isCurrent: false,
            },
            {
              id: 4,
              Name: 'Emily Davis',
              Role: 'Legal Secretary',
              'Practice Area': 'Litigation',
              Email: 'emily.davis@lawfirm.com',
              isCurrent: false,
            },
            {
              id: 5,
              Name: 'William Johnson',
              Role: 'Junior Attorney',
              'Practice Area': 'Criminal Law',
              Email: 'william.johnson@lawfirm.com',
              isCurrent: false,
            },
          ]}
        />
        <Pagination
          currentPage={1}
          totalPages={5}
          onPageChange={(page) => {
            // Handle page change
          }}
        />
      </section>
          {/* Forms */}
    <section className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Forms</h2>
      <Form />
    </section>

     {/* Header */}
     <section className="mb-8">
        <Header
          title="Cases (150)"
          subtitle="Manage all your cases here."
          actionLabel="Add Case"
          onActionClick={() => alert('Open side panel to add a new case')}
        />
      </section>

      {/* Stats */}
      <section className="mb-8">
        <Stats stats={statsData} title="Last 30 days" />
      </section>

      {/* Filter Bar */}
      <section className="mb-8">
        <FilterSearchBar
          filters={filterData}
          sortOptions={sortData.map((option) => ({ ...option, current: option.value === sortValue }))}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          onSearchChange={() => {}}
        />
      </section>

      {/* List Items */}
      <section className="mb-8">
        <div className="space-y-4">
          {listItems.map((item) => (
            <ListItem
              key={item.title}
              title={item.title}
              subtitle={item.subtitle}
              details={item.details}
              link={item.link}
            />
          ))}
        </div>
      </section>
    
      {/* Modal */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Confirmation Modals</h2>
        <Button variant="primary" onClick={() => setIsModalOpen(true)}>
          Open Confirmation Modal
        </Button>
        
        <Modal
          isOpen={isModalOpen}
          title="Delete Item"
          subtitle="Are you sure you want to delete this item? This action cannot be undone."
          icon={<ExclamationIcon />}
          onConfirm={() => {
            // Handle confirm action
            console.log('Item deleted');
            setIsModalOpen(false);
          }}
          onCancel={() => setIsModalOpen(false)}
          confirmLabel="Delete"
          cancelLabel="Cancel"
          variant="danger"
        />
      </section>

            {/* Stat Cards */}
            <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Stat Cards</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Simple Stat Card */}
          <StatCard
            title="Total Sales"
            value="$12,345"
            change="5%"
            changeType="increase"
            description="Compared to last month"
            icon={
              <svg
                className="w-6 h-6 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                {/* Replace with your icon */}
                <path d="M3 3h2v12H3V3zm4 2h2v10H7V5zm4 4h2v6h-2v-6zm4-4h2v10h-2V5z" />
              </svg>
            }
          />
          {/* Stat Card with Dark Variant */}
          <StatCard
            title="New Users"
            value="1,234"
            change="2%"
            changeType="decrease"
            description="Since last week"
            icon={
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                {/* Replace with your icon */}
                <path d="M10 2a6 6 0 016 6v2a6 6 0 01-12 0V8a6 6 0 016-6z" />
              </svg>
            }
          />
          {/* Stat Card with Trending Icon */}
          <StatCard
            title="Active Sessions"
            value="567"
            change="8%"
            changeType="increase"
            description="Compared to last day"
            icon={
              <svg
                className="w-6 h-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                {/* Replace with your icon */}
                <path d="M3 17h14v2H3v-2zm0-5h10v2H3v-2zm0-5h14v2H3V7zm0-5h10v2H3V2z" />
              </svg>
            }
          />
          {/* Stat Card with Brand Icon */}
          <StatCard
            title="Followers"
            value="45k"
            description="On social media"
            icon={
              <svg
                className="w-6 h-6 text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                {/* Replace with your brand icon */}
                <path d="M10 2a6 6 0 016 6v2a6 6 0 01-12 0V8a6 6 0 016-6z" />
              </svg>
            }
          />
          {/* Stat Card with Shared Borders */}
          <div className="grid grid-cols-2 divide-x rounded-lg overflow-hidden border">
            <StatCard
              title="Likes"
              value="12k"
              icon={
                <svg
                  className="w-6 h-6 text-pink-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  {/* Like icon */}
                  <path d="M3 8a4 4 0 014-4h1V2a1 1 0 112 0v2h2a4 4 0 014 4v4h-3v6H6V8H3z" />
                </svg>
              }
            />
            <StatCard
              title="Comments"
              value="3k"
              icon={
                <svg
                  className="w-6 h-6 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  {/* Comment icon */}
                  <path d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10l-4-4H4a2 2 0 01-2-2V5z" />
                </svg>
              }
            />
          </div>
        </div>
          </section>

        {/* Bid Component */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Bid on a Case</h2>
          <BidComponent
            caseId="case-123"
            onBidSuccess={handleBidSuccess}
            onBidFailure={handleBidFailure}
          />
        </section>

      {/* Grid System */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Grid System</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-primary-100 p-4">Grid Item 1</div>
          <div className="bg-secondary-100 p-4">Grid Item 2</div>
          <div className="bg-success-100 p-4">Grid Item 3</div>
          <div className="bg-warning-100 p-4">Grid Item 4</div>
          <div className="bg-danger-100 p-4">Grid Item 5</div>
          <div className="bg-destructive-100 p-4">Grid Item 6</div>
        </div>
      </section>

        {/* Containers */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Containers</h2>
          <div className="space-y-4">
            <div className="container-example">
              <p>This is a container with light shadow.</p>
            </div>
            <div className="container-example dark:bg-gray-700 text-white">
              <p>This is a container with dark shadow.</p>
            </div>
          </div>
        </section>

      {/* Cards */}
      <section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Cards</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <Card
      title="Cozy Apartment"
      price="$1,200/month"
      location="San Francisco, CA"
      status="Available"
      imageUrl="https://via.placeholder.com/400x300"
      meta={[
        { label: 'Bedrooms', value: '2' },
        { label: 'Bathrooms', value: '1' },
      ]}
    />
    <Card
      title="Modern Office Space"
      price="$2,500/month"
      location="New York, NY"
      status="Occupied"
      meta={[
        { label: 'Area', value: '1500 sqft' },
        { label: 'Floor', value: '5th' },
      ]}
    />
  </div>
</section>
      <section className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Toggles</h2>
      <Toggle enabled={toggleEnabled} onToggle={() => setToggleEnabled(!toggleEnabled)} />
    </section>
        {/* Dropdowns */}
        <section className="mb-25">
        <h2 className="text-2xl font-bold mb-4">Dropdowns</h2>
        <div className="space-y-4">
          <Dropdown
            options={[
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' },
              { value: 'option3', label: 'Option 3' },
            ]}
            placeholder="Select an option"
          />
          <Dropdown
            options={[
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' },
              { value: 'option3', label: 'Option 3' },
            ]}
            multiple
            placeholder="Select options"
          />
        </div>
      </section>
        </div>
    </div>
  );
};

export default DesignSystem;
