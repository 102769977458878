import { Dispatch } from 'redux';
import { IAddProjectPayload, IProject } from '../../components/Projects/ProjectsInterfaces';
import apis from '../../utils/apis';
import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_FAILURE, EDIT_PROJECT_REQUEST, EDIT_PROJECT_SUCCESS, EDIT_PROJECT_FAILURE, ADD_PROJECT_ASSIGNEE_REQUEST, ADD_PROJECT_ASSIGNEE_SUCCESS, ADD_PROJECT_ASSIGNEE_FAILURE, FETCH_PROJECTS_REQUEST, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECT_DETAILS_REQUEST, FETCH_PROJECT_DETAILS_SUCCESS, FETCH_PROJECT_DETAILS_FAILURE, DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE, APPROVE_PROJECT_REQUEST, APPROVE_PROJECT_SUCCESS, APPROVE_PROJECT_FAILURE, FETCH_USER_PROJECTS_REQUEST, } from '../types';
import { showFeedbackModal } from './UserFeedbackActions';



// Action Creator for Adding a Project
export const addProject = (data: any) => {
  return async (dispatch: Dispatch<any>): Promise<IProject | null> => {
    dispatch({ type: ADD_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/add_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_PROJECT_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        dispatch({ type: ADD_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to add project' });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data?.message || 'Failed to add project',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } catch (err: any) {
      dispatch({ type: ADD_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to add project' });
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: err.response?.data?.message || 'Failed to add project',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
    return null;
  };
};

// Action Creator for Editing a Project
export const editProject = (data: IAddProjectPayload) => {
  return async (dispatch: Dispatch<any>): Promise<IProject | null> => {
    dispatch({ type: EDIT_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'PUT',
        url: '/projects/edit_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: EDIT_PROJECT_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        dispatch({ type: EDIT_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to edit project' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to edit project',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: EDIT_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to edit project' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to edit project',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return null;
  };
};

// Action Creator for Adding Project Assignees
export const addProjectAssignee = (data: { ProjectID: string; Assignees: string[] }) => {
  return async (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({ type: ADD_PROJECT_ASSIGNEE_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/addProjectAssignee',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_PROJECT_ASSIGNEE_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        dispatch({ type: ADD_PROJECT_ASSIGNEE_FAILURE, payload: resp.data?.message || 'Failed to add assignees' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to add assignees',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: ADD_PROJECT_ASSIGNEE_FAILURE, payload: err.response?.data?.message || 'Failed to add assignees' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to add assignees',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return null;
  };
};

export const fetchProjects = () => {
  return async (dispatch: Dispatch<any>): Promise<IProject[] | null> => {
    dispatch({ type: FETCH_PROJECTS_REQUEST });
    try {
      const resp = await apis({
        method: 'GET',
        url: 'projects/get_projects',
        // data,
      });

      if (resp && resp.status === 200) {
        const mappedProjects: IProject[] = resp.data.Projects.map((project: any) => ({
          ProjectID: project.ProjectID,
          UserType: project.UserType,
          AllocatedServices: project.AllocatedServices,
          ProjectDescription: project.ProjectDescription || '',
          CategoryType: project.CategoryType || '',
          CategoryName: project.CategoryName || '',
          EndDate: project.EndDate || '',
          PriorityLevel: project.PriorityLevel || '',
          PriorityName: project.PriorityName || '',
          ProjectApprover: project.ProjectApprover || null,
          ProjectLead: project.ProjectLead || null,
          ProjectMembers: project.ProjectMembers || [],
          ProjectName: project.ProjectName || '',
          StartDate: project.StartDate || '',
          Status: project.Status || '',
          StatusID: project.StatusID || '',
          CreatedAt: project.CreatedAt || '',
          ApprovedBy: project.ApprovedBy || {},
        }));

        dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: mappedProjects });
        return mappedProjects;
      } else {
        dispatch({ type: FETCH_PROJECTS_FAILURE, payload: resp.data?.message || 'Failed to fetch projects' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to fetch projects',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: FETCH_PROJECTS_FAILURE, payload: err.response?.data?.message || 'Failed to fetch projects' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to fetch projects',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return null;
  };
};

// Action Creator for Fetching Project Details
export const fetchProjectDetails = (data: { ProjectID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<IProject | null> => {
    dispatch({ type: FETCH_PROJECT_DETAILS_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/get_project_details',
        data,
      });


      if (resp && resp.status === 200) {
        const project = resp.data.projectDetails;
        const mappedProject: IProject = {
          ProjectID: project.ProjectID,
          UserType: project.UserType,
          AllocatedServices: project.AllocatedServices,
          ProjectDescription: project.ProjectDescription || '',
          CategoryType: project.CategoryType || '',
          CategoryName: project.CategoryName || '',
          EndDate: project.EndDate || '',
          PriorityLevel: project.PriorityLevel || '',
          PriorityName: project.PriorityName || '',
          ProjectApprover: project.ProjectApprover || null,
          ProjectLead: project.ProjectLead || null,
          ProjectMembers: project.ProjectMembers || [],
          ProjectName: project.ProjectName || '',
          StartDate: project.StartDate || '',
          Status: project.Status || '',
          StatusID: project.StatusID || '',
          CreatedAt: project.CreatedAt || '',
          ApprovedBy: project.ApprovedBy || {},
          ProjectDocuments: project.ProjectDocuments || [],
        };

        dispatch({ type: FETCH_PROJECT_DETAILS_SUCCESS, payload: mappedProject });
        return mappedProject;
      } else {
        dispatch({ type: FETCH_PROJECT_DETAILS_FAILURE, payload: resp.data?.message || 'Failed to fetch project details' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to fetch project details',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: FETCH_PROJECT_DETAILS_FAILURE, payload: err.response?.data?.message || 'Failed to fetch project details' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to fetch project details',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return null;
  };
};

// Action Creator for Deleting a Project
export const deleteProject = (data: { ProjectID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<boolean> => {
    dispatch({ type: DELETE_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'DELETE',
        url: '/projects/delete_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: DELETE_PROJECT_SUCCESS, payload: data }); 
        return true;
      } else {
        dispatch({ type: DELETE_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to delete project' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to delete project',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: DELETE_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to delete project' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to delete project',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return false;
  };
};

// Action Creator for Approving a Project
export const approveProject = (data: { ProjectID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<boolean> => {
    dispatch({ type: APPROVE_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/approve_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: APPROVE_PROJECT_SUCCESS, payload: resp.data });
        return true;
      } else {
        dispatch({ type: APPROVE_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to approve project' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to approve project',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: APPROVE_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to approve project' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to approve project',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return false;
  };
};

// Action Creator for Fetching User's Projects
export const fetchUserProjects = () => {
  return async (dispatch: Dispatch<any>): Promise<IProject[] | null> => {
    dispatch({ type: FETCH_USER_PROJECTS_REQUEST });
    try {
      const resp = await apis({
        method: 'GET',
        url: '/projects/get_member_projects',
      });

      if (resp && resp.status === 200) {
        const mappedProjects = resp.data.Projects.map((project: any) => ({
          ProjectID: project.ProjectID,
          UserType: project.UserType,
          AllocatedServices: project.AllocatedServices,
          ProjectDescription: project.ProjectDescription || '',
          CategoryType: project.CategoryType || '',
          CategoryName: project.CategoryName || '',
          EndDate: project.EndDate || '',
          PriorityLevel: project.PriorityLevel || '',
          PriorityName: project.PriorityName || '',
          ProjectApprover: project.ProjectApprover || null,
          ProjectLead: project.ProjectLead || null,
          ProjectMembers: project.ProjectMembers || [],
          ProjectName: project.ProjectName || '',
          StartDate: project.StartDate || '',
          Status: project.Status || '',
          StatusID: project.StatusID || '',
          CreatedAt: project.CreatedAt || '',
          ApprovedBy: project.ApprovedBy || {},
        }));

        dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: mappedProjects });
        return mappedProjects;
      } else {
        dispatch({ type: FETCH_PROJECTS_FAILURE, payload: resp.data?.message || 'Failed to fetch user projects' });
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to fetch user projects',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch({ type: FETCH_PROJECTS_FAILURE, payload: err.response?.data?.message || 'Failed to fetch user projects' });
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to fetch user projects',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return null;
  };
};

export const recordAIServiceUsage = (data: { ProjectID: string; ServiceID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/record_ai_service_usage',
        data,
      });

      if (resp && resp.status === 200) {
        // Optionally dispatch a success action
        return true;
      } else {
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data?.message || 'Failed to record AI service usage',
          modalType: 'error',
          duration: 3000,
        }));
      }
    } catch (err: any) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: err.response?.data?.message || 'Failed to record AI service usage',
        modalType: 'error',
        duration: 3000,
      }));
    }
    return false;
  };
};
