import { useEffect, useRef, useState } from 'react';
import PaymentModal from '../components/Modal/PaymentModal';
import './FirstPage.css'
import VisualizationCanvas from '../components/SignUpSection/VisualizationCanva';
import AuthPage from '../components/Auth/AuthPage';
import { useHistory } from 'react-router-dom';

const FirstPage = () => {
  const [currentSection, setCurrentSection] = useState("signup");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isLoginExpanded, setIsLoginExpanded] = useState(false);
  const isDevEnvironment = window.location.hostname.includes('dev.pons.io');
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io')
    || window.location.hostname.includes('beta.pons.io');
    const userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  const accessTokenRef = useRef<string | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (userCred) {
      let data = JSON.parse(userCred);
      accessTokenRef.current = data?.token;
    }
    if (accessTokenRef.current) {
        history.push("/dashboard")
    }else {
      // Set loading to false to display the login form if no access token is found
      setLoading(false);
    }
  }, [userCred, history]);


  const [isLoginExpanded, setIsLoginExpanded] = useState(isDemoEnvironment);

  const changeSection = (section: string) => {
    setCurrentSection(section);
  };

  const handlePaymentModalClose = () => {
    setPaymentModalVisible(false);
  };
  // SocketServices.initializeSocket()
  if (loading) {
    return <div></div>; // Optionally, replace with a spinner component
  }
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', transition: 'all 0.3s ease' }} className="first-page">
      {isExpanded && !isDemoEnvironment && (
        <i onClick={() => setIsExpanded(false)} className='fas fa-chevron-right icon-hover-effect'></i>
      )}
      <div style={{ width: (isLoginExpanded || isDemoEnvironment) ? '100%' : (isExpanded ? '0%' : '47%'), transition: 'width 0.3s ease', overflowY: 'hidden' }} className="first-page__left">
        <AuthPage />
      </div>
      {1 > 2 && (
        <div
          style={{ width: isLoginExpanded ? '0%' : (isExpanded ? '100%' : '53%'), transition: 'width 0.3s ease', overflowY: 'auto', background: '#0D0D12' }}
          className="first-page__right"
        >
                    <VisualizationCanvas />
          {/* {currentSection === "signup" && <SignUpSection changeSection={changeSection} setPaymentModalVisible={setPaymentModalVisible} setIsExpanded={setIsExpanded} />}
          {currentSection === "terms" && <TermsConditions changeSection={changeSection} />}
          {currentSection === "privacy" && <PrivacyPolicy changeSection={changeSection} />} */}
        </div>
      )}
      <PaymentModal isOpen={paymentModalVisible} onClose={handlePaymentModalClose} />
    </div>
  );
};

export default FirstPage;