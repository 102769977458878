import React from 'react';
import clsx from 'clsx';

interface DividerProps {
  message?: string;
  className?: string;
}

const Divider: React.FC<DividerProps> = ({ message, className }) => {
  return (
    <div className={clsx('flex items-center my-4', className)}>
      <div className="flex-grow h-px bg-gray-300 dark:bg-gray-600"></div>
      {message && <span className="mx-2 text-sm text-gray-500 dark:text-gray-400">{message}</span>}
      <div className="flex-grow h-px bg-gray-300 dark:bg-gray-600"></div>
    </div>
  );
};

export default Divider;
