import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';

interface NewOtpInputProps {
  onSubmit: (data: string | File) => void;
  handleClose: () => void;
  method: string | undefined;
}

const NewOtpInput: React.FC<NewOtpInputProps> = ({ onSubmit, handleClose, method }) => {
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const errorMessage = useSelector((state: RootState) => state.otp.otpErrorMessage);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input
      if (value !== '' && index < 6 - 1) {
        const nextSibling = document.getElementById(`otp-${index + 1}`);
        if (nextSibling) {
          (nextSibling as HTMLInputElement).focus();
        }
      }
    }
  };

  const handleSubmit = () => {
    const otpValue = otp.join('');
    if (onSubmit) {
      onSubmit(otpValue);
    }
  };

  const handleClear = () => {
    setOtp(Array(6).fill(''));
  };

  return (
    <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-md max-w-md mx-auto">
      <div className="text-center mb-4">
        <h1 className="text-2xl font-semibold text-gray-800">
          {method === 'email'
            ? `${t('Verify your')} ${t(method.charAt(0).toUpperCase() + method.slice(1))}`
            : method === t('sms')
            ? `${t('Verify your')} ${method.toUpperCase()}`
            : `${t("Verify your Account")}`}
        </h1>
        <p className="text-gray-600 mt-2">
          {(method === 'email' || method === 'sms')
            ? t("Please enter the 6-digit code")
            : t("Please enter the 6-digit OTP from your authenticator app.")}
        </p>
      </div>

      <div className="flex justify-center space-x-2 mb-4">
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp-${index}`}
            type="text"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            maxLength={1}
            className="w-12 h-12 border border-gray-300 text-center text-xl font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        ))}
      </div>

      {errorMessage && (
        <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
      )}

      <div className="flex space-x-4">
        <Button
          onClick={handleSubmit}
          variant='primary'
        >
          {t('Verify')}
        </Button>
        <Button
          onClick={handleClear}
          variant='neutral'
        >
          {t('Clear')}
        </Button>
      </div>
    </div>
  );
};

export default NewOtpInput;
