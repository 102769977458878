import {
    SET_TIME_ENTRIES,
    START_TIMER,
    STOP_TIMER,
    PAUSE_TIMER,
    RESUME_TIMER,
    SET_TIMER_RUNNING_STATE,
    SET_TIMER,
    SET_PAUSED_STATE,
  } from "../types";
  
  export interface TimeEntry {
    EntryID: string;
    UserID: number;
    CaseID: string;
    TaskID: string;
    StartTime: string;
    EndTime: string;
    Duration: number;
    Description: string;
    Status: string;
  }
  
  
  interface TimeManagementState {
    timeEntries: TimeEntry[];
    isRunning: boolean;
    time: number; 
    isPaused: boolean; 
  }
  
  const initialState: TimeManagementState = {
    timeEntries: [],
    isRunning: false,
    time: 0,
    isPaused: false,
  };
  
  export const timeManagementReducer = (
    state = initialState,
    action: any
  ): TimeManagementState => {
    switch (action.type) {
      case SET_TIME_ENTRIES:
        return {
          ...state,
          timeEntries: action.payload,
        };
      case START_TIMER:
      case RESUME_TIMER:
        return {
          ...state,
          isRunning: true,
          isPaused: false,
        };
      case STOP_TIMER:
        return {
          ...state,
          isRunning: false,
          isPaused: false,
          time: 0,
        };
      case PAUSE_TIMER:
        return {
          ...state,
          isRunning: false,
          isPaused: true,
        };
      case SET_TIMER_RUNNING_STATE:
        return {
          ...state,
          isRunning: action.payload,
        };
      case SET_TIMER:
        return {
          ...state,
          time: action.payload,
        };
      case SET_PAUSED_STATE:
        return {
          ...state,
          isPaused: action.payload,
        };
      default:
        return state;
    }
  };
  