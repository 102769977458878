
import { ChatGroupData, FETCH_SERVICES_SUCCESS } from "../actions/ChatAction";
import {
  FETCH_CHATGROUPS_SUCCESS,
  FETCH_CONTRACTS_SUCCESS,
  SET_CHAT_GROUP_ID,
  SET_DOCUMENT_VISIBILITY,
  SET_FOLDER_VISIBILITY,
  SET_SELECTED_MODEL,
} from "../types";

interface Action {
  type: string;
  payload: any;
}

const getInitialModel = () => {
  const storedModel = localStorage.getItem('selectedModel');
  return storedModel ? storedModel : 'Law Research';
};

const getInitialChatGroupID = () => {
  // return localStorage.getItem('chatGroupID') || '';
};

const initialState = {
  selectedModel: getInitialModel(),
  chatGroupID: getInitialChatGroupID(),
  isFolderStructureVisible: true,
  isDocumentVisible: true,
  contracts: [],  
  chatGroups: [] as ChatGroupData[],
  services: [],

};

const modelReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.payload,
      };
    case SET_CHAT_GROUP_ID:
      return {
        ...state,
        chatGroupID: action.payload,
      };
    case SET_FOLDER_VISIBILITY:
      return {
        ...state,
        isFolderStructureVisible: action.payload,
      };
    case SET_DOCUMENT_VISIBILITY:
      return {
        ...state,
        isDocumentVisible: action.payload,
      };
    case FETCH_CONTRACTS_SUCCESS: 
      return {
        ...state,
        contracts: action.payload
      };
    case FETCH_CHATGROUPS_SUCCESS: 
      return {
        ...state,
        chatGroups: action.payload
      };
      case FETCH_SERVICES_SUCCESS:
        return {
          ...state,
          services: action.payload,
        };
        case 'ADD_CHAT_GROUP':
          return {
            ...state,
            chatGroups: [action.payload, ...state.chatGroups],
          };
    default:
      return state;
  }
};

export default modelReducer;
