import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../shared/TailwindComponents/Table';

interface EmployeeTimeTableProps {
  employeeTimeData: any[];
  timeInterval: 'daily' | 'weekly' | 'monthly' | 'yearly';
}

const EmployeeTimeTable: React.FC<EmployeeTimeTableProps> = ({ employeeTimeData, timeInterval }) => {
  const { t } = useTranslation();

  // Define columns based on the time interval
  const columns = ['Employee Name', 'Total Duration', 'Tasks', 'Cases'];
  if (timeInterval === 'daily') columns.splice(1, 0, 'Date');
  if (timeInterval === 'weekly') columns.splice(1, 0, 'Week Start');
  if (timeInterval === 'monthly') columns.splice(1, 0, 'Month');
  if (timeInterval === 'yearly') columns.splice(1, 0, 'Year');

  // Map data based on the time interval
  const data = employeeTimeData.flatMap((employee) => 
    employee.data.map((entry: any) => ({
      'Employee Name': entry.user_name,
      ...(timeInterval === 'daily' && { Date: new Date(entry.date).toLocaleDateString() }),
      ...(timeInterval === 'weekly' && { 'Week Start': new Date(entry.week_start).toLocaleDateString() }),
      ...(timeInterval === 'monthly' && { Month: new Date(entry.year, entry.month - 1).toLocaleString('default', { month: 'long' }) }),
      ...(timeInterval === 'yearly' && { Year: entry.year }),
      'Total Duration': `${entry.duration.toFixed(2)} ${t('hours')}`,
      Tasks: entry.task_id ? entry.task_id.join(', ') : t('No tasks'),
      Cases: entry.case_id ? entry.case_id.join(', ') : t('No cases'),
    }))
  );

  return <Table columns={columns} data={data} />;
};

export default EmployeeTimeTable;
