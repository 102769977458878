import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getLawyers } from '../../../store/actions/marketplace2/marketplaceActions';
import { Lawyer } from '../shared/types';
import LawyerCard from '../../shared/TailwindComponents/LawyerCard';
import SearchBar from '../../shared/TailwindComponents/SearchBar';
import SortBar from '../../shared/TailwindComponents/SortBar';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { ClipLoader } from 'react-spinners';
import Filters from '../../shared/TailwindComponents/Filters';

const LawyersList: React.FC = () => {
  const dispatch = useAppDispatch();
  const lawyers = useSelector((state: RootState) => state.marketplace.lawyers) as Lawyer[];
  const error = useSelector((state: RootState) => state.marketplace.error);
  const isLoading = useSelector((state: RootState) => state.marketplace.isLoadingLawyers);
  const currentPage = useSelector((state: RootState) => state.marketplace.currentPage);
  const totalPages = useSelector((state: RootState) => state.marketplace.totalPages);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortedLawyers, setSortedLawyers] = useState<Lawyer[]>([]);
  const [sortKey, setSortKey] = useState<'FirstName' | 'LastName' | 'RatingsAverage' | 'ExperienceYears'>('FirstName');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const params = {
      page,
      per_page: 10,
      Specialization: filters.Specialization,
      MinExperience: filters.MinExperience,
      MaxHourlyRate: filters.MaxHourlyRate,
    };
    dispatch(getLawyers(params));
  }, [dispatch, page, filters]);

  useEffect(() => {
    // Filter and sort lawyers
    let filteredLawyers = lawyers;

    // Filter by search term
    if (searchTerm) {
      filteredLawyers = filteredLawyers.filter((lawyer) =>
        `${lawyer.FirstName} ${lawyer.LastName}`.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sort the lawyers
    filteredLawyers.sort((a, b) => {
      let aValue: any = a[sortKey] || 0;
      let bValue: any = b[sortKey] || 0;

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedLawyers(filteredLawyers);
  }, [lawyers, searchTerm, sortKey, sortOrder]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Lawyers</h1>
      {/* Search, Sort, and Filter Bars */}
      <div className="flex flex-wrap justify-between items-center mb-4">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search lawyers..." />
        <SortBar<'FirstName' | 'LastName' | 'RatingsAverage' | 'ExperienceYears'>
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          options={[
            { value: 'FirstName', label: 'First Name' },
            { value: 'LastName', label: 'Last Name' },
            { value: 'RatingsAverage', label: 'Ratings' },
            { value: 'ExperienceYears', label: 'Experience' },
          ]}
        />
        <Filters filters={filters} setFilters={setFilters} />
      </div>

      {/* Lawyers List */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader color="#000" loading={true} size={30} />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 gap-y-20 mt-20">
            {sortedLawyers.map((lawyer) => (
              <LawyerCard key={lawyer.UserID} lawyer={lawyer} />
            ))}
          </div>

          {/* Pagination Controls */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </>
      )}
    </div>
  );
};

export default LawyersList;

  