import { SET_SESSIONS } from "../types";

const initialState = {
    sessions: []
};

const SecurityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SESSIONS:
      return {
        ...state,
        sessions: action.sessions
      };
   
    default:
      return state;
  }
};

export default SecurityReducer;
