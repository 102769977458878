import { useContext, useEffect } from "react";
import Projects from "../components/Projects/Projects";
import { LayoutContext } from "../contexts/LayoutContext";

function ProjectsPage() {
    const { setIsContentScrollable } = useContext(LayoutContext);

    useEffect(() => {
        setIsContentScrollable(false);
    
        // Reset to default when unmounting
        return () => {
          setIsContentScrollable(true);
        };
      }, [setIsContentScrollable]);
    
    return (
        <div>
                <Projects />
        </div>
    )
}

export default ProjectsPage;