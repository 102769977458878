import { AnyAction } from 'redux';
import {
  RUN_ANALYSIS_REQUEST,
  RUN_ANALYSIS_SUCCESS,
  RUN_ANALYSIS_FAILURE,
  FETCH_ANALYSIS_SUCCESS,
  FETCH_ANALYSIS_FAILURE,
  SET_ANALYSIS_PROGRESS,
  FETCH_PROGRESS_REQUEST,
  FETCH_PROGRESS_SUCCESS,
  FETCH_PROGRESS_FAILURE,
  CLEAR_ANALYSIS_DATA,
} from '../../actions/marketplace2/caseAnalysisActions';

interface AnalysisResults {
  [key: string]: string; 
}

interface ProgressStep {
  step: string;
  status: string;
}

interface AnalysisState {
  isFetching: boolean;
  isRunning: boolean;
  analysisData: AnalysisResults | null;
  error: string | null;
  progressSteps: ProgressStep[];
  analysisResults: AnalysisResults;
}

const initialState: AnalysisState = {
  isFetching: false,
  isRunning: false,
  analysisData: null,
  error: null,
  progressSteps: [],
  analysisResults: {},
};

export const caseAnalysisReducer = (state = initialState, action: AnyAction): AnalysisState => {
  switch (action.type) {
    case RUN_ANALYSIS_REQUEST:
      return {
        ...state,
        isRunning: true,
        error: null,
        progressSteps: [],
        analysisResults: {},
      };
    case SET_ANALYSIS_PROGRESS:
      return {
        ...state,
        progressSteps: action.payload.progress || [],
        analysisResults: action.payload.analysis_results || {},
      };
    case RUN_ANALYSIS_SUCCESS:
      return {
        ...state,
        isRunning: false,
        analysisData: action.payload,
        error: null,
      };
    case RUN_ANALYSIS_FAILURE:
      return {
        ...state,
        isRunning: false,
        error: action.payload,
      };
    case FETCH_PROGRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_PROGRESS_SUCCESS:
      const isCompleted = action.payload.progress.every(
        (step: any) => step.status === 'Completed'
      );
      return {
        ...state,
        isFetching: false,
        progressSteps: action.payload.progress,
        analysisData: isCompleted ? action.payload.analysis_results : null,
        isRunning: !isCompleted,
        error: null,
      };
    case FETCH_PROGRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isRunning: false,
        error: action.payload,
      };
    case CLEAR_ANALYSIS_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default caseAnalysisReducer;
