import { AnyAction } from 'redux';
import { DropdownOption } from '../../components/shared/GlobalDropdown';
import { ADD_SIGNATURE_FAILURE, ADD_SIGNATURE_REQUEST, ADD_SIGNATURE_SUCCESS, ANONYMIZE_DOCUMENT_FAILURE, ANONYMIZE_DOCUMENT_REQUEST, ANONYMIZE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE, DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS, FETCH_ANONYMIZATION_DATASETS_FAILURE, FETCH_ANONYMIZATION_DATASETS_REQUEST, FETCH_ANONYMIZATION_DATASETS_SUCCESS, FETCH_DOCUMENT_VERSIONS_FAILURE, FETCH_DOCUMENT_VERSIONS_REQUEST, FETCH_DOCUMENT_VERSIONS_SUCCESS, FETCH_DOCUMENTS_FAILURE, FETCH_DOCUMENTS_REQUEST, FETCH_DOCUMENTS_SUCCESS, FETCH_FILE_DETAILS_FAILURE, FETCH_FILE_DETAILS_REQUEST, FETCH_FILE_DETAILS_SUCCESS, FETCH_RECOMMENDED_DOCUMENTS_FAILURE, FETCH_RECOMMENDED_DOCUMENTS_REQUEST, FETCH_RECOMMENDED_DOCUMENTS_SUCCESS, FETCH_TRANSLATION_LANGUAGES_FAILURE, FETCH_TRANSLATION_LANGUAGES_REQUEST, FETCH_TRANSLATION_LANGUAGES_SUCCESS, RESET_SELECTED_DOCUMENT, SELECT_DOCUMENT_FOR_EDITING, TRANSLATE_DOCUMENT_FAILURE, TRANSLATE_DOCUMENT_REQUEST, TRANSLATE_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAILURE, UPLOAD_DOCUMENT_SUCCESS, SIGN_FILE_FAILURE, SIGN_FILE_REQUEST, SIGN_FILE_SUCCESS } from '../types';
import { FileProps } from '../../components/Documents/types';

interface DocumentState {
    loading: boolean;
    documents: FileProps[];
    recommendedDocuments: FileProps[];
    error: string;
    selectedDocument: FileProps | null;
    signature: string | null;
    uploadStatus: string | null;
    anonymizationDatasets: AnonymizeState[];
    anonymizeStatus: string | null;
    selectedFile: FileProps | null;
    loadingFileDetails: boolean;
    fileDetailsError: string | null;
    documentVersions: VersionProps[]; // New state property
    loadingVersions: boolean;
    versionsError: string | null;
    translationLanguages: DropdownOption[];
    translationLoading: boolean;
    translationError: string | null;
    translationResult: any;
    signedDocument: boolean
}

interface VersionProps {
  BlobURL: string;
  LastModified: string;
  VersionID: string;
}

export interface AnonymizeState {
  label: string;
  key: string;
}

const initialState: DocumentState = {
    loading: true,
    documents: [],
    recommendedDocuments: [],
    error: "",
    selectedDocument: null,
    signature: null,
    uploadStatus: null,
    anonymizationDatasets: [],
    anonymizeStatus: null,
    selectedFile: null,
    loadingFileDetails: false,
    fileDetailsError: null,
    documentVersions: [], // Initialize as empty array
    loadingVersions: false,
    versionsError: null,
    translationLanguages: [],
    translationLoading: false,
    translationError: null,
    translationResult: null,
    signedDocument: false
};

const documentReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case FETCH_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DOCUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: action.payload,
                error: ''
            };
        case FETCH_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_RECOMMENDED_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_RECOMMENDED_DOCUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                recommendedDocuments: action.payload,
                error: ''
            };
        case FETCH_RECOMMENDED_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: [...state.documents, action.payload],
                error: ''
            };
        case CREATE_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case DELETE_DOCUMENT_REQUEST:
            return { ...state, loading: true };
        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: state.documents.filter(doc => doc.FileID !== action.payload),
                error: ''
            };
        case DELETE_DOCUMENT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SELECT_DOCUMENT_FOR_EDITING:
            return {
                ...state,
                selectedDocument: action.payload,
            };

        case RESET_SELECTED_DOCUMENT:
            return {
                ...state,
                selectedDocument: null,
            };
        case ADD_SIGNATURE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signature: action.payload,
                error: '',
            };
        case ADD_SIGNATURE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            case UPLOAD_DOCUMENT_SUCCESS:
                return {
                  ...state,
                  uploadStatus: 'success',
                };
              case UPLOAD_DOCUMENT_FAILURE:
                return {
                  ...state,
                  uploadStatus: 'failure',
                };
                case FETCH_ANONYMIZATION_DATASETS_REQUEST:
                    return {
                      ...state,
                      loading: true,
                      error: '',
                    };
                  case FETCH_ANONYMIZATION_DATASETS_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      anonymizationDatasets: action.payload,
                      error: '',
                    };
                  case FETCH_ANONYMIZATION_DATASETS_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.payload,
                    };
                  case ANONYMIZE_DOCUMENT_REQUEST:
                    return {
                      ...state,
                      loading: true,
                      anonymizeStatus: null,
                      error: '',
                    };
                  case ANONYMIZE_DOCUMENT_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      anonymizeStatus: 'success',
                      error: '',
                    };
                  case ANONYMIZE_DOCUMENT_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      anonymizeStatus: 'failure',
                      error: action.payload,
                    };
                    case FETCH_FILE_DETAILS_REQUEST:
                        return {
                          ...state,
                          loadingFileDetails: true,
                          fileDetailsError: null,
                        };
                      case FETCH_FILE_DETAILS_SUCCESS:
                        return {
                          ...state,
                          loadingFileDetails: false,
                          selectedFile: action.payload, 
                          fileDetailsError: null,
                        };
                      case FETCH_FILE_DETAILS_FAILURE:
                        return {
                          ...state,
                          loadingFileDetails: false,
                          fileDetailsError: action.payload,
                        };
                        case FETCH_DOCUMENT_VERSIONS_REQUEST:
                          return {
                              ...state,
                              loadingVersions: true,
                              versionsError: null,
                          };
                      case FETCH_DOCUMENT_VERSIONS_SUCCESS:
                          return {
                              ...state,
                              loadingVersions: false,
                              documentVersions: action.payload,
                              versionsError: null,
                          };
                      case FETCH_DOCUMENT_VERSIONS_FAILURE:
                          return {
                              ...state,
                              loadingVersions: false,
                              versionsError: action.payload,
                          };
                          case FETCH_TRANSLATION_LANGUAGES_REQUEST:
                            return {
                              ...state,
                              translationLoading: true,
                              translationError: null,
                            };
                          case FETCH_TRANSLATION_LANGUAGES_SUCCESS:
                            return {
                              ...state,
                              translationLoading: false,
                              translationLanguages: action.payload,
                              translationError: null,
                            };
                          case FETCH_TRANSLATION_LANGUAGES_FAILURE:
                            return {
                              ...state,
                              translationLoading: false,
                              translationError: action.payload,
                            };
                      
                          case TRANSLATE_DOCUMENT_REQUEST:
                            return {
                              ...state,
                              translationLoading: true,
                              translationError: null,
                              translationResult: null,
                            };
                          case TRANSLATE_DOCUMENT_SUCCESS:
                            return {
                              ...state,
                              translationLoading: false,
                              translationResult: action.payload, 
                              translationError: null,
                            };
                          case TRANSLATE_DOCUMENT_FAILURE:
                            return {
                              ...state,
                              translationLoading: false,
                              translationError: action.payload,
                              translationResult: null,
                            };
                          case SIGN_FILE_REQUEST:
                            return {
                              ...state,
                              signedDocuemnt: false,
                            };
                          case SIGN_FILE_SUCCESS:
                            return {
                              ...state,
                              signedDocuemnt: true,
                            };
                          case SIGN_FILE_FAILURE:
                            return {
                              ...state,
                              signedDocuemnt: false,
                            };
              
        default:
            return state;
    }
};

export default documentReducer;
