
import Clients from "../components/Clients/Clients";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../contexts/LayoutContext";

const ClientPortalPage = () => {
  const { setIsContentScrollable } = useContext(LayoutContext);

  useEffect(() => {
      setIsContentScrollable(false);
  
      // Reset to default when unmounting
      return () => {
        setIsContentScrollable(true);
      };
    }, [setIsContentScrollable]);
  

    return (
      <>
        <Clients />
      </>
    );
  };
  
  export default ClientPortalPage;
  