import { SET_FONT_SIZE } from "../types";



export interface SetFontSizeAction {
  type: typeof SET_FONT_SIZE;
  payload: { fontSize: string };
}

export const setFontSize = (fontSize: string): SetFontSizeAction => ({
  type: SET_FONT_SIZE,
  payload: { fontSize },
});
