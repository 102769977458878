import React, { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import Header from '../shared/TailwindComponents/Header';
import Button from '../shared/TailwindComponents/Button';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  clearAnalysisData,
  fetchAnalysisProgress,
  runCaseAnalysis,
} from '../../store/actions/marketplace2/caseAnalysisActions';
import { fetchCaseById, generateDocument } from '../../store/actions/marketplace2/caseActions';
import ProgressStepper from './ProgressStepper';
import { getUserType } from '../../utils/authUtils';
import SplitButton from '../shared/TailwindComponents/SplitButton';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

interface RouteParams {
  caseSlug: string;
}

const AnalysisPage: React.FC = () => {
  const { caseSlug } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userType = getUserType();
  
  const analysisState = useSelector((state: RootState) => state.caseAnalysis);
  const caseItem = useSelector((state: RootState) => state.casesM.selectedCase);
  const documentUrls = useSelector((state: RootState) => state.casesM.documentUrls);
  const isGeneratingDocument = useSelector((state: RootState) => state.casesM.isGeneratingDocument);
  const caseId = caseItem.CaseID;
  
  const pollingRef = useRef<NodeJS.Timeout | null>(null);
  const analysisStateRef = useRef(analysisState);
  let sectionOrder: string[] = [];

  if (['LawFirmLawyer', 'IndependentLawyer', 'LawFirmEmployee'].includes(userType)) {
    sectionOrder = [
      'applicable_laws',
      'interpretation',
      'legal_analysis',
      'legal_strategy',
      'case_report',
    ];
  } else if (analysisState.analysisData) {
    sectionOrder = Object.keys(analysisState.analysisData);
    
    if (sectionOrder.includes('final_summary')) {
      sectionOrder = sectionOrder.filter((key) => key !== 'final_summary');
      sectionOrder.push('final_summary');
    }
  } else {
    sectionOrder = []; 
  }

  
  useEffect(() => {
    analysisStateRef.current = analysisState;
  }, [analysisState]);



// const startPolling = () => {
//   let attempts = 0;
//   const pollInterval = 5000;
//   const maxAttempts = 60;
//   console.log('Poll started running')
//   const poll = async () => {
//     attempts++;
//     try {
//       console.log('Poll fetching analysis...')
//       dispatch(fetchAnalysisProgress(caseId));

//       const isCompleted = analysisStateRef.current.progressSteps.every(
//         (step: any) => step.status === 'Completed'
//       );

//       if (!isCompleted && attempts < maxAttempts) {
//         pollingRef.current = setTimeout(poll, pollInterval);
//       } else {
//         if (pollingRef.current) {
//           clearTimeout(pollingRef.current);
//         }
//       }
//     } catch (error) {
//       if (attempts < maxAttempts) {
//         pollingRef.current = setTimeout(poll, pollInterval);
//       } else {
//         if (pollingRef.current) {
//           clearTimeout(pollingRef.current);
//         }
//       }
//     }
//   };

//   poll();
// };

const startPolling = (dispatch: ThunkDispatch<RootState, undefined, AnyAction>, caseId: string) => {
  let attempts = 0;
  const pollInterval = 5000;
  const maxAttempts = 60;

  const poll = async () => {
    attempts++;
    console.log(`Polling attempt ${attempts}...`);

    try {
      await dispatch(fetchAnalysisProgress(caseId));

      const isCompleted = analysisStateRef.current.progressSteps.every(
        (step: any) => step.status === 'Completed'
      );

      if (!isCompleted && attempts < maxAttempts) {
        pollingRef.current = setTimeout(poll, pollInterval);
      } else if (pollingRef.current) {
        clearTimeout(pollingRef.current);
      }
    } catch (error) {
      if (attempts < maxAttempts) {
        pollingRef.current = setTimeout(poll, pollInterval);
      } else if (pollingRef.current) {
        clearTimeout(pollingRef.current);
      }
    }
  };

  poll();
};


  useEffect(() => {
    if (!caseItem) {
      dispatch(fetchCaseById(caseId));
    } else {
      dispatch(fetchAnalysisProgress(caseId));
      dispatch(generateDocument(caseId)); 
    }

    return () => {
      dispatch(clearAnalysisData());
      if (pollingRef.current) {
        clearTimeout(pollingRef.current);
      }
    };
  }, [dispatch, caseId, caseItem]);

  useEffect(() => {
    if (analysisState.analysisData || analysisState.error) {
      if (pollingRef.current) {
        clearTimeout(pollingRef.current);
        pollingRef.current = null;
      }
    }
  }, [analysisState.analysisData, analysisState.error]);

  const handleRunAnalysis = async () => {
    // Clear previous analysis data
    await dispatch(clearAnalysisData());

    // Start the analysis
    dispatch(runCaseAnalysis(caseId));

    // Start polling
    startPolling(dispatch, caseId);
  };

  const handleReanalyze = () => {
    handleRunAnalysis();
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleGenerateDocument = () => {
    dispatch(generateDocument(caseId));
  };

  const handleDownloadPDF = () => {
    if (documentUrls?.pdf_url) {
      window.open(documentUrls.pdf_url, '_blank');
    }
  };
  
  const handleDownloadDocx = () => {
    if (documentUrls?.docx_url) {
      window.open(documentUrls.docx_url, '_blank');
    }
  };

  const CodeBlock = ({ inline, className, children, ...props }: any) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter
        style={darcula}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 p-6">
      <Header
        title={`Analysis for ${caseItem?.CaseName || `Case ${caseItem?.CaseID}`}`}
        actionLabel={
          analysisState.isRunning
            ? 'Running Analysis...'
            : analysisState.analysisData
            ? 'Re-analyze'
            : 'Run Analysis'
        }
        onActionClick={
          analysisState.isRunning ? undefined : handleRunAnalysis
        }
        additionalActions={[
          {
            label: 'Back',
            onClick: handleBack,
          },
        ]}
      />

      {analysisState.isRunning ? (
        // Display progress
        <div className="mt-8">
          <ProgressStepper steps={analysisState.progressSteps} />
        </div>
      ) : analysisState.error ? (
        // Display error message
        <div className="flex flex-col items-center justify-center mt-8">
          <p className="text-red-500">Error: {analysisState.error}</p>
          <Button onClick={handleRunAnalysis}>Retry Analysis</Button>
        </div>
      ) : analysisState.analysisData ? (
        // Display analysis results
        <div className="mt-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Analysis Results</h3>
            <SplitButton
                onGenerate={handleGenerateDocument}
                onDownloadPDF={handleDownloadPDF}
                onDownloadDocx={handleDownloadDocx}
                isGenerating={isGeneratingDocument}
                pdfAvailable={!!documentUrls?.pdf_url}
                docxAvailable={!!documentUrls?.docx_url}
              />

          </div>
          <div className="space-y-6">
            {Object.entries(analysisState.analysisData).map(([key, value]) => (
              <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
                <h4 className="text-lg font-medium capitalize">{key.replace('_', ' ')}</h4>
                <div className="mt-2 text-sm prose dark:prose-dark">
                <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code: CodeBlock,
                }}
              >
                {value as string}
              </ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // No analysis data yet
        <div className="flex flex-col items-center justify-center mt-8">
          <p>No analysis has been run for this case.</p>
        </div>
      )}
    </div>
  );
};

export default AnalysisPage;
