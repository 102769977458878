import React from 'react';
import { useHistory } from 'react-router-dom';
import Badge from './Badge';
import { BadgeItem } from './types/badgeTypes';

type ListItemProps = {
  title: string;
  subtitle?: string;
  details?: { label: string; value: string }[];
  badges?: BadgeItem[];
  link: string;
  onClick?: () => void;
};

const ListItem: React.FC<ListItemProps> = ({ title, subtitle, details, badges, link, onClick }) => {
  const history = useHistory();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(link);
    }
  };

  return (
    <div
      className="bg-white dark:bg-gray-800 shadow hover:shadow-md transition-shadow duration-300 rounded-lg p-4 cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
        {/* Badges */}
        {badges && (
          <div className="flex flex-wrap">
            {badges.map((badge, index) => (
              <Badge key={index} color={badge.color} className="ml-2 mb-1">
                {badge.label}
              </Badge>
            ))}
          </div>
        )}
      </div>
      {subtitle && <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">{subtitle}</p>}
      {details && (
        <div className="mt-4 flex justify-between">
          {details.map((detail) => (
            <div key={detail.label}>
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">{detail.label}</dt>
              <dd className="text-sm text-gray-900 dark:text-white">{detail.value}</dd>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListItem;
