import React, { useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAppDispatch } from '../store';
import { toggleSidebar } from '../store/actions/SidebarActions';
import { ThemeContext } from '../contexts/ThemeContext';

const GlobalShortcuts: React.FC = () => {
  const dispatch = useAppDispatch();
  const { toggleTheme } = useContext(ThemeContext); 

  useHotkeys(
    'mod+b',
    (event) => {
      event.preventDefault();
      dispatch(toggleSidebar());
    },
    [dispatch]
  );

  // Command/Ctrl + Shift + L: Toggle Theme
  useHotkeys(
    'mod+j',
    (event) => {
      event.preventDefault();
      toggleTheme();
    },
    [toggleTheme]
  );

  return null; // This component doesn't render anything
};

export default GlobalShortcuts;
