import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import { Employee, EmployeeActionTypes } from '../../../components/Management/Employees/types';
import { FETCH_EMPLOYEES_REQUEST, FETCH_EMPLOYEES_SUCCESS, FETCH_EMPLOYEES_FAILURE, ONBOARD_EMPLOYEE_REQUEST, ONBOARD_EMPLOYEE_SUCCESS, ONBOARD_EMPLOYEE_FAILURE, FETCH_EMPLOYEE_DETAILS_FAILURE, FETCH_EMPLOYEE_DETAILS_REQUEST, FETCH_EMPLOYEE_DETAILS_SUCCESS } from '../../types';
import { getUserType } from '../ChatAction';
import { showFeedbackModal } from '../UserFeedbackActions';

export const fetchAllEmployees = () => {
  return async (dispatch: Dispatch<EmployeeActionTypes>) => {
    dispatch({ type: FETCH_EMPLOYEES_REQUEST });
    try {
      const response = await apis.get('/users/all_employees');
      if (response && response.status === 200) {
        const employees: Employee[] = response.data.employees || [];
        dispatch({
          type: FETCH_EMPLOYEES_SUCCESS,
          payload: employees,
        });
      } else {
        dispatch({
          type: FETCH_EMPLOYEES_FAILURE,
          payload: 'Failed to fetch employees.',
        });
      }
    } catch (error: any) {
      console.error('Error fetching employees:', error);
      dispatch({
        type: FETCH_EMPLOYEES_FAILURE,
        payload: error.message || 'An error occurred while fetching employees.',
      });
    }
  };
};
  
  export const onboardEmployee = (employeeData: any) => {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      const state = getState();
      const userType = state.user.userType || getUserType();
  
      dispatch({ type: ONBOARD_EMPLOYEE_REQUEST });
      try {
        let response;
  
        // Set the UserTypeID based on the admin's type
        if (userType === 'LawFirmAdmin') {
          employeeData.UserTypeID = '5'; // LawFirmEmployee
          response = await apis.post('/user/onboard_lawfirm_employee', employeeData);
        } else if (userType === 'BusinessAdmin') {
          employeeData.UserTypeID = '3'; // BusinessEmployee
          response = await apis.post('/businessadmin/onboard-employee', employeeData);
        } else {
          throw new Error('User does not have permission to onboard employees.');
        }
  
        if (response && response.status === 200) {
          dispatch({
            type: ONBOARD_EMPLOYEE_SUCCESS
          });
          dispatch(
            showFeedbackModal({
              modalType: 'success',
              message: 'Employee onboarded successfully.',
              showModal: true,
              duration: 3000,
            })
          );
          // Refresh employee list
          dispatch(fetchAllEmployees());
        } else {
          dispatch({
            type: ONBOARD_EMPLOYEE_FAILURE,
            payload: 'Failed to onboard employee.',
          });
        }
      } catch (error: any) {
        console.error('Error onboarding employee:', error);
        dispatch({
          type: ONBOARD_EMPLOYEE_FAILURE,
          payload:
            error.message || 'An error occurred while onboarding employee.',
        });
        dispatch(
          showFeedbackModal({
            modalType: 'error',
            message:
              error.message || 'An error occurred while onboarding employee.',
            showModal: true,
            duration: 3000,
          })
        );
      }
    };
  };

export const fetchEmployeeDetails = (employeeId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_EMPLOYEE_DETAILS_REQUEST });
  try {
    const response = await apis.post('/users/employee_details', { EmployeeUserID: employeeId });
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};
