import { TaskActionTypes, TaskState } from "../../components/Tasks/types";
import {
    FETCH_TASKS,
    FETCH_TASK_DETAILS,
    CREATE_TASK_SUCCESS,
    ASSIGN_TASK_SUCCESS,
    UPDATE_TASK_SUCCESS,
    DELETE_TASK_SUCCESS,
    COMPLETE_TASK_SUCCESS,
  } from "../types";
  
  const initialState: TaskState = {
    tasks: [],
    taskDetails: null,
    loading: false,
    error: null,
  };
  
  export const taskReducer = (state = initialState, action: TaskActionTypes): TaskState => {
    switch (action.type) {
      case FETCH_TASKS:
        return { ...state, tasks: action.payload };
      case FETCH_TASK_DETAILS:
        return { ...state, taskDetails: action.payload };
        case UPDATE_TASK_SUCCESS:
          return {
            ...state,
            taskDetails: { ...state.taskDetails, ...action.payload },
          };
      case CREATE_TASK_SUCCESS:
        return {
          ...state,
          tasks: [...state.tasks, action.payload],
        };
      case ASSIGN_TASK_SUCCESS:
      case COMPLETE_TASK_SUCCESS:
        return {
          ...state,
          tasks: state.tasks.map(task =>
            task.TaskID === action.payload.TaskID ? action.payload : task
          ),
        };
      case DELETE_TASK_SUCCESS:
        return {
          ...state,
          tasks: state.tasks.filter(task => task.TaskID !== action.payload),
        };
        
      default:
        return state;
    }
  };
  