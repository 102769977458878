import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import {
  getPreferences,
  updatePreferences,
} from '../../../store/actions/DataAction';
import {
  getUserID,
  getUserType,
} from '../../../store/actions/ChatAction';
import { changeLanguage } from '../../../i18n/i18n';
import {
  fetchPlatformCurrencies,
  fetchPlatformLanguages,
} from '../../../store/actions/DropdownActions';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import { ThemeContext } from '../../../contexts/ThemeContext';
import SignatureModal from '../Security/DigitalSignature';
import Button from '../../shared/TailwindComponents/Button';
import ThemeToggle from '../../shared/TailwindComponents/ThemeToggle';

const PreferencesSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { theme, setTheme } = useContext(ThemeContext); // Access ThemeContext

  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const platformCurrencies = useSelector(
    (state: RootState) => state.dropdown.platformCurrencies
  ) as DropdownOption[];
  const platformLanguages = useSelector(
    (state: RootState) => state.dropdown.platformLanguages
  ) as DropdownOption[];

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const [selectedTheme, setSelectedTheme] = useState<string>('light');

  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    dispatch(getPreferences());
    dispatch(fetchPlatformCurrencies());
    dispatch(fetchPlatformLanguages());
  }, [dispatch, userType]);

// PreferencesSection.tsx

useEffect(() => {
  if (preferences) {
    const language = platformLanguages.find(
      (lang) => lang.value === preferences.Language
    );
    setSelectedLanguage(language?.value || 'en'); 

    const currency = platformCurrencies.find(
      (curr) => curr.value === preferences.Currency
    );
    setSelectedCurrency(currency?.value || 'USD'); 

    setSelectedTheme(preferences.ThemeColor || 'light');

    if (language) {
      changeLanguage(language.value);
    }

    if (preferences.ThemeColor) {
      setTheme(preferences.ThemeColor);
    }
  }
}, [preferences, platformLanguages, platformCurrencies, setTheme]);


  const handleLanguageChange = (selectedValues: string[] | string) => {
    const selectedId = Array.isArray(selectedValues)
      ? selectedValues[0]
      : selectedValues;
    const selectedOption = platformLanguages.find(
      (option) => option.value === selectedId
    );
    if (selectedOption) {
      setSelectedLanguage(selectedOption.value);
      changeLanguage(selectedOption.value);
      updatePreferencesOnServer({ Language: selectedOption.value });
    }
  };

  const handleCurrencyChange = (selectedValues: string[] | string) => {
    const selectedId = Array.isArray(selectedValues)
      ? selectedValues[0]
      : selectedValues;
    const selectedOption = platformCurrencies.find(
      (option) => option.value === selectedId
    );
    if (selectedOption) {
      setSelectedCurrency(selectedOption.value);
      updatePreferencesOnServer({
        Currency: selectedOption.value,
        CurrencyName: selectedOption.label,
      });
    }
  };

  const handleThemeToggle = () => {
    const newTheme = selectedTheme === 'light' ? 'dark' : 'light';
    setSelectedTheme(newTheme);
    setTheme(newTheme);
    updatePreferencesOnServer({ ThemeColor: newTheme });
  };

  const updatePreferencesOnServer = (updatedFields: Partial<typeof preferences>) => {
    const updatedPreferences = {
      UserID: userId,
      UserType: userType,
      Communication: preferences?.Communication || 'Email', 
      Language: updatedFields.Language || selectedLanguage,
      Currency: updatedFields.Currency || selectedCurrency,
      CurrencyName:
        updatedFields.CurrencyName ||
        platformCurrencies.find((curr) => curr.value === selectedCurrency)?.label ||
        '',
      ThemeColor: updatedFields.ThemeColor || selectedTheme || 'light',
    };
  
    dispatch(updatePreferences(updatedPreferences)).then(() => {
      // Fetch updated preferences to reflect changes
      dispatch(getPreferences());
    });
  };
  

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2">
      {/* Platform Preferences Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-8 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Platform Preferences')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Manage your language, currency, and theme settings.')}
          </p>
        </div>
        <div className="md:col-span-2 space-y-6">
          {/* Language Setting */}
          <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
            <div>
              <p className="font-medium text-gray-900 dark:text-white">
                {t('Language')}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('Choose your preferred language.')}
              </p>
            </div>
            <Dropdown
              options={platformLanguages}
              value={selectedLanguage}
              placeholder={t('Select Language')}
              onChange={handleLanguageChange}
              className="w-40"
            />
          </div>

          {/* Currency Setting */}
          <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
            <div>
              <p className="font-medium text-gray-900 dark:text-white">
                {t('Currency')}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('Choose your preferred currency.')}
              </p>
            </div>
            <Dropdown
              options={platformCurrencies}
              value={selectedCurrency}
              placeholder={t('Select Currency')}
              onChange={handleCurrencyChange}
              className="w-40"
            />
          </div>

                <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                  <div>
                    <p className="font-medium text-gray-900 dark:text-white">
                      {t('Theme')}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {t('Toggle between light and dark mode.')}
                    </p>
                  </div>
                  <ThemeToggle />
                </div>
        </div>
      </div>

      {/* Document Settings Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-5 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Document Settings')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Manage your document preferences and signature.')}
          </p>
        </div>
        <div className="md:col-span-2">
          <SignatureModal
            onSave={() => {}}
            userId={userId}
            onConfirm={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferencesSection;
