import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../store';
import {
  getMarketplaceSettings,
  updateLawyerTOS,
  updateMarketplaceSettings,
  uploadFirmLogo,
  uploadProfilePicture,
} from '../../../../store/actions/marketplace2/MarketplaceSettingsActions';
import Textarea from '../../../shared/TailwindComponents/Textarea';
import Input from '../../../shared/TailwindComponents/Input';
import Dropdown from '../../../shared/TailwindComponents/Dropdown';
import SingleFileInput from '../../../shared/TailwindComponents/SingleFileInput';
import Button from '../../../shared/TailwindComponents/Button';
import { getUserID } from '../../../../store/actions/ChatAction';
import ToggleSwitch from '../../../shared/TailwindComponents/ToggleSwitch';
import { setMarketplaceActive } from '../../../../store/actions/marketplace2/marketplaceActions';
import { getUserType } from '../../../../utils/authUtils';

const MarketplaceSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userId = getUserID();
  const userType = getUserType(); 


  const { settings, firmLogoUrl, profilePictureUrl, lawyerTOSUrl } = useSelector(
    (state: RootState) => state.marketplaceSettings
  );

  const [editing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const allowedFieldsByUserType: { [key: string]: string[] } = {
    IndependentLawyer: ['HourlyRate', 'Bio', 'Specializations', 'ExperienceYears', 'is_marketplace_active'],
    LawFirmAdmin: [
      'HourlyRate',
      'Bio',
      'Specializations',
      'ExperienceYears',
      'is_marketplace_active',
      'OrganizationName',
      'Description',
      'PracticeArea',
    ],
    LawFirmEmployee: ['HourlyRate', 'Bio', 'Specializations', 'ExperienceYears', 'is_marketplace_active'],
  };

  useEffect(() => {
    dispatch(getMarketplaceSettings());
  }, [dispatch]);

  const allowedFields = allowedFieldsByUserType[userType] || [];

  const initialValues = allowedFields.reduce((values: any, field: string) => {
    values[field] = '';
    return values;
  }, {});
  
  const validationSchemaFields: any = {
    HourlyRate: yup.number().required(t('Hourly Rate is required')),
    Bio: yup.string().required(t('Bio is required')),
    Specializations: yup.array().of(yup.string()),
    ExperienceYears: yup.number().required(t('Experience Years is required')),
    is_marketplace_active: yup.boolean(),
    OrganizationName: yup.string().required(t('Organization Name is required')),
    Description: yup.string(),
    PracticeArea: yup.array().of(yup.string()),
  };
  
  const validationSchema = yup.object(
    allowedFields.reduce((schema: any, field: string) => {
      if (validationSchemaFields[field]) {
        schema[field] = validationSchemaFields[field];
      }
      return schema;
    }, {})
  );
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Filter values to only include allowed fields
      const payload = allowedFields.reduce((obj: any, key: string) => {
        obj[key] = values[key];
        return obj;
      }, {});
  
      dispatch(updateMarketplaceSettings(payload));
      setEditing(false);
      setHasUnsavedChanges(false);
    },
  });

  useEffect(() => {
    if (settings) {
      const updatedValues = allowedFields.reduce((values: any, field: string) => {
        values[field] = settings[field] || '';
        return values;
      }, {});
      formik.setValues(updatedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const handleFirmLogoUpload = (file: File | null) => {
    // Do not dispatch upload action immediately
    setFirmLogoFile(file);
  };

  const handleProfilePictureUpload = (file: File | null) => {
    // Do not dispatch upload action immediately
    setProfilePictureFile(file);
  };

  const handleLawyerTOSUpload = (file: File | null) => {
    // Do not dispatch upload action immediately
    setLawyerTOSFile(file);
  };

  const [firmLogoFile, setFirmLogoFile] = useState<File | null>(null);
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);
  const [lawyerTOSFile, setLawyerTOSFile] = useState<File | null>(null);

  const handleSaveFiles = () => {
    if (firmLogoFile) {
      dispatch(uploadFirmLogo(firmLogoFile));
    }
    if (profilePictureFile) {
      dispatch(uploadProfilePicture(profilePictureFile));
    }
    if (lawyerTOSFile) {
      dispatch(updateLawyerTOS(lawyerTOSFile));
    }
    // Reset file states after upload
    setFirmLogoFile(null);
    setProfilePictureFile(null);
    setLawyerTOSFile(null);
  };

  const handleToggleMarketplaceActive = (checked: boolean) => {
    dispatch(setMarketplaceActive(checked));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.handleChange(e);
    setHasUnsavedChanges(true);
  };

  const handleSpecializationsChange = (values: string[] | string) => {
    formik.setFieldValue('Specializations', values);
    setHasUnsavedChanges(true);
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2">
      {/* Marketplace Settings Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-8 md:grid-cols-3">
        {/* Left Sidebar */}
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Marketplace Settings')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Manage your marketplace profile and settings.')}
          </p>
        </div>
        {/* Right Content */}
        <div className="md:col-span-2 space-y-6">
          {/* File Uploads at the Top */}
          <div className="flex flex-wrap gap-6">
            {/* Profile Picture Upload (Available to all user types) */}
            <SingleFileInput
              label={t('Profile Picture')}
              accept="image/*"
              file={profilePictureFile}
              setFile={handleProfilePictureUpload}
              existingFileUrl={profilePictureUrl as string}
            />

            {/* Firm Logo Upload (Only for LawFirmAdmin) */}
            {userType === 'LawFirmAdmin' && (
              <SingleFileInput
                label={t('Firm Logo')}
                accept="image/*"
                file={firmLogoFile}
                setFile={handleFirmLogoUpload}
                existingFileUrl={firmLogoUrl as string}
              />
            )}

            {/* Lawyer TOS Upload (Available to all user types) */}
            <SingleFileInput
              label={t('Terms of Service')}
              accept=".pdf,.doc,.docx"
              file={lawyerTOSFile}
              setFile={handleLawyerTOSUpload}
              existingFileUrl={lawyerTOSUrl as string}
            />
          </div>
          {/* Save Files Button */}
          {(firmLogoFile || profilePictureFile || lawyerTOSFile) && (
            <div className="mt-4">
              <Button variant="primary" onClick={handleSaveFiles}>
                {t('Save Files')}
              </Button>
            </div>
          )}
          {/* Marketplace Active Toggle */}
          <div className="flex items-center mt-4">
            <label
              htmlFor="is_marketplace_active"
              className="mr-2 text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              {t('Marketplace Active')}
            </label>
            <ToggleSwitch
              id="is_marketplace_active"
              name="is_marketplace_active"
              checked={settings?.is_marketplace_active || false}
              onChange={handleToggleMarketplaceActive}
            />
          </div>
          {/* Form for Hourly Rate, Bio, etc. */}
          {editing ? (
          <form onSubmit={formik.handleSubmit} className="space-y-6 mt-6">
          {/* Hourly Rate */}
          {allowedFields.includes('HourlyRate') && (
            <Input
              label={t('Hourly Rate')}
              id="HourlyRate"
              name="HourlyRate"
              type="number"
              value={formik.values.HourlyRate}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              error={formik.touched.HourlyRate && Boolean(formik.errors.HourlyRate)}
              // helperText={formik.touched.HourlyRate && formik.errors.HourlyRate}
            />
          )}

          {/* Bio */}
          {allowedFields.includes('Bio') && (
            <Textarea
              label={t('Bio')}
              id="Bio"
              name="Bio"
              value={formik.values.Bio}
              onChange={handleInputChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.Bio && Boolean(formik.errors.Bio)}
              // helperText={formik.touched.Bio && formik.errors.Bio}
            />
          )}

          {/* Specializations */}
          {allowedFields.includes('Specializations') && (
            <Dropdown
              label={t('Specializations')}
              id="Specializations"
              options={[
                { value: 'Contract Law', label: t('Contract Law') },
                { value: 'Business Law', label: t('Business Law') },
                // Add more options as needed
              ]}
              value={formik.values.Specializations}
              onChange={handleSpecializationsChange}
              multiple
            />
          )}

          {/* Experience Years */}
          {allowedFields.includes('ExperienceYears') && (
            <Input
              label={t('Years of Experience')}
              id="ExperienceYears"
              name="ExperienceYears"
              type="number"
              value={formik.values.ExperienceYears}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ExperienceYears && Boolean(formik.errors.ExperienceYears)}
              // helperText={formik.touched.ExperienceYears && formik.errors.ExperienceYears}
            />
          )}

          {/* Organization Name */}
          {allowedFields.includes('OrganizationName') && (
            <Input
              label={t('Organization Name')}
              id="OrganizationName"
              name="OrganizationName"
              type="text"
              value={formik.values.OrganizationName}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              error={formik.touched.OrganizationName && Boolean(formik.errors.OrganizationName)}
              // helperText={formik.touched.OrganizationName && formik.errors.OrganizationName}
            />
          )}

          {/* Description */}
          {allowedFields.includes('Description') && (
            <Textarea
              label={t('Description')}
              id="Description"
              name="Description"
              value={formik.values.Description}
              onChange={handleInputChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.Description && Boolean(formik.errors.Description)}
              // helperText={formik.touched.Description && formik.errors.Description}
            />
          )}

          {/* Practice Area */}
          {allowedFields.includes('PracticeArea') && (
            <Dropdown
              label={t('Practice Area')}
              id="PracticeArea"
              options={[
                { value: 'Corporate Law', label: t('Corporate Law') },
                { value: 'Tax Law', label: t('Tax Law') },
                // Add more options as needed
              ]}
              value={formik.values.PracticeArea}
              onChange={(values) => {
                formik.setFieldValue('PracticeArea', values);
                setHasUnsavedChanges(true);
              }}
              multiple
            />
          )}

          {/* Save and Cancel Buttons */}
          <div className="mt-8 flex gap-x-4">
            <Button variant="primary" type="submit">
              {t('Save')}
            </Button>
            <Button
              variant="destructive"
              onClick={() => {
                setEditing(false);
                formik.resetForm();
                setHasUnsavedChanges(false);
              }}
            >
              {t('Cancel')}
            </Button>
          </div>
        </form>
          ) : (
            <div className="space-y-6 mt-6">
              {/* Display Settings */}
              {/* Hourly Rate */}
              <div>
                <p className="font-medium text-gray-700 dark:text-gray-300">
                  {t('Hourly Rate')}
                </p>
                <p className="text-gray-900 dark:text-white">
                  {settings?.HourlyRate ? `$${settings.HourlyRate}` : t('Not set')}
                </p>
              </div>
              {/* Bio */}
              <div>
                <p className="font-medium text-gray-700 dark:text-gray-300">
                  {t('Bio')}
                </p>
                <p className="text-gray-900 dark:text-white">
                  {settings?.Bio || t('Not set')}
                </p>
              </div>
              {/* Specializations */}
              <div>
                <p className="font-medium text-gray-700 dark:text-gray-300">
                  {t('Specializations')}
                </p>
                <p className="text-gray-900 dark:text-white">
                  {settings?.Specializations?.length
                    ? settings.Specializations.join(', ')
                    : t('Not set')}
                </p>
              </div>
              {/* Experience Years */}
              <div>
                <p className="font-medium text-gray-700 dark:text-gray-300">
                  {t('Years of Experience')}
                </p>
                <p className="text-gray-900 dark:text-white">
                  {settings?.ExperienceYears || t('Not set')}
                </p>
              </div>
              <div className="mt-8 flex">
                <Button variant="primary" onClick={() => setEditing(true)}>
                  {t('Edit')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketplaceSettings;

