import { AnyAction } from "redux";
import {
    DELETE_BLOB_DOCUMENT_FAILURE,
    DELETE_BLOB_DOCUMENT_SUCCESS,
    DELETE_INDEX_FAILURE,
    DELETE_INDEX_REQUEST,
    DELETE_INDEX_SUCCESS,
    FETCH_BLOB_DOCUMENTS_FAILURE,
    FETCH_BLOB_DOCUMENTS_REQUEST,
    FETCH_BLOB_DOCUMENTS_SUCCESS,
    FETCH_INDEX_INFO_FAILURE,
    FETCH_INDEX_INFO_REQUEST,
    FETCH_INDEX_INFO_SUCCESS,
    POLL_INDEX_STATUS_START,
    POLL_INDEX_STATUS_STOP,
    POST_ADDITIONAL_COMPANY_FILES_FAILURE,
    POST_ADDITIONAL_COMPANY_FILES_REQUEST,
    POST_ADDITIONAL_COMPANY_FILES_SUCCESS,
    POST_COMPANY_FILES_FAILURE,
    POST_COMPANY_FILES_REQUEST,
    POST_COMPANY_FILES_SUCCESS,
    RERUN_INDEX_FAILURE,
    RERUN_INDEX_REQUEST,
    RERUN_INDEX_SUCCESS,
    UPDATE_INDEX_STATUS_MESSAGE
} from "../types";

interface CompanyAgentState {
    loading: boolean;
    companyFilesData: any | null;
    additionalCompanyFilesData: any | null;
    indexInfo: any | undefined;
    indexExists: boolean;
    blobDocuments: string[] | null;
    deleteBlobDocumentResult: any | null;
    rerunIndexResult: any | null;
    error: string | null;
    polling: boolean;
    statusMessage: string;
}

const initialState: CompanyAgentState = {
    loading: false,
    companyFilesData: null,
    additionalCompanyFilesData: null,
    indexInfo: undefined,
    indexExists: false,
    blobDocuments: null,
    deleteBlobDocumentResult: null,
    rerunIndexResult: null,
    error: null,
    polling: false,
    statusMessage: "Loading..."
};

const companyAgentReducer = (state = initialState, action: AnyAction): CompanyAgentState => {
    switch (action.type) {
        case FETCH_INDEX_INFO_REQUEST:
        case POST_ADDITIONAL_COMPANY_FILES_REQUEST:
        case POST_COMPANY_FILES_REQUEST:
        case RERUN_INDEX_REQUEST:
        case FETCH_BLOB_DOCUMENTS_REQUEST:
        case DELETE_INDEX_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case POST_COMPANY_FILES_SUCCESS:
            return {
                ...state,
                companyFilesData: action.payload,
                loading: false,
                error: null
            };
        case POST_COMPANY_FILES_FAILURE:
            return {
                ...state,
                companyFilesData: null,
                loading: false,
                error: action.payload.error
            };
        case POST_ADDITIONAL_COMPANY_FILES_SUCCESS:
            return {
                ...state,
                additionalCompanyFilesData: action.payload,
                loading: false,
                error: null
            };
        case POST_ADDITIONAL_COMPANY_FILES_FAILURE:
            return {
                ...state,
                additionalCompanyFilesData: null,
                loading: false,
                error: action.payload.error
            };
        case FETCH_INDEX_INFO_SUCCESS:
            return {
                ...state,
                indexInfo: action.payload,
                indexExists: true,
                loading: false,
                error: null
            };
        case FETCH_INDEX_INFO_FAILURE:
            return {
                ...state,
                indexInfo: undefined,
                indexExists: action.payload.indexExists,
                loading: false,
                error: action.payload.error
            };
        case FETCH_BLOB_DOCUMENTS_SUCCESS:
            return {
                ...state,
                blobDocuments: action.payload,
                loading: false,
                error: null
            };
        case FETCH_BLOB_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                blobDocuments: [],
                error: action.payload.error
            };
        case DELETE_BLOB_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteBlobDocumentResult: action.payload,
                error: null
            };
        case DELETE_BLOB_DOCUMENT_FAILURE:
            return {
                ...state,
                deleteBlobDocumentResult: null,
                error: action.payload.error
            };
        case DELETE_INDEX_SUCCESS:
            return {
                ...state,
                indexExists: false,
                indexInfo: undefined,
                loading: false,
                error: action.payload.error,
            };
        case DELETE_INDEX_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case RERUN_INDEX_SUCCESS:
            return {
                ...state,
                rerunIndexResult: action.payload,
                error: null
            };
        case RERUN_INDEX_FAILURE:
            return {
                ...state,
                rerunIndexResult: null,
                error: action.payload.error
            };
        case POLL_INDEX_STATUS_START:
            return {
                ...state,
                polling: true,
                statusMessage: "Building Agent..."
            };
        case POLL_INDEX_STATUS_STOP:
            return {
                ...state,
                polling: false,
            };
        case UPDATE_INDEX_STATUS_MESSAGE:
            return {
                ...state,
                statusMessage: action.payload,
            };
        default:
            return state;
    }
};

export default companyAgentReducer;
