import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideFeedbackModal } from '../../store/actions/UserFeedbackActions';
import Alert from './TailwindComponents/Alert';
import { RootState } from '../../store';
import { Transition } from '@headlessui/react';

const UserFeedbackModal: React.FC = () => {
  const dispatch = useDispatch();
  const userFeedbackModalState = useSelector(
    (state: RootState) => state.userFeedback
  );

  if (!userFeedbackModalState.showModal) return null;

  return (
    <Transition.Root show={userFeedbackModalState.showModal} as={Fragment}>
      <div className="fixed bottom-0 left-0 right-0 flex items-end justify-center z-50 mb-4">
        <Transition.Child
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transform ease-in duration-200 transition"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-4"
        >
          <Alert
            type={userFeedbackModalState.modalType || 'info'}
            message={userFeedbackModalState.message || ''}
            onClose={() => {
              dispatch(hideFeedbackModal());
            }}
          />
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default UserFeedbackModal;
