import React from 'react';
import CurrencyInput from 'react-currency-input-field';

interface BudgetInputProps {
  id: string;
  label: string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const BudgetInput: React.FC<BudgetInputProps> = ({ id, label, value, onChange }) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <CurrencyInput
        id={id}
        name={id}
        placeholder="Enter your budget"
        defaultValue={value}
        decimalsLimit={2}
        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all duration-300"
        prefix="$"
        onValueChange={(value) => onChange(value)}
      />
    </div>
  );
};

export default BudgetInput;
