import axios from 'axios';
import { config } from "./config";

const paymentApis = axios.create({
    baseURL: `${config.payment_url}`
    // baseURL: "https://dev.api.pons.io/"
});

export default paymentApis;

paymentApis.interceptors.request.use(function (config) {
    const auth_token = localStorage.getItem("token") || sessionStorage.getItem("token");

    if (auth_token)
        Object.assign(config.headers, {
            Authorization: `Bearer ${JSON.parse(auth_token).token}`,
        })
    return config;
}, function (error) {
    return Promise.reject(error);
});


// Add a response interceptor
paymentApis.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // toast.dismiss()
        if (error.response?.status === 500) {
            // toast.error(error.response.statusText)
        } else
            if (error.response?.status === 403) {
                // toast.error("Unauthorized request,login with valid Credentials ")
            } else if (error.response?.status === 401) {
                // window.localStorage.clear();
                // const url = window.location.href;

                // if (url.indexOf("login") === -1) {
                //     window.location.href = "/login";
                //     toast.error("Unauthorized request, Please Login Again...")
                // }
                return error
            }
        return error;

    }
);
