import React, { useRef, useState } from 'react';
import Badge from './Badge';
import { useTranslation } from 'react-i18next';

interface FileUploaderProps {
  label: string;
  onFileSelect: (file: File | null) => void;
  file: File | null;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFileSelect, file, label }) => {
  const [dragActive, setDragActive] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (['dragenter', 'dragover'].includes(e.type)) {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      onFileSelect(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const handleRemoveFile = () => {
    onFileSelect(null);
  };

  return (
    <div className="flex flex-col items-start w-full">
      <label className="mb-2 text-gray-700 dark:text-gray-300">{label}</label>
      <div
        className={`flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-md cursor-pointer transition-colors duration-200 ${
          dragActive ? 'border-primary-500' : 'border-gray-300 dark:border-gray-600'
        } ${file ? 'bg-gray-50 dark:bg-gray-700' : ''}`}
        onClick={onButtonClick}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input ref={inputRef} type="file" className="hidden" onChange={handleChange} />
        {file ? (
          <div className="flex flex-wrap gap-2">
            <Badge
              color="blue"
              onClose={handleRemoveFile}
              className="flex items-center space-x-2 max-w-[200px]" 
            >
              <span className="truncate max-w-full">{file.name}</span> 
            </Badge>
          </div>
        ) : (
          <p className="text-gray-500 dark:text-gray-400 text-center px-4">
            {t('Drag & drop a file here or click to select a file')}
          </p>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
