import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store';
import { updatePreferences } from '../store/actions/DataAction';
import { getUserID, getUserType } from '../store/actions/ChatAction';

interface ThemeContextProps {
  theme: string;
  setTheme: (theme: string) => void;
  toggleTheme: () => void;
}

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: 'light',
  setTheme: () => {},
  toggleTheme: () => {},
});

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const savedTheme = localStorage.getItem('theme') as 'light' | 'dark' | null;
  const [theme, setThemeState] = useState<string>(savedTheme || 'light');
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    if (preferences && preferences.ThemeColor) {
      if (preferences.ThemeColor !== theme) {
        setTheme(preferences.ThemeColor);
      }
    }
  }, [preferences]);

  useEffect(() => {
    const root = window.document.documentElement;

    // Remove both 'light' and 'dark' classes
    root.classList.remove('light', 'dark');

    // Add the current theme class
    root.classList.add(theme);
  }, [theme]);

  const setTheme = (newTheme: string) => {
    setThemeState(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    // Update backend preferences
    const updatedPreferences = {
      UserID: userId,
      UserType: userType,
      Communication: preferences?.Communication || 'Email',
      Language: preferences?.Language || 'en', // Default to 'en' if not set
      Currency: preferences?.Currency || '',
      CurrencyName: preferences?.CurrencyName || '',
      ThemeColor: newTheme,
    };

    dispatch(updatePreferences(updatedPreferences));
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
