import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { anonymizeDocument } from '../../store/actions/DocumentsAction';
import { hideModal } from '../../store/actions/modalActions';
import { RootState, useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import { AnonymizeState } from '../../store/reducer/documentsReducer';

interface AnonymizeDocumentModalProps {
  fileID: string | undefined;
}

const AnonymizeDocumentModal: React.FC<AnonymizeDocumentModalProps> = ({ fileID }) => {
  const dispatch = useAppDispatch();
  const datasets: AnonymizeState[] = useSelector(
    (state: RootState) => state.documents.anonymizationDatasets
  );
  const loading = useSelector((state: RootState) => state.documents.loading);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleOptionChange = (value: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleSubmit = () => {
    dispatch(anonymizeDocument(fileID!, selectedOptions));
    dispatch(hideModal());
  };

  return (
    <div className="bg-background-light dark:bg-background-dark p-6 rounded-lg  mx-auto">
      {loading ? (
        <div className="flex justify-center items-center">
          <ClipLoader size={20} color="#4A90E2" loading={true} />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {datasets && datasets.length > 0 ? (
              datasets.map((field: AnonymizeState, index) => (
                <label key={index} className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={field.key}
                    checked={selectedOptions.includes(field.key)}
                    onChange={() => handleOptionChange(field.key)}
                    className="appearance-none h-5 w-5 border border-gray-300 rounded-sm bg-white dark:bg-gray-700 checked:bg-blue-600 checked:border-transparent focus:outline-none transition duration-200"
                  />
                  <span className="ml-2 text-sm text-text-light dark:text-text-dark">{field.label}</span>
                </label>
              ))
            ) : (
              <p className="text-text-light dark:text-text-dark">
                {t('No fields available for anonymization.')}
              </p>
            )}
          </div>
          <div className="flex justify-end mt-6">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={selectedOptions.length === 0}
              className='w-full'
            >
              {t('Anonymize Document')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AnonymizeDocumentModal;
