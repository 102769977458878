import PerformanceSection from "../components/Dashboard/AdminFirm/PerformanceSection";
function FinancePage() {
    return (
        <div>

            <PerformanceSection />
        </div>
    )
}

export default FinancePage;