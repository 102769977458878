import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getPublishedCases } from '../../../store/actions/marketplace2/marketplaceActions';
import { Case } from '../shared/types';
import SearchBar from '../../shared/TailwindComponents/SearchBar';
import SortBar from '../../shared/TailwindComponents/SortBar';
import ClipLoader from 'react-spinners/ClipLoader';
import CaseCard from '../../shared/TailwindComponents/CaseCars';

const PublishedCasesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const publishedCases = useSelector((state: RootState) => state.marketplace.publishedCases) as Case[];
  const error = useSelector((state: RootState) => state.marketplace.error);
  const isLoading = useSelector((state: RootState) => state.marketplace.isLoadingLawyerProfile);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortedCases, setSortedCases] = useState<Case[]>([]);
  const [sortKey, setSortKey] = useState<'AnonymizedDescription' | 'Budget' | 'Deadline' | 'CaseType'>('Deadline');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    dispatch(getPublishedCases());
  }, [dispatch]);

  useEffect(() => {
    // Filter and sort cases
    let filteredCases = publishedCases;

    // Filter by search term
    if (searchTerm) {
      filteredCases = filteredCases.filter((caseItem) =>
        caseItem.AnonymizedDescription.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sort the cases
    filteredCases.sort((a, b) => {
      let aValue: any = a[sortKey];
      let bValue: any = b[sortKey];

      // Handle null or undefined values
      if (aValue === null || aValue === undefined) aValue = '';
      if (bValue === null || bValue === undefined) bValue = '';

      // For dates, parse them to timestamps
      if (sortKey === 'Deadline') {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      }

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedCases(filteredCases);
  }, [publishedCases, searchTerm, sortKey, sortOrder]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Published Cases ({sortedCases.length})</h1>
      {/* Search and Sort Bars */}
      <div className="flex flex-wrap justify-between items-center mb-4">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search cases..." />
        <SortBar<'AnonymizedDescription' | 'Budget' | 'Deadline' | 'CaseType'>
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          options={[
            { value: 'Deadline', label: 'Deadline' },
            { value: 'Budget', label: 'Budget' },
            { value: 'CaseType', label: 'Case Type' },
          ]}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader color="#000" loading={true} size={30} />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sortedCases.length > 0 ? (
            sortedCases.map((caseItem) => (
              <CaseCard key={caseItem.CaseID} caseItem={caseItem} />
            ))
          ) : (
            <p className="text-center w-full">No published cases available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PublishedCasesList;
