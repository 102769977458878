import React, { useEffect } from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import BidCard from '../../shared/TailwindComponents/BidCard';
import ClipLoader from 'react-spinners/ClipLoader';
import { getClientBids } from '../../../store/actions/marketplace2/marketplaceBidsActions';

const ClientBidsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const bids = useSelector((state: RootState) => state.marketplaceBids.clientBids);
  const isLoading = useSelector((state: RootState) => state.marketplaceBids.isLoading);
  const error = useSelector((state: RootState) => state.marketplaceBids.error);

  useEffect(() => {
    dispatch(getClientBids());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-4">My Bids</h1>
      {bids && bids.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {bids.map((bid) => (
            <BidCard key={bid.BidID} bid={bid} />
          ))}
        </div>
      ) : (
        <p>No bids available.</p>
      )}
    </div>
  );
};

export default ClientBidsList;
