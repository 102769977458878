import React from 'react';

interface SortOption<T> {
  value: T;
  label: string;
}

interface SortBarProps<T> {
  sortKey: T;
  setSortKey: (key: T) => void;
  sortOrder: 'asc' | 'desc';
  setSortOrder: (order: 'asc' | 'desc') => void;
  options: SortOption<T>[];
}

const SortBar = <T extends string>({ sortKey, setSortKey, sortOrder, setSortOrder, options }: SortBarProps<T>) => {
  return (
    <div className="flex items-center space-x-2">
      <label className="text-gray-700 dark:text-gray-300">Sort by:</label>
      <select
        value={sortKey}
        onChange={(e) => setSortKey(e.target.value as T)}
        className="rounded-md bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary-500"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <button
        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
        className="p-1 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
      >
        {sortOrder === 'asc' ? 'Asc' : 'Desc'}
      </button>
    </div>
  );
};

export default SortBar;
