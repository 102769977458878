import { AnyAction } from 'redux';
import {
  FETCH_WHITELABEL_SETTINGS_SUCCESS,
  FETCH_WHITELABEL_SETTINGS_FAILURE,
  UPDATE_WHITELABEL_SETTINGS_SUCCESS,
  UPDATE_WHITELABEL_SETTINGS_FAILURE,
} from '../../types';

const initialState = {
  loading: false,
  settings: {},
  error: null,
};

const whiteLabelReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_WHITELABEL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    case UPDATE_WHITELABEL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: { ...state.settings, ...action.payload },
      };
    case FETCH_WHITELABEL_SETTINGS_FAILURE:
    case UPDATE_WHITELABEL_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default whiteLabelReducer;
