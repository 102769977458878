// src/components/GlobalModal.tsx

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { RootState, useAppDispatch } from '../../store';
import { hideModal } from '../../store/actions/modalActions';
import NewFileModal, { NewFileData } from '../Documents/NewFile';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import SignatureModal from '../Settings/Security/DigitalSignature';
import AddTask from '../Tasks/AddTaskComponent';
import OtpInput from '../LoginSection/OtpInput';
import { createNewDocument } from '../../store/actions/DocumentsAction';
import BankFormItems from '../Settings/Security/BankFormItems';
import { fetchStructure } from '../../store/actions/FolderAction';
import PricingPage from '../PlanCard/PricingPage';
import AnonymizeDocumentModal from '../Documents/AnonymizeDocumentModal';
import ShareContractModal from '../Documents/ShareContractModal';
import TranslationModal from '../Documents/TranslationModal';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import ResetPassword from '../LoginSection/ResetPassword';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import Textarea from '../shared/TailwindComponents/Textarea';
import { CheckIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import NewOtpInput from '../Auth/NewOtpInput';

const GlobalModal: React.FC = () => {
  const modalState = useSelector((state: RootState) => state.modal);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userId = getUserID();
  const userType = getUserType();

  const [inputData, setInputData] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [completeTask, setCompleteTask] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);

  // Function to close the modal
  const handleClose = () => {
    setInputData('');
    setCompleteTask(false);
    dispatch(hideModal());
    if (modalState.onCancel) {
      modalState.onCancel();
    }
  };

  // Function to confirm folder creation
  const handleConfirmCreateFolder = () => {
    if (modalState.type === 'create' && modalState.onConfirm) {
      modalState.onConfirm(inputData);
    }
    handleClose();
  };
  
  // Function to handle confirmation actions based on modal type
  const handleConfirm = () => {
    if (modalState.onConfirm) {
      if (modalState.type === 'timeStop') {
        modalState.onConfirm(inputData, completeTask);
      } else if (modalState.type === 'addSignature') {
        const signatureBlob = new Blob([inputData], { type: 'image/svg+xml' });
        const signatureFile = new File([signatureBlob], 'signature.svg', { type: 'image/svg+xml' });
        modalState.onConfirm(signatureFile);
      } else if (['share', 'sign', 'password', 'promptfeedback', 'confirmation'].includes(modalState.type)) {
        modalState.onConfirm(inputData);
      } else if (modalState.type === 'forgotPassword') {
        modalState.onConfirm(modalState.inputData ? modalState.inputData : email);
        setEmail('');
      } else if (modalState.type === '2fa-qrcode') {
        modalState.onConfirm(verificationCode);
        setVerificationCode('');
      } else if (modalState.type === 'alert') {
        modalState.onConfirm(modalState.inputData ? modalState.inputData : '');
      } else if (modalState.type === 'confirm-upload') {
        modalState.onConfirm(inputData);
      }
    }

    if (modalState.type !== '2fa-qrcode') {
      handleClose();
    }
  };
  
  useEffect(() => {
    if (!modalState.showModal) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const target = event.target as HTMLElement;
        const isTextarea = target.tagName === 'TEXTAREA';
        if (!isTextarea) {
          handleConfirm();
        }
      } else if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalState.showModal, handleConfirm, handleClose]);

  // Functions to handle signature modal
  const handleOpenSignatureModal = () => {
    setIsSignatureModalOpen(true);
  };

  const handleCloseSignatureModal = () => {
    setIsSignatureModalOpen(false);
  };

  const handleSaveSignature = (signature: string) => {
    setSignature(signature);
  };

  // Function to get the appropriate icon based on modal type
  const getIcon = () => {
    switch (modalState.type) {
      case 'success':
        return (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 dark:bg-green-800">
            <CheckIcon className="h-6 w-6 text-green-600 dark:text-green-100" aria-hidden="true" />
          </div>
        );
      case 'error':
        return (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100 dark:bg-red-800">
            <XCircleIcon className="h-6 w-6 text-red-600 dark:text-red-100" aria-hidden="true" />
          </div>
        );
      case 'warning':
        return (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-800">
            <ExclamationCircleIcon className="h-6 w-6 text-yellow-600 dark:text-yellow-100" aria-hidden="true" />
          </div>
        );
      default:
        return null;
    }
  };

  // Main return
  return (
    <Transition.Root show={modalState.showModal} as={Fragment}>
      <Dialog as="div" className="relative z-[300000000]" onClose={handleClose}>
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Modal Content */}
        <div className="fixed inset-0 z-[3000] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {/* Modal Panel */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
        <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {/* Close Button */}
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Icon */}
                {getIcon()}

                {/* Title and Subtitle */}
                <div className="mt-3 text-center sm:mt-5">
                  {modalState.message && (
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                      {modalState.message}
                    </Dialog.Title>
                  )}
                  {modalState.subMsg && (
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: modalState.subMsg }}></p>
                    </div>
                  )}
                </div>

                {/* Modal Content Based on Type */}
                <div className="mt-5 sm:mt-6">
                  {/* Create Folder */}
                  {modalState.type === 'create' && (
                    <>
                      <Input
                        type="text"
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                        placeholder={t('Folder name')}
                        className="w-full mb-4" id={''}                      />
                      <Button variant="primary" onClick={handleConfirmCreateFolder} className="w-full">
                        {t('Create')}
                      </Button>
                    </>
                  )}

                  {/* Forgot Password */}
                  {modalState.type === 'forgotPassword' && (
                    <>
                      <Input
                        type="email"
                        placeholder={t('Enter your email')}
                        value={modalState.inputData ? modalState.inputData : email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full mb-4" id={''}                      />
                      <Button variant="primary" onClick={handleConfirm} className="w-full">
                        {t('Send Reset Link')}
                      </Button>
                    </>
                  )}

                  {/* Reset Password */}
                  {modalState.type === 'resetPassword' && (
                    <ResetPassword />
                  )}

                  {/* Tips */}
                  {modalState.type === 'tips' && (
                    <>
                      <p className="text-sm text-gray-500 dark:text-gray-300">{modalState.info}</p>
                      <Button variant="primary" onClick={handleClose} className="mt-4 w-full">
                        {t('Close')}
                      </Button>
                    </>
                  )}

                  {/* Info */}
                  {modalState.type === 'info' && (
                    <>
                      <h1 className="text-lg font-medium text-gray-900 dark:text-gray-100">{modalState.title}</h1>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: modalState.info! }}></p>
                      </div>
                    </>
                  )}

                  {/* Add Task */}
                  {modalState.type === 'addTask' && (
                    <AddTask />
                  )}


                  {/* Password */}
                  {modalState.type === 'password' && (
                    <>
                      <Input
                        type="password"
                        placeholder={t('Enter your password')}
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                        className="w-full mb-4" id={''}                      />
                      <Button variant="primary" onClick={handleConfirm} className="w-full">
                        {t('Save Changes')}
                      </Button>
                    </>
                  )}

                  {/* Prompt Feedback */}
                  {modalState.type === 'promptfeedback' && (
                    <>
                      <p className="text-sm text-gray-500 dark:text-gray-300">{t('Please let us know what went wrong.')}</p>
                      <Textarea
                        placeholder={t('Please provide a feedback on what went wrong')}
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)} 
                        id={''} 
                        label={'Feedback'}
                      />
                      <Button variant="primary" onClick={handleConfirm} className="w-full">
                        {t('Send feedback')}
                      </Button>
                    </>
                  )}

                  {/* Share or Sign */}
                  {(modalState.type === 'share' || modalState.type === 'sign') && (
                    <ShareContractModal />
                  )}

                  {/* Confirmation */}
                  {modalState.type === 'confirmation' && (
                    <div className="flex justify-between space-x-4">
                      <Button variant="destructive" onClick={handleClose} className="w-1/2">
                        {t('No')}
                      </Button>
                      <Button variant="primary" onClick={() => modalState.onConfirm && modalState.onConfirm(inputData)} className="w-1/2">
                        {t('Yes')}
                      </Button>
                    </div>
                  )}

                  {/* Success, Error, Warning, Feedback */}
                  {['success', 'error', 'warning', 'feedback'].includes(modalState.type) && (
                    <div className="mt-5 sm:mt-6">
                      {/* Additional content if needed */}
                    </div>
                  )}

                  {/* Alert */}
                  {modalState.type === 'alert' && (
                    <div className="mt-5 sm:mt-6 flex justify-end space-x-4">
                      <Button variant="destructive" onClick={handleClose}>
                        {t('Cancel')}
                      </Button>
                      <Button variant="primary" onClick={handleConfirm}>
                        {t('Yes, Sign out')}
                      </Button>
                    </div>
                  )}

                  {/* OTP Verification */}
                  {modalState.type === 'otp-verification' && (
                    <>
                      <NewOtpInput
                        onSubmit={modalState.onConfirm ? (data: File | string) => modalState.onConfirm!(data) : () => { }}
                        handleClose={handleClose}
                        method={modalState.inputData}
                      />
                    </>
                  )}

                  {/* Subscription Modal */}
                  {modalState.type === 'subscription-modal' && (
                    <PricingPage />
                  )}

                  {/* 2FA QR Code */}
                  {modalState.type === '2fa-qrcode' && (
                    <>
                      <p className="text-sm text-gray-500 dark:text-gray-300">{t('Enhance the security of your account by enabling 2FA. To set up 2FA, please follow the steps below:')}</p>
                      <div className="mt-4">
                        {modalState.inputData && (
                          <img src={`data:image/png;base64,${modalState.inputData}`} alt="QR Code" className="mx-auto mb-4" />
                        )}
                        <ol className="list-decimal list-inside text-sm text-gray-500 dark:text-gray-300">
                          <li>{t("Open your mobile device's camera or QR code scanner app.")}</li>
                          <li>{t("Point your camera at the QR code displayed on the screen.")}</li>
                          <li>{t("Wait for the QR code to be recognized and follow the on-screen instructions.")}</li>
                          <li>{t("Enter the passcode displayed on your device into the input field below the QR code.")}</li>
                        </ol>
                      </div>
                      <Input
                        type="text"
                        name="verification-code"
                        id="verification-code"
                        placeholder={t('Code')}
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="w-full mt-4 mb-4"
                      />
                      <div className="flex justify-end space-x-4">
                        <Button variant="destructive" onClick={handleClose}>
                          {t('Cancel')}
                        </Button>
                        <Button variant="primary" onClick={handleConfirm}>
                          {t('Enable')}
                        </Button>
                      </div>
                    </>
                  )}

                  {/* Receiver Options */}
                  {modalState.type === 'receiver-options' && (
                    <BankFormItems />
                  )}

                  {/* Anonymize Document */}
                  {modalState.type === 'anonymize' && (
                    <AnonymizeDocumentModal fileID={modalState.fileID} />
                  )}

                  {/* Translate Document */}
                  {modalState.type === 'translate' && (
                    <TranslationModal fileID={modalState.fileID} />
                  )}

                    {modalState.type === 'confirm-upload' && (
                      <div className="flex flex-col space-y-4">
                        <Button variant="primary" onClick={() => modalState.onConfirm && modalState.onConfirm('append')}>
                          {t('Append')}
                        </Button>
                        <Button variant="primary" onClick={() => modalState.onConfirm && modalState.onConfirm('replace')}>
                          {t('Replace')}
                        </Button>
                      </div>
                    )}

                  {/* Time Stop */}
                  {modalState.type === 'timeStop' && (
                    <>
                      <p className="text-sm text-gray-500 dark:text-gray-300">{modalState.message}</p>
                      <Textarea
                        placeholder={t("Optional description of time spent")}
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)} id={''} label={'Time Description'}                      />
                      {modalState.taskId && (
                        <div className="flex items-center mb-4">
                          <input
                            type="checkbox"
                            id="completeTaskCheckbox"
                            checked={completeTask}
                            onChange={(e) => setCompleteTask(e.target.checked)}
                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                          <label htmlFor="completeTaskCheckbox" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                            {t('Complete the task')}
                          </label>
                        </div>
                      )}
                      <div className="flex justify-end space-x-4">
                        <Button variant="primary" onClick={handleConfirm}>
                          {t('Yes')}
                        </Button>
                        <Button variant="destructive" onClick={handleClose}>
                          {t('No')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GlobalModal;
