import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import {
  deleteClient,
  fetchAllClientDetails, 
} from '../../store/actions/ClientAction';
import { useTranslation } from 'react-i18next';

import ClipLoader from 'react-spinners/ClipLoader';
import { showModal, hideModal } from '../../store/actions/modalActions';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import Button from '../shared/TailwindComponents/Button';
import { useSelector } from 'react-redux';
import { showAddCaseSidePanel } from '../../store/actions/sidePanelActions';
import { fetchCaseById } from '../../store/actions/marketplace2/caseActions';
import { LayoutContext } from '../../contexts/LayoutContext';
import Badge from '../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

interface RouteParams {
  clientId: string;
}

const ClientDetails: React.FC = () => {
  const { clientId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation();
  const history = useHistory();
  const { setIsContentScrollable } = useContext(LayoutContext);
  const [client, setClient] = useState<any | null>(null);
  const [clientCases, setClientCases] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const allClients = useSelector((state: RootState) => state.client.allClients);
  const clientUserType =
  client && client.OrganizationID ? 'BusinessClient' : 'IndividualClient';
  // Extract clientId and slug from URL
  const clientType = client?.UserTypeName === 'BusinessAdmin' || client?.UserTypeName === 'BusinessEmployee'
  ? 'Business'
  : 'Individual';

  
  const clientDetails = useSelector((state: RootState) => state.client.clientDetails[clientId as string]);
  // Fetch client details
  useEffect(() => {
    if (!clientDetails && clientId) {
      dispatch(fetchAllClientDetails(clientId));
    } else if (clientDetails) {
      setClient(clientDetails.client);
      setClientCases(clientDetails.cases);
    }
  }, [clientDetails, clientId, dispatch]);

  useEffect(() => {
    if (clientDetails) {
      const client = clientDetails.client;
      const clientName =
        client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
          ? client.BusinessName || 'Business Client'
          : `${client.FirstName} ${client.LastName}`;
      setBreadcrumbs([
        { name: 'Clients', href: '/clients', current: false },
        { name: clientName, href: `/clients/${clientId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [clientDetails, clientId, setBreadcrumbs]);

  useEffect(() => {
      setIsContentScrollable(false);
  
      // Reset to default when unmounting
      return () => {
        setIsContentScrollable(true);
      };
    }, [setIsContentScrollable]);
  

  // Utility function to generate slug
  const slugify = (text: string) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')        // Replace spaces with -
      .replace(/[^\w\-]+/g, '')    // Remove all non-word chars
      .replace(/\-\-+/g, '-')      // Replace multiple - with single -
      .replace(/^-+/, '')          // Trim - from start of text
      .replace(/-+$/, '');         // Trim - from end of text
  };


  // Set client from allClients if available
  useEffect(() => {
    if (!client && allClients.length > 0) {
      const selectedClient = allClients.find((c) => c.ClientID === clientId);

      if (selectedClient) {
        setClient(selectedClient);
      } else {
        console.error('Client not found');
        history.push('/clients');
      }
    }
  }, [client, allClients, clientId, history]);

  const handleDeleteClient = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this client?'),
        onConfirm: async () => {
          const success = await dispatch(deleteClient(client.ClientID));
          if (success) {
            history.push('/clients');
          }
          dispatch(hideModal());
        },
        onCancel: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const handleAddCaseClick = () => {
    if (client) {
      history.push({
        pathname: '/cases',
        state: {
          isAddingCase: true,
          clientId: client.ClientID,
          clientUserType: client.UserTypeName,
        },
      });
    }
  };
  

  const handleCaseClick = (caseItem: any) => {
    dispatch(fetchCaseById(caseItem.CaseID));
    const caseSlug = generateCaseSlug(caseItem);
    history.push(`/cases/${caseSlug}`, { caseItem });
  };
  

  const generateCaseSlug = (caseItem: any) => {
    const caseName = caseItem.CaseName || `Case-${caseItem.CaseID}`;
    return `${caseItem.CaseID}-${slugify(caseName)}`;
  };

  if (isLoading || !client) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 p-6">
      {/* Header */}
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-semibold">
              {clientType === 'Business'
                ? client?.BusinessName || 'No business name provided'
                : `${client?.FirstName} ${client?.LastName}`}
            </h1>
            <p className="text-sm text-gray-500">{client?.Email}</p>
          </div>
          <Button variant="primary" onClick={handleAddCaseClick}>
            {t('Add Case')}
          </Button>
        </div>
        {/* Badges */}
        <div className="mt-2 flex space-x-2">
          <Badge color="blue">{clientType}</Badge>
          <Badge
            color={
              client?.IDVerificationStatus === 'Verified'
                ? 'green'
                : client?.IDVerificationStatus === 'Pending'
                ? 'yellow'
                : 'red'
            }
          >
            {client?.IDVerificationStatus}
          </Badge>
        </div>
      </div>

      {/* Client Information */}
      <section className="mb-8">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('Client Information')}</h3>
          {/* Business Client Details */}
          {clientType === 'Business' ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Business Name')}</p>
                <p className="text-sm">{client?.BusinessName || t('N/A')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Business ID')}</p>
                <p className="text-sm">{client?.BusinessIdentityNumber || t('N/A')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Address')}</p>
                <p className="text-sm">{client?.Address || t('N/A')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Phone Number')}</p>
                <p className="text-sm">{client?.PhoneNumber || t('N/A')}</p>
              </div>
              {/* Add more business-specific details if available */}
            </div>
          ) : (
            // Individual Client Details
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">{t('First Name')}</p>
                <p className="text-sm">{client?.FirstName || t('N/A')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Last Name')}</p>
                <p className="text-sm">{client?.LastName || t('N/A')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Address')}</p>
                <p className="text-sm">{client?.Address || t('N/A')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Phone Number')}</p>
                <p className="text-sm">{client?.PhoneNumber || t('N/A')}</p>
              </div>
              {/* Add more individual-specific details if available */}
            </div>
          )}
          {/* Delete Client Button */}
          <div className="mt-6">
            <Button variant="danger" onClick={handleDeleteClient}>
              {t('Delete Client')}
            </Button>
          </div>
        </div>
      </section>

      {/* Client Cases */}
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">{t('Client Cases')}</h3>
        {clientCases && clientCases.length > 0 ? (
          <div className="space-y-4">
            {clientCases.map((clientCase: any) => (
              <ListItem
                key={clientCase.CaseID}
                title={clientCase.CaseName || 'Case'}
                subtitle={`Status: ${clientCase.case_status || 'Unknown'}`}
                details={[
                  {
                    label: 'Created At',
                    value: new Intl.DateTimeFormat('en-GB', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    }).format(new Date(clientCase.created_at)),
                  },
                ]}
                onClick={() => handleCaseClick(clientCase)}
                link={''}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center h-32">
            <p className="text-center text-gray-500">{t('No cases available for this client')}</p>
          </div>
        )}
      </section>
    </div>
  );
};

export default ClientDetails;
