import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../utils/authUtils';
import { updateAISettings, getAISettings } from '../../../store/actions/DataAction';
import { setFontSize } from '../../../store/actions/FontSizeAction';
import { setVoice } from '../../../store/actions/SpeechAction';
import { useTranslation } from 'react-i18next';
import { AISettings } from '../../../store/reducer/DataReducer';
import { useLocation } from 'react-router-dom';
import {
  fetchAIVoices,
  fetchAIJurisdictions,
  fetchAILanguages,
  fetchAIFontSizes,
} from '../../../store/actions/DropdownActions';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import { getUserID } from '../../../store/actions/ChatAction';
import { LayoutContext } from '../../../contexts/LayoutContext';

const AISettingsComponent: React.FC<{ style?: CSSProperties; backgroundColor?: string }> = ({
  style,
  backgroundColor,
}) => {
  const dispatch = useAppDispatch();
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const aiVoices = useSelector((state: RootState) => state.dropdown.aiVoices) as DropdownOption[];
  const aiJurisdictions = useSelector((state: RootState) => state.dropdown.aiJurisdictions) as DropdownOption[];
  const aiLanguages = useSelector((state: RootState) => state.dropdown.aiLanguages) as DropdownOption[];
  const fontSizes = useSelector((state: RootState) => state.dropdown.aiFontSizes) as DropdownOption[];
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedAILanguage, setSelectedAILanguage] = useState<string>('');
  const [selectedVoice, setSelectedVoiceState] = useState<string>('');
  const [selectedFontSize, setSelectedFontSizeState] = useState<string>('');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>('Norway');
  const { setIsContentScrollable } = useContext(LayoutContext);

  useEffect(() => {
    setIsContentScrollable(false);
  
    return () => {
      setIsContentScrollable(true);
    };
  }, [setIsContentScrollable]);


  useEffect(() => {
    dispatch(getAISettings());
    dispatch(fetchAIVoices());
    dispatch(fetchAIJurisdictions());
    dispatch(fetchAILanguages());
    dispatch(fetchAIFontSizes());
  }, [dispatch]);

  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedFontSizeState(aiSettings.FontSize);
      setSelectedJurisdiction(aiSettings.CountryOfJurisdiction || '');
    }
  }, [aiSettings]);  

  const handleSelectAILanguage = (selectedValue: string | string[]) => {
    const selectedOption = aiLanguages.find(option => option.value === selectedValue);
    if (selectedOption) {
      setSelectedAILanguage(selectedOption.value);
      updateAISettingsOnServer({ AILanguageResponse: selectedOption.value });
    }
  };

  const handleSelectAIVoice = (selectedValue: string | string[]) => {
    const selectedOption = aiVoices.find(option => option.value === selectedValue);
    if (selectedOption) {
      setSelectedVoiceState(selectedOption.value);
      dispatch(setVoice(selectedOption.value));
      updateAISettingsOnServer({ AIVoice: selectedOption.value });
    }
  };

  const handleSelectFontSize = (selectedValue: string | string[]) => {
    const selectedOption = fontSizes.find(option => option.value === selectedValue);
    if (selectedOption) {
      setSelectedFontSizeState(selectedOption.value);
      dispatch(setFontSize(selectedOption.value));
      updateAISettingsOnServer({ FontSize: selectedOption.value });
    }
  };

  const handleSelectJurisdiction = (selectedValue: string | string[]) => {
    const selectedOption = aiJurisdictions.find(option => option.label === selectedValue);
    if (selectedOption) {
      setSelectedJurisdiction(selectedOption.label);
      updateAISettingsOnServer({ CountryOfJurisdiction: selectedOption.label });
    }
  };

  const updateAISettingsOnServer = (updatedFields: Partial<AISettings>) => {
    const updatedAISettings: AISettings = {
      UserID: getUserID(),
      UserType: getUserType(),
      AIVoice: updatedFields.AIVoice || selectedVoice,
      AILanguageResponse: updatedFields.AILanguageResponse || selectedAILanguage,
      FontSize: updatedFields.FontSize || selectedFontSize,
      CountryOfJurisdiction: 'Norway',
      ThemeColor: 'dark', // Fixed since we're removing the theme toggle
      // Nationality: userInfo?.Nationality || '',
      FontFamily: '"Poppins", sans-serif',
    };
    dispatch(updateAISettings(updatedAISettings));
  };

  return (
    <div className='h-calc-100vh-90px'>
    <div
      className={`bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark rounded-lg w-full p-4 shadow-light-md dark:shadow-dark-md overflow-visible ${
        backgroundColor ? `bg-[${backgroundColor}]` : ''
      }`}
      style={style}
    >
      {location.pathname === '/settings/account' && (
        <h1 className="text-xl font-semibold mb-5 text-left">{t('AI Settings')}</h1>
      )}
      <div className="flex flex-col gap-4">
        {/* Jurisdiction Dropdown */}
        <div className="flex flex-row flex-wrap items-center justify-between space-y-2">
          <div className="flex flex-col gap-1">
            <label className="text-sm text-text-light dark:text-text-dark">{t('Jurisdiction')}</label>
            {location.pathname === '/settings/account' && (
              <span className="text-xs text-gray-400">{t('Select your jurisdiction.')}</span>
            )}
          </div>
          <Dropdown
            options={[{ label: 'Norway', value: 'Norway' }]}
            value={'Norway'}
            placeholder={t('Select Jurisdiction')}
            onChange={handleSelectJurisdiction}
          />
        </div>

        {/* Language Dropdown */}
        <div className="flex flex-row flex-wrap items-center justify-between space-y-2">
          <div className="flex flex-col gap-1">
            <label className="text-sm text-text-light dark:text-text-dark">{t('Language')}</label>
            {location.pathname === '/settings/account' && (
              <span className="text-xs text-gray-400">{t('Select AI conversation language.')}</span>
            )}
          </div>
          <Dropdown
            options={aiLanguages}
            value={selectedAILanguage}
            placeholder={t('Select Language')}
            onChange={handleSelectAILanguage}
          />
        </div>

        {/* Voice Dropdown */}
        <div className="flex flex-row flex-wrap items-center justify-between space-y-2">
          <div className="flex flex-col gap-1">
            <label className="text-sm text-text-light dark:text-text-dark">{t('Speaker Voice')}</label>
            {location.pathname === '/settings/account' && (
              <span className="text-xs text-gray-400">{t('Select AI Voice.')}</span>
            )}
          </div>
          <Dropdown
            options={aiVoices}
            value={selectedVoice}
            placeholder={t('Select Voice')}
            onChange={handleSelectAIVoice}
          />
        </div>

        {/* Font Size Dropdown */}
        <div className="flex flex-row flex-wrap items-center justify-between space-y-2">
          <div className="flex flex-col gap-1">
            <label className="text-sm text-text-light dark:text-text-dark">{t('Font Size')}</label>
            {location.pathname === '/settings/account' && (
              <span className="text-xs text-gray-400">{t('Select AI conversation font size.')}</span>
            )}
          </div>
          <Dropdown
            options={fontSizes}
            value={selectedFontSize}
            placeholder={t('Select Font Size')}
            onChange={handleSelectFontSize}
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default AISettingsComponent;
