import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { fetchMarketplaceCaseById } from '../../../store/actions/marketplace2/caseActions';
import ClipLoader from 'react-spinners/ClipLoader';
import BidComponent from '../../shared/TailwindComponents/BidComponent';
import Badge from '../../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';

interface RouteParams {
  caseId: string;
}

const MarketplaceCaseDetails: React.FC = () => {
  const { caseId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();

  const marketplaceCase = useSelector((state: RootState) => state.casesM.selectedMarketplaceCase);
  const isLoading = useSelector((state: RootState) => state.casesM.isLoading);
  const error = useSelector((state: RootState) => state.casesM.error);

  useEffect(() => {
    if (caseId) {
      dispatch(fetchMarketplaceCaseById(caseId));
    }
  }, [caseId, dispatch]);

  useEffect(() => {
    if (marketplaceCase) {
      setBreadcrumbs([
        { name: 'Marketplace', href: '/marketplace', current: false },
        { name: marketplaceCase.CaseType || `Case ${caseId}`, href: `/marketplace/case/${caseId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [marketplaceCase, caseId, setBreadcrumbs]);

  if (isLoading || !marketplaceCase) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const {
    CaseID,
    AnonymizedDescription,
    Budget,
    Deadline,
    Categories,
    Tags,
    CaseType,
    Progress,
  } = marketplaceCase;

  return (
    <div className="bg-white shadow-md dark:shadow-dark-md dark:bg-gray-900 rounded">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
        {/* Case Details and Bid Component Layout */}
        <div className="lg:grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-16">
          {/* Case Details */}
          <div className="lg:col-span-4">
            {/* Header with Case Type */}
            <div className="mb-6">
              <h1 className="text-3xl font-bold dark:text-gray-100 text-gray-900">{CaseType}</h1>
              {/* Badges for Budget and Deadline */}
              <div className="flex items-center mt-2">
                <Badge color="green" className="mr-2">
                  Budget: ${Budget}
                </Badge>
                <Badge color="yellow">
                  Deadline: {Deadline ? new Date(Deadline).toLocaleDateString() : 'No deadline'}
                </Badge>
              </div>
            </div>

            {/* Anonymized Description */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Case Description</h2>
              <p className="text-base dark:text-gray-200 text-gray-700">{AnonymizedDescription}</p>
            </div>

            {/* Categories */}
            {Categories && Categories.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-2">Categories</h2>
                <div className="flex flex-wrap">
                  {Categories.map((category: any) => (
                    <Badge key={category} color="blue" className="mr-2 mb-2">
                      {category}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* Tags */}
            {Tags && Tags.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Tags</h2>
                <div className="flex flex-wrap">
                  {Tags.map((tag: any) => (
                    <Badge key={tag} color="indigo" className="mr-2 mb-2">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* Progress */}
            {Progress && Progress.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Progress</h2>
                <ul className="list-disc pl-5 dark:text-gray-200 text-gray-700">
                  {Progress.map((item: any, index: any) => (
                    <li key={index}>
                      <strong>{item.step}:</strong> {item.status}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Bid Component */}
          <div className="mt-8 lg:mt-0 lg:col-span-3">
            <BidComponent
              caseId={CaseID}
              onBidSuccess={() => {
                // Handle bid success if needed
              }}
              onBidFailure={() => {
                // Handle bid failure if needed
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceCaseDetails;
