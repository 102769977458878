import React from 'react';

const Shadows: React.FC = () => {
  return (
    <section className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Shadows</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Light Mode Shadow Example */}
        <div className="p-4 bg-white dark:bg-gray-800 shadow-light rounded">
          <p>Light Shadow</p>
        </div>
        {/* Dark Mode Shadow Example */}
        <div className="p-4 bg-gray-800 dark:bg-gray-700 shadow-dark text-white rounded">
          <p>Dark Shadow</p>
        </div>
        {/* Additional Shadow Levels */}
        <div className="p-4 bg-white dark:bg-gray-800 shadow-light-md rounded">
          <p>Light Medium Shadow</p>
        </div>
        <div className="p-4 bg-gray-800 dark:bg-gray-700 shadow-dark-md text-white rounded">
          <p>Dark Medium Shadow</p>
        </div>
        <div className="p-4 bg-white dark:bg-gray-800 shadow-light-lg rounded">
          <p>Light Large Shadow</p>
        </div>
        <div className="p-4 bg-gray-800 dark:bg-gray-700 shadow-dark-lg text-white rounded">
          <p>Dark Large Shadow</p>
        </div>
        {/* Continue as needed */}
      </div>
    </section>
  );
};

export default Shadows;
