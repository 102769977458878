import React, { useState } from 'react';
import { useAppDispatch } from '../../store';
import { fetchAllClients, onboardClient } from '../../store/actions/ClientAction';
import { ClientData } from '../../store/reducer/ClientReducer';
import { useTranslation } from 'react-i18next';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';

interface AddClientFormProps {
  onClose: () => void;
}

const AddClientForm: React.FC<AddClientFormProps> = ({ onClose }) => {
  const [clientType, setClientType] = useState<'individual' | 'business'>('individual');
  const [formData, setFormData] = useState<Record<string, any>>({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let clientData: ClientData;

    if (clientType === 'individual') {
      clientData = {
        // client_type: 'individual',
        ClientID: '',
        UserTypeName: 'IndividualClient',
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        Email: formData.Email,
        PhoneNumber: formData.PhoneNumber,
        Address: formData.Address,
      };
    } else {
      clientData = {
        // client_type: 'business',
        ClientID: '',
        UserTypeName: 'BusinessAdmin',
        BusinessName: formData.CompanyName,
        FirstName: formData.ContactFirstName,
        LastName: formData.ContactLastName,
        Email: formData.ContactEmail,
        PhoneNumber: formData.ContactPhoneNumber,
        Address: formData.Address,
        BusinessID: formData.OrganizationNumber || '',
      };
    }

    const success = await dispatch(onboardClient(clientData));
    if (success) {
      await dispatch(fetchAllClients());
      onClose();
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          message: t('Client onboarded'),
          duration: 3000,
          showModal: true,
        })
      );
    } else {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Could not add a new client. Please contact support.'),
          duration: 3000,
          showModal: true,
        })
      );
    }
  };

  return (
    <div className="p-6 rounded-md">
      <div className="mb-6">
        <h3 className="text-2xl font-semibold mb-4">{t('Add New Client')}</h3>
        <div className="flex space-x-2">
          <Button
            type="button"
            variant={clientType === 'individual' ? 'primary' : 'secondary'}
            onClick={() => setClientType('individual')}
          >
            {t('Individual')}
          </Button>
          <Button
            type="button"
            variant={clientType === 'business' ? 'primary' : 'secondary'}
            onClick={() => setClientType('business')}
          >
            {t('Business')}
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {clientType === 'individual' ? (
          <>
            <Input
              type="text"
              name="FirstName"
              label={t('First Name')}
              onChange={handleInputChange}
              required
              placeholder='First Name'
            />
            <Input
              type="text"
              name="LastName"
              label={t('Last Name')}
              onChange={handleInputChange}
              required
              placeholder='Last Name'
            />
            <Input
              type="email"
              name="Email"
              label={t('Email')}
              onChange={handleInputChange}
              required
              placeholder='Email'
            />
            <Input
              type="tel"
              name="PhoneNumber"
              label={t('Phone Number')}
              onChange={handleInputChange}
              placeholder='Phone Number'
            />
            <Textarea
              id="Address"
              name="Address"
              label={t('Address')}
              onChange={handleInputChange}
              placeholder='Address'
            />
          </>
        ) : (
          <>
            <Input
              type="text"
              name="CompanyName"
              label={t('Business Name')}
              onChange={handleInputChange}
              required
              placeholder='Company Name'
            />
            <Input
              type="text"
              name="ContactFirstName"
              label={t('Contact First Name')}
              onChange={handleInputChange}
              required
              placeholder='First Name'
            />
            <Input
              type="text"
              name="ContactLastName"
              label={t('Contact Last Name')}
              onChange={handleInputChange}
              required
              placeholder='Last Name'
            />
            <Input
              type="email"
              name="ContactEmail"
              label={t('Contact Email')}
              onChange={handleInputChange}
              required
              placeholder='Email'
            />
            <Input
              type="tel"
              name="ContactPhoneNumber"
              label={t('Contact Phone Number')}
              onChange={handleInputChange}
              placeholder='Phone Number'
            />
            <Textarea
              id="Address"
              name="Address"
              label={t('Business Address')}
              onChange={handleInputChange}
              placeholder='Address'
            />
            <Input
              type="text"
              name="OrganizationNumber"
              label={t('Organization Number')}
              onChange={handleInputChange}
              placeholder='Organization Number'
            />
          </>
        )}

        <div className="flex space-x-2">
          <Button type="submit" variant="primary">
            {t('Add Client')}
          </Button>
          <Button type="button" variant="destructive" onClick={onClose}>
            {t('Cancel')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddClientForm;
