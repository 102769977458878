import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { getLawyerProfile } from '../../../store/actions/marketplace2/marketplaceActions';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../shared/TailwindComponents/Button';
import Badge from '../../shared/TailwindComponents/Badge';
import { Lawyer } from '../shared/types';
import { StarIcon } from '@heroicons/react/24/solid';
import ProposeCaseModal from '../../shared/TailwindComponents/ProposeCaseModal';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';

interface RouteParams {
  lawyerId: string;
}

const LawyerProfile: React.FC = () => {
  const { lawyerId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();

  const lawyerProfile = useSelector(
    (state: RootState) => state.marketplace.lawyerProfiles[lawyerId]
  );
  const error = useSelector((state: RootState) => state.marketplace.error);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (lawyerId) {
      dispatch(getLawyerProfile(lawyerId));
    }
  }, [dispatch, lawyerId]);

  useEffect(() => {
    if (lawyerProfile) {
      const fullName = `${lawyerProfile.FirstName} ${lawyerProfile.LastName}`;
      setBreadcrumbs([
        { name: 'Marketplace', href: '/marketplace', current: false },
        { name: fullName, href: `/marketplace/lawyer/${lawyerId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [lawyerProfile, lawyerId, setBreadcrumbs]);

  if (!lawyerProfile) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const {
    FirstName,
    LastName,
    ProfilePicture,
    RatingsAverage,
    RatingsCount,
    ExperienceYears,
    Specializations,
    Bio,
    HourlyRate,
    TermsOfServiceURL,
  } = lawyerProfile as Lawyer;

  const fullName = `${FirstName} ${LastName}`;

  return (
    <div className="bg-white">
      <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {/* Profile Header */}
        <div className="lg:grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-16">
          {/* Lawyer Details */}
          <div className="lg:col-span-4">
            <div className="flex items-center mb-6">
              {ProfilePicture ? (
                <img
                  src={ProfilePicture}
                  alt={fullName}
                  className="w-24 h-24 rounded-full object-cover mr-4"
                />
              ) : (
                <div className="w-24 h-24 rounded-full bg-secondary-900 flex items-center justify-center text-3xl text-sky-300 mr-4">
                  {FirstName[0]}
                  {LastName[0]}
                </div>
              )}
              <div>
                <h1 className="text-3xl font-bold">{fullName}</h1>
                <div className="flex items-center mt-2">
                  <div className="flex items-center mr-2">
                    <StarIcon className="text-yellow-500 mr-1 h-5 w-5" />
                    <span className="text-sm">
                      {RatingsAverage?.toFixed(1) || 'N/A'} ({RatingsCount || 0} reviews)
                    </span>
                  </div>
                  <span className="text-sm text-gray-600">
                    {ExperienceYears
                      ? `${ExperienceYears} years experience`
                      : 'Experience not specified'}
                  </span>
                </div>
              </div>
            </div>

            {/* Bio */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">About</h2>
              <p className="text-base text-gray-700">{Bio || 'No bio available'}</p>
            </div>

            {/* Specializations */}
            {Specializations && Specializations.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Specializations</h2>
                <div className="flex flex-wrap">
                  {Specializations.map((spec, index) => (
                    <Badge key={index} color="blue" className="mr-2 mb-2">
                      {spec}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* Terms of Service */}
            {TermsOfServiceURL && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Terms of Service</h2>
                <a
                  href={TermsOfServiceURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600"
                >
                  View Terms of Service
                </a>
              </div>
            )}
          </div>

          {/* Action Section */}
          <div className="mt-8 lg:mt-0 lg:col-span-3">
            <div className="space-y-6">
              {/* Hourly Rate */}
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Hourly Rate</h2>
                <p className="text-2xl font-bold text-gray-900">${HourlyRate || 'N/A'}</p>
              </div>

              {/* Propose Case Button */}
              <Button onClick={() => setIsModalOpen(true)} variant="primary" className="w-full">
                Propose a Case
              </Button>

              {/* Propose Case Modal */}
              {isModalOpen && (
                <ProposeCaseModal
                  lawyerId={lawyerId}
                  onClose={() => setIsModalOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawyerProfile;
