import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { toggleSidebar } from '../../store/actions/SidebarActions';

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const userType = getUserType();
  const isDevEnv =
    window.location.hostname.includes('dev.pons.io') ||
    window.location.hostname.includes('localhost');

    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    if (!isOpen) {
      setIsTextVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsTextVisible(true);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div
      className={clsx(
        'fixed top-0 left-0 h-full flex flex-col bg-background-light shadow-lg dark:shadow-dark-md dark:bg-background-dark text-text-light dark:text-text-dark transition-all duration-300',
        isOpen ? 'w-60' : 'w-15'
      )}
    >
      <div className={clsx("flex items-center justify-start py-4",

      )}>
        <NavLink
          exact
          to="/dashboard"
          className="flex items-center"
        >
          <i className={clsx("fa-kit dark:text-sky-400/100 text-primary-500 fa-logo text-5xl",
            !isOpen ? 'pl-0.5' : 'ml-[-3px]'
          )}></i>
          {isOpen && (
            <h1
              className={clsx(
                'text-4xl text-grey-50 font-semibold transition-opacity duration-300',
                !isTextVisible && 'opacity-0 transform -translate-x-4'
              )}
            >
              PONS
            </h1>
          )}
        </NavLink>
      </div>

      {/* Navigation Section */}
      <nav className="flex-1 px-2 space-y-2 overflow-y-auto">
        <ul className="space-y-1">
          {/* Summary */}
          <li>
            <Tooltip title={!isOpen ? t('Dashboard') : ''} placement="right">
              <NavLink
                exact
                to="/dashboard"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center' 
                )}
              >
                <i className="bi bi-grid-fill text-lg"></i>
                {isOpen && <span className="ml-3">{t('Dashboard')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          {/* Artefacts */}
          {(userType === 'LawFirmAdmin' ||
            userType === 'LawFirmEmployee' ||
            userType === 'IndependentLawyer') && (
            <li>
              <Tooltip title={!isOpen ? t('Clients') : ''} placement="right">
                <NavLink
                  to="/clients"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  <i className="fas fa-screen-users text-lg"></i>
                  {isOpen && <span className="ml-3">{t('Clients')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          )}

          {(userType === 'BusinessAdmin' || userType === 'BusinessEmployee') && (
            <li>
              <Tooltip title={!isOpen ? t('Projects') : ''} placement="right">
                <NavLink
                  to="/projects"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  <i className="fas fa-briefcase text-lg"></i>
                  {isOpen && <span className="ml-3">{t('Projects')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          )}

          <li>
            <Tooltip title={!isOpen ? t('Cases') : ''} placement="right">
              <NavLink
                to="/cases"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <i className="fas fa-book-section text-lg"></i>
                {isOpen && <span className="ml-3">{t('Cases')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          <li>
            <Tooltip title={!isOpen ? t('Files') : ''} placement="right">
              <NavLink
                to="/files"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <i className="fas fa-folder-open text-lg"></i>
                {isOpen && <span className="ml-3">{t('Files')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          {/* Tools */}
          <li>
            <Tooltip title={!isOpen ? t('Legal Services') : ''} placement="right">
              <NavLink
                to="/legal-services"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <i className="bi bi-stars text-lg"></i>
                {isOpen && <span className="ml-3">{t('Legal Services')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          <li>
            <Tooltip title={!isOpen ? t('Marketplace') : ''} placement="right">
              <NavLink
                to="/marketplace"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <i className="fas fa-scale-balanced text-lg"></i>
                {isOpen && <span className="ml-3">{t('Marketplace')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          <li>
            <Tooltip title={!isOpen ? t('Analytics') : ''} placement="right">
              <NavLink
                to="/analytics"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <i className="fas fa-chart-simple text-lg"></i>
                {isOpen && <span className="ml-4">{t('Analytics')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          {/* Admin and Finance for LawFirmAdmin */}
          {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
            <>
              <li>
                <Tooltip title={!isOpen ? t('Management') : ''} placement="right">
                  <NavLink
                    to="/management"
                    activeClassName="bg-primary-500 text-white"
                    className={clsx(
                      'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                      !isOpen && 'justify-center'
                    )}
                  >
                    <i className="fas fa-user-cog text-lg"></i>
                    {isOpen && <span className="ml-3">{t('Management')}</span>}
                  </NavLink>
                </Tooltip>
              </li>

              <li>
                <Tooltip title={!isOpen ? t('Finance') : ''} placement="right">
                  <NavLink
                    to="/finance"
                    activeClassName="bg-primary-500 text-white"
                    className={clsx(
                      'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                      !isOpen && 'justify-center'
                    )}
                  >
                    <i className="fas fa-coins text-lg"></i>
                    {isOpen && <span className="ml-3">{t('Finance')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
            </>
          )}

          {/* Design System (Development Only) */}
          {isDevEnv && (
            <li>
              <Tooltip title={!isOpen ? t('Design System') : ''} placement="right">
                <NavLink
                  to="/design-system"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  <i className="fas fa-drafting-compass text-lg"></i>
                  {isOpen && <span className="ml-3">{t('Design System')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          )}
        </ul>
      </nav>

      {/* Help & Get Started */}
      {/* <div className="p-4">
        <Tooltip title={!isOpen ? t('Help') : ''} placement="right">
          <NavLink
            to="/help"
            activeClassName="bg-primary-500 text-white"
            className={clsx(
              'flex items-center w-full p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
              !isOpen && 'justify-center'
            )}
          >
            <i className="bi bi-question-circle text-lg"></i>
            {isOpen && (
              <span className="text-nowrap ml-3">{t('Help & Get Started')}</span>
            )}
          </NavLink>
        </Tooltip>
      </div> */}

      {/* Collapse/Expand Button */}
      <div
        className="absolute bottom-12 right-0 transform -translate-y-1/2 w-1 h-12 bg-gray-900 dark:bg-gray-200 rounded hover:dark:bg-transparent hover:bg-transparent cursor-pointer flex items-center justify-center group"
        onClick={handleToggleSidebar}
      >
        <i
          className={clsx(
            'fas',
            isOpen ? 'fa-chevron-left' : 'fa-chevron-right',
            'text-gray-800 dark:text-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300'
          )}
        ></i>
      </div>
    </div>
  );
};

export default Sidebar;
