import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import './GlobalDropdown.css';
import { useTranslation } from 'react-i18next';
import { useDropdown } from './contexts/DropdownContext';
import { Tooltip } from '@mui/material';

export interface DropdownOption {
  id: string;
  label: string;
  subOptions?: DropdownOption[];
  active?: boolean;
}

interface GlobalDropdownProps {
  options: DropdownOption[];
  onSelect: (id: string) => void;
  selectedLabel: string;
  placeholder?: string;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  reverseHeader?: boolean;
  name?: string;
  iconStyle?: CSSProperties;
  isStatic?: boolean; 
  id?: string; 
  multiSelect?: boolean;
}

const GlobalDropdown: React.FC<GlobalDropdownProps> = ({
  options,
  onSelect,
  selectedLabel,
  placeholder,
  style = {},
  headerStyle = {},
  iconStyle = {},
  reverseHeader = false,
  isStatic = false, 
  id,
  multiSelect = false
}) => {
  const { openDropdownId, setOpenDropdownId } = useDropdown();
  const [isOpen, setIsOpen] = useState(false);
  const [shouldDropUp, setShouldDropUp] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const newPlaceHolder = placeholder || t('Select');
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>({});

  
  const uniqueId = React.useId();
  const dropdownId = id || uniqueId;

  const toggleFolder = (id: string) => {
    setOpenFolders(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleSelect = (option: DropdownOption) => {
    if (option.active !== false) { 
      onSelect(option.id);
      setOpenDropdownId(null); 
    }
  };

  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);

  useEffect(() => {
    if (selectedLabel) {
      const foundOption = options.find(option => option.label === selectedLabel);
      setSelectedOption(foundOption || null);
    }
  }, [selectedLabel, options]);

  useEffect(() => {
    const checkDropdownPosition = () => {
      if (dropdownRef.current && dropdownListRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const dropdownListHeight = dropdownListRef.current.offsetHeight;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        if (dropdownRect.bottom + dropdownListHeight > viewportHeight) {
          setShouldDropUp(true);
        } else {
          setShouldDropUp(false);
        }
      }
    };

    if (isOpen) {
      checkDropdownPosition();
      window.addEventListener('resize', checkDropdownPosition);
    } else {
      window.removeEventListener('resize', checkDropdownPosition);
    }

    return () => {
      window.removeEventListener('resize', checkDropdownPosition);
    };
  }, [isOpen]);

  useEffect(() => {
    if (openDropdownId !== dropdownId && isOpen) {
      setIsOpen(false);
    }
  }, [openDropdownId, dropdownId, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setOpenDropdownId(null);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setOpenDropdownId]);

  const renderOptions = (options: DropdownOption[]) => {
    return options.map(option => (
      <div key={option.id}>
        <div 
          // onClick={() => handleSelect(option)} 
          className={`global-dropdown-item ${selectedLabel === option.label ? 'selected' : ''}`}
          style={{
            color: option.active === false ? '#aaa' : undefined,
            cursor: option.active === false ? 'default' : 'pointer',
            pointerEvents: option.active === false ? 'none' : 'auto',
          }}
        >
          <span onClick={() => handleSelect(option)}>{t(option.label)}</span>
          {option.subOptions && option.subOptions.length > 0 && (
            <i
              className={`fas fa-chevron-${openFolders[option.id] ? 'down' : 'right'}`}
              style={{ zIndex: 100000000000000001 }}
              onClick={() => toggleFolder(option.id)}
            ></i>
          )}
        </div>
        {option.subOptions && openFolders[option.id] && (
          <div className="global-dropdown-suboptions">
            {renderOptions(option.subOptions)}
          </div>
        )}
      </div>
    ));
  };

  const handleHeaderClick = () => {
    if (!isStatic) {
      if (isOpen) {
        setIsOpen(false);
        setOpenDropdownId(null);
      } else {
        setIsOpen(true);
        setOpenDropdownId(dropdownId);
      }
    }
  };

  return (
    <Tooltip
    title={`${isStatic ? 'This is the only option currently available.' : ''}`}
    >
    <div  
      className={`global-dropdown ${shouldDropUp ? 'drop-up' : ''}`} 
      style={style} 
      ref={dropdownRef}
    >
      <div
        className={`global-dropdown-header ${reverseHeader ? 'reverse' : ''} ${isOpen ? 'active' : ''}`}
        style={{
          ...headerStyle,
          cursor: isStatic ? 'default' : 'pointer', 
          border: isStatic ? '1px solid #ccc' : headerStyle.border, 
          opacity: isStatic ? 0.6 : 1, 
        }}
        onClick={handleHeaderClick}  
      >
        <span style={{ fontSize: '0.8rem', color: '#fff' }}>
          {selectedOption ? t(selectedOption.label) : selectedLabel ? t(selectedLabel) : t(newPlaceHolder)}
        </span>
        <i
          className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}
          style={{
            fontSize: '14px',
            color: isStatic ? '#aaa' : '#64d2ff',  
            ...iconStyle,
          }}
        ></i>
      </div>
      {!isStatic && isOpen && (
        <div className="global-dropdown-list" ref={dropdownListRef}>
          {renderOptions(options)}
        </div>
      )}
    </div>
    </Tooltip>
  );
};

export default GlobalDropdown;
