import { useContext, useEffect } from "react";
import MessageCenter from "../components/Dashboard/AdminFirm/MessageCenter/MessageCenter";
import { LayoutContext } from "../contexts/LayoutContext";

function MessageCenterPage() {
    const { setIsContentScrollable } = useContext(LayoutContext);

    useEffect(() => {
        setIsContentScrollable(false);
    
        // Reset to default when unmounting
        return () => {
          setIsContentScrollable(true);
        };
      }, [setIsContentScrollable]);
    
    return (
        <div className="h-calc-100vh-90px">
            <MessageCenter />
        </div>
    )
}

export default MessageCenterPage;