import { AnyAction } from "redux";
import { FETCH_CATEGORIES_FAILURE, FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS } from "../types";
import { ICategory } from "../../components/Documents/types";

interface CategoryState {
    loading: boolean;
    categories: ICategory[];
    error: string;
}

const initialState: CategoryState = {
    loading: false,
    categories: [],
    error: ""
};

const categoriesReducer = (state = initialState, action: AnyAction): CategoryState => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return { ...state, loading: true };
        case FETCH_CATEGORIES_SUCCESS:
            return { ...state, loading: false, categories: action.payload, error: "" };
        case FETCH_CATEGORIES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default categoriesReducer;
