import { CLEAR_TOKEN, SET_QR_CODE, SET_TOKEN } from "../types";

interface AuthState {
  token: string | null;
  userId: string | null;
  qr: string | null;
}

const initialState: AuthState = {
  token: null,
  userId: null,
  qr: null,
};


const authReducer = (state = initialState, action: { type: string; token: string | null; userId: string | null; qr: string | null }) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        userId: action.userId
      };
    case CLEAR_TOKEN:
      return initialState;
    case SET_QR_CODE:
      return {
        ...state,
        qr: action.qr
      }
    default:
      return state;
  }
};

export default authReducer;