// Projects.tsx

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

// Other imports
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { getUserType } from '../../utils/authUtils';
import { fetchProjects } from '../../store/actions/ProjectActions';
import { BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import ProjectCreate from './ProjectCreate'; // Import ProjectCreate component

const Projects: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const isProjectsLoading = useSelector((state: RootState) => state.projects.loading);
  const allProjects = useSelector((state: RootState) => Object.values(state.projects.projects));
  const userType = getUserType();

  const [scrollTop, setScrollTop] = useState(0);
  const projectListRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddProjectForm, setShowAddProjectForm] = useState(false); // New state variable

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  // Handle scrolling for the shrinking header effect
  const handleScroll = () => {
    const scrollPosition = projectListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    const projectList = projectListRef.current;
    if (projectList) {
      projectList.addEventListener('scroll', handleScroll);
      return () => {
        projectList.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const shrinkThreshold = 150; // Adjust this value as needed
  const isShrunk = scrollTop >= shrinkThreshold;

  const handleAddProjectClick = () => {
    setShowAddProjectForm((prev) => !prev); // Toggle the form visibility
  };

  const handleProjectClick = (project: any) => {
    history.push(`/projects/${project.ProjectID}`);
  };
  

  // Filters and sort options
  const filters = [
    {
      id: 'Status',
      name: 'Project Status',
      options: [
        { value: 'Active', label: 'Active' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Approved', label: 'Approved' },
      ],
    },
  ];

  const sortOptions = [
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
    { value: 'date_newest', label: 'Newest First', current: false },
    { value: 'date_oldest', label: 'Oldest First', current: false },
  ];

  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState(sortOptions[0].value);

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  // Filter and sort projects
  const filteredProjects = allProjects.filter((project: any) => {
    let matches = true;

    // Filter by Status
    if (selectedFilters['Status'] && selectedFilters['Status'].length > 0) {
      matches = matches && selectedFilters['Status'].includes(project.Status);
    }

    // Filter by Search Query
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      const projectName = (project.ProjectName || '').toLowerCase();
      matches = matches && projectName.includes(searchLower);
    }

    return matches;
  });

  const handleProjectCreateSuccess = () => {
    setShowAddProjectForm(false); 
    dispatch(fetchProjects());    
  };
  

  // Sort projects
  const sortedProjects = filteredProjects.sort((a: any, b: any) => {
    if (sortValue === 'name_asc') {
      return (a.ProjectName || '').localeCompare(b.ProjectName || '');
    } else if (sortValue === 'name_desc') {
      return (b.ProjectName || '').localeCompare(a.ProjectName || '');
    } else if (sortValue === 'date_newest') {
      return new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime();
    } else if (sortValue === 'date_oldest') {
      return new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime();
    }
    return 0;
  });

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      {/* Header and Filter/Search Bar */}
      <div className={`transition-all duration-200 bg-background-light dark:bg-background-dark`}>
        <Header
          title={t('Projects')}
          subtitle={t('Manage your projects and track their progress.')}
          actionLabel={showAddProjectForm ? t('Cancel') : t('Add Project')}
          onActionClick={handleAddProjectClick}
        />
        {!showAddProjectForm && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
          />
        )}
      </div>

      {/* Add Project Form */}
      {showAddProjectForm && (
        <div className="px-4 py-4 overflow-y-auto">
          <ProjectCreate
            onCancel={() => setShowAddProjectForm(false)}
            onSuccess={handleProjectCreateSuccess}
          />
        </div>
      )}

      {/* Projects List */}
      {!showAddProjectForm && (
        <div className="flex-grow overflow-y-auto h-full mb-20" ref={projectListRef}>
          {isProjectsLoading ? (
            <div className="flex justify-center items-center h-64">
              <ClipLoader color="#000" loading={isProjectsLoading} size={30} />
            </div>
          ) : (
            <div className="space-y-4 px-4 py-4">
              {sortedProjects.length > 0 ? (
                sortedProjects.map((project: any) => {
                  // Generate badges
                  const badges: BadgeItem[] = [];

                  // Project Status Badge
                  badges.push({
                    label: project.Status,
                    color:
                      project.Status === 'Active'
                        ? 'green'
                        : project.Status === 'Pending'
                        ? 'yellow'
                        : 'blue',
                  });

                  // Prepare details
                  const details = [
                    {
                      label: 'Lead',
                      value: project.ProjectLead?.LeadName || 'N/A',
                    },
                    {
                      label: 'Deadline',
                      value: new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      }).format(new Date(project.EndDate)),
                    },
                  ];

                  return (
                    <ListItem
                      key={project.ProjectID}
                      title={project.ProjectName || `Project ${project.ProjectID}`}
                      subtitle={project.ProjectDescription || ''}
                      details={details}
                      badges={badges}
                      onClick={() => handleProjectClick(project)}
                      link={`/projects/${project.ProjectID}`}
                    />
                  );
                })
              ) : (
                <p className="text-center">{t('No projects available')}</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Projects;
