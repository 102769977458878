import React from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';

export type StatItem = {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: 'increase' | 'decrease';
};

type StatsProps = {
  stats: StatItem[];
  title?: string;
  className?: string;
  isShrunk?: boolean;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Stats: React.FC<StatsProps> = ({ stats, title, className, isShrunk }) => {
  return (
    <div className={className}>
      {title && (
        <h3
          className={`${
            isShrunk ? 'text-sm' : 'text-base'
          } font-semibold leading-6 text-gray-900 dark:text-white`}
        >
          {title}
        </h3>
      )}
      <dl
        className={`mt-2 grid ${
          isShrunk ? 'grid-cols-2' : 'grid-cols-1'
        } ${isShrunk ? 'gap-2' : 'divide-y divide-gray-200'} overflow-hidden rounded-lg bg-white shadow dark:bg-gray-800 dark:divide-gray-700 ${
          isShrunk ? '' : 'md:grid-cols-3 md:divide-x md:divide-y-0'
        } transition-all duration-300`}
      >
        {stats.map((item) => (
          <div
            key={item.name}
            className={`px-4 ${
              isShrunk ? 'py-2' : 'py-5 sm:p-6'
            } transition-all duration-300`}
          >
            <dt
              className={`${
                isShrunk ? 'text-sm' : 'text-base'
              } font-normal text-gray-900 dark:text-white`}
            >
              {item.name}
            </dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div
                className={`flex items-baseline ${
                  isShrunk ? 'text-xl' : 'text-2xl'
                } font-semibold text-indigo-600 dark:text-indigo-400`}
              >
                {item.stat}
                {!isShrunk && (
                  <span className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-300">
                    from {item.previousStat}
                  </span>
                )}
              </div>
              {!isShrunk && (
                <div
                  className={classNames(
                    item.changeType === 'increase'
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                      : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300',
                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon
                      aria-hidden="true"
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500 dark:text-green-300"
                    />
                  ) : (
                    <ArrowDownIcon
                      aria-hidden="true"
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500 dark:text-red-300"
                    />
                  )}
                  <span className="sr-only">
                    {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by
                  </span>
                  {item.change}
                </div>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Stats;
